import { CCPAState } from '../constants/interfaces';
import { AnyAction } from 'redux';
import { CCPA_CLEAR, CCPA_DATA_GET, CCPA_UPDATE_STEP } from '../constants/action-types';

const initialState: CCPAState = {
	ccpa: {
		name: '',
		type: '',
		status: '',
		lastAccessedStep: 5,
		website: '',
		values: [],
		alertMessage: '',
		showAlert: false,
	},
};

export const ccpa = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case CCPA_DATA_GET: {
			return {
				...state,
				ccpa: { ...state.ccpa, ...action.data },
			};
		}
		case CCPA_UPDATE_STEP: {
			return {
				...state,
				ccpa: { ...state.ccpa, ...action.data },
			};
		}
		case CCPA_CLEAR:
			return initialState;
		default:
			return state;
	}
};
