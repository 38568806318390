import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import focusTrap from 'focus-trap';

import DefaultPopover from './DefaultPopover';

class FocusablePopover extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent Popover component
     */
    anchor: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    isVisible: PropTypes.bool.isRequired,
    /**
     * Passed internally from parent DismissiblePopover
     */
    onDeactivate: PropTypes.func,
  };

  static defaultProps = {
    anchor: null,
    onDeactivate: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.getContentRef = this.getContentRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isVisible, anchor } = this.props;

    const isOpening = !prevProps.isVisible && isVisible;
    const isClosing = prevProps.isVisible && !isVisible;
    const isMoving = prevProps.anchor !== anchor && isVisible;

    if (isOpening || isMoving) {
      // focusTrap will auto-focus into the first focusable element within the popover,
      // and prevent keyboard users from accidentally tabbing outside the popover
      setTimeout(() => this.focusTrap.activate(), 100);
      // setTimeout ensures that the popover is done positioning/being shown
      // before focusing into it - otherwise, .focus() sometimes scrolls/jumps
      // to an incorrect position (happens if the page content shifted)
    }
    if (isClosing) {
      // focusTrap will attempt to auto-focus back onto item that toggled the Poppover
      this.focusTrap.deactivate();
    }
  }

  componentWillUnmount() {
    if (this.focusTrap) this.focusTrap.deactivate();
  }

  getContentRef(el) {
    this.setState({ contentRef: el });
  }

  createFocusTrap() {
    if (this.focusTrap) return; // Already initialized

    const { contentRef } = this.state;
    if (contentRef) {
      // @see https://github.com/davidtheclark/focus-trap
      this.focusTrap = focusTrap(contentRef, {
        clickOutsideDeactivates: true,
        fallbackFocus: contentRef,
        onDeactivate: this.props.onDeactivate,
      });
    }
  }

  render() {
    this.createFocusTrap();

    return (
      <DefaultPopover {...this.props} getContentRef={this.getContentRef} />
    );
  }
}

export default FocusablePopover;
