import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './PopoverSeparator.less';

const PopoverSeparator = props => {
  const { className, ...rest } = props;
  const classes = classnames('PopoverSeparator', className);

  return <hr className={classes} {...rest} />;
};

PopoverSeparator.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

PopoverSeparator.defaultProps = {
  className: '',
};

export default PopoverSeparator;
