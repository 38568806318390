import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getComponentName } from 'components/helpers';
import ScrollIndicator from 'components/ScrollIndicator';

import './Table.less';

class Table extends PureComponent {
  static propTypes = {
    /**
     * Child nodes (e.g. TableRow)
     */
    children: PropTypes.node.isRequired,

    /**
     * Renders a more condensed variant
     */
    isCondensed: PropTypes.bool,

    /**
     * Determines if table rows highlight on hover
     */
    hasHover: PropTypes.bool,

    /**
     * Should the table scroll horizontally if content overflows
     */
    canScroll: PropTypes.bool,

    /**
     * Determines whether child TableHeaders should be pinned to the top of the Table.
     * This flag requires that a maxHeight be set - headers will not simply scroll
     * with the page, but with the Table container.
     */
    hasPinnedHeader: PropTypes.bool,

    /**
     * Determines whether the first column should be pinned to the left of the Table,
     * if `canScroll` has been set and the table is wide enough to scroll horizontally.
     */
    hasPinnedFirstColumn: PropTypes.bool,

    /**
     * Sets the maximum scrollable height of the Table container.
     * Requires hasPinnedHeader to be true to be activated.
     */
    maxHeight: PropTypes.string,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    isCondensed: false,
    hasHover: false,
    canScroll: true,
    hasPinnedHeader: false,
    hasPinnedFirstColumn: false,
    maxHeight: '100vh',
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      tableHead: [],
      tableBody: [],
      tableFoot: [],
    };
  }

  static getDerivedStateFromProps(props) {
    const { children, isCondensed, hasHover } = props;
    const tableHead = [];
    const tableBody = [];
    const tableFoot = [];

    React.Children.forEach(children, (child, i) => {
      if (!React.isValidElement(child)) return;

      // Have children automatically inherit parent Table props
      const parentProps = { isCondensed, hasHover, key: child.key || i };
      const props = { ...child.props, ...parentProps };
      const childWithProps = React.cloneElement(child, props);

      const name = getComponentName(child);

      if (['TableHeader', 'DataTableHeader'].includes(name))
        tableHead.push(childWithProps);
      else if (['TableRow', 'DataTableBody'].includes(name))
        tableBody.push(childWithProps);
      else if (['TableFooter', 'DataTableFooter'].includes(name))
        tableFoot.push(childWithProps);
    });

    return { tableHead, tableBody, tableFoot };
  }

  render() {
    const {
      canScroll,
      maxHeight,
      hasPinnedHeader,
      hasPinnedFirstColumn,
      hasHover,
      isCondensed,
      className,
      ...rest
    } = this.props;
    const { tableHead, tableBody, tableFoot } = this.state;

    const classes = classnames('Table', className, {
      'Table--noScroll': !canScroll,
      'Table--pinnedFirstColumn': hasPinnedFirstColumn,
      'Table--pinnedHeader': hasPinnedHeader,
    });

    const hasHead = tableHead.length > 0;
    const hasBody = tableBody.length > 0;
    const hasFoot = tableFoot.length > 0;

    const table = (
      <table {...rest} className={classes}>
        {hasHead && <thead className="Table__head">{tableHead}</thead>}
        {hasBody && <tbody className="Table__body">{tableBody}</tbody>}
        {hasFoot && <tfoot className="Table__foot">{tableFoot}</tfoot>}
      </table>
    );

    const scrollHeight = hasPinnedHeader ? { style: { maxHeight } } : {};
    const scrollingTable = (
      <ScrollIndicator
        direction="both"
        className="ScrollWrapper--bg-white TableWrapper"
        {...scrollHeight}
      >
        {table}
      </ScrollIndicator>
    );

    return canScroll ? (
      scrollingTable
    ) : (
      <div className="TableWrapper">{table}</div>
    );
  }
}

export default Table;
