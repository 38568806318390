import React, { createRef } from 'react';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Bookmarklet.less';

const Bookmarklet = ({ text, href, className, ...rest }) => {
  const classes = classnames('Bookmarklet__wrapper', className);
  const ref = createRef();

  const onMouseDownHandler = () => {
    ref.current.href = href;
  };

  return (
    <div className={classes} {...rest}>
      <a
        ref={ref}
        href="#javascript"
        onMouseDown={onMouseDownHandler}
        draggable="true"
        className="Bookmarklet__link"
      >
        <div className="px-4">
          <Icon className="rotate-45" type="screen-full" />
        </div>
        <div className="Bookmarklet__text">{text}</div>
      </a>
    </div>
  );
};

Bookmarklet.defaultProps = {
  className: '',
};

Bookmarklet.propTypes = {
  /**
   * Text
   */
  text: PropTypes.node.isRequired,

  /**
   * Link URL or Javascript code
   */
  href: PropTypes.string.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

export default Bookmarklet;
