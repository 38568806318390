import { getComponentName, inputTypes } from 'components/helpers';

export const getFieldValue = (child, values) => {
  const componentType = getComponentName(child);

  const { name } = child.props;
  const { value, isControlled } = (values && values[name]) || {};
  if (isControlled === false) {
    return [isControlled, null, value];
  }

  const isCheckbox = ['Checkbox', 'Switch'].includes(componentType);
  const controllingProp = isCheckbox ? 'isChecked' : 'value';
  const { [controllingProp]: controlledValue } = child.props;

  return [
    isControlled || (controlledValue !== null && controlledValue !== undefined),
    controlledValue,
    value,
  ];
};

const { allInputs } = inputTypes;
export const isValidField = child => {
  return allInputs.includes(getComponentName(child));
};

export const unmapState = state => {
  return Object.entries((state && state.values) || {}).reduce(
    (acc, [key, { value }]) => {
      acc[key] = value;

      return acc;
    },
    {},
  );
};
