import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const ShopifyAPi = axios.create({
	baseURL: REACT_APP_API_URL,
	timeout: 120000,
});

export default ShopifyAPi;
