import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/Icon';
import Tag from 'components/Tag';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';

import countFilters from './countFilters';
import './AdvancedFilterGroup.less';

class AdvancedFilterGroup extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     *Reset button (works within AdvancedFilterDrawer & AdvancedFilter)
     */
    isResettable: PropTypes.bool,

    /**
     * Child nodes
     */
    children: PropTypes.node,

    /**
     * Whether or not the AdvancedFilterGroup is expanded on page load.
     * If false, the AdvancedFilterGroup will be collapsed
     */
    initiallyExpanded: PropTypes.bool,

    /**
     * AdvancedFilterGroup title
     */
    heading: PropTypes.node.isRequired,

    /**
     * Passed internally from parent AdvancedFilter component
     */
    fields: PropTypes.arrayOf(PropTypes.string),

    /**
     * Passed internally from parent AdvancedFilter component
     */
    filters: PropTypes.shape({}),

    /**
     * Prop from the Form component
     *
     * @ignore
     */
    onReset: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    children: null,
    initiallyExpanded: true,
    fields: [],
    filters: {},
    onReset: () => {},
    isResettable: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.initiallyExpanded,
      isOpenToggle: props.initiallyExpanded,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.afterTransition = this.afterTransition.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.getActiveFiltersAmount = this.getActiveFiltersAmount.bind(this);
    this.resetGroup = this.resetGroup.bind(this);
    this.content = React.createRef();
  }

  componentDidMount() {
    const content = this.content.current;
    const { initiallyExpanded } = this.props;

    if (content && !initiallyExpanded) {
      content.style.height = 0;
    }
  }

  getActiveFiltersAmount() {
    const { filters, fields } = this.props;

    const activeFilters = Object.entries(filters).filter(([key]) =>
      fields.includes(key),
    );

    return countFilters(activeFilters);
  }

  handleClick(event) {
    event.preventDefault();
    const { isOpen } = this.state;

    if (isOpen) {
      this.collapseContent();
      this.setState({ isOpenToggle: false });
    } else {
      this.setState({ isOpen: true, isOpenToggle: true }, () =>
        this.expandContent(),
      );
    }
  }

  handleKeyDown(event) {
    // This is essentially an accessibility polyfill for IE/Edge,
    // which do not currently support summary/details behavior
    if (!('open' in document.createElement('details'))) {
      if (event.which === 13 || event.which === 32) {
        event.preventDefault();
        this.handleClick(event);
      }
    }
  }

  collapseContent() {
    const content = this.content.current;

    requestAnimationFrame(() => {
      content.style.height = `${content.scrollHeight}px`;
      content.style.overflow = 'hidden';

      requestAnimationFrame(() => {
        content.style.height = 0;
      });
    });
  }

  expandContent() {
    const content = this.content.current;

    content.style.height = `${content.scrollHeight}px`;
    content.style.overflow = 'hidden'; // Prevent scrollbars during transition
  }

  afterTransition() {
    const { isOpenToggle } = this.state;
    const content = this.content.current;

    if (isOpenToggle) {
      // Remove the inline style once the height transition is done,
      // so the section height doesn't remain static if the content changes
      content.style.height = null;
      content.style.overflow = null;
    } else {
      this.setState({ isOpen: false });
    }
  }

  resetGroup() {
    const { fields, onReset } = this.props;
    onReset(fields);
  }

  renderIcon() {
    const { isOpenToggle } = this.state;
    const { className } = this.props;
    const iconClasses = classnames('AdvancedFilterGroup__icon', className, {
      'AdvancedFilterGroup__icon--open': isOpenToggle,
    });

    return <Icon type="chevron-down" className={iconClasses} />;
  }

  render() {
    const { isOpen } = this.state;
    const { className, heading, children, isResettable } = this.props;

    const amountOfFilters = this.getActiveFiltersAmount();

    const classes = classnames('AdvancedFilterGroup', className);
    const toggleClasses = classnames('AdvancedFilterGroup__toggle', className);
    const headingClasses = classnames(
      'AdvancedFilterGroup__heading',
      className,
    );

    const listClasses = classnames('AdvancedFilterGroup__list', className);

    return (
      <details className={classes} open={isOpen}>
        <summary
          className={toggleClasses}
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          role="button"
          tabIndex="0"
        >
          <div className="AdvancedFilterGroup__headingWrapper">
            <div className="AdvancedFilterGroup__headingContainer">
              {this.renderIcon()}
              <span className={headingClasses}>{heading}</span>
            </div>

            {amountOfFilters && !isOpen ? (
              <Tag isDismissible={false} isFilled>
                {`${amountOfFilters} Filters Selected`}
              </Tag>
            ) : null}
          </div>
        </summary>
        <div
          className={listClasses}
          hidden={!isOpen}
          ref={this.content}
          onTransitionEnd={this.afterTransition}
        >
          <div className="AdvancedFilterGroup__item flex flex-col">
            <div>{children}</div>
            {isResettable ? (
              <div>
                <Button
                  onClick={this.resetGroup}
                  isTertiary
                  className="AdvancedFilterGroup_ClearFilter"
                >
                  <FormattedMessage
                    defaultMessage="Clear"
                    description="AdvancedFilterGroup reset button text"
                  />
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </details>
    );
  }
}

export default AdvancedFilterGroup;
