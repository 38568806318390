import { defineMessages } from 'react-intl';

const messages = defineMessages({
  calendarLabel: {
    defaultMessage: 'Calendar',
    description: 'calender',
  },
  closeDatePicker: {
    defaultMessage: 'Close',
    description: 'close',
  },
  dateIsUnavailable: {
    defaultMessage: 'Not available. {date}',
    description: 'DatePicker unavailable date',
  },
  keyboardNavigationInstructions: {
    defaultMessage:
      'Press the down arrow key to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.',
    description: 'DatePicker keyboard instructions',
  },
  dateIsSelected: {
    defaultMessage: 'Selected. {date}',
    description: 'DatePicker selected date',
  },
  clearDate: {
    defaultMessage: 'Clear date',
    description: 'DatePicker clear date',
  },
});

const phrases = intl => ({
  calendarLabel: intl.formatMessage(messages.calendarLabel),
  closeDatePicker: intl.formatMessage(messages.closeDatePicker),
  dateIsUnavailable: ({ date }) =>
    intl.formatMessage(messages.dateIsUnavailable, { date }),
  keyboardNavigationInstructions: intl.formatMessage(
    messages.keyboardNavigationInstructions,
  ),
  dateIsSelected: ({ date }) =>
    intl.formatMessage(messages.dateIsSelected, { date }),
  clearDate: intl.formatMessage(messages.clearDate),
});

export default phrases;
