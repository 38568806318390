import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';
import { injectIntl, defineMessages } from 'react-intl';

import { typeOfInstance, getComponentName } from 'components/helpers';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';

import './CheckboxGroup.less';
import { Checkbox } from './Checkbox';

class CheckboxGroup extends PureComponent {
  static propTypes = {
    /**
     * Child Checkbox nodes
     */
    children: typeOfInstance(Checkbox).isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Renders disabled Checkboxes
     */
    isDisabled: PropTypes.bool,

    /**
     * Renders inline Checkboxes
     */
    isInline: PropTypes.bool,

    /**
     * Marks the Checkbox as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the Checkbox is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Label text for CheckboxGroup
     */
    label: PropTypes.node.isRequired,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Checkbox group name, creates a group context
     */
    name: PropTypes.string.isRequired,

    /**
     * Selected values of the group
     *
     * This array should consist of strings or numbers only
     */
    value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),

    /**
     * CheckboxGroup ID
     */
    id: PropTypes.string,

    /**
     * Handler to call when the CheckboxGroup state has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    id: '',
    className: '',
    isDisabled: false,
    isInline: false,
    isValid: false,
    hasError: false,
    helperText: '',
    hasHiddenLabel: false,
    value: undefined,
    onChange: () => {},
    isRequired: false,
    hasAsterisk: false,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
  });

  constructor(props) {
    super(props);
    this.state = { value: props.value !== undefined ? props.value : [] };

    this.setCheckboxSelection = this.setCheckboxSelection.bind(this);
    this.renderChildrenWithProps = this.renderChildrenWithProps.bind(this);
    this.id = this.props.id || `CheckboxGroup-${shortid.generate()}`;
  }

  /**
   * This allows parent components to dynamically update input content via the value="" prop
   */
  static getDerivedStateFromProps(props, state) {
    const { value } = props;

    return JSON.stringify(value) !== JSON.stringify(state.value) &&
      JSON.stringify(value) !== JSON.stringify(state.previousValue)
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  setCheckboxSelection(response) {
    const { event } = response;
    const { name, onChange } = this.props;
    const stateValues = this.state.value || [];

    if (response.isChecked) {
      const value = [...stateValues, response.value];
      this.setState({ value }, () => onChange({ value, name, event }));
    }

    if (!response.isChecked) {
      const value = stateValues.filter(el => el !== response.value);
      this.setState({ value }, () => onChange({ value, name, event }));
    }
  }

  renderChildrenWithProps() {
    const { children, name, isDisabled, isInline } = this.props;
    const { value } = this.state;

    return React.Children.map(children, child => {
      const childName = getComponentName(child);

      if (childName !== 'Checkbox') {
        return null;
      }

      const childDisabled = child.props.isDisabled;

      const checkBox = React.cloneElement(child, {
        name,
        isDisabled: childDisabled !== undefined ? childDisabled : isDisabled,
        isChecked: value && value.indexOf(child.props.value) >= 0,
        onChange: this.setCheckboxSelection,
      });

      const classes = classnames('CheckboxGroup__wrapper', {
        'CheckboxGroup__wrapper--inline': isInline,
      });

      return <div className={classes}>{checkBox}</div>;
    });
  }

  render() {
    const {
      name,
      label,
      hasHiddenLabel,
      className,
      // The following props are only destructured so they don't end up in ...rest
      children,
      isDisabled,
      isInline,
      isValid,
      hasError,
      helperText,
      onChange,
      id,
      value,
      intl,
      isRequired,
      hasAsterisk,
      ...rest
    } = this.props;
    const classes = classnames(className, 'CheckboxGroup', {
      'CheckboxGroup--error': hasError,
      'CheckboxGroup--valid': isValid,
    });
    const labelClasses = classnames('CheckboxGroup__legend', {
      'CheckboxGroup__legend--hidden': hasHiddenLabel,
      'CheckboxGroup__legend--asterisk': hasAsterisk && isRequired,
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('CheckboxGroup__icon', {
      'CheckboxGroup__icon--error': hasError,
      'CheckboxGroup__icon--valid': isValid,
    });

    return (
      <div className={classes}>
        <fieldset
          {...rest}
          id={this.id}
          name={name}
          className="CheckboxGroup__fieldset"
        >
          <legend className={labelClasses}>{label}</legend>
          {this.renderChildrenWithProps()}
        </fieldset>
        <div
          className="CheckboxGroup__helperWrapper"
          aria-live="polite"
          role="status"
        >
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText isLarge={isLarge} hasError={hasError}>
              {helperText}
            </HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(CheckboxGroup);
