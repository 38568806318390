import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { BlueprintsProvider } from '@blueprints2/core';
import Router from './components/router/Router';

const App: FunctionComponent = () => {
	return (
		<BlueprintsProvider>
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</BlueprintsProvider>
	);
};
export default App;
