import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import ControlStrip from 'components/ControlStrip';
import { Button } from 'components/Button';

import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Popover from 'components/Popover';
import PopoverApply from 'components/PopoverApply';
import { PopoverActions, PopoverAction } from 'components/PopoverActions';
import Form from 'components/Form';
import { Checkbox } from 'components/Checkbox';
import PopoverSeparator from 'components/PopoverSeparator';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';
import './DataTableHeading.less';

class DataTableHeading extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable component
     */
    header: PropTypes.node.isRequired,
    hideCog: PropTypes.bool.isRequired,
    dataExportLink: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    visibilityMapHelpers: PropTypes.shape({
      convertMapToFormData: PropTypes.func.isRequired,
      convertFormDataToMap: PropTypes.func.isRequired,
    }).isRequired,
    onColumnsToggle: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    getConfigRef: PropTypes.func.isRequired,
    withControlStrip: PropTypes.bool,
    controlStripChildren: PropTypes.node,
    controlStripProps: PropTypes.shape({}),
    controlStripActionButtons: PropTypes.node,
    /**
     * Passed from react-intl HOC
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    withControlStrip: false,
    controlStripChildren: null,
    controlStripProps: {},
    controlStripActionButtons: null,
  };

  messages = defineMessages({
    TableConfig: {
      defaultMessage: 'Table configuration',
      description: 'DataTable menu ariaLabel',
    },
    TableExport: {
      defaultMessage: 'Export table data',
      description: 'DataTable export option',
    },
    ToggleColumns: {
      defaultMessage: 'Show / hide columns',
      description: 'DataTable show/hide option',
    },
    TableReset: {
      defaultMessage: 'Reset table',
      description: 'DataTable reset option',
    },
    SelectAll: {
      defaultMessage: 'Select All',
      description: 'Select All',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      isPopoverOpen: false,
      isFlyoutOpen: false,
      resetForm: 0,
      selectAll: true,
      formValues: this.props.visibilityMapHelpers.convertMapToFormData(),
      submittedFormValues: this.props.visibilityMapHelpers.convertMapToFormData(),
    };
    this.formRef = React.createRef();

    this.onReset = this.onReset.bind(this);
    this.onColumnsToggle = this.onColumnsToggle.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.openFlyout = this.openFlyout.bind(this);
    this.closeFlyout = this.closeFlyout.bind(this);

    // Set local popover anchor ref and pass it back up to parent
    this.setToggleRef = this.setToggleRef.bind(this);
  }

  onReset() {
    this.togglePopover();
    this.props.onReset();
  }

  onColumnsToggle(event, formData) {
    const { convertFormDataToMap } = this.props.visibilityMapHelpers;
    const visibleColumns = convertFormDataToMap(formData);
    event.preventDefault();

    this.setState(({ formValues }) => ({ submittedFormValues: formValues }));
    this.togglePopover();
    this.props.onColumnsToggle(visibleColumns);
  }

  setToggleRef(el) {
    this.popoverAnchor = el;
    this.props.getConfigRef(el);
  }

  togglePopover() {
    this.setState(({ isPopoverOpen, isFlyoutOpen, resetForm }) => ({
      isPopoverOpen: !isPopoverOpen,
      isFlyoutOpen: isPopoverOpen ? false : isFlyoutOpen, // Make sure the flyout also closes when the popover closes
      resetForm: !isPopoverOpen ? resetForm + 1 : resetForm, // Force the Form state to reset on cancel/open
    }));

    setTimeout(() => {
      this.setState(({ submittedFormValues }) => ({
        selectAll: Object.values(submittedFormValues).every(
          item => item === true,
        ),
        formValues: submittedFormValues,
      }));
    }, [10]);
  }

  closePopover() {
    this.setState({ isPopoverOpen: false, isFlyoutOpen: false });
  }

  openFlyout() {
    this.setState({ isFlyoutOpen: true });
  }

  closeFlyout() {
    this.setState({ isFlyoutOpen: false });
  }

  renderExport() {
    const { dataExportLink, intl } = this.props;

    return dataExportLink ? (
      <a
        className="PopoverAction"
        href={dataExportLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {intl.formatMessage(this.messages.TableExport)}
      </a>
    ) : null;
  }

  renderColumns() {
    const { columns, intl, withControlStrip } = this.props;
    const { isFlyoutOpen, resetForm, formValues } = this.state;

    const handleChange = e => {
      const newObj = { ...formValues };

      if (typeof e.value === 'number' && e.name !== 'selectAll') {
        newObj[e.value] = e.isChecked;
      }

      if (e && e.name === 'selectAll') {
        this.setState({ selectAll: e.isChecked });
        Object.keys(newObj).forEach(index => {
          newObj[index] = e.isChecked;
        });
      }

      this.setState(prevState => ({
        ...prevState,
        selectAll: Object.values(newObj).every(item => item === true),
        formValues: newObj,
      }));
    };

    const buttonClasses = classnames('DataTableHeading__flyoutToggle', {
      'DataTableHeading__flyoutToggle--open': isFlyoutOpen,
    });
    const flyoutClasses = classnames({
      'DataTableHeading__flyout--visible': isFlyoutOpen,
      DataTableHeading__flyout: !withControlStrip,
    });

    return (
      <Fragment>
        {!withControlStrip && (
          <PopoverAction
            className={buttonClasses}
            aria-haspopup="true"
            aria-expanded={isFlyoutOpen}
            onMouseEnter={this.openFlyout}
            onMouseLeave={this.closeFlyout}
            onFocus={this.openFlyout}
            onBlur={this.closeFlyout}
          >
            {intl.formatMessage(this.messages.ToggleColumns)}
          </PopoverAction>
        )}

        <div
          className={flyoutClasses}
          onMouseEnter={this.openFlyout}
          onMouseLeave={this.closeFlyout}
          onFocus={this.openFlyout}
          onBlur={this.closeFlyout}
        >
          <Form
            className={classnames({
              'DataTableForm flex flex-col': withControlStrip,
            })}
            key={`form-${resetForm}`}
            values={formValues}
            onSubmit={this.onColumnsToggle}
          >
            <Checkbox
              className={classnames('mx-4', { 'mt-6': withControlStrip })}
              onChange={e => handleChange(e)}
              isChecked={this.state.selectAll}
              label={intl.formatMessage(this.messages.SelectAll)}
              name="selectAll"
              value="selectAll"
            />
            <PopoverSeparator />
            {formValues && (
              <div className="DataTable--checkboxes mx-4 flex flex-col">
                {columns.map((column, i) => (
                  <Checkbox
                    className="my-1"
                    onChange={e => handleChange(e)}
                    value={i}
                    isChecked={i === 0 ? true : formValues[i] === true}
                    key={`${column.header + i}`}
                    label={column.header}
                    name={String(i)}
                    isDisabled={i === 0} // Don't allow hiding the first column
                  />
                ))}
              </div>
            )}
            <PopoverSeparator />
            <PopoverApply className="mx-4 mb-4" onCancel={this.togglePopover} />
          </Form>
        </div>
      </Fragment>
    );
  }

  renderReset() {
    const { intl } = this.props;

    return (
      <PopoverAction onClick={this.onReset}>
        {intl.formatMessage(this.messages.TableReset)}
      </PopoverAction>
    );
  }

  render() {
    const {
      header,
      hideCog,
      intl,
      withControlStrip,
      controlStripChildren,
      controlStripProps,
      controlStripActionButtons,
    } = this.props;
    const { isPopoverOpen, isFlyoutOpen } = this.state;
    const popoverClasses = classnames('DataTablePopover', {
      'DataTablePopover--flyoutOpen': isFlyoutOpen,
      'DataTablePopover--controlStrip': withControlStrip,
    });

    return (
      <div className="DataTableHeading">
        {withControlStrip && (
          <ControlStrip
            {...controlStripProps}
            actionButtons={
              <Fragment>
                <HelperTooltip
                  text="Choose Columns"
                  forceHideTooltip={isPopoverOpen}
                  position="bottom"
                  withArrow={false}
                  className="bg-gray-600 py-0 text-sm"
                >
                  <Button
                    className="DataTableHeading--button"
                    onClick={this.togglePopover}
                    isTertiary
                    ref={this.setToggleRef}
                  >
                    <Icon type="view-column" />
                  </Button>
                </HelperTooltip>
                {controlStripActionButtons}
              </Fragment>
            }
          >
            {controlStripChildren}
          </ControlStrip>
        )}

        {!hideCog && !withControlStrip && (
          <button
            type="button"
            className="DataTableHeading__config"
            onClick={this.togglePopover}
            ref={this.setToggleRef}
          >
            <Icon
              type="cog"
              title={intl.formatMessage(this.messages.TableConfig)}
            />
          </button>
        )}
        <Heading level={2} className="DataTableHeading__title">
          {header}
        </Heading>

        <Popover
          className={popoverClasses}
          position={withControlStrip ? 'bottom-right' : 'bottom-left'}
          anchor={this.popoverAnchor}
          customXOffset={withControlStrip ? 12 : 0}
          isVisible={isPopoverOpen}
          hasAutoFocus
          hasAutoDismiss
          onDismiss={this.closePopover}
        >
          <PopoverActions className="DataTableHeading__popover">
            {!withControlStrip && this.renderExport()}
            {this.renderColumns()}
            {!withControlStrip && this.renderReset()}
          </PopoverActions>
        </Popover>
      </div>
    );
  }
}

export default injectIntl(DataTableHeading);
