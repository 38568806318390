import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/Icon';
import './Trend.less';

const Trend = props => {
  const { type, label } = props;
  const arrowDirection = {
    positive: 'arrow-up',
    negative: 'arrow-down',
    neutral: 'arrow-right',
  };
  const classes = classnames('Trend', `Trend--${type}`);
  return (
    <div className={classes}>
      <Icon
        type={arrowDirection[type]}
        className="Trend__arrow"
        title={arrowDirection[type]}
      />
      <div className="Trend__label">{label}</div>
    </div>
  );
};

Trend.propTypes = {
  /**
   * Sets the arrow direction and color of the component (green, red or gray)
   */
  type: PropTypes.oneOf(['positive', 'negative', 'neutral']),

  /**
   * Trend label
   */
  label: PropTypes.node.isRequired,
};

Trend.defaultProps = {
  type: 'neutral',
};

export default Trend;
