import resolveConfig from 'tailwindcss/resolveConfig';
import tailwind from 'tailwind';

/**
 * Ignoring this file for Jest's branch coverage -
 * Enzyme/JSDOM doesn't currently support window.matchMedia,
 * so it's better off being E2E than unit-tested
 */
/* istanbul ignore file */

const tw = resolveConfig(tailwind);

const isMobile = window.matchMedia
  ? !window.matchMedia(`(min-width: ${tw.theme.screens.md})`).matches
  : false;

export default isMobile;
