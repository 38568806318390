import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';

import {
  typeOfInstance,
  getComponentName,
  arraysChanged,
} from 'components/helpers';

import './MultiSelectBrickGroup.less';
import MultiSelectBrick from './MultiSelectBrick';

class MultiSelectBrickGroup extends PureComponent {
  static propTypes = {
    /**
     * Child MultiSelectBrick nodes
     */
    children: typeOfInstance(MultiSelectBrick).isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Renders disabled MultiSelectBricks
     */
    isDisabled: PropTypes.bool,

    /**
     * MultiSelectBrick group name, creates a group context
     */
    name: PropTypes.string.isRequired,

    /**
     * Selected values of the group
     *
     * This array should consist of strings or numbers only
     */
    value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),

    /**
     * MultiSelectBrickGroup ID
     */
    id: PropTypes.string,

    /**
     * Handler to call when the MultiSelectBrickGroup state has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    className: '',
    isDisabled: false,
    value: undefined,
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { value: props.value !== undefined ? props.value : [] };

    this.setMultiSelectBrickSelection = this.setMultiSelectBrickSelection.bind(
      this,
    );
    this.id = this.props.id || `MultiSelectBrickGroup-${shortid.generate()}`;
  }

  /**
   * This allows parent components to dynamically update input content via the value="" prop
   */
  static getDerivedStateFromProps(props, state) {
    const { value } = props;

    return arraysChanged(value, state.value) &&
      arraysChanged(value, state.previousValue || [])
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  setMultiSelectBrickSelection(response) {
    const { event } = response;
    const { name, onChange } = this.props;
    const stateValues = this.state.value || [];

    if (response.isChecked) {
      const value = [...stateValues, response.value];
      this.setState({ value }, () => onChange({ value, name, event }));
    }

    if (!response.isChecked) {
      const value = stateValues.filter(el => el !== response.value);
      this.setState({ value }, () => onChange({ value, name, event }));
    }
  }

  renderChildrenWithProps() {
    const { children, name, isDisabled } = this.props;
    const { value } = this.state;

    return React.Children.map(children, child => {
      const childName = getComponentName(child);

      if (childName !== 'MultiSelectBrick') {
        return null;
      }

      const childDisabled = child.props.isDisabled;

      const multiSelectBrick = React.cloneElement(child, {
        name,
        isDisabled: childDisabled !== undefined ? childDisabled : isDisabled,
        isChecked: value && value.indexOf(child.props.value) >= 0,
        onChange: this.setMultiSelectBrickSelection,
      });

      return multiSelectBrick;
    });
  }

  render() {
    const { className } = this.props;
    const classes = classnames('MultiSelectBrickGroup', className);

    return <div className={classes}>{this.renderChildrenWithProps()}</div>;
  }
}

export default MultiSelectBrickGroup;
