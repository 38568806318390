import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import Select, { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';

import InputLabel from 'components/InputLabel';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';
import './AdvancedSelect.less';

// Custom react-select components to use BP2 icons
const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon type="close" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <Icon type="close" />
    </components.ClearIndicator>
  );
};

const Menu = props => {
  const optionSelectedLength = props.getValue().length || 0;
  const { maxSelectable } = props.selectProps;
  const { maxAchievedMessage } = props.selectProps;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < maxSelectable ? (
        props.children
      ) : (
        <div>{maxAchievedMessage}</div>
      )}
    </components.Menu>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div className={classnames('truncate')}>{props.data.label}</div>
      <div
        className={classnames('text-xs', {
          'text-gray-600': !props.isDisabled,
          'text-gray-200': props.isDisabled,
        })}
      >
        {props.data.subLabel}
      </div>
    </components.Option>
  );
};

class AdvancedSelect extends React.PureComponent {
  static propTypes = {
    /**
     * Array of objects that populate the select menu options. This prop is required unless isAsync prop is set to true.
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.node,
      }),
    ),

    /**
     * AdvancedSelect name
     */
    name: PropTypes.string.isRequired,

    /**
     * AdvancedSelect ID
     */
    id: PropTypes.string,

    /**
     * Sets a value for the AdvancedSelect component. Use this to control the AdvancedSelect
     */
    selectedOption: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),

    /**
     * Adds an initial placeholder value
     */
    placeholder: PropTypes.node,

    /**
     * Adds an initial no options found message
     */
    noOptionsMessage: PropTypes.node,

    /**
     * Adds an initial loading message
     */
    loadingMessage: PropTypes.node,

    /**
     * AdvancedSelect label
     */
    label: PropTypes.node,

    /**
     * Sets a position for label
     */
    labelPosition: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Adds asterisk next to the label to indicate the AdvancedSelect is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Marks the AdvancedSelect as required
     */
    isRequired: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the AdvancedSelect is required
     */
    html5: PropTypes.bool,

    /**
     * Disables the AdvancedSelect
     */
    isDisabled: PropTypes.bool,

    /**
     * Supports multiple selected options
     */
    isMulti: PropTypes.bool,

    /**
     * Renders a smaller version of the AdvancedSelect
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger version of the AdvancedSelect
     */
    isLarge: PropTypes.bool,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Item helper text can be used to provide additional
     * text under the select items
     */
    subLabel: PropTypes.node,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Renders async paginated AdvancedSelect
     */
    isAsync: PropTypes.bool,

    /**
     * Additional data, e.g. current page number when isAsync prop is set to true.
     */
    additional: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    /**
     * Async function that take next arguments. This prop is required if isAsync prop is set to true:
     *
     * @param {string} search - Current value of search input
     * @param {Array} loadOptions - Loaded options for current search
     * @param {number} additional- Collected additional data, e.g. page number
     */
    loadOptions: PropTypes.func,

    /**
     * If present, will be applied to the width style of the element.
     * Useful for widths not covered by utility classes
     */
    fixedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Function called when the selection has changed
     *
     * @param {Object} args - {name, event}
     */
    onChange: PropTypes.func,

    /**
     * Sets option to disabled state
     *
     */
    isOptionDisabled: PropTypes.func,

    /**
     * Whether to enable search functionality
     */
    isSearchable: PropTypes.bool,

    /**
     * Closes the select menu when the user selects an option.
     */
    closeMenuOnSelect: PropTypes.bool,

    /**
     * Sets default value when AdvancedSelect component is not controlled
     */
    defaultValue: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Maximum selectable items. Optional.
     *
     */
    maxSelectable: PropTypes.number,

    /**
     * Message when maximum items achieved. Optional
     *
     */
    maxAchievedMessage: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    id: '',
    selectedOption: null,
    defaultValue: null,
    placeholder: '',
    noOptionsMessage: '',
    loadingMessage: '',
    label: '',
    hasHiddenLabel: false,
    isSearchable: true,
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    isSmall: false,
    isLarge: false,
    isValid: false,
    hasError: false,
    helperText: '',
    subLabel: null,
    fixedWidth: null,
    onChange: () => {},
    isOptionDisabled: () => {},
    labelPosition: 'top',
    isMulti: false,
    isAsync: false,
    closeMenuOnSelect: true,
    additional: {
      page: 1,
    },
    loadOptions: () => {},
    options: [],
    maxSelectable: Number.MAX_SAFE_INTEGER,
    maxAchievedMessage: 'Max limit achieved',
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.id = this.props.id || `AdvancedSelect-${shortid.generate()}`;
  }

  handleChange(event) {
    const { onChange, name } = this.props;

    this.setState({ selectedOption: event }, () => {
      onChange({ name, event });
    });
  }

  render() {
    const {
      options,
      className,
      id,
      name,
      selectedOption,
      placeholder,
      label,
      hasHiddenLabel,
      hasAsterisk,
      isRequired,
      html5,
      isDisabled,
      isSmall,
      isLarge,
      isValid,
      hasError,
      helperText,
      isMulti,
      fixedWidth,
      onChange,
      isOptionDisabled,
      labelPosition,
      noOptionsMessage,
      loadingMessage,
      isSearchable,
      defaultValue,
      isAsync,
      additional,
      loadOptions,
      closeMenuOnSelect,
      intl,
      maxSelectable,
      maxAchievedMessage,
      ...rest
    } = this.props;

    const messages = defineMessages({
      placeholder: {
        defaultMessage: 'Search',
        description: 'AdvancedSelect Search',
      },
      noOptions: {
        defaultMessage: 'No options found',
        description: 'AdvancedSelect No options found',
      },
      ariaLabel: {
        defaultMessage: 'Search',
        description: 'AdvancedSelect Search ariaLabel',
      },
      loading: {
        defaultMessage: 'Loading...',
        description: 'AdvancedSelect Loading',
      },
      validated: {
        defaultMessage: 'Validated',
        description: 'validated',
      },
      error: {
        defaultMessage: 'Error',
        description: 'error',
      },
    });

    const classes = classnames('AdvancedSelect', 'AdvancedSelect-container', {
      'AdvancedSelect--disabled': isDisabled,
      'AdvancedSelect--small': isSmall,
      'AdvancedSelect--large': isLarge,
      'AdvancedSelect--valid': isValid,
      'AdvancedSelect--error': hasError,
    });

    const wrapperClasses = classnames('AdvancedSelectWrapper', className, {
      'AdvancedSelectWrapper--small': isSmall,
      'AdvancedSelectWrapper--large': isLarge,
      'AdvancedSelectWrapper--valid': isValid,
      'AdvancedSelectWrapper--error': hasError,
      'AdvancedSelectWrapper--row': labelPosition === 'left',
      'AdvancedSelectWrapper--col': labelPosition === 'top',
      'AdvancedSelectWrapper--rowReverse': labelPosition === 'right',
      'AdvancedSelectWrapper--colReverse': labelPosition === 'bottom',
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? messages.error : messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('Select__icon', {
      'AdvancedSelect__icon--error': hasError,
      'AdvancedSelect__icon--valid': isValid,
    });

    // To prevent react-select's styles are passed to these components
    const customStyles = {
      control: () => ({ width: fixedWidth }),
      container: defaultStyle => ({ ...defaultStyle, width: fixedWidth }),
      indicatorSeparator: () => ({}),
      singleValue: () => ({}),
      multiValue: () => ({}),
      multiValueLabel: () => ({}),
      multiValueRemove: () => ({}),
      clearIndicator: () => ({}),
    };

    const Component = isAsync ? AsyncPaginate : Select;

    return (
      <div className={wrapperClasses}>
        <InputLabel
          htmlFor={this.id}
          isHidden={hasHiddenLabel}
          hasAsterisk={hasAsterisk && isRequired}
          className="AdvancedSelect__label"
        >
          {label}
        </InputLabel>

        <Component
          ref={this.props.innerRef}
          id={this.id}
          name={name}
          className={classes}
          value={this.state.selectedOption}
          classNamePrefix="AdvancedSelect"
          defaultValue={defaultValue}
          onChange={this.handleChange}
          isDisabled={isDisabled}
          aria-label={intl.formatMessage(messages.ariaLabel)}
          styles={customStyles}
          isMulti={isMulti}
          options={options}
          closeMenuOnSelect={closeMenuOnSelect}
          isOptionDisabled={isOptionDisabled}
          isSearchable={isSearchable}
          placeholder={placeholder || intl.formatMessage(messages.placeholder)}
          loadingMessage={() =>
            loadingMessage || intl.formatMessage(messages.loading)
          }
          noOptionsMessage={() =>
            noOptionsMessage || intl.formatMessage(messages.noOptions)
          }
          components={{ MultiValueRemove, ClearIndicator, Menu, Option }}
          additional={additional}
          loadOptions={loadOptions}
          maxSelectable={maxSelectable}
          maxAchievedMessage={maxAchievedMessage}
          required={isRequired && html5}
          {...rest}
        />
        <div
          className="AdvancedSelect__helperWrapper"
          aria-live="polite"
          role="status"
        >
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText isLarge={isLarge} hasError={hasError}>
              {helperText}
            </HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(AdvancedSelect);
