import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';
import { injectIntl, defineMessages } from 'react-intl';

import InputLabel from 'components/InputLabel';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';

import './Select.less';

class Select extends React.PureComponent {
  static propTypes = {
    /**
     * Child nodes, option elements expected
     */
    children: PropTypes.node.isRequired,

    /**
     * Select name
     */
    name: PropTypes.string.isRequired,

    /**
     * Select ID
     */
    id: PropTypes.string,

    /**
     * Sets a value for the Select component. Use this to control the Select
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Adds an initial placeholder value
     */
    placeholder: PropTypes.node,

    /**
     * Select label
     */
    label: PropTypes.node,

    /**
     * Sets a position for label
     */
    labelPosition: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Marks the Select as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the Select is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the Select is required
     */
    html5: PropTypes.bool,

    /**
     * Disables the Select input
     */
    isDisabled: PropTypes.bool,

    /**
     * Renders a smaller version of the Select
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger version of the Select
     */
    isLarge: PropTypes.bool,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * If present, will be applied to the width style of the element.
     * Useful for widths not covered by utility classes
     */
    fixedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Function called when the selection has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Additional classes to apply to the select
     */
    selectClassName: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Tooltip body
     */
    tooltipBody: PropTypes.node,

    /**
     * Tooltip header
     */
    tooltipHeading: PropTypes.node,

    /**
     * Use ellipsis when text overflows
     */
    truncateText: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    selectClassName: '',
    id: '',
    value: undefined,
    placeholder: '',
    label: '',
    hasHiddenLabel: false,
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    isSmall: false,
    isLarge: false,
    isValid: false,
    hasError: false,
    helperText: '',
    fixedWidth: null,
    onChange: () => {},
    labelPosition: 'top',
    tooltipBody: undefined,
    tooltipHeading: undefined,
    truncateText: false,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      value: props.value !== undefined ? props.value : '',
    };

    this.onChange = this.onChange.bind(this);
    this.id = this.props.id || `Select-${shortid.generate()}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    return value !== state.value && value !== state.previousValue
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  onChange(event) {
    event.persist();
    const { onChange, name } = this.props;
    const { value } = event.target;

    this.setState({ value }, () => {
      onChange({ name, value, event });
    });
  }

  render() {
    const {
      children,
      className,
      selectClassName,
      id,
      name,
      value,
      placeholder,
      label,
      hasHiddenLabel,
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      isSmall,
      isLarge,
      isValid,
      hasError,
      helperText,
      fixedWidth,
      onChange,
      labelPosition,
      intl,
      tooltipBody,
      tooltipHeading,
      truncateText,
      ...rest
    } = this.props;

    const classes = classnames('Select', selectClassName, {
      'Select--ellipsis': truncateText,
      'Select--disabled': isDisabled,
      'Select--small': isSmall,
      'Select--large': isLarge,
      'Select--valid': isValid,
      'Select--error': hasError,
    });

    const wrapperClasses = classnames('SelectWrapper', className, {
      'SelectWrapper--small': isSmall,
      'SelectWrapper--large': isLarge,
      'SelectWrapper--valid': isValid,
      'SelectWrapper--error': hasError,
      'SelectWrapper--row': labelPosition === 'left',
      'SelectWrapper--col': labelPosition === 'top',
      'SelectWrapper--rowReverse': labelPosition === 'right',
      'SelectWrapper--colReverse': labelPosition === 'bottom',
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('Select__icon', {
      'Select__icon--error': hasError,
      'Select__icon--valid': isValid,
    });

    return (
      <div className={wrapperClasses}>
        <InputLabel
          tooltipBody={tooltipBody}
          tooltipHeading={tooltipHeading}
          htmlFor={this.id}
          isHidden={hasHiddenLabel}
          hasAsterisk={hasAsterisk && isRequired}
          className="Select__label"
        >
          {label}
        </InputLabel>

        <select
          required={isRequired && html5}
          {...rest}
          id={this.id}
          name={name}
          value={this.state.value}
          onChange={this.onChange}
          className={classes}
          disabled={isDisabled}
          style={{ width: fixedWidth }}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <div className="Select__helperWrapper" aria-live="polite" role="status">
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText isLarge={isLarge} hasError={hasError}>
              {helperText}
            </HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(Select);
