import React from 'react';
import { Main } from '@blueprints2/core';
import './AppMain.css';

const AppMain = (props: any) => {
	return (
		<>
			<Main className='px-10 pt-6' style={{ minHeight: '25rem' }}>
				{props.children}
			</Main>
		</>
	);
};

export default AppMain;
