import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ListItem.less';

const ListItem = props => {
  const { children, className } = props;
  const classes = classnames('ListItem', className);

  return <li className={classes}>{children}</li>;
};

ListItem.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

ListItem.defaultProps = {
  className: '',
};

export default ListItem;
