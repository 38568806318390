// TODO: make enum to manage severity
const enabled = true;

function error(message?: any, ...optionalParams: any[]): void {
	if (enabled) {
		console.error(message, optionalParams);
	}
}

function log(message?: any, ...optionalParams: any[]): void {
	if (enabled) {
		console.log(message, optionalParams);
	}
}

const Logger = {
	error,
	log,
};

export { Logger };
