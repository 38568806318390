import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import InputLabel from 'components/InputLabel';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';
import DatePickerNav from './DatePickerNav';

import isMobile from './isMobile';
import phrases from './i18n/phrases';
import './DatePicker.less';

class DatePicker extends PureComponent {
  static propTypes = {
    /**
     * Date value
     *
     * Accepts either a JS Date object or a Moment object
     */
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),

    /**
     * DatePicker name/ID. Must be unique per DatePicker
     */
    name: PropTypes.string.isRequired,

    /**
     * Marks the DatePicker as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the DatePicker is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the DatePicker is required
     */
    html5: PropTypes.bool,

    /**
     * Disables the DatePicker
     */
    isDisabled: PropTypes.bool,

    /**
     * Renders a smaller DatePicker
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger DatePicker
     */
    isLarge: PropTypes.bool,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * DatePicker label
     */
    label: PropTypes.node.isRequired,

    /**
     * Placeholder text. Defaults to the current day if not specified
     */
    placeholder: PropTypes.node,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Callback to fire when the DatePicker's value changes
     *
     * @param {Date} date - A JS [Date object](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date).
     * Returns null on invalid or empty dates.
     */
    onChange: PropTypes.func,

    /**
     * Optionally restrict user-selectable dates. This expects a function that evaluates to either true or
     * false depending on the day, typically using Moment.js. See the "Advanced react-dates" section
     * below for an example.
     *
     * By default, all dates are selectable in DatePicker.
     */
    isOutsideRange: PropTypes.func,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Sets the current visible month
     *
     * Date value
     *
     * Accepts either a JS Date object or a Moment object
     */
    initialVisibleMonth: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),

    /**
     * Hide keyboard shortcuts panel
     */
    hideKeyboardShortcutsPanel: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    isOutsideRange: () => false, // Allows all dates / dates in the past to be selected
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    isSmall: false,
    isLarge: false,
    hasHiddenLabel: false,
    placeholder: undefined,
    isValid: false,
    hasError: false,
    helperText: '',
    className: '',
    initialVisibleMonth: null,
    hideKeyboardShortcutsPanel: false,
    onChange: () => {},
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
  });

  constructor(props) {
    super(props);
    this.state = {};

    moment.locale(props.intl.locale);
    this.dateFormat = 'll';
    this.placeholder = moment().format(this.dateFormat);
    this.phrases = phrases(props.intl);

    // Developer-input moment objs need to be re-localized -
    // otherwise they'll inherit from older global settings
    this.formatDateProps = value => {
      if (!value) return null;

      const date = moment(value);
      date.format(this.dateFormat);
      date.locale(props.intl.locale);

      return date;
    };

    if (props.value) this.state.date = this.formatDateProps(props.value);

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderCustomNav = this.renderCustomNav.bind(this);
    this.handleInitialVisibleMonth = this.handleInitialVisibleMonth.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value)
      this.setState({ date: this.formatDateProps(value) });
  }

  onDateChange(date) {
    const { name, onChange } = this.props;
    const value = date ? date._d : null; // eslint-disable-line no-underscore-dangle

    onChange({ name, value });
    this.setState({ date });
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  handleInitialVisibleMonth() {
    const defaultDate = this.formatDateProps(this.props.value || new Date());
    return this.props.initialVisibleMonth
      ? moment(this.props.initialVisibleMonth)
      : defaultDate;
  }

  renderCustomNav({ month, onMonthSelect, onYearSelect }) {
    return (
      <DatePickerNav
        month={month}
        onMonthSelect={onMonthSelect}
        onYearSelect={onYearSelect}
      />
    );
  }

  render() {
    const { date, focused } = this.state;
    const {
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      isSmall,
      isLarge,
      className,
      name,
      label,
      placeholder,
      isValid,
      hasError,
      helperText,
      hasHiddenLabel,
      isOutsideRange,
      initialVisibleMonth,
      hideKeyboardShortcutsPanel,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      value,
      intl,
      ...rest
    } = this.props;

    const classes = classnames('DatePicker', className, {
      'DatePicker--small': isSmall,
      'DatePicker--large': isLarge,
      'DatePicker--focused': focused,
      'DatePicker--error': hasError,
      'DatePicker--valid': isValid,
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('DatePicker__icon', {
      'DatePicker__icon--error': hasError,
      'DatePicker__icon--valid': isValid,
    });

    return (
      <div className={classes}>
        <InputLabel
          htmlFor={name}
          isHidden={hasHiddenLabel}
          hasAsterisk={hasAsterisk && isRequired}
        >
          {label}
        </InputLabel>
        <SingleDatePicker
          {...rest}
          // Required props
          id={name}
          date={date}
          onDateChange={this.onDateChange}
          focused={focused}
          onFocusChange={this.onFocusChange}
          // Presentation
          block
          withFullScreenPortal={isMobile}
          numberOfMonths={1}
          daySize={32}
          showDefaultInputIcon
          // Custom elements/navigation
          renderMonthElement={this.renderCustomNav}
          hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel || isMobile}
          // Copy/i18n
          displayFormat={this.dateFormat}
          phrases={this.phrases}
          placeholder={placeholder || this.placeholder}
          // Behavior
          required={isRequired && html5}
          disabled={isDisabled}
          isOutsideRange={isOutsideRange}
          initialVisibleMonth={this.handleInitialVisibleMonth}
        />
        <div
          className="DatePicker__helperWrapper"
          aria-live="polite"
          role="status"
        >
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText isLarge={isLarge} hasError={hasError}>
              {helperText}
            </HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(DatePicker);
