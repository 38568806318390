import React from 'react';
import TextLink from 'components/TextLink';
import PropTypes from 'prop-types';
import './AdvancedSwitcherFooter.less';

const AdvancedSwitcherFooter = React.memo(({ links }) => {
  return (
    <div className="AdvancedSwitcherFooter">
      {links.map(({ onClick, to, href, text, ...rest }) => (
        <div key={text} onClick={onClick} aria-hidden="true">
          <TextLink
            {...rest}
            data-cy="advanced-group-footer-link"
            to={to || null}
            href={href || null}
            className="AdvancedSwitcherFooter__link"
          >
            {text}
          </TextLink>
        </div>
      ))}
    </div>
  );
});

AdvancedSwitcherFooter.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
};

AdvancedSwitcherFooter.defaultProps = {
  links: [],
};

export default AdvancedSwitcherFooter;
