import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './DataTableResizer.less';

class DataTableResizer extends PureComponent {
  static propTypes = {
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    reset: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      columnWidths: [],
      isHovering: -1,
      isResizing: -1,
      fromPositionX: 0,
    };

    this.onResizeStart = this.onResizeStart.bind(this);
    this.onResizeMove = this.onResizeMove.bind(this);
    this.onResizeEnd = this.onResizeEnd.bind(this);
    this.renderResizeIndicator = this.renderResizeIndicator.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reset !== this.props.reset) {
      this.setState({ columnWidths: [] });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousemove', this.onResizeMove);
    document.body.removeEventListener('touchmove', this.onResizeMove);
    document.body.removeEventListener('mouseup', this.onResizeEnd);
    document.body.removeEventListener('touchend', this.onResizeEnd);
  }

  onResizeStart(event) {
    if (event.touches) event.preventDefault();
    const touchOption = { passive: false };

    document.body.addEventListener('mousemove', this.onResizeMove);
    document.body.addEventListener('touchmove', this.onResizeMove, touchOption);
    document.body.addEventListener('mouseup', this.onResizeEnd);
    document.body.addEventListener('touchend', this.onResizeEnd);

    const positionX = event.touches ? event.touches[0].clientX : event.clientX;
    const column = event.target.parentNode;
    const columns = column.parentNode.childNodes;
    const columnWidths = [];

    // Due to <table>'s automatic layout algorithm (@see https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout)
    // it's important that we 'snapshot' the widths of all columns to prevent unexpected jumping
    [...columns].forEach(cell => {
      const columnWidth = cell.getBoundingClientRect().width;
      columnWidths.push(columnWidth);
    });

    this.setState({
      columnWidths,
      isResizing: column.cellIndex,
      fromPositionX: positionX,
    });
  }

  onResizeMove(event) {
    const { isResizing, fromPositionX } = this.state;
    if (isResizing === -1) return;
    if (event.touches) event.preventDefault();

    const toPositionX = event.touches
      ? event.touches[0].clientX
      : event.clientX;

    const delta = toPositionX - fromPositionX;
    if (!delta) return;

    const { columnWidths } = this.state;
    const currentColumn = isResizing;
    const currentColumnWidth = columnWidths[currentColumn];
    const newColumnWidth = currentColumnWidth + delta;

    const updatedWidths = Array.from(columnWidths);
    updatedWidths[currentColumn] = newColumnWidth;

    this.setState({
      columnWidths: updatedWidths,
      fromPositionX: toPositionX,
    });
  }

  onResizeEnd() {
    document.body.removeEventListener('mousemove', this.onResizeMove);
    document.body.removeEventListener('touchmove', this.onResizeMove);
    document.body.removeEventListener('mouseup', this.onResizeEnd);
    document.body.removeEventListener('touchend', this.onResizeEnd);

    this.setState({ isResizing: -1 });
  }

  renderResizeIndicator(index) {
    const classes = classnames(
      'DataTable__columnIndicator',
      'DataTable__resizer',
      {
        'DataTable__resizer--hover': this.state.isHovering === index,
        'DataTable__resizer--active': this.state.isResizing === index,
      },
    );

    return (
      <button
        type="button"
        className={classes}
        onMouseDown={this.onResizeStart}
        onMouseEnter={() => this.setState({ isHovering: index })}
        onMouseLeave={() => this.setState({ isHovering: -1 })}
        onTouchStart={this.onResizeStart}
        // Note that DataTableResizer currently does not support VO or keyboard access.
        // This could be potentially implemented someday, e.g. by updating columnWidth when an arrow key is held down
        aria-hidden="true"
        tabIndex="-1"
      />
    );
  }

  render() {
    const { columnWidths } = this.state;

    return this.props.render({
      renderResizer: this.renderResizeIndicator,
      columnWidths,
    });
  }
}

export default DataTableResizer;
