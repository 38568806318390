import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import {
	PIXEL_VERIFICATION_CLEAR,
	PIXEL_VERIFICATION_GET,
	PIXEL_VERIFICATION_STATUS_GET,
	PIXEL_VERIFICATION_URL_GET,
} from '../constants/action-types';
import { PixelTrackingRequest } from '../constants/interfaces';

export default {
	getPixelTrackingStatus: (mid: string, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.pixelVerification.getStatus}?mid=${mid}`);
			dispatch({ type: PIXEL_VERIFICATION_STATUS_GET, data: response.data });
		} catch (err: any) {
			console.error(`Error while getting pixel verification status: ${err.response.data.message}`);
			errorCallback(err.response.status, err.response.data && err.response.data.message ? err.response.data.message : null);
		}
	},
	getUrl: (mid: string, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.pixelVerification.url}?mid=${mid}`);
			dispatch({ type: PIXEL_VERIFICATION_URL_GET, data: response.data });
		} catch (err: any) {
			console.error(`Error while getting url: ${err.response.data.message}`);
			errorCallback(err.response.status, err.response.data && err.response.data.message ? err.response.data.message : null);
		}
	},
	getPixelVerification: (mid: string, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.pixelVerification.get}?mid=${mid}`);
			dispatch({ type: PIXEL_VERIFICATION_GET, data: response.data });
		} catch (err: any) {
			console.error(`Error occurred while getting pixel verification details: ${err.response.data.message}`);
			errorCallback(err.response.status, err.response.data && err.response.data.message ? err.response.data.message : null);
		}
	},
	savePixelVerification:
		(pixelTrackingRequest: PixelTrackingRequest, submitCallback: Function, redirectCallback: Function, errorCallback: Function) =>
		async (dispatch: Dispatch) => {
			try {
				const response = await ShopifyAPi.post(API.V1.pixelVerification.create, pixelTrackingRequest);
				redirectCallback(response.status, response.data.message);
			} catch (err: any) {
				console.error(`Error while saving order number: ${err.response.data.message}`);
				submitCallback();
				errorCallback(err.response.status, err.response.data && err.response.data.message ? err.response.data.message : null);
			}
		},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: PIXEL_VERIFICATION_CLEAR }),
};
