import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import focusTrap from 'focus-trap';
import ReactResizeDetector from 'react-resize-detector';

import { createPortalContainer, createPortal } from 'components/helpers';
import './Blanket.less';

class Blanket extends PureComponent {
  static propTypes = {
    /**
     * Child text/nodes
     */
    children: PropTypes.node,

    /**
     * Sets the isVisible state from parent component
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * Function to be triggered when the blanket closes itself
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Element ID to provide top offset
     */
    topOffsetElementID: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    topOffsetElementID: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      topOffset: 0,
      isMounted: false,
    };

    this.portalNode = createPortalContainer();

    this.closeBlanket = this.closeBlanket.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.getContentRef = this.getContentRef.bind(this);
    this.focusTrap = null;
    this.createFocusTrap = this.createFocusTrap.bind(this);
  }

  componentDidMount() {
    document.body.appendChild(this.portalNode);
    document.addEventListener('keydown', this.handleEscapeKey);
    if (this.props.topOffsetElementID) this.getTopOffset();
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (!prevProps.isVisible && isVisible) {
      this.lockBackgroundScroll();
      this.setState({ isMounted: true });
    }
    if (prevProps.isVisible && !isVisible) {
      this.unlockBackgroundScroll();
      this.setState({ isMounted: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeKey);
    if (document.body.contains(this.portalNode)) {
      document.body.removeChild(this.portalNode);
    }
    this.unlockBackgroundScroll();
    this.deactivateFocus();
  }

  getTopOffset() {
    const offsetElement = document.getElementById(
      this.props.topOffsetElementID,
    );

    this.setState({
      topOffset: offsetElement ? offsetElement.clientHeight : 0,
    });
  }

  getContentRef(el) {
    this.setState({ contentRef: el });
  }

  handleEscapeKey(event) {
    if (event.keyCode === 27 && this.props.isVisible) {
      this.closeBlanket();
    }
  }

  activateFocus() {
    if (this.focusTrap) this.focusTrap.activate();
  }

  deactivateFocus() {
    if (this.focusTrap) this.focusTrap.deactivate();
  }

  createFocusTrap(el) {
    const { contentRef } = this.state;

    const createFocus = () => {
      if (!el || !contentRef) {
        this.focusTrap = null;
        return;
      }

      if (this.focusTrap) {
        return;
      } // Already initialized

      // @see https://github.com/davidtheclark/focus-trap
      this.focusTrap = focusTrap(el, {
        escapeDeactivates: false,
        clickOutsideDeactivates: true,
        fallbackFocus: contentRef,
      });

      this.activateFocus();
    };

    // focusTrap error in enzyme
    // @see https://github.com/davidtheclark/focus-trap-react/issues/24
    if (window.name === 'nodejs') {
      setTimeout(() => {
        createFocus();
      }, 10);
    } else {
      createFocus();
    }
  }

  closeBlanket() {
    this.props.onClose();
  }

  lockBackgroundScroll() {
    document.body.classList.add('Blanket--overflowHidden');
  }

  unlockBackgroundScroll() {
    document.body.classList.remove('Blanket--overflowHidden');
  }

  render() {
    const { topOffset, isMounted } = this.state;
    const { children, isVisible } = this.props;

    const topOffsetStyle = topOffset ? { style: { top: topOffset } } : {};
    const handleClose = isVisible ? this.closeBlanket : undefined;

    const transitionWrapper = (
      <CSSTransitionGroup
        transitionName={{
          enter: 'Blanket--enter',
          enterActive: 'Blanket--enterActive',
          leave: 'Blanket--leave',
          leaveActive: 'Blanket--leaveActive',
        }}
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
      >
        {isVisible ? (
          <div
            className={classnames('Blanket', {
              'Blanket--isMounted': isVisible && isMounted,
              'Blanket--underHeader': !!this.props.topOffsetElementID,
            })}
            {...topOffsetStyle}
          >
            <div
              onClick={handleClose}
              className="Blanket__Overlay"
              role="presentation"
              ref={this.createFocusTrap}
            >
              {children}
              <div
                className="focusFallback"
                ref={this.getContentRef}
                tabIndex="-1"
              />
            </div>

            <ReactResizeDetector
              handleWidth
              onResize={() => {
                if (this.props.topOffsetElementID) this.getTopOffset();
              }}
            />
          </div>
        ) : null}
      </CSSTransitionGroup>
    );

    return createPortal(transitionWrapper, this.portalNode);
  }
}

export default Blanket;
