//App Home
export const APP_HOME_RESPONSE_GET = 'APP_HOME_RESPONSE_GET';
export const APP_HOME_RESPONSE_CLEAR = 'APP_HOME_RESPONSE_CLEAR';

//App Callback
export const APP_CALLBACK_GET = 'APP_CALLBACK_GET';
export const APP_CALLBACK_CLEAR = 'APP_CALLBACK_CLEAR';

// Merchant
export const MERCHANT_GET = 'MERCHANT_GET';
export const MERCHANT_UPDATE = 'MERCHANT_UPDATE';
export const MERCHANT_CLEAR = 'MERCHANT_CLEAR';

//SPI
export const SPI_GET = 'SPI_GET';
export const SPI_CREATE = 'SPI_CREATE';
export const SPI_CLEAR = 'SPI_CLEAR';

//HOME
export const HOME_STATUSES_GET = 'HOME_STATUSES_GET';
export const HOME_PRODUCT_FEED_UPDATE = 'HOME_PRODUCT_FEED_UPDATE';
export const HOME_ORDER_CANCELLATION_UPDATE = 'HOME_ORDER_CANCELLATION_UPDATE';
export const HOME_STATUSES_CLEAR = 'HOME_STATUSES_CLEAR';

//Additional Script
export const ADDITIONAL_SCRIP_GET = 'ADDITIONAL_SCRIP_GET';
export const ADDITIONAL_SCRIP_STATUS_GET = 'ADDITIONAL_SCRIP_STATUS_GET';
export const ADDITIONAL_SCRIPT_REVIEW_STATUS = 'ADDITIONAL_SCRIPT_REVIEW_STATUS';
export const ADDITIONAL_SCRIP_CLEAR = 'ADDITIONAL_SCRIP_CLEAR';

//Shopify
export const AUTH_SHOP_ORIGIN_ADD = 'AUTH_SHOP_ORIGIN_ADD';
export const AUTH_HOST_ADD = 'AUTH_HOST_ADD';
export const AUTH_DATA_ADD = 'AUTH_DATA_ADD';
export const AUTH_CLEAR = 'AUTH_CLEAR';

//Third Party
export const THIRD_PARTY_STATUS_GET = 'THIRD_PARTY_STATUS_GET';
export const THIRD_PARTY_DETAILS_GET = 'THIRD_PARTY_DETAILS_GET';
export const THIRD_PARTY_CLEAR = 'THIRD_PARTY_CLEAR';

//CCPA
export const CCPA_DATA_GET = 'CCPA_DATA_GET';
export const CCPA_UPDATE_STEP = 'CCPA_UPDATE_STEP';
export const CCPA_SHOW_ERROR = 'CCPA_SHOW_ERROR';
export const CCPA_CLEAR = 'CCPA_CLEAR';

//Pixel Verification
export const PIXEL_VERIFICATION_STATUS_GET = 'PIXEL_VERIFICATION_STATUS_GET';
export const PIXEL_VERIFICATION_GET = 'PIXEL_VERIFICATION_GET';
export const PIXEL_VERIFICATION_URL_GET = 'PIXEL_VERIFICATION_URL_GET';
export const PIXEL_VERIFICATION_CLEAR = 'PIXEL_VERIFICATION_CLEAR';
