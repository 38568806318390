import React, { PureComponent, Fragment } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import InputLabelWithIcon from '../InputLabelWithIcon';

import './RadioButton.less';

class RadioButton extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * RadioButton label
     */
    label: PropTypes.node.isRequired,

    /**
     * Helper text that displays under the radiobutton label
     */
    helperText: PropTypes.node,

    /**
     * Change default circle for green check icon
     */
    withIcon: PropTypes.bool,

    /**
     * Renders selected/checked state
     */
    isChecked: PropTypes.bool,

    /**
     * Marks the RadioButton as required, triggering HTML5 validation in modern browsers
     */
    isRequired: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the RadioButton is required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Handler to call when the RadioButton state has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * RadioButton name
     */
    name: PropTypes.string,

    /**
     * RadioButton ID
     */
    id: PropTypes.string,

    /**
     * RadioButton value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Add border
     */
    withBorder: PropTypes.bool,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,
  };

  static defaultProps = {
    id: '',
    className: '',
    isChecked: false,
    isRequired: undefined,
    html5: undefined,
    isDisabled: undefined,
    onChange: () => {},
    helperText: '',
    withIcon: null,
    name: '',
    withBorder: false,
    isValid: false,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
  });

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.id = this.props.id || `RadioButton-${shortid.generate()}`;
  }

  handleChange(event) {
    event.persist();
    const { onChange, value, name } = this.props;

    onChange({ value, name, event });
  }

  render() {
    const {
      className,
      name,
      value,
      isChecked,
      isRequired,
      html5,
      isDisabled,
      label,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      id,
      withIcon,
      withBorder,
      helperText,
      isValid,
      intl,
      ...rest
    } = this.props;
    const classes = classnames('RadioButton', className, {
      'RadioButton--disabled': isDisabled,
      'RadioButton--checked': isChecked,
      'RadioButton--border': withBorder,
      'RadioButton--valid': isValid,
      RadioButton__iconVariant: withIcon,
    });
    const radioClasses = classnames('RadioButton__canvas', {
      RadioButton__radio: helperText,
    });

    const iconTitle = intl.formatMessage(this.messages.validated);
    const iconType = 'checkmark-solid';
    const iconClasses = classnames('RadioButton__icon', {
      'RadioButton__icon--valid': isValid,
    });

    return (
      <Fragment>
        <input
          className="RadioButtonInput"
          type="radio"
          id={this.id}
          name={name || null}
          value={value}
          checked={isChecked}
          required={isRequired && html5}
          disabled={isDisabled}
          onChange={this.handleChange}
        />
        <label {...rest} htmlFor={this.id} className={classes}>
          <svg className={radioClasses} viewBox="0 0 24 24">
            <rect
              className="RadioButton__background"
              width="100%"
              height="100%"
              rx="12"
            />
            {isChecked && withIcon ? (
              <path
                className="Radiobutton__icon"
                d="M17.1 2.9l-.2-.2c-4-3.9-10.4-3.7-14.2.2-3.8 4-3.7 10.3.2 14.1 3.9 3.7 10 3.7 13.9 0 4-3.8 4.1-10.1.3-14.1zM9 13.4L5.3 9.7l1.4-1.4L9 10.6l4.3-4.3 1.4 1.4L9 13.4z"
              />
            ) : (
              <circle className="RadioButton__circle" cx="50%" cy="50%" r="6" />
            )}
            <path
              className="RadioButton__border"
              d="M12 2C6.477 2 2 6.477 2 12 2 17.523 6.477 22 12 22 17.523 22 22 17.523 22 12 22 6.477 17.523 2 12 2Z
            M12 0C18.627 0 24 5.373 24 12 24 18.627 18.627 24 12 24 5.373 24 0 18.627 0 12 0 5.373 5.373 0 12 0Z"
            />
          </svg>
          <span className="RadioButton__label">{label}</span>
        </label>
        {isValid && (
          <InputLabelWithIcon
            iconTitle={iconTitle}
            iconType={iconType}
            helperText={helperText}
            iconClasses={iconClasses}
          />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(RadioButton);
