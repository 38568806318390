import React, { PureComponent } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  getComponentName,
  consoleWarnWithLink,
  deprecatedProp,
} from 'components/helpers';
import Blanket from 'components/Blanket';
import Icon from 'components/Icon';

import './Modal.less';

class Modal extends PureComponent {
  static propTypes = {
    /**
     * Content of the modal
     */
    children: PropTypes.node,

    /**
     * Additional classes to apply to the Modal wrapper
     */
    className: PropTypes.string,

    /**
     * Setting this prop to false will mean that the modal component will not be able to close itself.
     * If you are setting this to false you have to provide a method of closing the modal within your implementation.
     * Look at the examples below to see how to safely implement with isDismissible set to false.
     */
    isDismissible: PropTypes.bool,

    /**
     * @deprecated in `4.0.0`, use `isDismissible` instead
     */
    isDismissable: (props, deprecatedName, componentName) =>
      deprecatedProp({
        props,
        deprecatedName,
        newName: 'isDismissible',
        isRequired: false,
        versionNumber: 'v4.0.0',
        componentName,
        propType: 'boolean',
      }),

    /**
     * Sets the visibility of the modal
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * Function required for when the modal closes itself
     *
     * This is not used if `isDismissible` is false
     */
    onClose: PropTypes.func,

    /**
     * @deprecated in `4.0.0`, use `onClose` instead
     */
    modalClosed: (props, deprecatedName, componentName) =>
      deprecatedProp({
        props,
        deprecatedName,
        newName: 'onClose',
        isRequired: false,
        versionNumber: 'v4.0.0',
        componentName,
        propType: 'function',
      }),

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * When true - show the text "Close" near the close icon
     */
    closeWithText: PropTypes.bool,

    /**
     * Additional classes to the modal content
     */
    modalContentClasses: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: '',
    isDismissable: undefined,
    isDismissible: true,
    onClose: undefined,
    modalClosed: undefined,
    closeWithText: false,
    modalContentClasses: '',
  };

  messages = defineMessages({
    closeButton: {
      defaultMessage: 'Close',
      description: 'Modal close ariaLabel',
    },
  });

  componentDidMount() {
    this.checkChildren();
  }

  checkChildren() {
    let headerCount = 0;
    let bodyCount = 0;
    let footerCount = 0;

    React.Children.forEach(this.props.children, child => {
      const childName = getComponentName(child);

      if (childName === 'ModalHeader') {
        headerCount += 1;
      } else if (childName === 'ModalBody') {
        bodyCount += 1;
      } else if (childName === 'ModalFooter') {
        footerCount += 1;
      }
    });

    if (
      headerCount + bodyCount + footerCount === 0 ||
      (headerCount === 1 && bodyCount === 1 && footerCount === 1)
    ) {
      return;
    }

    if (
      headerCount + bodyCount + footerCount > 0 &&
      headerCount + bodyCount + footerCount < 3
    ) {
      consoleWarnWithLink({
        link: 'Modal',
        warning: `This modal has some, but not all of the sub-components as children. Please either use all of the sub-components (ModalHeader/ModalBody/ModalFooter) or none of the sub-components.`,
      });
    }

    if (headerCount > 1 || bodyCount > 1 || footerCount > 1) {
      consoleWarnWithLink({
        link: 'Modal',
        warning: `This modal has more than one of a type of sub-component being used. Please include one of each type (ModalHeader/ModalBody/ModalFooter) or none of the sub-components.`,
      });
    }
  }

  render() {
    const {
      className,
      children,
      intl,
      // following props for Blanket
      isVisible,
      // unused destructured props
      modalClosed: _modalClosed,
      onClose: _onClose,
      isDismissible: _isDismissible,
      isDismissable: _isDismissable,
      closeWithText,
      modalContentClasses,
      ...rest
    } = this.props;

    // support for deprecated prop names
    const isDismissible =
      this.props.isDismissable === undefined
        ? this.props.isDismissible
        : this.props.isDismissable;
    let onClose;
    if (typeof this.props.modalClosed === 'function')
      onClose = this.props.modalClosed;
    if (typeof this.props.onClose === 'function') onClose = this.props.onClose;

    const handleClose = isDismissible && onClose ? onClose : () => {};

    return (
      <Blanket
        topOffsetElementID=""
        isVisible={isVisible}
        onClose={handleClose}
      >
        <div className="ModalContainer">
          <div
            className={classnames('Modal', className)}
            onClick={event => event.stopPropagation()}
            role="presentation"
            {...rest}
          >
            <div className={classnames('Modal__content', modalContentClasses)}>
              {children}
            </div>

            {isDismissible && (
              <div className="Modal__close">
                <button
                  type="button"
                  className="Modal__close_button"
                  aria-label={intl.formatMessage(this.messages.closeButton)}
                  onClick={handleClose}
                >
                  {closeWithText &&
                    intl.formatMessage(this.messages.closeButton)}
                  <Icon type="close" className="ml-1 w-4 h-4" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Blanket>
    );
  }
}

export default injectIntl(Modal);
