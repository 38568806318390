import { Grid, GridCell, Icon, Switch, Text } from '@blueprints2/core';
import React from 'react';

interface OnOffSwitcherProps {
	value: boolean;
	isLoading: boolean;
	onChange: Function;
	mainText: any;
	additionalText?: any;
}

const OnOffSwitcher = (props: OnOffSwitcherProps) => {
	const { value, isLoading, onChange, mainText, additionalText } = props;

	return (
		<Grid>
			<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
				<>
					<Icon type='checkmark-solid' className={value ? 'w-6 h-8 text-green-500 mt-4' : 'w-6 h-8 text-gray-500 mt-4'} />
					<Text className={value ? 'text-green-500 status-text' : 'text-gray-500 status-text'}>Complete</Text>
				</>
			</GridCell>
			<GridCell key={2} width='7/12' className='mt-4 px-2' style={{ margin: 'auto' }}>
				<Text variation='strong'>{mainText}</Text>
				{!!additionalText && additionalText}
			</GridCell>
			<GridCell key={3} width='1/4' className='text-center m-auto'>
				<span style={{ display: 'flex', justifyContent: 'center' }}>
					<Text style={{ marginBottom: 0, marginRight: '-1rem' }}>{value ? 'On' : 'Off'}</Text>
					<Switch isChecked={value} value={value} onChange={() => onChange()} isDisabled={isLoading} />
				</span>
			</GridCell>
		</Grid>
	);
};

export default OnOffSwitcher;
