import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getComponentName } from 'components/helpers';
import Icon from 'components/Icon';

import './Breadcrumbs.less';

const Breadcrumbs = props => {
  const { children, className, isSmall } = props;
  const classes = classnames('Breadcrumbs', className, {
    'Breadcrumbs--small': isSmall,
  });

  const BreadcrumbSeparator = (
    <Icon type="chevron-right" className="Breadcrumbs__separator" />
  );

  const renderBreadcrumbsChildren = () => {
    const numChildren = React.Children.count(children) - 1;

    return React.Children.map(children, (child, index) => {
      const childName = getComponentName(child);
      const allowedChildren = ['Breadcrumb'];
      if (!allowedChildren.includes(childName)) return null;

      const isLastChild = index === numChildren;

      const breadcrumb = React.cloneElement(child, {
        'aria-current': isLastChild ? 'page' : null,
      });
      const separator = isLastChild ? null : BreadcrumbSeparator;

      return (
        <Fragment>
          {breadcrumb}
          {separator}
        </Fragment>
      );
    });
  };

  return <div className={classes}>{renderBreadcrumbsChildren()}</div>;
};

Breadcrumbs.propTypes = {
  /**
   * Child Breadcrumb nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Renders smaller breadcrumbs when true
   */
  isSmall: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: '',
  isSmall: false,
};

export default Breadcrumbs;
