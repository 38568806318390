import React, { memo } from 'react';
import Image from 'components/Image';
import './PromotionalBannerImage.less';
import PropTypes from 'prop-types';

const PromotionalBannerImage = memo(({ fallback, src }) => {
  return (
    <div className="PromotionalBannerImage__wrapper">
      <Image
        src={src}
        fallback={fallback}
        className="PromotionalBannerImage__content"
      />
    </div>
  );
});

PromotionalBannerImage.propTypes = {
  fallback: PropTypes.node,
  src: PropTypes.string,
};

PromotionalBannerImage.defaultProps = {
  fallback: undefined,
  src: undefined,
};
export default PromotionalBannerImage;
