import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';

import { defineMessages, injectIntl } from 'react-intl';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import InputLabel from 'components/InputLabel';
import HelperText from 'components/HelperText';

import './TextInput.less';

class TextInput extends PureComponent {
  static propTypes = {
    /**
     * Input name
     */
    name: PropTypes.string.isRequired,

    /**
     * Content of the input
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * HTML input type - e.g., text, number, search
     */
    type: PropTypes.string,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Renders a smaller TextInput
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger TextInput
     */
    isLarge: PropTypes.bool,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Marks the TextInput as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the TextInput is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the TextInput is required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * TextInput label
     */
    label: PropTypes.node.isRequired,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Placeholder text
     */
    placeholder: PropTypes.node,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Input ID
     */
    id: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * A function that should trigger an action whenever the input's
     * onChange event fires.
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Adds prefix visualization
     */
    prefix: PropTypes.string,

    /**
     * Helper that can be used to invoke action button,
     * returns current input value
     */
    buttonOnClick: PropTypes.func,

    /**
     * button icon
     */
    buttonIcon: PropTypes.string,

    /**
     * Tooltip body
     */
    tooltipBody: PropTypes.node,

    /**
     * Tooltip heading
     */
    tooltipHeading: PropTypes.node,
  };

  static defaultProps = {
    hasError: false,
    isSmall: false,
    isLarge: false,
    isValid: false,
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    hasHiddenLabel: false,
    helperText: '',
    placeholder: '',
    className: '',
    id: '',
    value: '',
    type: 'text',
    prefix: undefined,
    onChange: () => {},
    buttonOnClick: undefined,
    buttonIcon: 'refresh',
    tooltipBody: undefined,
    tooltipHeading: undefined,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
  });

  constructor(props) {
    super(props);
    this.state = {};

    this.onChange = this.onChange.bind(this);
    this.id = this.props.id || `TextInput-${shortid.generate()}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    // This allows parent components to dynamically update input content via the value="" prop
    return value !== state.value && value !== state.previousValue
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  onChange(event) {
    const { onChange, name } = this.props;
    const { value } = event.target;
    event.persist();
    this.setState({ value }, () => {
      onChange({ name, value, event });
    });
  }

  render() {
    const { value } = this.state;
    const {
      hasError,
      isSmall,
      isLarge,
      isValid,
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      hasHiddenLabel,
      label,
      helperText,
      placeholder,
      className,
      id,
      type,
      name,
      intl,
      prefix,
      buttonOnClick,
      buttonIcon,
      tooltipBody,
      tooltipHeading,
      ...rest
    } = this.props;

    const classes = classnames('TextInput__element', className, {
      'TextInput__element--error': hasError,
      'TextInput__element--valid': isValid,
      'TextInput__element--disabled': isDisabled,
      'TextInput__element--small': isSmall,
      'TextInput__element--large': isLarge,
    });

    const textInputPrefixWrapper = classnames('TextInput__prefixWrapper', {
      'TextInput__prefixWrapper--disabled': isDisabled,
    });

    const prefixClasses = classnames('TextInput__prefix', {
      'TextInput__prefix--large': isLarge,
      'TextInput__prefix--small': isSmall,
    });

    const actionButtonClasses = classnames('TextInput__actionButton', {
      'TextInput__actionButton--large': isLarge,
      'TextInput__actionButton--small': isSmall,
    });

    const textInputContainerClasses = classnames('TextInput__container', {
      'TextInput__container--large': isLarge,
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('TextInput__icon', {
      'TextInput__icon--error': hasError,
      'TextInput__icon--valid': isValid,
    });

    return (
      <div className="TextInput flex flex-col">
        <div className="InputLabel__wrapper">
          {label && (
            <InputLabel
              tooltipBody={tooltipBody}
              tooltipHeading={tooltipHeading}
              htmlFor={this.id}
              isHidden={hasHiddenLabel}
              hasAsterisk={hasAsterisk && isRequired}
            >
              {label}
            </InputLabel>
          )}
        </div>
        <div className={classes}>
          {prefix && (
            <div className={textInputPrefixWrapper}>
              <div className={prefixClasses}>
                <div>{prefix}</div>
              </div>
            </div>
          )}
          <div className="TextInput__container--base">
            <input
              className={textInputContainerClasses}
              required={isRequired && html5}
              {...rest}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={this.onChange}
              disabled={isDisabled}
              id={this.id}
              name={name}
            />
          </div>
          {buttonOnClick && (
            <Button
              className={actionButtonClasses}
              onClick={() => buttonOnClick(value)}
              isDisabled={isDisabled}
            >
              <Icon
                className="TextInput__actionButton--icon"
                type={buttonIcon}
              />
            </Button>
          )}
        </div>
        <div
          className="TextInput__helperWrapper"
          aria-live="polite"
          role="status"
        >
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText isLarge={isLarge} hasError={hasError}>
              {helperText}
            </HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(TextInput);
