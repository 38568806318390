import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import { TableHeader, TableHeaderCell } from 'components/Table';
import Popover from 'components/Popover';
import { PopoverActions, PopoverAction } from 'components/PopoverActions';

import './DataTableHeader.less';

class DataTableHeader extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable component
     */
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    visibleColumns: PropTypes.instanceOf(Map).isRequired,
    onColumnHide: PropTypes.func.isRequired,
    hideDragControls: PropTypes.bool.isRequired,
    disableHideColumns: PropTypes.bool,
    currentSort: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onColumnSort: PropTypes.func.isRequired,
    renderDragger: PropTypes.node.isRequired,
    draggedColumn: PropTypes.number.isRequired,
    draggingOver: PropTypes.number.isRequired,
    renderResizer: PropTypes.func.isRequired,
    columnWidths: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    renderCheckbox: PropTypes.func.isRequired,
    customColumnCheckbox: PropTypes.bool,
    /**
     * Passed from react-intl HOC
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    // Note: ...rest will primarily pass props inherited from Table (e.g. isCondensed, hasHover)
  };

  static defaultProps = {
    customColumnCheckbox: false,
    disableHideColumns: false,
  };

  messages = defineMessages({
    ColumnConfig: {
      defaultMessage: 'Column configuration',
      description: 'DataTable column menu ariaLabel',
    },
    SortAscending: {
      defaultMessage: 'Sort ascending',
      description: 'DataTable column sort ascending option',
    },
    SortDescending: {
      defaultMessage: 'Sort descending',
      description: 'DataTable column sort descending option',
    },
    HideColumn: {
      defaultMessage: 'Hide column',
      description: 'DataTable column hide option',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      isPopoverOpen: false,
      popoverAnchor: null,
      popoverIndex: null,
    };

    this.onPopoverDismiss = this.onPopoverDismiss.bind(this);
    this.setPopoverAnchor = this.setPopoverAnchor.bind(this);
    this.hideColumn = this.hideColumn.bind(this);
  }

  onPopoverDismiss() {
    // If we're clicking from one header button to the next, re-show the popover
    this.setState(({ isAnchorMoving }) => ({
      isPopoverOpen: isAnchorMoving,
      isAnchorMoving: false,
    }));
  }

  setPopoverAnchor(newAnchor, index) {
    this.setState(({ isPopoverOpen, popoverAnchor }) => {
      const isAnchorMoving = isPopoverOpen && newAnchor !== popoverAnchor;
      return {
        isAnchorMoving,
        isPopoverOpen: !isAnchorMoving,
        popoverAnchor: newAnchor,
        popoverIndex: index,
      };
    });
  }

  getSort(column) {
    const { currentSort } = this.props;
    const isSortedKey = column === currentSort.column;
    const isAscending = isSortedKey && currentSort.order === 'asc';
    const isDescending = isSortedKey && currentSort.order === 'desc';

    return { isSortedKey, isAscending, isDescending };
  }

  sortColumn(order) {
    const { columns } = this.props;
    const { popoverIndex } = this.state;
    this.setState({ isPopoverOpen: false });
    this.props.onColumnSort(columns[popoverIndex], order);
  }

  hideColumn() {
    const { columns } = this.props;
    const { popoverIndex } = this.state;
    this.setState({ isPopoverOpen: false });
    this.props.onColumnHide(columns[popoverIndex]);
  }

  renderColumnPopover() {
    const { columns, disableHideColumns, intl } = this.props;
    const { popoverIndex } = this.state;

    const isFirstColumn = popoverIndex === 0;
    const { isAscending, isDescending } = this.getSort(columns[popoverIndex]);
    const disableHideOption =
      columns[popoverIndex] && !!columns[popoverIndex].disableHideOption;
    const hideSort = columns[popoverIndex] && !!columns[popoverIndex].hideSort;

    return (
      <PopoverActions className="DataTable__headerPopover">
        {!hideSort && (
          <>
            <PopoverAction
              onClick={() => this.sortColumn('asc')}
              isActive={isAscending}
            >
              {intl.formatMessage(this.messages.SortAscending)}
            </PopoverAction>
            <PopoverAction
              onClick={() => this.sortColumn('desc')}
              isActive={isDescending}
            >
              {intl.formatMessage(this.messages.SortDescending)}
            </PopoverAction>
          </>
        )}
        {!isFirstColumn &&
        !(disableHideColumns || disableHideOption) && ( // Don't allow hiding the first column
            <PopoverAction onClick={this.hideColumn}>
              {intl.formatMessage(this.messages.HideColumn)}
            </PopoverAction>
          )}
      </PopoverActions>
    );
  }

  render() {
    const {
      columns,
      visibleColumns,
      hideDragControls,
      disableHideColumns,
      renderDragger,
      draggedColumn,
      draggingOver,
      renderResizer,
      columnWidths,
      renderCheckbox,
      customColumnCheckbox,
      intl,
      ...rest
    } = this.props;
    const { isPopoverOpen, popoverAnchor } = this.state;

    return (
      <Fragment>
        <TableHeader {...rest}>
          {columns.map((column, i) => {
            const { isHidden } = visibleColumns.get(column) || {};
            const { isSortedKey, isDescending } = this.getSort(column);
            const { withCustomCheckbox } = column;
            const isFirstCell = i === 0;

            const width = columnWidths[i]
              ? { minWidth: columnWidths[i] }
              : null;
            const classes = classnames({
              // Border workaround classes
              'Table__TableHeader--nextVisibleColumn':
                i === visibleColumns.get('nextVisibleColumn'),
              'Table__TableHeader--lastVisibleColumn':
                i === visibleColumns.get('lastVisibleColumn'),

              // Drag classes
              'Table__TableHeader--dragged': i === draggedColumn,
              'Table__TableHeader--draggingOver':
                i && i !== draggedColumn && i === draggingOver,
              'Table__TableHeader--draggingNextToFirst':
                isFirstCell && i + 1 === draggingOver,
            });
            const buttonClasses = classnames(
              'DataTable__headerButton DataTable__headerButton--arrow',
              {
                'DataTable__headerButton--active': isSortedKey,
                'DataTable__headerButton--arrowDescending': isDescending,
              },
            );
            const divClasses = classnames('DataTable__TableHeaderDiv');

            return (
              <TableHeaderCell
                className={classes}
                style={width}
                hidden={isHidden && !isFirstCell}
                key={`th-${column.dataKey}-${column.header}`}
              >
                <div className={divClasses}>
                  {!customColumnCheckbox && isFirstCell && renderCheckbox()}
                  {customColumnCheckbox &&
                    withCustomCheckbox &&
                    renderCheckbox()}
                  {!isFirstCell && !hideDragControls && renderDragger}

                  {column.header}
                  {!(
                    column.hideSort &&
                    (disableHideColumns || !!column.disableHideOption)
                  ) && (
                    <button
                      type="button"
                      className={buttonClasses}
                      onClick={event => this.setPopoverAnchor(event.target, i)}
                      aria-label={intl.formatMessage(
                        this.messages.ColumnConfig,
                      )}
                    />
                  )}

                  {renderResizer(i)}
                </div>
              </TableHeaderCell>
            );
          })}
        </TableHeader>
        <Popover
          className="DataTablePopover"
          position="bottom-left"
          anchor={popoverAnchor}
          isVisible={isPopoverOpen}
          hasAutoFocus
          hasAutoDismiss
          onDismiss={this.onPopoverDismiss}
        >
          {this.renderColumnPopover()}
        </Popover>
      </Fragment>
    );
  }
}

export default injectIntl(DataTableHeader);
