import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { LoadingSpinner } from 'components/Loading';
import './Button.less';
import PopoverMenu from 'components/PopoverMenu';

class Button extends PureComponent {
  static propTypes = {
    /**
     * Child text/nodes
     */
    children: PropTypes.node.isRequired,

    /**
     * Renders a smaller button
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger button
     */
    isLarge: PropTypes.bool,

    /**
     * Renders button full width
     */
    isFullWidth: PropTypes.bool,

    /**
     * Renders primary state
     */
    isPrimary: PropTypes.bool,

    /**
     * Renders secondary state
     */
    isSecondary: PropTypes.bool,

    /**
     * Renders tertiary state
     */
    isTertiary: PropTypes.bool,

    /**
     * Renders loading state
     */
    isLoading: PropTypes.bool,

    /**
     * Renders warning state
     */
    isWarning: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Renders an icon when specified by class name
     */
    icon: PropTypes.string,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Button HTML type
     */
    type: PropTypes.oneOf(['button', 'submit', 'reset']),

    /**
     * Handler to call when the button gets pressed
     */
    onClick: PropTypes.func,

    /**
     * Renders drop menu (for not tertiary buttons only)
     */
    dropMenu: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.node.isRequired,
        icon: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        hasSeparator: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
      }),
    ),
  };

  static defaultProps = {
    isPrimary: false,
    isSmall: false,
    isLarge: false,
    isFullWidth: false,
    isSecondary: false,
    isTertiary: false,
    isLoading: false,
    isWarning: false,
    isDisabled: false,
    icon: null,
    className: '',
    type: 'button',
    onClick: null,
    dropMenu: undefined,
  };

  constructor(props) {
    super(props);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.renderWithIcon = this.renderWithIcon.bind(this);
    this.anchor = React.createRef();
    this.state = {
      shouldBePressed: false,
      visible: false,
    };
    this.closePopover = this.closePopover.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  onMouseDown() {
    this.setState({ shouldBePressed: true });
  }

  onMouseLeave(event) {
    this.setState({ shouldBePressed: false });
    event.currentTarget.blur();
  }

  closePopover() {
    this.setState({ visible: false });
  }

  handleButtonClick(e) {
    const { onClick, dropMenu, isTertiary } = this.props;

    if (onClick) {
      this.props.onClick(e);
    }
    if (dropMenu && !isTertiary) {
      this.setState(prevState => ({
        ...prevState,
        visible: !prevState.visible,
      }));
    }
  }

  renderWithIcon(buttonIcon) {
    const { isLoading, isTertiary, isFullWidth } = this.props;

    if (isLoading && (buttonIcon || isTertiary || isFullWidth)) {
      return (
        <span className="Button__icon">
          <LoadingSpinner color="white" size="sm" />
        </span>
      );
    }
    if (isLoading) {
      return (
        <LoadingSpinner color="white" size="sm" className="NoIconSpinner" />
      );
    }
    return (
      <span className="Button__icon">
        <Icon type={buttonIcon} />
      </span>
    );
  }

  render() {
    const {
      children,
      isSmall,
      isLarge,
      isFullWidth,
      isPrimary,
      isSecondary,
      isTertiary,
      isLoading,
      isWarning,
      isDisabled,
      icon,
      className,
      type,
      onClick,
      dropMenu,
      ...rest
    } = this.props;

    const { visible } = this.state;

    const buttonContentClass =
      React.Children.count(children) > 1
        ? 'Button__container'
        : 'Button__label';
    const isDisabledOrLoading = isDisabled || isLoading;

    const classes = classnames('Button', className, {
      'Button--primary': isPrimary || !(isSecondary || isTertiary),
      'Button--secondary': isSecondary,
      'Button--tertiary': isTertiary,
      'Button--disabled': isDisabledOrLoading,
      'Button--loading': isLoading,
      'Button--small': isSmall,
      'Button--large': isLarge,
      'Button--fullWidth': isFullWidth,
      'Button--pressed': this.state.shouldBePressed && !isDisabledOrLoading,
      'Button--menu': dropMenu,
    });

    const popoverIconClasses = classnames('PopoverIcon', {
      PopoverIcon__up: visible,
    });

    // We handle only these two "special" scenarios
    // Otherwise (i.e: these props are not present or "false") we just render what the user requires
    const buttonIcon = isWarning ? 'exclamation-outline' : icon;
    /* eslint-disable react/button-has-type */

    return (
      <button
        {...rest}
        type={type}
        className={classes}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseLeave}
        onMouseLeave={this.onMouseLeave}
        onClick={this.handleButtonClick}
        disabled={isDisabledOrLoading}
      >
        {(buttonIcon || isLoading) && this.renderWithIcon(buttonIcon)}
        <div className={buttonContentClass}>{children}</div>
        {dropMenu && !isTertiary && (
          <div ref={this.anchor} className="PopoverWrapper">
            <Icon type="arrow-nav" className={popoverIconClasses} />
            <PopoverMenu
              visible={visible}
              close={this.closePopover}
              options={dropMenu}
              anchor={this.anchor}
            />
          </div>
        )}
      </button>
    );
    /* eslint-enable react/button-has-type */
  }
}

export default Button;
