import React from 'react';
import { Grid, GridCell, Select, TextInput } from '@blueprints2/core';
import { CMP_VALUE } from '../../../constants/constants';
import { CMPFormState, CMPValue } from '../../../constants/interfaces';

interface CMPDropdownProps {
	state: CMPFormState;
	setState: Function;
	onSelect: Function;
	values: CMPValue[];
}

const CMPDropdown = (props: CMPDropdownProps) => {
	const { state, values, setState, onSelect } = props;

	return (
		<>
			<Select
				value={state.cmpName}
				required
				name='select-cmp'
				hasHiddenLabel
				className='ml-8'
				onChange={(option: any) => {
					setState({ ...state, cmpName: option.value, cmpType: CMP_VALUE.iabFramework });
					if (option.value === '') {
						onSelect(false);
					} else if (option.value === 'cmpothers') {
						if (state.others === '') {
							onSelect(false);
						} else {
							onSelect(true);
						}
					} else {
						onSelect(true);
					}
				}}
			>
				<option value=''>Please select</option>
				{values.map((item) => {
					if (item.type === CMP_VALUE.iabFramework) {
						return (
							<option key={item.type} value={item.name}>
								{item.name}
							</option>
						);
					}
				})}
				<option value='cmpothers'>Other - Please specify</option>
			</Select>
			{state.cmpName === 'cmpothers' && (
				<Grid className='ml-8'>
					<GridCell width='inherit'>
						<TextInput
							label='Other'
							value={state.others}
							onChange={(text: any) => {
								setState({ ...state, others: text.value });
								if (text.value === '') {
									onSelect(false);
								} else {
									onSelect(true);
								}
							}}
						/>
					</GridCell>
				</Grid>
			)}
		</>
	);
};

export default CMPDropdown;
