import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { defineMessages, injectIntl } from 'react-intl';

import Icon from 'components/Icon';

import './TextLink.less';

const TextLink = props => {
  const {
    children,
    href,
    to,
    newWindow,
    hideNewWindowIcon,
    className,
    intl,
    ...rest
  } = props;
  const classes = classnames('TextLink', className);

  const messages = defineMessages({
    title: {
      defaultMessage: 'Opens in new window',
      description: 'TextLink new window icon label',
    },
  });

  const isNew = newWindow && !to;
  const newWindowProps = isNew ? { target: '_blank', rel: 'noopener' } : {};
  const newWindowIcon =
    isNew && !hideNewWindowIcon ? (
      <Icon
        type="external-link"
        className="TextLink__icon"
        title={intl.formatMessage(messages.title)}
      />
    ) : null;

  const LinkComponent = to ? Link : 'a';
  const linkProps = to ? { to } : { href, ...newWindowProps };

  return (
    <LinkComponent {...linkProps} className={classes} {...rest}>
      {newWindowIcon}
      {children}
    </LinkComponent>
  );
};

TextLink.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Prop from react-intl HOC
   *
   * @ignore
   */
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

  /**
   * React Router link location
   *
   * - Either **href** or **to** should be provided
   *
   * @see https://reacttraining.com/react-router/web/api/Link
   */

  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),

  /**
   * Link URL
   *
   * - Either **href** or **to** should be provided
   */
  href: PropTypes.string,

  /**
   * Opens the link in a new window and shows a new window icon
   */
  newWindow: PropTypes.bool,

  /**
   * Hides icon displayed when used with newWindow prop
   */
  hideNewWindowIcon: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

TextLink.defaultProps = {
  href: '',
  to: '',
  newWindow: false,
  hideNewWindowIcon: false,
  className: '',
};

export default injectIntl(TextLink);
