import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Blanket from 'components/Blanket';
import AdvancedSearchBar from 'components/AdvancedSearchBar';
import './SearchModal.less';

class SearchModal extends PureComponent {
  static propTypes = {
    /**
     * Sets visibility of the Modal
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * Function to be triggered when the modal closes itself
     */
    onClose: PropTypes.func.isRequired,

    /**
     * AdvancedSearchBar props.
     *
     * @see AdvancedSearchBar.jsx for prop details
     */
    searchBar: PropTypes.shape({
      onSearch: PropTypes.func.isRequired,
      options: PropTypes.node,
      inputName: PropTypes.string,
      inputID: PropTypes.string,
      selectName: PropTypes.string,
      selectID: PropTypes.string,
      inputPlaceholder: PropTypes.node,
      selectPlaceholder: PropTypes.node,
      selectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textValue: PropTypes.string,
      isInputDisabled: PropTypes.bool,
      isSelectDisabled: PropTypes.bool,
    }).isRequired,

    /**
     * Sets focus
     */
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    autoFocus: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: props.isVisible,
    };

    this.handleOpenKey = this.handleOpenKey.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleOpenKey);
  }

  componentDidUpdate(_prevProps, { isVisible: prevStateV }) {
    const {
      props: { isVisible: nextPropV },
      state: { isVisible: nextStateV },
    } = this;

    // If state has been updated then assume it's the source of truth.
    if (prevStateV !== nextStateV) {
      return;
    }

    if (nextPropV !== nextStateV) {
      this.setState({ isVisible: nextPropV });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleOpenKey);
  }

  handleOpenKey(event) {
    const { isVisible } = this.state;

    const forwardSlash = 191;

    if (
      (event.ctrlKey || event.metaKey) &&
      event.keyCode === forwardSlash &&
      !isVisible
    ) {
      this.setState({ isVisible: true });
    }
  }

  handleClose() {
    this.setState({ isVisible: false });
    this.props.onClose();
  }

  render() {
    const {
      searchBar: { options, onSearch, ...restSearch },
      autoFocus,
    } = this.props;

    const { isVisible } = this.state;
    const searchOptions =
      options && options.props && options.props.children
        ? options.props.children
        : undefined;

    return (
      <Blanket
        topOffsetElementID=""
        isVisible={isVisible}
        onClose={this.handleClose}
      >
        <div className="SearchModal">
          <div
            className="SearchBarContainer"
            onClick={event => event.stopPropagation()}
            role="presentation"
          >
            <AdvancedSearchBar
              onSearch={searchTerm => {
                onSearch(searchTerm);
                this.handleClose();
              }}
              autoFocus={autoFocus}
              {...restSearch}
            >
              {searchOptions}
            </AdvancedSearchBar>
          </div>
        </div>
      </Blanket>
    );
  }
}

export default SearchModal;
