import PropTypes from 'prop-types';
import React from 'react';

const BadgeRecommends = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <svg width="255px" height="30px" viewBox="0 0 255.0 30.0">
        <defs>
          <clipPath id="i0">
            <path d="M240,0 C248.284271,2.7666336e-14 255,6.71572875 255,15 C255,23.2842712 248.284271,30 240,30 L15,30 C6.71572875,30 1.01453063e-15,23.2842712 0,15 C-1.01453063e-15,6.71572875 6.71572875,1.52179594e-15 15,0 L240,0 Z" />
          </clipPath>
          <radialGradient
            id="i1"
            cx="0px"
            cy="30px"
            r="255.674357px"
            gradientTransform="translate(-3.505237427021916 0.20548187031371512) rotate(-6.7098368077569335)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#635CBF" offset="0%" />
            <stop stopColor="#8A229C" offset="48.6592061%" />
            <stop stopColor="#D15783" offset="99.5777027%" />
          </radialGradient>
          <clipPath id="i2">
            <path d="M34.181,0 L2.723,2.723 L0,0 L34.181,0 Z" />
          </clipPath>
          <clipPath id="i3">
            <path d="M4.214,0 C4.993,0 5.563,0.235 6.197,0.639 L6.197,0.228 L8.302,0.228 L8.302,9.503 L6.197,9.503 L6.197,9.091 C5.563,9.496 4.993,9.73 4.214,9.73 C1.819,9.73 0,7.548 0,4.866 C0,2.184 1.819,0 4.214,0 Z M4.214,2.235 C3.033,2.235 2.169,3.398 2.169,4.866 C2.169,6.334 3.033,7.497 4.214,7.497 C5.396,7.497 6.229,6.334 6.229,4.866 C6.229,3.398 5.396,2.235 4.214,2.235 Z" />
          </clipPath>
          <clipPath id="i4">
            <path d="M2.105,0 L2.105,5.448 C2.105,6.47 2.807,7.334 3.829,7.334 C4.852,7.334 5.554,6.47 5.554,5.448 L5.554,0 L7.659,0 L7.659,9.275 L5.554,9.275 L5.554,8.93 C5.554,8.93 4.8,9.503 3.765,9.503 C1.529,9.503 0,7.684 0,5.448 L0,0 L2.105,0 Z" />
          </clipPath>
          <clipPath id="i5">
            <path d="M3.894,0 C6.13,0 7.659,1.819 7.659,4.055 L7.659,9.503 L5.554,9.503 L5.554,4.055 C5.554,3.033 4.852,2.169 3.83,2.169 C2.807,2.169 2.105,3.033 2.105,4.055 L2.105,9.503 L0,9.503 L0,0.228 L2.105,0.228 L2.105,0.573 C2.105,0.573 2.859,0 3.894,0 Z" />
          </clipPath>
          <clipPath id="i6">
            <path d="M9.2106,0 L9.2106,11.586 L0,11.586 L0,0 L9.2106,0 Z" />
          </clipPath>
          <clipPath id="i7">
            <path d="M3.639,0 C5.843,0 7.635,1.79 7.635,3.995 C7.635,5.337 6.969,6.522 5.953,7.25 L9.211,11.586 L6.455,11.586 L3.759,7.993 L2.202,7.993 L2.202,11.586 L0,11.586 L0,0 Z M3.639,2.2 L2.202,2.2 L2.202,5.791 L3.639,5.791 C4.627,5.791 5.436,4.988 5.436,3.995 C5.436,3.004 4.627,2.2 3.639,2.2 Z" />
          </clipPath>
          <clipPath id="i8">
            <path d="M3.219,0 L3.219,2.312 L5.019,2.312 L5.019,4.513 L3.219,4.513 L3.219,8.482 C3.219,9.357 3.867,9.62 4.211,9.62 C4.487,9.62 4.726,9.52 4.915,9.392 L6.302,11.239 C5.64,11.631 4.828,11.822 4.243,11.822 C2.674,11.822 1.113,10.585 1.114,8.514 L1.114,4.513 L0,4.513 L0,2.312 L1.114,2.312 L1.114,0 L3.219,0 Z" />
          </clipPath>
          <clipPath id="i9">
            <path d="M8.222,0 L8.222,12.3519 L0,12.3519 L0,0 L8.222,0 Z" />
          </clipPath>
          <clipPath id="i10">
            <path d="M2.168,0 L2.168,6.308 L4.693,3.077 L7.644,3.077 L4.033,7.425 L8.222,12.352 L5.272,12.352 L2.168,8.542 L2.168,12.352 L0,12.352 L0,0 L2.168,0 Z" />
          </clipPath>
          <clipPath id="i11">
            <path d="M4.184,0 C6.58,0 8.734,2.191 8.215,5.522 L2.151,5.521 C2.428,7.641 4.824,8.716 6.262,6.597 L8.122,7.671 C6.926,9.366 5.504,9.747 4.394,9.747 C2.194,9.747 0,7.746 0,4.873 C0,2.141 1.748,0 4.184,0 Z M6.022,3.676 C5.612,1.379 2.674,1.504 2.231,3.676 Z" />
          </clipPath>
          <clipPath id="i12">
            <path d="M5.745,0 L9.834,11.007 L8.333,11.007 L7.35,8.264 L2.398,8.264 L1.432,11.007 L0,11.007 L4.089,0 L5.745,0 Z M4.865,1.311 L2.864,6.953 L6.884,6.953 L4.865,1.311 Z" />
          </clipPath>
          <clipPath id="i13">
            <path d="M8.195,0 L8.195,12.249 L7.005,12.249 L6.85,10.593 C6.585,11.145 6.188,11.588 5.659,11.921 C5.13,12.255 4.532,12.422 3.865,12.422 C3.141,12.422 2.485,12.226 1.898,11.835 C1.312,11.444 0.849,10.904 0.509,10.213 C0.17,9.523 0,8.736 0,7.85 C0,6.953 0.17,6.162 0.509,5.478 C0.849,4.793 1.312,4.256 1.898,3.865 C2.485,3.474 3.141,3.278 3.865,3.278 C4.532,3.278 5.125,3.442 5.642,3.77 C6.16,4.097 6.55,4.538 6.815,5.09 L6.815,0 L8.195,0 Z M4.124,4.624 C3.606,4.624 3.143,4.759 2.735,5.029 C2.327,5.3 2.005,5.676 1.769,6.159 C1.533,6.642 1.415,7.206 1.415,7.85 C1.415,8.482 1.533,9.043 1.769,9.532 C2.005,10.021 2.327,10.401 2.735,10.67 C3.143,10.941 3.606,11.076 4.124,11.076 C4.641,11.076 5.104,10.941 5.513,10.67 C5.921,10.401 6.243,10.021 6.479,9.532 C6.714,9.043 6.833,8.482 6.833,7.85 C6.833,7.206 6.714,6.642 6.479,6.159 C6.243,5.676 5.921,5.3 5.513,5.029 C5.104,4.759 4.641,4.624 4.124,4.624 Z" />
          </clipPath>
          <clipPath id="i14">
            <path d="M1.553,0 L4.245,7.281 L6.918,0 L8.368,0 L4.986,8.799 L3.382,8.799 L0,0 L1.553,0 Z" />
          </clipPath>
          <clipPath id="i15">
            <path d="M3.951,0 C4.721,0 5.4,0.198 5.987,0.595 C6.573,0.992 7.033,1.535 7.367,2.226 C7.7,2.916 7.867,3.715 7.867,4.624 L7.867,4.969 L1.415,4.969 C1.472,5.935 1.754,6.668 2.26,7.168 C2.766,7.668 3.427,7.919 4.244,7.919 C5.291,7.919 6.165,7.395 6.867,6.349 L7.764,7.349 C7.361,7.936 6.841,8.393 6.202,8.721 C5.564,9.049 4.877,9.213 4.141,9.213 C3.301,9.213 2.571,9.02 1.95,8.635 C1.329,8.25 0.848,7.712 0.509,7.022 C0.17,6.332 0,5.532 0,4.624 C0,3.715 0.17,2.916 0.509,2.226 C0.848,1.535 1.314,0.992 1.907,0.595 C2.499,0.198 3.18,0 3.951,0 Z M3.951,1.294 C3.272,1.294 2.714,1.516 2.277,1.958 C1.84,2.401 1.564,3.019 1.45,3.813 L6.435,3.813 C6.32,3.019 6.047,2.401 5.616,1.958 C5.184,1.516 4.63,1.294 3.951,1.294 Z" />
          </clipPath>
          <clipPath id="i16">
            <path d="M3.744,0 C4.319,0 4.836,0.19 5.296,0.569 L4.813,1.949 C4.422,1.616 4.014,1.449 3.588,1.449 C3.174,1.449 2.8,1.584 2.467,1.855 C2.133,2.125 1.869,2.49 1.674,2.95 C1.478,3.41 1.38,3.928 1.38,4.503 L1.38,8.971 L0,8.971 L0,0.172 L1.19,0.172 L1.346,1.846 C1.575,1.283 1.904,0.834 2.329,0.5 C2.755,0.167 3.226,0 3.744,0 Z" />
          </clipPath>
          <clipPath id="i17">
            <path d="M2.915,0 L2.915,2.277 L5.986,2.277 L5.986,3.554 L2.915,3.554 L2.915,8.212 C2.915,9.339 3.346,9.902 4.209,9.902 C4.473,9.902 4.723,9.854 4.959,9.756 C5.195,9.658 5.457,9.5 5.744,9.281 L6.141,10.627 C5.578,11.041 4.893,11.248 4.088,11.248 C2.386,11.248 1.535,10.259 1.535,8.281 L1.535,3.554 L0,3.554 L0,2.277 L1.535,2.277 L1.535,0 L2.915,0 Z" />
          </clipPath>
          <clipPath id="i18">
            <path d="M227.339,0 L227.339,16.813 L0,16.813 L0,0 L227.339,0 Z" />
          </clipPath>
          <clipPath id="i19">
            <path d="M1.691,3.329 L1.691,12.128 L0.311,12.128 L0.311,3.329 L1.691,3.329 Z M1.001,0 C1.288,0 1.527,0.101 1.717,0.302 C1.906,0.503 2.001,0.736 2.001,1 C2.001,1.265 1.906,1.498 1.717,1.699 C1.527,1.901 1.288,2.001 1.001,2.001 C0.713,2.001 0.475,1.901 0.285,1.699 C0.095,1.498 0,1.265 0,1 C0,0.736 0.095,0.503 0.285,0.302 C0.475,0.101 0.713,0 1.001,0 Z" />
          </clipPath>
          <clipPath id="i20">
            <path d="M3.45,0 C4.071,0 4.649,0.13 5.184,0.388 C5.719,0.647 6.136,1.013 6.435,1.484 L5.573,2.519 C5.262,2.082 4.943,1.769 4.615,1.579 C4.287,1.389 3.899,1.294 3.45,1.294 C2.991,1.294 2.611,1.404 2.312,1.622 C2.013,1.841 1.863,2.128 1.863,2.485 C1.863,2.807 1.978,3.065 2.208,3.261 C2.438,3.457 2.829,3.629 3.382,3.779 L4.348,4.037 C5.095,4.244 5.673,4.569 6.082,5.012 C6.489,5.455 6.694,5.987 6.694,6.608 C6.694,7.379 6.406,8.005 5.831,8.489 C5.256,8.972 4.491,9.213 3.537,9.213 C2.812,9.213 2.145,9.058 1.536,8.747 C0.926,8.437 0.414,7.988 0,7.402 L0.897,6.383 C1.553,7.407 2.427,7.919 3.519,7.919 C4.083,7.919 4.52,7.813 4.831,7.6 C5.141,7.387 5.296,7.085 5.296,6.694 C5.296,6.004 4.79,5.532 3.778,5.279 L2.76,5.021 C2.047,4.837 1.489,4.529 1.087,4.098 C0.684,3.666 0.483,3.152 0.483,2.554 C0.483,2.059 0.612,1.619 0.871,1.234 C1.13,0.848 1.484,0.547 1.932,0.328 C2.381,0.11 2.887,0 3.45,0 Z" />
          </clipPath>
          <clipPath id="i21">
            <path d="M1.691,3.329 L1.691,12.128 L0.311,12.128 L0.311,3.329 L1.691,3.329 Z M1.001,0 C1.288,0 1.527,0.101 1.717,0.302 C1.907,0.503 2.002,0.736 2.002,1 C2.002,1.265 1.907,1.498 1.717,1.699 C1.527,1.901 1.288,2.001 1.001,2.001 C0.713,2.001 0.475,1.901 0.285,1.699 C0.095,1.498 0,1.265 0,1 C0,0.736 0.095,0.503 0.285,0.302 C0.475,0.101 0.713,0 1.001,0 Z" />
          </clipPath>
          <clipPath id="i22">
            <path d="M4.227,0 C5.181,0 5.94,0.334 6.504,1 C7.068,1.668 7.349,2.634 7.349,3.899 L7.349,8.971 L5.969,8.971 L5.969,3.968 C5.969,3.117 5.782,2.467 5.409,2.018 C5.035,1.57 4.532,1.346 3.899,1.346 C3.404,1.346 2.968,1.481 2.588,1.751 C2.208,2.022 1.912,2.381 1.7,2.829 C1.486,3.278 1.38,3.767 1.38,4.296 L1.38,8.971 L0,8.971 L0,0.172 L1.191,0.172 L1.346,1.812 C1.633,1.248 2.027,0.805 2.527,0.483 C3.028,0.161 3.594,0 4.227,0 Z" />
          </clipPath>
          <clipPath id="i23">
            <path d="M3.813,0 C4.457,0 5.04,0.167 5.564,0.501 C6.087,0.835 6.487,1.26 6.763,1.777 L6.901,0.173 L8.091,0.173 L8.091,7.764 C8.091,9.305 7.729,10.473 7.004,11.266 C6.28,12.06 5.262,12.457 3.951,12.457 C3.215,12.457 2.519,12.336 1.863,12.095 C1.208,11.853 0.65,11.508 0.189,11.059 L0.724,9.8 C1.161,10.225 1.653,10.55 2.2,10.775 C2.746,10.999 3.318,11.111 3.916,11.111 C5.791,11.111 6.728,9.996 6.728,7.764 L6.728,7.126 C6.452,7.643 6.058,8.075 5.546,8.42 C5.035,8.765 4.457,8.937 3.813,8.937 C3.088,8.937 2.438,8.747 1.863,8.368 C1.288,7.989 0.834,7.462 0.5,6.79 C0.166,6.117 0,5.355 0,4.503 C0,3.641 0.164,2.87 0.492,2.192 C0.819,1.513 1.271,0.978 1.846,0.587 C2.421,0.196 3.077,0 3.813,0 Z M4.054,1.329 C3.537,1.329 3.079,1.461 2.683,1.726 C2.286,1.991 1.972,2.359 1.743,2.83 C1.512,3.302 1.397,3.848 1.397,4.469 C1.397,5.09 1.512,5.636 1.743,6.108 C1.972,6.58 2.286,6.948 2.683,7.212 C3.079,7.477 3.537,7.609 4.054,7.609 C4.583,7.609 5.049,7.474 5.452,7.203 C5.854,6.933 6.17,6.562 6.401,6.091 C6.63,5.619 6.745,5.079 6.745,4.469 C6.745,3.848 6.63,3.302 6.401,2.83 C6.17,2.359 5.854,1.991 5.452,1.726 C5.049,1.461 4.583,1.329 4.054,1.329 Z" />
          </clipPath>
          <clipPath id="i24">
            <path d="M2.416,0 C3.504,0 4.307,0.207 4.827,0.62 C5.346,1.032 5.606,1.656 5.606,2.49 C5.606,2.957 5.52,3.345 5.348,3.656 C5.177,3.966 4.958,4.216 4.692,4.404 C4.426,4.592 4.146,4.735 3.852,4.834 L6.256,8.759 L4.968,8.759 L2.846,5.14 L1.104,5.14 L1.104,8.759 L0,8.759 L0,0 L2.416,0 Z M2.355,0.957 L1.104,0.957 L1.104,4.208 L2.416,4.208 C3.128,4.208 3.647,4.067 3.974,3.784 C4.301,3.502 4.465,3.087 4.465,2.539 C4.465,1.967 4.293,1.56 3.95,1.319 C3.606,1.078 3.075,0.957 2.355,0.957 Z" />
          </clipPath>
          <clipPath id="i25">
            <path d="M4.895,0 L4.895,0.969 L1.104,0.969 L1.104,3.705 L4.674,3.705 L4.674,4.662 L1.104,4.662 L1.104,7.79 L4.895,7.79 L4.895,8.759 L0,8.759 L0,0 L4.895,0 Z" />
          </clipPath>
          <clipPath id="i26">
            <path d="M4.207,0 C5.131,0 5.937,0.172 6.624,0.515 L6.182,1.448 C5.913,1.325 5.612,1.215 5.281,1.116 C4.95,1.018 4.588,0.969 4.195,0.969 C3.255,0.969 2.514,1.284 1.975,1.914 C1.435,2.544 1.165,3.406 1.165,4.502 C1.165,5.59 1.416,6.451 1.92,7.085 C2.422,7.719 3.177,8.035 4.183,8.035 C4.559,8.035 4.919,8.003 5.262,7.937 C5.606,7.872 5.945,7.79 6.281,7.692 L6.281,8.649 C5.953,8.771 5.616,8.861 5.268,8.919 C4.921,8.976 4.506,9.005 4.023,9.005 C3.132,9.005 2.388,8.82 1.791,8.453 C1.194,8.085 0.746,7.561 0.447,6.882 C0.149,6.203 0,5.406 0,4.49 C0,3.607 0.161,2.828 0.484,2.153 C0.807,1.478 1.283,0.951 1.913,0.571 C2.543,0.19 3.307,0 4.207,0 Z" />
          </clipPath>
          <clipPath id="i27">
            <path d="M4.061,0 C4.936,0 5.672,0.186 6.269,0.558 C6.866,0.93 7.318,1.454 7.625,2.128 C7.931,2.803 8.085,3.594 8.085,4.502 C8.085,5.41 7.931,6.201 7.625,6.876 C7.318,7.55 6.864,8.076 6.263,8.452 C5.662,8.828 4.924,9.016 4.049,9.016 C3.141,9.016 2.386,8.828 1.785,8.452 C1.184,8.076 0.736,7.548 0.442,6.87 C0.148,6.191 0,5.398 0,4.49 C0,3.599 0.148,2.815 0.442,2.14 C0.736,1.466 1.184,0.94 1.785,0.564 C2.386,0.188 3.145,0 4.061,0 Z M4.061,0.969 C3.063,0.969 2.331,1.282 1.865,1.907 C1.399,2.533 1.166,3.398 1.166,4.502 C1.166,5.606 1.399,6.475 1.865,7.109 C2.331,7.743 3.059,8.06 4.049,8.06 C5.038,8.06 5.764,7.743 6.226,7.109 C6.688,6.475 6.919,5.606 6.919,4.502 C6.919,3.398 6.69,2.533 6.232,1.907 C5.774,1.282 5.051,0.969 4.061,0.969 Z" />
          </clipPath>
          <clipPath id="i28">
            <path d="M1.631,0 L4.33,7.177 L4.379,7.177 L7.127,0 L8.746,0 L8.746,8.759 L7.655,8.759 L7.655,3.373 C7.655,2.948 7.665,2.527 7.685,2.11 C7.706,1.693 7.724,1.354 7.741,1.092 L7.691,1.092 L4.772,8.759 L3.864,8.759 L0.981,1.079 L0.932,1.079 C0.957,1.333 0.977,1.674 0.993,2.104 C1.01,2.533 1.018,2.981 1.018,3.447 L1.018,8.759 L0,8.759 L0,0 L1.631,0 Z" />
          </clipPath>
          <clipPath id="i29">
            <path d="M1.275,0 L5.949,7.25 L5.998,7.25 C5.99,7.119 5.98,6.923 5.968,6.661 C5.955,6.399 5.943,6.115 5.931,5.808 C5.919,5.502 5.912,5.214 5.912,4.944 L5.912,0 L6.943,0 L6.943,8.759 L5.655,8.759 L0.969,1.484 L0.92,1.484 C0.936,1.771 0.956,2.127 0.981,2.552 C1.005,2.977 1.018,3.414 1.018,3.864 L1.018,8.759 L0,8.759 L0,0 L1.275,0 Z" />
          </clipPath>
          <clipPath id="i30">
            <path d="M2.699,0 C3.574,0 4.337,0.163 4.987,0.49 C5.637,0.817 6.138,1.3 6.49,1.938 C6.842,2.576 7.018,3.361 7.018,4.293 C7.018,5.774 6.614,6.888 5.809,7.636 C5.003,8.384 3.881,8.759 2.442,8.759 L0,8.759 L0,0 L2.699,0 Z M2.54,0.944 L1.104,0.944 L1.104,7.814 L2.294,7.814 C4.666,7.814 5.852,6.653 5.852,4.33 C5.852,3.16 5.564,2.304 4.987,1.76 C4.411,1.216 3.595,0.944 2.54,0.944 Z" />
          </clipPath>
          <clipPath id="i31">
            <path d="M2.908,0 C3.382,0 3.82,0.045 4.22,0.135 C4.621,0.226 4.989,0.344 5.325,0.491 L4.981,1.435 C4.678,1.305 4.347,1.194 3.987,1.105 C3.627,1.015 3.259,0.969 2.883,0.969 C2.335,0.969 1.922,1.086 1.644,1.319 C1.366,1.552 1.227,1.861 1.227,2.245 C1.227,2.54 1.289,2.785 1.411,2.981 C1.534,3.178 1.734,3.356 2.012,3.515 C2.29,3.674 2.667,3.84 3.141,4.012 C3.656,4.2 4.092,4.403 4.447,4.619 C4.803,4.836 5.073,5.095 5.257,5.398 C5.441,5.701 5.533,6.081 5.533,6.539 C5.533,7.316 5.251,7.921 4.687,8.354 C4.122,8.788 3.362,9.005 2.405,9.005 C1.914,9.005 1.46,8.968 1.043,8.895 C0.626,8.821 0.278,8.718 0,8.588 L0,7.533 C0.295,7.664 0.661,7.782 1.098,7.888 C1.536,7.995 1.988,8.048 2.454,8.048 C3.1,8.048 3.59,7.921 3.926,7.668 C4.261,7.414 4.429,7.071 4.429,6.637 C4.429,6.351 4.368,6.11 4.245,5.914 C4.122,5.717 3.909,5.537 3.607,5.373 C3.304,5.21 2.891,5.03 2.368,4.834 C1.616,4.564 1.051,4.233 0.675,3.84 C0.299,3.448 0.111,2.912 0.111,2.233 C0.111,1.775 0.229,1.379 0.467,1.043 C0.704,0.708 1.031,0.45 1.448,0.27 C1.865,0.09 2.352,0 2.908,0 Z" />
          </clipPath>
        </defs>
        <g clipPath="url(#i0)">
          <polygon
            points="0,0 255,0 255,30 0,30 0,0"
            stroke="none"
            fill="url(#i1)"
          />
        </g>
        <g transform="translate(14.0 8.000000000000002)">
          <g transform="translate(11.299500000000002 14.0905)">
            <g clipPath="url(#i2)">
              <polygon
                points="0,0 34.181,0 34.181,2.723 0,2.723 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(8.2684 2.849599999999999)">
            <g clipPath="url(#i3)">
              <polygon
                points="0,0 8.302,0 8.302,9.73 0,9.73 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(25.774 3.077399999999999)">
            <g clipPath="url(#i4)">
              <polygon
                points="0,0 7.659,0 7.659,9.503 0,9.503 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(48.83730000000001 2.8492999999999995)">
            <g clipPath="url(#i5)">
              <polygon
                points="0,0 7.659,0 7.659,9.503 0,9.503 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(0.0 0.7659999999999982)">
            <g clipPath="url(#i6)">
              <g transform="translate(-0.00039999999999995595 0.0)">
                <g clipPath="url(#i7)">
                  <polygon
                    points="0.0004,0 9.211,0 9.211,11.586 0.0004,11.586 0.0004,0"
                    stroke="none"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(34.191500000000005 0.7655999999999992)">
            <g clipPath="url(#i8)">
              <polygon
                points="0,0 6.302,0 6.302,11.822 0,11.822 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(17.771 0.0)">
            <g clipPath="url(#i9)">
              <g transform="translate(0.0 -9.999999999976694e-05)">
                <g clipPath="url(#i10)">
                  <polygon
                    points="0,0.0001 8.222,0.0001 8.222,12.352 0,12.352 0,0.0001"
                    stroke="none"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(39.78440000000001 2.8416999999999977)">
            <g clipPath="url(#i11)">
              <polygon
                points="0,0 8.29296443,0 8.29296443,9.747 0,9.747 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(59.740700000000004 1.3450999999999995)">
            <g clipPath="url(#i12)">
              <polygon
                points="0,0 9.834,0 9.834,11.007 0,11.007 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(69.50510000000001 0.10319999999999752)">
            <g clipPath="url(#i13)">
              <polygon
                points="0,0 8.195,0 8.195,12.422 0,12.422 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(78.5967 3.5532000000000004)">
            <g clipPath="url(#i14)">
              <polygon
                points="0,0 8.368,0 8.368,8.799 0,8.799 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(86.82620000000001 3.3466999999999985)">
            <g clipPath="url(#i15)">
              <polygon
                points="0,0 7.867,0 7.867,9.213 0,9.213 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(95.7783 3.3811999999999998)">
            <g clipPath="url(#i16)">
              <polygon
                points="0,0 5.296,0 5.296,8.971 0,8.971 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g transform="translate(101.8342 1.2766999999999982)">
            <g clipPath="url(#i17)">
              <polygon
                points="0,0 6.141,0 6.141,11.248 0,11.248 0,0"
                stroke="none"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g clipPath="url(#i18)">
            <g transform="translate(108.907 0.2240000000000002)">
              <g clipPath="url(#i19)">
                <polygon
                  points="0,0 2.001,0 2.001,12.128 0,12.128 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(111.56379999999999 3.3463999999999974)">
              <g clipPath="url(#i20)">
                <polygon
                  points="0,0 6.694,0 6.694,9.213 0,9.213 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(119.051 0.2240000000000002)">
              <g clipPath="url(#i21)">
                <polygon
                  points="0,0 2.002,0 2.002,12.128 0,12.128 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(122.2604 3.3811999999999998)">
              <g clipPath="url(#i22)">
                <polygon
                  points="0,0 7.349,0 7.349,8.971 0,8.971 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(130.7142 3.3805999999999976)">
              <g clipPath="url(#i23)">
                <polygon
                  points="0,0 8.091,0 8.091,12.457 0,12.457 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(143.47900000000004 3.5651999999999973)">
              <g clipPath="url(#i24)">
                <polygon
                  points="0,0 6.256,0 6.256,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(151.10860000000002 3.5651999999999973)">
              <g clipPath="url(#i25)">
                <polygon
                  points="0,0 4.895,0 4.895,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(157.48859999999996 3.4423999999999992)">
              <g clipPath="url(#i26)">
                <polygon
                  points="0,0 6.624,0 6.624,9.005 0,9.005 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(164.99539999999996 3.4304999999999986)">
              <g clipPath="url(#i27)">
                <polygon
                  points="0,0 8.085,0 8.085,9.016 0,9.016 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(175.01870000000002 3.5651999999999973)">
              <g clipPath="url(#i28)">
                <polygon
                  points="0,0 8.746,0 8.746,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(186.1454 3.5651999999999973)">
              <g clipPath="url(#i28)">
                <polygon
                  points="0,0 8.746,0 8.746,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(197.2711 3.5651999999999973)">
              <g clipPath="url(#i25)">
                <polygon
                  points="0,0 4.895,0 4.895,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(204.0928 3.5651999999999973)">
              <g clipPath="url(#i29)">
                <polygon
                  points="0,0 6.943,0 6.943,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(213.4151 3.565599999999998)">
              <g clipPath="url(#i30)">
                <polygon
                  points="0,0 7.018,0 7.018,8.759 0,8.759 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
            <g transform="translate(221.80609999999996 3.4421)">
              <g clipPath="url(#i31)">
                <polygon
                  points="0,0 5.5329,0 5.5329,9.005 0,9.005 0,0"
                  stroke="none"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

BadgeRecommends.defaultProps = {
  className: '',
};

BadgeRecommends.propTypes = {
  className: PropTypes.string,
};

export default BadgeRecommends;
