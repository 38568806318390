import React, { PureComponent } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './WizardNav.less';

class WizardNav extends PureComponent {
  static propTypes = {
    /**
     * Passed by parent Wizard
     */
    title: PropTypes.node.isRequired,
    isComplete: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    currentStage: PropTypes.number.isRequired,
    furthestStage: PropTypes.number.isRequired,
    updateFurthestStage: PropTypes.func.isRequired,
    goToStage: PropTypes.func.isRequired,
    /**
     * Prop from react-intl HOC
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    navigationDisabled: PropTypes.bool,
  };

  static defaultProps = {
    navigationDisabled: false,
  };

  i18n = defineMessages({
    current: {
      defaultMessage: 'Current stage',
      description: 'WizardNav current icon label',
    },
    completed: {
      defaultMessage: 'Completed',
      description: 'completed',
    },
    notComplete: {
      defaultMessage: 'Not yet complete',
      description: 'WizardNav not yet complete icon label',
    },
    notAvailable: {
      defaultMessage: 'Not yet available',
      description: 'WizardNav not yet available icon label',
    },
  });

  constructor(props) {
    super(props);

    this.goToStage = this.goToStage.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Accessibility: Focus back to top-level wizard nav on stage change
    const { currentStage, index } = this.props;
    const stageChanged = prevProps.currentStage !== currentStage;
    const isCurrentStage = currentStage === index;

    if (stageChanged && isCurrentStage && this.btnEl) {
      setTimeout(() => this.btnEl.focus());
    }

    // Reset furthest stage progress if a user jumps back and un-completes a stage
    const { isComplete, updateFurthestStage } = this.props;
    const completionChanged = prevProps.isComplete !== isComplete;

    if (completionChanged && !isComplete) {
      updateFurthestStage(index);
    }
  }

  goToStage() {
    this.props.goToStage(this.props.index);
  }

  render() {
    const {
      title,
      index,
      currentStage,
      furthestStage,
      intl,
      navigationDisabled,
    } = this.props;

    const isCurrentStage = index === currentStage;
    const isFurthestStage = index === furthestStage;
    const isToBeCompleted = index > furthestStage;
    const isComplete = this.props.isComplete && !isToBeCompleted;

    const titleClasses = classnames('Wizard__navTitle', {
      'Wizard__navTitle--current': isCurrentStage,
    });
    const indicatorClasses = classnames('Wizard__navIndicators', {
      'Wizard__navIndicators--current': isCurrentStage,
      'Wizard__navIndicators--complete': isComplete,
      'Wizard__navIndicators--furthest': isFurthestStage,
    });
    const iconClasses = classnames('Wizard__navIcon', {
      'Wizard__navIcon--current': isCurrentStage,
      'Wizard__navIcon--complete': isComplete,
      'Wizard__navIcon--toComplete': isToBeCompleted,
    });

    let iconLabel = intl.formatMessage(this.i18n.completed);
    if (isFurthestStage) iconLabel = intl.formatMessage(this.i18n.notComplete);
    if (isToBeCompleted) iconLabel = intl.formatMessage(this.i18n.notAvailable);
    if (isCurrentStage) iconLabel = intl.formatMessage(this.i18n.current);

    return (
      <button
        type="button"
        className="Wizard__navItem"
        onClick={this.goToStage}
        disabled={navigationDisabled || isToBeCompleted}
        ref={el => (this.btnEl = el)}
      >
        <div className={titleClasses}>
          {index + 1}. {title}
        </div>
        <div className={indicatorClasses}>
          <div className={iconClasses} aria-label={`- ${iconLabel}`} />
        </div>
      </button>
    );
  }
}

export default injectIntl(WizardNav);
