import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { Button } from 'components/Button';
import AdvancedFilterDrawer from './AdvancedFilterDrawer';

import countFilters from './countFilters';
import './AdvancedFilter.less';

class AdvancedFilter extends PureComponent {
  static propTypes = {
    /**
     * Element ID to provide offset, by default this is the BP header ID
     */
    offsetElementID: PropTypes.string,

    /**
     * Function run when filters are applied or removed
     *
     * @param {obj} filters - Passes an object containing all filter data
     */
    onChange: PropTypes.func,

    /**
     * Function run when filters are submitted or reset
     *
     * @param {obj} filters - Passes an object containing all filter data
     */
    onSubmit: PropTypes.func,

    /**
     * Function to be called when filters are reset with the 'Clear filters' button
     */
    onClear: PropTypes.func,

    /**
     * Child text/nodes
     */
    children: PropTypes.node,

    /**
     * Optional filters to initialize the Filter with. Expects an object, with each key
     * corresponding to the `name` of the input whose value it is prepopulating.
     *
     * The amount of filters displayed within the button will reflect the amount of keys
     * in the defaultFilters prop, even if the key does not map to an input
     *
     * This prop populates the Filter on init only, and cannot be updated dynamically
     * to override filters set by users.
     */
    defaultFilters: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    /**
     * Function to be triggered when the filter drawer closes itself
     */
    onClose: PropTypes.func,

    /**
     * Heading to be displayed in the top of the Filter Drawer
     */
    heading: PropTypes.node.isRequired,

    /**
     * Label of Advanced Filter Component. Defaults to, 'Filters (X Selected)'
     */
    filterLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * Custom Apply Filters button label. Defaults to, 'Apply Filters'
     */
    applyFilterLabel: PropTypes.node,

    /**
     * Adds borders to the filter button
     */
    isSecondary: PropTypes.bool,

    /**
     * Renders a larger filter button
     */
    isLarge: PropTypes.bool,

    /**
     * Additional classes to apply for the filter button
     */
    buttonClassName: PropTypes.string,
  };

  static defaultProps = {
    filterLabel: null,
    applyFilterLabel: null,
    offsetElementID: null,
    defaultFilters: {},
    onChange: () => {},
    onSubmit: () => {},
    onClear: () => {},
    onClose: () => {},
    children: null,
    isSecondary: false,
    isLarge: false,
    buttonClassName: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      filtersOpen: false,
      amountOfFilters: 0,
    };

    this.openFilters = this.openFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.countFilters = this.countFilters.bind(this);
    this.displayFilterLabel = this.displayFilterLabel.bind(this);
  }

  componentDidMount() {
    this.countFilters(this.props.defaultFilters);
  }

  static getDerivedStateFromProps(props, state) {
    const currAmount = state.amountOfFilters;
    const nextAmount = countFilters(Object.entries(props.defaultFilters));

    if (currAmount !== nextAmount && !state.filtersOpen) {
      return {
        ...state,
        amountOfFilters: nextAmount,
      };
    }
    return state;
  }

  onSubmit(updatedFilters) {
    this.countFilters(updatedFilters);
    this.props.onSubmit(updatedFilters);
  }

  openFilters() {
    this.setState({ filtersOpen: true });
  }

  closeFilters() {
    this.props.onClose();
    this.setState({ filtersOpen: false });
  }

  countFilters(filters) {
    const amountOfFilters = countFilters(Object.entries(filters));
    this.setState({ amountOfFilters });
  }

  displayFilterLabel() {
    const { amountOfFilters } = this.state;
    const { filterLabel } = this.props;

    if (filterLabel) {
      switch (typeof filterLabel) {
        case 'function':
          return filterLabel(this.state.amountOfFilters);
        default:
          return filterLabel;
      }
    }
    return (
      <FormattedMessage
        defaultMessage="Filters ({amountOfFilters} Selected)"
        description="AdvancedFilter button text"
        values={{ amountOfFilters }}
      />
    );
  }

  render() {
    const {
      heading,
      applyFilterLabel,
      offsetElementID,
      defaultFilters,
      children,
      isSecondary,
      isLarge,
      buttonClassName,
    } = this.props;
    const { filtersOpen } = this.state;

    const displayFilterLabel = this.displayFilterLabel();

    return (
      <React.Fragment>
        <Button
          isTertiary={!isSecondary}
          isSecondary={isSecondary}
          isLarge={isLarge}
          icon="sliders"
          onClick={this.openFilters}
          className={classnames('AdvancedFilter__Button', buttonClassName)}
        >
          {displayFilterLabel}
        </Button>

        <AdvancedFilterDrawer
          amountOfFilters={this.state.amountOfFilters}
          applyFilterLabel={applyFilterLabel}
          isVisible={filtersOpen}
          offsetElementID={offsetElementID}
          onSubmit={this.onSubmit}
          onChange={newValues => {
            this.props.onChange(newValues);
          }}
          onClear={() => {
            this.props.onClear();
          }}
          onClose={this.closeFilters}
          defaultFilters={defaultFilters}
          heading={heading}
        >
          {children}
        </AdvancedFilterDrawer>
      </React.Fragment>
    );
  }
}

export default AdvancedFilter;
