import React from 'react';
import { CardHeader } from 'components/Card';
import PropTypes from 'prop-types';
// Review to be done of Heading colors as this should be a Heading 3
const AppSwitcherCardHeader = React.memo(({ header, subHeader }) => (
  <CardHeader
    title={
      <span className="text-gray-700 font-semibold text-lg" level={3}>
        {header}
      </span>
    }
    subHeader={subHeader}
  />
));

AppSwitcherCardHeader.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node,
};

AppSwitcherCardHeader.defaultProps = {
  header: '',
  subHeader: '',
};

export default AppSwitcherCardHeader;
