import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { createPortalContainer, createPortal } from 'components/helpers';

import './Main.less';

class Main extends Component {
  static propTypes = {
    /**
     * Child content
     */
    children: PropTypes.node.isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Main container ID
     */
    id: PropTypes.string,

    /**
     * This prop affects skip link functionality (see the Accessibility section below).
     *
     * Customize the container ID to skip to, if for any reason (e.g. side nav)
     * your immediate main content is elsewhere on the page
     */
    skipToId: PropTypes.string,

    /**
     * This prop affects skip link functionality (see the Accessibility section below).
     *
     * Enable this flag if you want to disable default anchor link behavior, which adds
     * a #hash to the page URL. This might be required for, e.g., React hash routers
     */
    useJavascriptScroll: PropTypes.bool,
  };

  static defaultProps = {
    useJavascriptScroll: false,
    skipToId: 'MainContent',
    id: 'MainContent',
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.hasSkipLink = this.getSkipLink();

    if (this.hasSkipLink) {
      this.portalNode = this.hasSkipLink;
    } else {
      this.portalNode = createPortalContainer();
      this.portalNode.setAttribute('id', 'SkipToMainContent');
    }

    this.onSkip = this.onSkip.bind(this);
  }

  componentDidMount() {
    document.body.prepend(this.portalNode);
  }

  componentWillUnmount() {
    if (document.body.contains(this.portalNode)) {
      document.body.removeChild(this.portalNode);
    }
  }

  onSkip(event) {
    const { useJavascriptScroll, skipToId } = this.props;
    if (!useJavascriptScroll) return;

    event.preventDefault();

    const mainContent = document.getElementById(skipToId);
    if (mainContent) {
      // Keyboard accessibility
      mainContent.setAttribute('tabIndex', '-1');
      mainContent.focus();

      // For sighted keyboard users
      mainContent.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }

  getSkipLink() {
    return document.getElementById('SkipToMainContent');
  }

  render() {
    const {
      children,
      skipToId,
      id,
      className,
      useJavascriptScroll,
      ...rest
    } = this.props;

    const skipLink = (
      <a href={`#${skipToId}`} className="SkipToMain" onClick={this.onSkip}>
        Skip to main content
      </a>
    );

    return (
      <Fragment>
        {!this.hasSkipLink && createPortal(skipLink, this.portalNode)}

        <main {...rest} className={classnames('Main', className)} id={id}>
          {children}
        </main>
      </Fragment>
    );
  }
}

export default Main;
