import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';

import './InputLabel.less';

const InputLabel = props => {
  const {
    children,
    htmlFor,
    isHidden,
    hasAsterisk,
    className,
    tooltipBody,
    tooltipHeading,
    ...rest
  } = props;

  const classes = classnames('InputLabel', className, {
    'InputLabel--hidden': isHidden,
    InputLabel__asterisk: hasAsterisk,
  });

  return children ? (
    <div className="flex InputLabel--wrapper">
      <label className={classes} htmlFor={htmlFor} {...rest}>
        {children}
      </label>
      <div className="InputLabel__Tooltip_block">
        {tooltipBody && (
          <Tooltip
            className="InputLabel__Tooltip_wrapper"
            position="top"
            heading={tooltipHeading}
            body={tooltipBody}
          >
            {toggleVisibility => (
              <div
                className="InputLabel__Tooltip"
                role="presentation"
                onClick={toggleVisibility}
              >
                <Icon
                  type="question-solid"
                  className="InputLabel__Icon"
                />
              </div>
            )}
          </Tooltip>
        )}
      </div>
    </div>
  ) : null;
};

InputLabel.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * Describes an input element (by corresponding ID attribute) belonging to this label.
   */
  htmlFor: PropTypes.string.isRequired,

  /**
   * Adds asterisk next to the label
   */
  hasAsterisk: PropTypes.bool,

  /**
   * Visually hides the label, while remaining accessible to screen readers
   */
  isHidden: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Tooltip text
   */
  tooltipBody: PropTypes.node,

  /**
   * Tooltip heading
   */
  tooltipHeading: PropTypes.node,
};

InputLabel.defaultProps = {
  children: null,
  isHidden: false,
  hasAsterisk: false,
  className: '',
  tooltipBody: undefined,
  tooltipHeading: undefined,
};

export default InputLabel;
