import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TableCell.less';

const TableDataCell = ({ children, rowSpan, colSpan, className, ...rest }) => {
  const classes = classnames('Table__TableData', className);

  return (
    <td {...rest} {...{ colSpan, rowSpan }} className={classes}>
      {children}
    </td>
  );
};

TableDataCell.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Table columns to span, for when the data applies to multiple column headers
   */
  colSpan: PropTypes.number,

  /**
   * Table rows to span, for when the data applies to multiple row headers
   */
  rowSpan: PropTypes.number,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

TableDataCell.defaultProps = {
  colSpan: 1,
  rowSpan: 1,
  className: '',
};

export default TableDataCell;
