import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const PaginationTextButton = ({ isActive, onClick, children }) => {
  const classname = classnames('Pagination__text', {
    'Pagination__text--active': isActive,
  });
  return (
    <span className={classname}>
      <button
        type="button"
        className="Pagination__textButton"
        onClick={onClick}
      >
        {children}
      </button>
    </span>
  );
};

PaginationTextButton.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node,

  /**
   * Function to execute on click
   */
  onClick: PropTypes.func,

  /**
   * Sets the PaginationTextButton to the active state
   */
  isActive: PropTypes.bool,
};

PaginationTextButton.defaultProps = {
  children: null,
  onClick: () => {},
  isActive: false,
};

export default PaginationTextButton;
