import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Checkbox } from 'components/Checkbox';
import { Button } from 'components/Button';

import './DataTableSelection.less';

class DataTableSelection extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable components
     */
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    canSelect: PropTypes.bool.isRequired,
    hideCheckbox: PropTypes.func.isRequired,
    showCheckboxAlternative: PropTypes.func.isRequired,
    checkboxAlternative: PropTypes.node,
    onSelect: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    paginatedData: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    currentPagination: PropTypes.number.isRequired,
    reset: PropTypes.number.isRequired,
    /**
     * Passed from react-intl HOC
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    checkboxAlternative: null,
  };

  messages = defineMessages({
    SelectRow: {
      defaultMessage: 'Select row',
      description: 'DataTable select row hidden label',
    },
    SelectRows: {
      defaultMessage: 'Select all visible rows',
      description: 'DataTable select all hidden label',
    },
    Selected: {
      defaultMessage: 'selected',
      description: 'DataTable selected',
    },
    SelectAll: {
      defaultMessage: 'Select all',
      description: 'DataTable select all button',
    },
    DeselectAll: {
      defaultMessage: 'Deselect all',
      description: 'DataTable deselect all button',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      selectableData: this.selectableData(props.data),
      selectedData: this.resetPaginatedSelectedData(),
      selectedRows: [],
    };

    this.selectableData = this.selectableData.bind(this);
    this.isSelectable = this.isSelectable.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onDeselectAll = this.onDeselectAll.bind(this);
    this.isRowSelected = this.isRowSelected.bind(this);
    this.renderRowCheckbox = this.renderRowCheckbox.bind(this);
    this.renderPageCheckbox = this.renderPageCheckbox.bind(this);
    this.renderSelectAllButton = this.renderSelectAllButton.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { paginatedData, reset } = this.props;
    const { selectedRows } = this.state;

    if (prevState.selectedRows !== selectedRows) {
      this.props.onSelect(selectedRows);
    }
    if (prevProps.reset !== reset) {
      this.onDeselectAll();
    }
    if (prevProps.paginatedData !== paginatedData) {
      this.updateSelectedData();
    }
  }

  /**
   * Events
   */

  onRowSelect(event) {
    const { row, isChecked: isSelected } = event;
    const { currentPagination } = this.props;
    const { selectedData, selectedRows } = this.state;

    // Set internal isSelected flag
    const updatedMap = selectedData[currentPagination];
    updatedMap.set(row, { isSelected });

    // Update flat array of selected items to be sent back
    const updatedSelection = Array.from(selectedRows);

    if (isSelected) {
      updatedSelection.push(row);
    } else {
      // This indexOf is why it's important not to clone our data/sortedData objs - the search will fail if selectedRow is cloned
      const index = updatedSelection.indexOf(row);
      const isInArray = index >= 0;
      if (isInArray) updatedSelection.splice(index, 1);
    }

    this.setState({ selectedData, selectedRows: updatedSelection });
  }

  onPageSelect(event) {
    const { isChecked: isSelected } = event;
    const {
      paginatedData,
      currentPagination,
      hideCheckbox,
      showCheckboxAlternative,
    } = this.props;
    const { selectedData, selectedRows } = this.state;

    const pageData = paginatedData[currentPagination];

    const updatedMap = selectedData[currentPagination];
    const updatedSelection = Array.from(selectedRows);

    pageData.forEach(row => {
      // Set isSelected flag on all internal data objs
      const selectable = this.isSelectable(row);
      if (!selectable) {
        return;
      }

      updatedMap.set(row, { isSelected });

      // Update flat array of selected items to be sent back
      const selectedIndex = updatedSelection.indexOf(row);
      const alreadyInArray = selectedIndex >= 0;

      if (
        isSelected &&
        !alreadyInArray &&
        !hideCheckbox(row) &&
        !showCheckboxAlternative(row)
      ) {
        updatedSelection.push(row);
      } else if (
        !isSelected &&
        alreadyInArray &&
        !hideCheckbox(row) &&
        !showCheckboxAlternative(row)
      ) {
        updatedSelection.splice(selectedIndex, 1);
      }
    });

    this.setState({ selectedData, selectedRows: updatedSelection });
  }

  onSelectAll() {
    const { paginatedData } = this.props;
    const { selectableData } = this.state;

    // Send back array of data objects (all)
    const updatedSelection = selectableData;

    // Set isSelected flag on all internal data objs
    const updatedMap = this.resetPaginatedSelectedData();
    updatedMap.forEach((page, i) => {
      paginatedData[i].forEach(row => {
        if (this.isSelectable(row)) {
          page.set(row, { isSelected: true });
        }
      });
    });

    this.setState({ selectedData: updatedMap, selectedRows: updatedSelection });
  }

  onDeselectAll() {
    const updatedMap = this.resetPaginatedSelectedData();
    const updatedSelection = [];

    this.setState({ selectedData: updatedMap, selectedRows: updatedSelection });
  }

  selectableData(data) {
    return data.filter(item => this.isSelectable(item));
  }

  isSelectable(row) {
    const { hideCheckbox, showCheckboxAlternative } = this.props;

    return !hideCheckbox(row) && !showCheckboxAlternative(row);
  }

  /**
   * Helpers
   */

  resetPaginatedSelectedData() {
    const { paginatedData } = this.props;
    const pagination = paginatedData.length;

    return Array.from({ length: pagination }, () => new Map());
  }

  updateSelectedData() {
    // Whenever data or paginatedData changes (e.g. gets filtered), we want to persist valid selections
    // while scrubbing through selectedData & selectedRows to remove selected rows that no longer exist.
    const { data, paginatedData } = this.props;
    const { selectedRows } = this.state;

    const updatedSelection = [];
    selectedRows.forEach(row => {
      const stillInData = data.indexOf(row) >= 0;
      if (stillInData) updatedSelection.push(row);
    });

    const updatedMap = this.resetPaginatedSelectedData();
    // This O(n) could probably stand to be improved/optimized at some point, especially if dealing with tons of data
    updatedMap.forEach((page, i) => {
      paginatedData[i].forEach(row => {
        const isStillSelected = updatedSelection.indexOf(row) >= 0;
        if (isStillSelected) page.set(row, { isSelected: true });
      });
    });

    this.setState({ selectedData: updatedMap, selectedRows: updatedSelection });
  }

  isRowSelected(row) {
    if (!this.props.canSelect) return false;
    const { selectedData } = this.state;
    const { currentPagination } = this.props;

    const { isSelected } = selectedData[currentPagination].get(row) || {};
    return isSelected;
  }

  /**
   * Rendering
   */

  renderRowCheckbox(row) {
    if (!this.props.canSelect) return null;

    const {
      intl,
      hideCheckbox,
      showCheckboxAlternative,
      checkboxAlternative,
    } = this.props;

    return showCheckboxAlternative(row) ? (
      <span className="DataTable__Checkbox">{checkboxAlternative}</span>
    ) : (
      <Checkbox
        name="selectRow"
        label={intl.formatMessage(this.messages.SelectRow)}
        hasHiddenLabel
        className={`DataTable__Checkbox ${hideCheckbox(row) && 'invisible'}`}
        onChange={event => this.onRowSelect({ row, ...event })}
        isChecked={this.isRowSelected(row)}
      />
    );
  }

  renderPageCheckbox() {
    if (!this.props.canSelect) return null;
    const { paginatedData, currentPagination, intl } = this.props;
    const { selectedData, selectedRows } = this.state;

    const pageData = paginatedData[currentPagination];
    const selectedPageData = selectedData[currentPagination];

    let pageSelectionCount = 0;
    selectedPageData.forEach(dataItem => {
      if (dataItem.isSelected) pageSelectionCount += 1;
    });
    const isPageSelectedAll =
      pageSelectionCount === this.selectableData(pageData).length &&
      pageSelectionCount > 0;
    const hasSelectedSome = !isPageSelectedAll && selectedRows.length > 0;

    return (
      <Checkbox
        name="selectAllRows"
        label={intl.formatMessage(this.messages.SelectRows)}
        hasHiddenLabel
        className="DataTable__Checkbox"
        onChange={this.onPageSelect}
        isChecked={isPageSelectedAll}
        isIndeterminate={hasSelectedSome}
      />
    );
  }

  renderSelectAllButton() {
    if (!this.props.canSelect) return null;
    const { data, intl } = this.props;
    const { selectedRows } = this.state;

    const selectable = this.selectableData(data).length;
    const isSelectedAll = selectedRows.length === selectable;
    const onSelectAllCallback = isSelectedAll
      ? this.onDeselectAll
      : this.onSelectAll;

    const selectAllButton = (
      <Button isTertiary onClick={onSelectAllCallback}>
        {isSelectedAll
          ? intl.formatMessage(this.messages.DeselectAll)
          : intl.formatMessage(this.messages.SelectAll)}
      </Button>
    );

    // Example output: 0 selected (Select all) / 24 selected (Deselect all)
    // prettier-ignore
    return (
      <div className="DataTableSelection">
        {selectedRows.length} {intl.formatMessage(this.messages.Selected)} ({selectAllButton})
      </div>
    );
  }

  render() {
    return this.props.render({
      renderRowCheckbox: this.renderRowCheckbox,
      renderPageCheckbox: this.renderPageCheckbox,
      renderSelectAllButton: this.renderSelectAllButton(),
      isRowSelected: this.isRowSelected,
    });
  }
}

export default injectIntl(DataTableSelection);
