import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Pagination from 'components/Pagination';

import './DataTablePagination.less';

class DataTablePagination extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable components
     */
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    paginationOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    reset: PropTypes.number.isRequired,
    fallbackMessage: PropTypes.node,
  };

  static defaultProps = {
    fallbackMessage: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.onDataReset(),
      ...this.onUserReset(),
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.getCurrentPageRange = this.getCurrentItemsRange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { reset, data, paginationOptions } = this.props;

    if (prevProps.reset !== reset) {
      this.setState(this.onUserReset());
    }
    // If the dataset changes (is filtered, etc.) or items per page or pagination options change,
    // Re-paginate our data but attempt to keep the user on the same page if possible
    if (
      prevState.pagination !== this.state.pagination ||
      prevProps.data !== data ||
      !this.arraysAreEqual(prevProps.paginationOptions, paginationOptions)
    ) {
      let state;

      // Unless pagination options have changed, we should take into account
      // the current pagination when resetting
      if (
        !this.arraysAreEqual(prevProps.paginationOptions, paginationOptions)
      ) {
        state = this.onDataReset();
      } else {
        state = this.onDataReset(this.state.pagination);
      }

      // Catch data changes that cause the current page to become out of bounds
      if (state.pageCount <= this.state.currentPage) {
        state.currentPage = state.pageCount - 1;
      }

      this.setState(state);
    }

    if (prevState.currentPage !== this.state.currentPage) {
      const itemsRange = this.getCurrentItemsRange(prevState.pagination);
      this.setState({ itemsRange });
    }
  }

  /**
   * Events
   */

  onDataReset(prevPagination = null) {
    const { paginationOptions } = this.props;
    const pagination = prevPagination || paginationOptions[0];
    const pageCount = this.getPageCount(pagination);
    const paginatedData = this.getPaginatedData(pagination);
    const showPaginationButtons = pagination > 0 && pageCount > 1;
    const itemsRange = this.getCurrentItemsRange(pagination);

    const state = {
      showPaginationButtons,
      paginatedData,
      pageCount,
      pagination,
      itemsRange,
    };
    return state;
  }

  onUserReset() {
    const state = { currentPage: 0 };
    return state;
  }

  onPageChange({ pageNumber }) {
    const currentPage = pageNumber - 1;
    this.setState({ currentPage });
  }

  onPaginationChange(value) {
    // value comes from a dropdown select, so we need to parse the string to int
    this.setState({ pagination: parseInt(value, 10) });
  }

  /**
   * Pagination logic & chunking
   */

  getPageCount(pagination) {
    const { data } = this.props;
    return Math.ceil(data.length / pagination) || 1;
    // If data.length is 0, we should still at least have 1 page
  }

  getPaginatedData(pagination) {
    const { data } = this.props;
    if (pagination <= 0 || data.length <= pagination) return [data];

    const paginatedData = [];
    let i = 0;
    while (i < data.length) {
      paginatedData.push(data.slice(i, (i += pagination)));
    }
    return paginatedData;
  }

  getCurrentItemsRange(pagination) {
    const { data } = this.props;
    const currentPage = (this.state && this.state.currentPage) || 0;
    const range = {};

    range.total = data.length;

    const zeroIndexedRangeStart = pagination * currentPage;

    range.start = range.total > 0 ? zeroIndexedRangeStart + 1 : 0;
    range.end =
      zeroIndexedRangeStart + pagination > data.length
        ? data.length
        : zeroIndexedRangeStart + pagination;

    return range;
  }

  arraysAreEqual(a1, a2) {
    // This is a naive method of comparing arrays, but sufficient for our
    // one-dimensional arrays of integers
    return JSON.stringify(a1) === JSON.stringify(a2);
  }

  /**
   * Rendering
   */

  renderPagination() {
    const { paginationOptions } = this.props;
    const {
      showPaginationButtons,
      currentPage,
      pageCount,
      itemsRange,
    } = this.state;

    if (paginationOptions[0] === 0) return false;

    return (
      <Pagination
        className="DataTablePagination"
        currentPage={currentPage + 1}
        pageCount={pageCount}
        onChange={this.onPageChange}
        onPaginationChange={this.onPaginationChange}
        itemsRange={itemsRange}
        paginationOptions={paginationOptions}
        showPaginationButtons={showPaginationButtons}
      />
    );
  }

  render() {
    const { currentPage, paginatedData } = this.state;
    const { fallbackMessage } = this.props;

    return (
      <>
        {this.props.render({
          data: paginatedData,
          currentPagination: currentPage,
        })}
        {!fallbackMessage && this.renderPagination()}
      </>
    );
  }
}

export default DataTablePagination;
