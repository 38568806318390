import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './LoadingSpinner.less';
import LoadingProgress from './LoadingProgress';

const radius = 20;
const circumference = 2 * radius * Math.PI;

const LoadingSpinner = props => {
  const {
    className,
    size,
    color,
    isComplete,
    percentage,
    initialPercentage,
    showPercentage,
  } = props;

  // If no percentage or complete states were passed, this
  // is an indeterminate / infinitely spinning loader.
  const isInfinite = !percentage && !isComplete && !initialPercentage;
  const shouldRenderPercentage = !isInfinite && showPercentage;

  const classes = classnames('LoadingSpinner', className, {
    [`LoadingSpinner--${size}`]: size,
  });
  const svgClasses = classnames('LoadingSpinner__svg', {
    'LoadingSpinner__svg--sm': size === 'sm',
    'LoadingSpinner__svg--animated': isInfinite,
  });
  const bgClasses = classnames('LoadingSpinner__background', {
    'LoadingSpinner__background--sm': size === 'sm',
    'LoadingSpinner__background--white': color === 'white',
  });
  const fillClasses = classnames('LoadingSpinner__fill', {
    [`LoadingSpinner__fill--${color}`]: color,
    'LoadingSpinner__fill--sm': size === 'sm',
    'LoadingSpinner__fill--animated': isInfinite,
  });
  const textClasses = classnames('LoadingSpinner__percent', {
    'LoadingSpinner__percent--red': color === 'red',
    'LoadingSpinner__percent--green': color === 'green',
    [`LoadingSpinner__percent--${size}`]: size,
  });

  const setProgress = currentProgress =>
    (circumference / 100) * (100 - currentProgress);

  return (
    <LoadingProgress
      isComplete={isComplete}
      percentage={percentage}
      initialPercentage={initialPercentage}
    >
      {currentProgress => (
        <div className={classes}>
          <svg className={svgClasses} viewBox="25 25 50 50">
            <LoadingCircle className={bgClasses} />
            <LoadingCircle
              className={fillClasses}
              progress={setProgress(currentProgress)}
            />
          </svg>
          {shouldRenderPercentage && (
            <div className={textClasses}>{currentProgress}%</div>
          )}
        </div>
      )}
    </LoadingProgress>
  );
};

LoadingSpinner.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Color variant
   */
  color: PropTypes.oneOf(['red', 'green', 'white']),

  /**
   * Spinner size
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),

  /**
   * When set to true, the LoadingSpinner will immediately animate to the end
   */
  isComplete: PropTypes.bool,

  /**
   * Percent complete. Will animate to this percentage after load. If no
   * percentage is passed, the loader will default to spinning infinitely
   */
  percentage: PropTypes.number,

  /**
   * Percentage complete to show on initial load
   */
  initialPercentage: PropTypes.number,

  /**
   * When set to true, the current percentage will be displayed as text
   */
  showPercentage: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  className: '',
  color: 'red',
  size: 'md',
  isComplete: false,
  initialPercentage: 0,
  percentage: 0,
  showPercentage: true,
};

const LoadingCircle = props => (
  <circle
    className={props.className}
    cx="50"
    cy="50"
    r={radius}
    strokeDasharray={circumference}
    strokeDashoffset={props.progress}
  />
);
LoadingCircle.propTypes = {
  className: PropTypes.string.isRequired,
  progress: PropTypes.number,
};
LoadingCircle.defaultProps = {
  progress: 0,
};

export default LoadingSpinner;
