import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import { APP_CALLBACK_CLEAR, APP_CALLBACK_GET } from '../constants/action-types';

export default {
	invokeCallback: (params: string) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.shopifyApp.callBack}?${params}`);
			dispatch({ type: APP_CALLBACK_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while invoking app callback: ' + err.response.data.message);
		}
	},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: APP_CALLBACK_CLEAR }),
};
