import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  getComponentName,
  inputTypes,
  getCurrentWindow,
  WINDOW_BREAK_POINTS,
} from 'components/helpers';
import Text from 'components/Text';
import Drawer from 'components/Drawer';
import { Button } from 'components/Button';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import ScrollIndicator from 'components/ScrollIndicator';
import Form from 'components/Form';

import './AdvancedFilterDrawer.less';
import countFilters from './countFilters';

class AdvancedFilterDrawer extends PureComponent {
  static propTypes = {
    /**
     * Element ID to provide offset, by default this is the BP header ID
     */
    offsetElementID: PropTypes.string,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Function run when filters are applied or removed
     *
     * @param {obj} filters - Passes an object containing all filter data
     */
    onChange: PropTypes.func,

    /**
     * Function run when filters are submitted or reset
     *
     * @param {obj} filters - Passes an object containing all filter data
     */
    onSubmit: PropTypes.func,

    /**
     * Function to be called when filters are reset with the 'Clear filters' button
     */
    onClear: PropTypes.func,

    /**
     * When true, drawer will display Modal to confirm when user tries to close
     */
    confirmClose: PropTypes.bool,

    /**
     * Child text/nodes
     */
    children: PropTypes.node,

    /**
     * isVisible
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * Optional filters to initialize the Filter with. Expects an object, with each key
     * corresponding to the `name` of the input whose value it is prepopulating.
     *
     * This prop populates the Filter on init only, and cannot be updated dynamically
     * to override filters set by users.
     */
    defaultFilters: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    /**
     * Function to be triggered when the filter drawer closes itself
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Heading to be displayed in the top of the Filter Drawer
     */
    heading: PropTypes.node.isRequired,

    /**
     * If provided from AdvancedFilter, this will update the Apply Filters button
     */
    applyFilterLabel: PropTypes.node,

    /**
     * Prop from the AdvancedFilter component
     * @ignore
     */
    amountOfFilters: PropTypes.number,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    applyFilterLabel: null,
    offsetElementID: null,
    defaultFilters: {},
    className: '',
    confirmClose: false,
    onChange: () => {},
    onSubmit: () => {},
    onClear: () => {},
    children: null,
    amountOfFilters: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeFilters: props.defaultFilters,
      currentFilters: props.defaultFilters,
      resetForm: 0,
      amountOfFilters: this.props.amountOfFilters,
      currentWindow: getCurrentWindow(),
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.mapFields = this.mapFields.bind(this);
    this.countFilters = this.countFilters.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.defaultFilters !== state.activeFilters && !props.isVisible) {
      return {
        ...state,
        defaultFilters: props.defaultFilters,
        currentFilters: props.defaultFilters,
      };
    }
    return state;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onFormSubmit(event, updatedFilters) {
    event.preventDefault();
    this.setState({
      activeFilters: updatedFilters,
      currentFilters: updatedFilters,
    });
    this.props.onClose();
    this.props.onSubmit(updatedFilters);
  }

  onFormChange(_, formState) {
    this.countFilters(formState);
    this.setState({ currentFilters: formState });
    this.props.onChange(formState);
  }

  onCancel() {
    this.setState(({ activeFilters }) => ({
      currentFilters: activeFilters,
    }));
    this.props.onClose();
  }

  handleResize() {
    const currentWindow = getCurrentWindow();
    this.setState(prevState => ({ ...prevState, currentWindow }));
  }

  clearFilters() {
    this.setState(({ resetForm }) => ({
      currentFilters: {},
      resetForm: resetForm + 1,
      amountOfFilters: 0,
    }));

    this.props.onClear();
  }

  mapFields(children) {
    let fields = [];

    React.Children.map(children, child => {
      if (!React.isValidElement(child)) return;

      const props = (child || {}).props || {};
      const { name } = props;

      const componentType = getComponentName(child);
      const isInput = inputTypes.allInputs.includes(componentType);

      if (!isInput) {
        if (props.children) {
          fields = [...fields, ...this.mapFields(props.children)];
        }
        return;
      }

      fields.push(name);
    });

    return fields;
  }

  countFilters(filters) {
    const amountOfFilters = countFilters(Object.entries(filters));
    if (this.state.amountOfFilters !== amountOfFilters) {
      this.setState({ amountOfFilters });
    }
  }

  renderChildrenWithProps(children) {
    return React.Children.map(children, child => {
      const componentType = getComponentName(child);
      if (componentType !== 'AdvancedFilterGroup') return child;

      const fields = this.mapFields(child.props.children);

      return React.cloneElement(child, {
        fields,
        filters: this.state.currentFilters,
      });
    });
  }

  render() {
    const {
      applyFilterLabel,
      isVisible,
      className,
      offsetElementID,
      heading,
      children,
      confirmClose,
      amountOfFilters,
      intl,
    } = this.props;
    const { currentFilters, resetForm, currentWindow } = this.state;

    const isMobile = currentWindow === WINDOW_BREAK_POINTS.MOBILE;

    const classes = classnames('AdvancedFilterDrawer', className);

    const headerOffset = !offsetElementID ? {} : { offsetElementID };

    const displayApplyFilterLabel = applyFilterLabel || (
      <FormattedMessage
        defaultMessage="Apply filters {filters}"
        values={{
          filters:
            amountOfFilters !== undefined
              ? `(${this.state.amountOfFilters})`
              : null,
        }}
        description="AdvancedFilterDrawer apply button text"
      />
    );

    return (
      <Drawer
        useCustomClose
        isVisible={isVisible}
        onClose={this.onCancel}
        position="left"
        confirmClose={confirmClose}
        {...headerOffset}
      >
        <div className={classes}>
          <Form
            values={currentFilters}
            key={resetForm}
            onChange={this.onFormChange}
            onSubmit={this.onFormSubmit}
            className="AdvancedFilterForm"
          >
            <div className="AdvancedFilterDrawer__HeadingWrapper">
              <span className="AdvancedFilterDrawer__HeadingContainer">
                <div className="flex items-center">
                  <Icon type="sliders" className="text-purple-500 w-6 h-6" />
                </div>
                <Heading className="AdvancedFilterDrawer__Heading" level={3}>
                  {heading}
                </Heading>
              </span>
              <div className="flex items-center">
                <Button
                  onClick={this.clearFilters}
                  isTertiary
                  className="AdvancedFilterDrawer__ClearFilter"
                >
                  <FormattedMessage
                    defaultMessage="Reset"
                    description="AdvancedFilterDrawer clear button text"
                  />
                </Button>
                <div>
                  <button
                    onClick={this.onCancel}
                    type="button"
                    className="text-gray-600 flex items-center"
                    id="close-drawer"
                    aria-label={intl.formatMessage({
                      defaultMessage: 'Close drawer',
                      description: 'Drawer close ariaLabel',
                    })}
                  >
                    <Text className="text-gray-600 text-xs text-xs mr-1">
                      <FormattedMessage defaultMessage="Close" />
                    </Text>
                    <Icon type="close" />
                  </button>
                </div>
              </div>
            </div>
            <ScrollIndicator
              direction="vertical"
              className="AdvancedFilterDrawer__Content"
            >
              <div className="AdvancedFilterDrawer__Body">
                {this.renderChildrenWithProps(children)}
              </div>
            </ScrollIndicator>

            <div className="AdvancedFilterDrawer__Footer">
              <Button
                className="AdvancedFilterDrawer__button"
                type="submit"
                isLarge={isMobile}
                isDisabled={false}
              >
                {displayApplyFilterLabel}
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    );
  }
}

export default injectIntl(AdvancedFilterDrawer);
