import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { API } from '../../constants/api';
import { AUTH_SHOP_ORIGIN_ADD } from '../../constants/action-types';
import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

interface AppBridgeParams extends RouteComponentProps<any> {
	scopes: string;
	apiKey: string;
	shopOrigin: string;
	state: string;
}

const AppBridge = (props: AppBridgeParams) => {
	const { scopes, state, shopOrigin, apiKey } = props;
	const { REACT_APP_BASE_URL, REACT_APP_API_KEY } = process.env;
	const dispatch = useDispatch();
	const permissionUrl = `/oauth/authorize?client_id=${apiKey}&scope=${scopes}&state=${state}&redirect_uri=${REACT_APP_BASE_URL}${API.V1.shopify.callback}`;

	// sessionStorage.setItem(SHOP_ORIGIN_KEY, shopOrigin);
	dispatch({ type: AUTH_SHOP_ORIGIN_ADD, data: { shopOrigin: `admin.shopify.com/store/${shopOrigin.split('.myshopify.com')[0]}` } });

	// If the current window is the 'parent', change the URL by setting location.href
	if (window.top === window.self) {
		console.log('Bridge if');
		window.location.assign(`https://admin.shopify.com/store/${shopOrigin.split('.myshopify.com')[0]}/` + permissionUrl);
		// If the current window is the 'child', change the parent's URL with Shopify App Bridge's Redirect action
	} else {
		const app = createApp({
			apiKey: REACT_APP_API_KEY as string,
			host: btoa(`admin.shopify.com/store/${shopOrigin.split('.myshopify.com')[0]}`),
			forceRedirect: true,
		});

		Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH, permissionUrl);
	}
	return <> </>;
};

export default withRouter(AppBridge);
