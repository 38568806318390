import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import { APP_HOME_RESPONSE_CLEAR, APP_HOME_RESPONSE_GET } from '../constants/action-types';

export default {
	getAppResponse: (shop: string) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.shopifyApp.appHome}?shop=${shop}`);
			dispatch({ type: APP_HOME_RESPONSE_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while getting app response: ' + err.response.data.message);
		}
	},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: APP_HOME_RESPONSE_CLEAR }),
};
