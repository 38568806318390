import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../store';
import { Button, Grid, Heading, Text, TextLink } from '@blueprints2/core';
import { Spinner } from '../common';

const Welcome = () => {
	const { merchant } = useSelector((state: Store) => state.merchant);
	const [state, setState] = useState({ merchantName: undefined, imported: undefined });

	useEffect(() => {
		if (merchant) {
			setState({ merchantName: merchant.name, imported: merchant.imported });
		}
	}, [merchant]);

	if (state.merchantName === undefined) {
		return <Spinner />;
	}

	return (
		<>
			<Text>
				<Heading className='text-purple-600' level={1}>
					Welcome {state.merchantName}
				</Heading>
			</Text>
			<Text>
				<Heading level={3} className='mt-8'>
					To get your program up and running, there are a few more steps we need to take.
				</Heading>
			</Text>
			<TextLink to='/home'>
				<Grid className='mt-16 justify-end'>
					<Button>Let's start</Button>
				</Grid>
			</TextLink>
		</>
	);
};

export default Welcome;
