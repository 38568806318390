import React, { createContext } from 'react';
import store from '../store';
import { AUTH_HOST_ADD, AUTH_SHOP_ORIGIN_ADD } from '../constants/action-types';

type ContextProps = {
	authenticated: boolean;
	shopOriginValue: string | null;
	hostValue: string | null;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
	// const authShopOrigin = sessionStorage.getItem(SHOP_ORIGIN_KEY);
	// const host = sessionStorage.getItem(HOST_KEY) || "";

	const params = new URLSearchParams(window.location.search);

	const host = params.get('host');
	const shop = params.get('shop');

	if (shop != null) {
		store.dispatch({ type: AUTH_SHOP_ORIGIN_ADD, data: { shopOrigin: shop } });
	}

	if (host != null) {
		store.dispatch({ type: AUTH_HOST_ADD, data: { host: host } });
	}

	return (
		<AuthContext.Provider
			value={{
				authenticated: !!host && !!shop,
				shopOriginValue: shop,
				hostValue: host,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
