import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { requireEitherProp } from 'components/helpers';
import TextLink from 'components/TextLink';

import './Breadcrumbs.less';

const Breadcrumb = props => {
  const { children, path, to, className, ...rest } = props;
  const classes = classnames('Breadcrumbs__textlink', className, {
    TextLink: to,
  });

  const LinkComponent = to ? Link : TextLink;
  const linkProp = to ? { to } : { href: path };

  return (
    <LinkComponent {...linkProp} className={classes} {...rest}>
      {children}
    </LinkComponent>
  );
};

Breadcrumb.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * URL path
   *
   * - Either **path** or **to** is required
   */
  path: requireEitherProp('to', 'path'),

  /**
   * React Router link location
   *
   * - Either **path** or **to** is required
   *
   * @see https://reacttraining.com/react-router/web/api/Link
   */
  to: requireEitherProp('to', 'path'),

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Breadcrumb.defaultProps = {
  path: '',
  to: '',
  className: '',
};

export default Breadcrumb;
