import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { typeOfInstance } from 'components/helpers';
import Card from './Card';
import './CardGroup.less';

const CardGroup = ({
  className,
  cardsInRow,
  children,
  background,
  cardGroupHeader,
  cardGroupFooter,
  paddingTop,
  paddingLeft,
  ...rest
}) => {
  const getMaxCardsAmount = () => {
    const updatedWidth =
      window &&
      window.innerWidth !== undefined &&
      window.innerHeight !== undefined
        ? window.innerWidth
        : document.documentElement.clientWidth;

    if (updatedWidth <= 768 && cardsInRow > 1) {
      return 1;
    }
    if (updatedWidth <= 1280 && cardsInRow > 2) {
      return 2;
    }
    if (updatedWidth <= 1920 && cardsInRow > 3) {
      return 3;
    }
    return cardsInRow;
  };

  const [cardsAmount, setCardsAmount] = useState(getMaxCardsAmount());

  useEffect(() => {
    const handleResize = () => {
      const updatedCardsAmount = getMaxCardsAmount();
      setCardsAmount(updatedCardsAmount);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const classes = classnames('flex flex-col', {
    'CardGroup__background--purple ': background === 'purple',
    'CardGroup__background--gray ': background === 'gray',
  });

  return (
    <div className={classes}>
      {cardGroupHeader && (
        <div
          className={classnames('text-3xl font-light', {
            'text-white': background === 'purple',
          })}
        >
          {cardGroupHeader}
        </div>
      )}
      <div className={classnames(className)} {...rest}>
        {React.Children.map(children, (card, i) => {
          const indexInRow = i % cardsAmount;
          // if first in row, no left padding
          const leftPadding = `md:pl-${indexInRow === 0 ? '0' : paddingLeft}`;
          // if in first row, no top padding (or first item at all, no mobile top padding)
          const topPadding = `${i === 0 ? '' : 'pt-2'} md:pt-${
            i < cardsAmount ? '0' : paddingTop
          }`;
          const classNames = `w-full md:w-1/${cardsAmount} ${topPadding} ${leftPadding}`;
          return (
            <div className={classnames('CardWrapper', classNames)}>{card}</div>
          );
        })}
      </div>
      {cardGroupFooter && cardGroupFooter}
    </div>
  );
};
CardGroup.propTypes = {
  /**
   * Additional classes
   */
  className: PropTypes.string,
  /**
   * Number of cards per row in larger screens. Recommended maximum: 6 (may cause formatting issues if value exceeds 6)
   */
  cardsInRow: PropTypes.number,
  /**
   * Child Card nodes
   */
  children: typeOfInstance(Card).isRequired,
  /**
   * Gradient background
   */
  background: PropTypes.string,
  /**
   * Header
   */
  cardGroupHeader: PropTypes.node,
  /**
   * Footer
   */
  cardGroupFooter: PropTypes.node,
  /**
   * Padding top
   */
  paddingTop: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16]),
  /**
   * Padding left
   */
  paddingLeft: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16]),
};
CardGroup.defaultProps = {
  className: '',
  cardsInRow: 3,
  background: '',
  cardGroupHeader: undefined,
  cardGroupFooter: undefined,
  paddingTop: 2,
  paddingLeft: 2,
};
export default CardGroup;
