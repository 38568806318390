import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormattedMessage } from 'react-intl';

import Select from 'components/Select';

import PaginationDots from './PaginationDots';
import PaginationTextButton from './PaginationTextButton';
import PaginationButton from './PaginationButton';
import './Pagination.less';

class Pagination extends PureComponent {
  static propTypes = {
    /**
     * Handler that is called when current page changes from user action
     *
     * @param {Object} args - {pageNumber, event}
     */
    onChange: PropTypes.func,

    /**
     * Handler that is called when pagination value changes from user action
     *
     * @param {Object} args - {paginationValue, event}
     */
    onPaginationChange: PropTypes.func,

    /**
     * Number of pages that can be paginated through
     */
    pageCount: PropTypes.number.isRequired,

    /**
     * Number of buttons to show in the Pagination component
     */
    pagesToShow: PropTypes.number,

    /**
     * Sets current page in the Pagination component (controlled pattern)
     */
    currentPage: PropTypes.number, // eslint-disable-line react/no-unused-prop-types

    /**
     * Range of currently viewable items
     */
    itemsRange: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      total: PropTypes.number,
    }),

    /**
     * Options for user to change pagination of datatable
     */
    paginationOptions: PropTypes.arrayOf(PropTypes.number),

    /**
     * Show navigation buttons because there are additional pages to see
     */
    showPaginationButtons: PropTypes.bool,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    onPaginationChange: () => {},
    pagesToShow: 7,
    currentPage: 1,
    className: null,
    itemsRange: null,
    paginationOptions: null,
    showPaginationButtons: true,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  state = {};

  static getDerivedStateFromProps(props, state) {
    const { currentPage } = props;
    return currentPage !== state.previousPage
      ? { currentPage, previousPage: currentPage }
      : null;
  }

  onChange({ pageNumber, event }) {
    event.persist();

    this.setState({ currentPage: pageNumber }, () => {
      this.props.onChange({ pageNumber, event });
    });
  }

  onPaginationChange(value) {
    this.props.onPaginationChange(value);
  }

  renderChildren() {
    const { currentPage } = this.state;
    const { pagesToShow, pageCount } = this.props;
    const listSize = pagesToShow < pageCount ? pagesToShow : pageCount;
    const spread = listSize - 5;
    let pageNumber = 1;

    return Array.from(Array(listSize)).map((item, index) => {
      if (listSize < pageCount) {
        if (index === 1 && currentPage - spread > 2) {
          if (currentPage + spread >= pageCount) {
            pageNumber = pageCount - (spread + 2);
          } else {
            pageNumber = currentPage - Math.floor(spread / 2);
          }
          return <PaginationDots key="prevDots" location="prev" />;
        }
        if (index === pagesToShow - 2 && pageNumber !== pageCount - 1) {
          pageNumber = pageCount;
          return <PaginationDots key="nextDots" location="next" />;
        }
      }
      const returnVal = (
        <PaginationButton
          onClick={this.onChange}
          isActive={pageNumber === currentPage}
          pageNumber={pageNumber}
          key={pageNumber}
        />
      );
      pageNumber += 1;
      return returnVal;
    });
  }

  render() {
    const { currentPage } = this.state;
    const {
      className,
      pageCount,
      itemsRange,
      paginationOptions,
      showPaginationButtons,
    } = this.props;
    const classname = classnames('Pagination', className);

    return (
      <div className={classname}>
        <div className="Pagination__select">
          {paginationOptions && (
            <FormattedMessage
              defaultMessage="Show {options} results"
              description="items per page"
              values={{
                options: (
                  <Select
                    name="pagination"
                    className="mx-2"
                    isSmall
                    onChange={({ value }) => this.onPaginationChange(value)}
                  >
                    {paginationOptions.map(val => (
                      <option key={val} value={`${val}`}>
                        {val}
                      </option>
                    ))}
                  </Select>
                ),
              }}
            />
          )}
        </div>
        <div className="Pagination__buttons">
          {showPaginationButtons && (
            <>
              <PaginationTextButton
                isActive={currentPage > 1}
                onClick={event =>
                  this.onChange({ pageNumber: currentPage - 1, event })
                }
              >
                <FormattedMessage
                  defaultMessage="Previous"
                  description="previous"
                />
              </PaginationTextButton>
              {this.renderChildren()}
              <PaginationTextButton
                isActive={currentPage < pageCount}
                onClick={event =>
                  this.onChange({ pageNumber: currentPage + 1, event })
                }
              >
                <FormattedMessage defaultMessage="Next" description="next" />
              </PaginationTextButton>
            </>
          )}
        </div>
        <div className="Pagination__range">
          {itemsRange && (
            <FormattedMessage
              defaultMessage="Viewing {start} - {end} of {total}"
              description="current items range"
              values={{
                start: itemsRange.start,
                end: itemsRange.end,
                total: itemsRange.total,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Pagination;
