import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './LoadingBar.less';
import LoadingProgress from './LoadingProgress';

const LoadingBar = props => {
  const {
    className,
    color,
    isSmall,
    isComplete,
    initialPercentage,
    percentage,
    showPercentage,
    isStatic,
  } = props;

  const classes = classnames('LoadingBar', className, {
    'LoadingBar--small': isSmall,
  });
  const bgClasses = classnames('LoadingBar__background');
  const fillClasses = classnames('LoadingBar__fill', {
    'LoadingBar__fill--red': color === 'red' && !isStatic,
    'LoadingBar__fill--green': color === 'green' && !isStatic,
    'LoadingBar__fill--green--static': color === 'green' && isStatic,
    'LoadingBar__fill--red--static': color === 'red' && isStatic,
  });
  const textClasses = classnames('LoadingBar__percent');

  return (
    <LoadingProgress
      isComplete={isComplete}
      percentage={percentage}
      initialPercentage={initialPercentage}
    >
      {currentProgress => (
        <div className={classes}>
          <div className={bgClasses}>
            <div
              className={fillClasses}
              style={{ width: `${currentProgress}%` }}
            />
          </div>
          {showPercentage && (
            <div className={textClasses}>{currentProgress}%</div>
          )}
        </div>
      )}
    </LoadingProgress>
  );
};

LoadingBar.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Color variant
   */
  color: PropTypes.oneOf(['red', 'green']),

  /**
   * When set to true, renders a smaller loading bar
   */
  isSmall: PropTypes.bool,

  /**
   * When set to true, the LoadingBar will immediately animate to the end
   */
  isComplete: PropTypes.bool,

  /**
   * Percent complete. Will animate to this percentage after load
   */
  percentage: PropTypes.number,

  /**
   * Percentage complete to show on initial load
   */
  initialPercentage: PropTypes.number,

  /**
   * When set to true, the current percentage will be displayed as text
   */
  showPercentage: PropTypes.bool,

  /**
   * When true - show static progress bar without animation
   */
  isStatic: PropTypes.bool,
};

LoadingBar.defaultProps = {
  className: '',
  color: 'red',
  isSmall: false,
  isComplete: false,
  initialPercentage: 0,
  percentage: 0,
  showPercentage: false,
  isStatic: false,
};

export default LoadingBar;
