import defaultPhrases from 'react-dates/lib/defaultPhrases';
import datePicker from './datePicker';
import dateRangePicker from './dateRangePicker';
import keyboardShortcuts from './shortcutPanel';

const phrases = intl => ({
  ...defaultPhrases,
  ...datePicker(intl),
  ...dateRangePicker(intl),
  ...keyboardShortcuts(intl),
});

export default phrases;
