import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import Filter from '../Filter';
import './ControlStrip.less';

const ControlStrip = ({
  withLables,
  filterChildren,
  filterProps,
  actionButtons,
  actionButtonClassName,
  className,
  filterButtonBelow,
  onlyActionButtons,
  withCustomFilter,
  children,
}) => {
  const classes = classnames('ControlStrip', className, {
    'py-3': onlyActionButtons,
    'ControlStrip--withLabelsHeight': withLables,
  });
  const wrapperClasses = classnames('ControlStrip--wrapper', {
    'ControlStrip--onlyActionButtons': onlyActionButtons,
    'ControlStrip--withLabels items-start': withLables,
  });

  const actionButtonClasses = classnames(
    'ControlStrip--actionButtons',
    actionButtonClassName,
    {
      'mt-1 md:mt-6': withLables,
      'md:mb-0': withLables && !filterButtonBelow,
    },
  );

  const filterClasses = classnames('ControlStrip--filters', {
    'items-end': withLables,
    'items-center': !withLables,
  });

  const filterBelowClasses = classnames('ControlStrip--filterBelow', {
    'mt-1': withLables,
  });

  return (
    <div className={classes}>
      <div className={wrapperClasses}>
        {!onlyActionButtons && (
          <div className={filterClasses}>
            {children}
            {filterChildren && !filterButtonBelow && !withCustomFilter && (
              <Filter
                className={filterClasses}
                tagsAfterFilterBtn
                labelDefault={false}
                labelWithFilters={false}
                {...filterProps}
              >
                {filterChildren}
              </Filter>
            )}
          </div>
        )}

        <div className={actionButtonClasses}>{actionButtons}</div>
      </div>
      {filterChildren && filterButtonBelow && !withCustomFilter && (
        <>
          <Filter
            className={filterBelowClasses}
            labelDefault={false}
            labelWithFilters={false}
            {...filterProps}
          >
            {filterChildren}
          </Filter>
        </>
      )}
    </div>
  );
};

ControlStrip.defaultProps = {
  filterChildren: null,
  withLables: false,
  filterProps: {},
  actionButtons: null,
  className: '',
  actionButtonClassName: '',
  filterButtonBelow: false,
  onlyActionButtons: false,
  children: null,
  withCustomFilter: false,
};

ControlStrip.propTypes = {
  /**
   * Pass filter children
   */
  filterChildren: PropTypes.node,

  /**
   * When true - applies additional margins for the components with labels
   */
  withLables: PropTypes.bool,

  /**
   * Pass props for the Filter component
   */
  filterProps: PropTypes.shape({}),

  /**
   * Pass action buttons from the right side of the component
   */
  actionButtons: PropTypes.node,

  /**
   * Pass additional classes
   */
  className: PropTypes.string,

  /**
   * When true - display filter button below of the main row
   */
  filterButtonBelow: PropTypes.bool,

  /**
   * When true - apply styles to display only action buttons
   */
  onlyActionButtons: PropTypes.bool,

  /**
   *  Pass control strip children
   */
  children: PropTypes.node,

  /**
   * when custom filter true - pass your own filter to the children of the ControlStrip component
   */
  withCustomFilter: PropTypes.bool,

  /**
   * Additional classes for the action buttons
   */
  actionButtonClassName: PropTypes.string,
};

export default ControlStrip;
