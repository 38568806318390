/* eslint-disable prefer-object-spread */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import { Button } from 'components/Button';
import UploadStatus from './UploadStatus';

import './FileUpload.less';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

function hasRoomToAddFile(file, files, allowMultiple, maxFiles) {
  const filesArray = Object.values(files);
  const hasFileSpace =
    (!allowMultiple && filesArray.length === 0) ||
    (allowMultiple && (!maxFiles || filesArray.length < maxFiles));

  return hasFileSpace && !files[file.id];
}
class FileUpload extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * An endpoint for your file uploads or extended server configuration (see:
     * https://pqina.nl/filepond/docs/api/server/)
     */
    endpoint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        url: PropTypes.string,
        timeout: PropTypes.number,
        headers: PropTypes.any,
        process: PropTypes.any,
        revert: PropTypes.any,
        restore: PropTypes.any,
        load: PropTypes.any,
        fetch: PropTypes.any,
        remove: PropTypes.any,
      }),
    ]).isRequired,

    /**
     * Enable or disable file type validation
     */
    allowFileSizeValidation: PropTypes.bool,

    /**
     * Max individual file size in bytes
     */
    maxFileSize: PropTypes.number,

    /**
     * Min individual file size in bytes
     */
    minFileSize: PropTypes.number,

    /**
     * Max total file size in bytes
     */
    maxTotalFileSize: PropTypes.number,

    /**
     * Enable or disable file type validation
     */
    allowFileTypeValidation: PropTypes.bool,

    /**
     * What file types to accept (example: image/*, image/png)
     */
    acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),

    /**
     * Error labels
     */
    labels: PropTypes.shape({
      minFileSizeExceeded: PropTypes.node,
      minFileSize: PropTypes.node,
      maxFileSizeExceeded: PropTypes.node,
      maxFileSize: PropTypes.node,
      maxTotalFileSizeExceeded: PropTypes.node,
      maxTotalFileSize: PropTypes.node,
      fileTypeNotAllowed: PropTypes.node,
    }),

    /**
     * The maximum number of files that the FileUpload can handle
     */
    maxFiles: PropTypes.number,

    /**
     * Enable or disable adding multiple files.
     */
    allowMultiple: PropTypes.bool,

    /**
     * Initial files to restore earlier temporary uploads or already uploaded files
     */
    initialFiles: PropTypes.oneOfType([PropTypes.object]),

    /**
     * Optional callback to sync the FileUpload files with our own state
     */
    onUpdateInitialFiles: PropTypes.func,

    /**
     * Immediately upload new files to the server
     */
    isInstantUpload: PropTypes.bool,

    /**
     * Delay the progress loading of file until the file is processed
     */
    delayUploadProgress: PropTypes.bool,

    /**
     * Remove UploadStatus after currentProgress is 100
     */
    removeAfterUpload: PropTypes.bool,

    /**
     * Enable to show thumbnails for uploaded images
     */
    showImageThumbnail: PropTypes.bool,
  };

  static defaultProps = {
    className: '',

    allowFileSizeValidation: false,
    maxFileSize: null,
    minFileSize: null,
    maxTotalFileSize: null,

    labels: {
      minFileSizeExceeded: 'File is too small',
      minFileSize: 'Minimum file size is {filesize}',
      maxFileSizeExceeded: 'File is too large',
      maxFileSize: 'Maximum file size is {filesize}',
      maxTotalFileSizeExceeded: 'Maximum total size exceeded',
      maxTotalFileSize: 'Maximum total file size is {filesize}',
      fileTypeNotAllowed: 'File is of invalid type',
    },

    allowFileTypeValidation: false,
    acceptedFileTypes: [],
    allowMultiple: true,
    maxFiles: null,
    initialFiles: null,
    onUpdateInitialFiles: null,
    isInstantUpload: true,
    delayUploadProgress: false,
    removeAfterUpload: false,
    showImageThumbnail: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: props.initialFiles || {},
      // Using Javascript to style drag and hover states as drag styling using pure CSS is not currently available.
      active: false,
    };
    this.setActive = this.setActive.bind(this);
    this.setInactive = this.setInactive.bind(this);
    this.addFile = this.addFile.bind(this);
    this.processFile = this.processFile.bind(this);
    this.processFileProgress = this.processFileProgress.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.reset = this.reset.bind(this);
    this.browseFiles = this.browseFiles.bind(this);
    this.updateInitFiles = this.updateInitFiles.bind(this);
    this.processFiles = this.processFiles.bind(this);
  }

  setActive() {
    this.setState({ active: true });
  }

  setInactive() {
    this.setState({ active: false });
  }

  addFile(error, file) {
    /* eslint-disable no-param-reassign */
    file.setMetadata('id', file.id, true);

    // eslint-disable-next-line react/no-access-state-in-setstate
    const files = Object.assign({}, this.state.files);
    const { allowMultiple, maxFiles } = this.props;

    if (!hasRoomToAddFile(file, files, allowMultiple, maxFiles)) {
      return;
    }

    const hasUIError =
      error &&
      (this.props.allowFileTypeValidation ||
        this.props.allowFileSizeValidation);
    if (hasUIError) {
      let errorMessage = error.main || '';
      errorMessage += errorMessage ? ': ' : '';
      errorMessage += error.sub || '';
      file.errorMessage = errorMessage;
    } else {
      // if don't need to immediately upload and no UI errors,
      // mark the file like successfully uploaded to show the green checkmark icon on UploadStatus
      file.currentProgress =
        !this.props.isInstantUpload && !this.props.delayUploadProgress
          ? 100
          : 0;
    }

    files[file.id] = file;
    this.setState({ files });
    this.updateInitFiles(files);
  }

  updateInitFiles(newFiles) {
    const { onUpdateInitialFiles } = this.props;
    if (onUpdateInitialFiles) {
      onUpdateInitialFiles(newFiles);
    }
  }

  processFile(error, file) {
    if (!error) return;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const files = Object.assign({}, this.state.files);
    const currentFile = files[file.id];
    currentFile.errorMessage = `${error.code}: ${error.body}`;
    files[file.id] = currentFile;

    this.setState({ files });
    this.updateInitFiles(files);
  }

  processFileProgress(file, progress) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const files = Object.assign({}, this.state.files);

    if (file && files[file.id]) {
      const percentageComplete = Math.floor(progress * 100);
      const currentFile = files[file.id];
      currentFile.currentProgress = percentageComplete;
      files[file.id] = currentFile;
      this.setState({ files });
      this.updateInitFiles(files);
    }
  }

  processFiles() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const files = Object.assign({}, this.state.files);
    const newFiles = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(this.state.files)) {
      if (files[key].currentProgress === 100) {
        if (!this.props.removeAfterUpload) {
          newFiles[key] = files[key];
        } else {
          this.pond.removeFile(files[key].id);
        }
      }
    }
    this.setState({ files: newFiles });
    this.updateInitFiles(newFiles);
  }

  removeFile(event) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const files = Object.assign({}, this.state.files); // Create a copy of the previous state
    const fileId = event.target.parentNode.dataset.id;

    this.pond.removeFile(fileId);
    delete files[fileId];
    this.setState({ files });
    this.updateInitFiles(files);
  }

  browseFiles(event) {
    const pressedKey = event.which;

    if (!pressedKey || pressedKey === 13 || pressedKey === 32) {
      this.pond.browse();
    }
  }

  fileList() {
    const { files } = this.state;
    const filesArray = Object.values(files);

    return filesArray.length > 0
      ? filesArray.map(fileObject => (
          <UploadStatus
            key={fileObject.id}
            id={fileObject.id}
            removeFileHandler={this.removeFile}
            currentProgress={fileObject.currentProgress}
            errorMessage={fileObject.errorMessage}
            file={fileObject.file}
            showImageThumbnail={this.props.showImageThumbnail}
          />
        ))
      : null;
  }

  reset() {
    this.pond.removeFiles();
    this.setState({ files: {}, active: false });
    this.updateInitFiles({});
  }

  render() {
    const { active, files } = this.state;
    const filesArray = Object.values(files);
    const {
      endpoint,
      className,
      allowFileSizeValidation,
      maxFileSize,
      minFileSize,
      maxTotalFileSize,
      labels,
      allowFileTypeValidation,
      acceptedFileTypes,
      allowMultiple = true,
      maxFiles,
      isInstantUpload,
    } = this.props;
    const fileUploadClasses = classnames('FileUpload', className);
    const iconClasses = classnames('FileUpload__icon', {
      'FileUpload__icon--active': active,
    });

    return (
      <div
        onMouseOver={this.setActive}
        onMouseOut={this.setInactive}
        onDragOver={this.setActive}
        onDragLeave={this.setInactive}
        onFocus={this.setActive}
        onBlur={this.setInactive}
        className={fileUploadClasses}
      >
        <div className="FileUpload__area">
          <div className={iconClasses}>
            <Icon type="cloud-upload" />
          </div>
          <FormattedMessage
            defaultMessage="Drag and drop files here {br}or{br}"
            description="FileUpload drag files message"
            values={{
              br: <br />,
            }}
          />
          <Button
            onClick={this.browseFiles}
            onKeyDown={this.browseFiles}
            isSecondary
          >
            <FormattedMessage
              defaultMessage="Browse files"
              description="FileUpload browse files message"
            />
          </Button>
        </div>
        <FilePond
          files={filesArray}
          credits={false}
          className="FileUpload__filepond"
          labelIdle=""
          allowMultiple={allowMultiple}
          maxFiles={maxFiles}
          dropOnElement
          instantUpload={isInstantUpload}
          labelTapToRetry=""
          onprocessfile={this.processFile}
          onprocessfileprogress={this.processFileProgress}
          server={endpoint}
          onprocessfiles={this.processFiles}
          onaddfile={this.addFile}
          ref={ref => (this.pond = ref)}
          allowFileSizeValidation={allowFileSizeValidation}
          maxFileSize={maxFileSize}
          minFileSize={minFileSize}
          maxTotalFileSize={maxTotalFileSize}
          allowFileTypeValidation={allowFileTypeValidation}
          acceptedFileTypes={acceptedFileTypes}
          labelMinFileSizeExceeded={labels.minFileSizeExceeded}
          labelMinFileSize={labels.minFileSize}
          labelMaxFileSizeExceeded={labels.maxFileSizeExceeded}
          labelMaxFileSize={labels.maxFileSize}
          labelMaxTotalFileSizeExceeded={labels.maxTotalFileSizeExceeded}
          labelMaxTotalFileSize={labels.maxTotalFileSize}
          labelFileTypeNotAllowed={labels.fileTypeNotAllowed}
        />

        <div className="UploadStatus__list">{this.fileList()} </div>
      </div>
    );
  }
}

export default FileUpload;
