import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Thumbnail.less';

const Thumbnail = props => {
  const { image, alt, variation, className, ...rest } = props;

  const classes = classnames('Thumbnail', className, {
    [`Thumbnail--${variation}`]: variation,
  });

  return <img src={image} alt={alt} className={classes} {...rest} />;
};

Thumbnail.propTypes = {
  /**
   * Image path
   */
  image: PropTypes.string.isRequired,

  /**
   * Alt attribute for image
   */
  alt: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Thumbnail variations to choose from
   */
  variation: PropTypes.oneOf(['md', 'lg', 'sm']),
};

Thumbnail.defaultProps = {
  variation: 'md',
  alt: '',
  className: '',
};

export default Thumbnail;
