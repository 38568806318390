import React from 'react';
import PropTypes from 'prop-types';

const CardExpandableContent = props => {
  const { children, label } = props;

  return <div label={label}>{children}</div>;
};

CardExpandableContent.propTypes = {
  /**
   * Child nodes for expanded details
   */
  children: PropTypes.node.isRequired,

  /**
   * Expand label to activate hover
   */
  label: PropTypes.node.isRequired,
};

export default CardExpandableContent;
