import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './PopoverActions.less';

const PopoverActions = props => {
  const { children, className, ...rest } = props;
  const classes = classnames('PopoverActions', className);

  const wrappedChildren = React.Children.map(children, child =>
    child ? <li className="PopoverActions__item">{child}</li> : null,
  );

  return (
    <ul className={classes} {...rest}>
      {wrappedChildren}
    </ul>
  );
};

PopoverActions.propTypes = {
  /**
   * Child PopoverAction nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

PopoverActions.defaultProps = {
  className: '',
};

export default PopoverActions;
