import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CMPFormState, CMPValue, StatusRequest } from '../../constants/interfaces';
import CcpaAPI from '../../actions/ccpa';
import { CMP_VALUE, UserConsentSelection } from '../../constants/constants';
import { Redirect } from 'react-router-dom';
import { Grid, GridCell, Heading, PageSection, RadioButton, Select, Text, TextInput } from '@blueprints2/core';
import CMPDropdown from './cmp-dropdown';
import { getCmpName, getOthers, getSelectedConsent } from '../../constants/functions';

interface CMPNotDetectedProps {
	onSelected: Function;
	onFormSubmitted: Function;
	className?: string;
	cmpName: string;
	cmpType: string;
	values: CMPValue[];
}

const CMPNotDetected = (props: CMPNotDetectedProps) => {
	const { onSelected, onFormSubmitted, className, cmpName, cmpType, values } = props;
	const [formState, setFormState] = useState({
		selectedConsent: getSelectedConsent(cmpType),
		cmpName: getCmpName(values, cmpName, cmpType),
		cmpType: cmpType,
		others: getOthers(values, cmpName, cmpType),
		redirect: false,
	} as CMPFormState);

	const linkStyle = {
		fontSize: 16,
		color: '#7141d8',
		fontWeight: 400,
	};

	const dispatch = useDispatch();

	const redirectCallback = () => {
		setFormState({ ...formState, redirect: true });
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		if (form.checkValidity() && formState.selectedConsent !== undefined) {
			saveCMPStatus();
		}
		event.preventDefault();
		event.stopPropagation();
	};

	const saveCMPStatus = async () => {
		const request = {
			name: formState.cmpName === 'cmpothers' || formState.cmpName === 'cookieothers' ? formState.others : formState.cmpName,
			type: formState.cmpType,
			value: formState.cmpName,
			detected: false,
		} as StatusRequest;
		onFormSubmitted(true);
		await dispatch(CcpaAPI.saveCMPStatus(request, redirectCallback, onFormSubmitted));
	};

	const onSelectionChange = (e: any) => {
		const val = e.value as unknown as UserConsentSelection;
		//@ts-ignore
		setFormState({ ...formState, selectedConsent: val, others: '', cmpName: '', cmpType: '' });
		if (val === UserConsentSelection.CMPPlatform || val === UserConsentSelection.CookieConsent) {
			if (formState.cmpName === '' || formState.cmpName === 'cmpothers' || formState.cmpName === 'cookieothers') {
				onSelected(false);
			}
		}
	};

	if (formState.redirect) {
		return <Redirect to={{ pathname: '/home' }} />;
	}

	return (
		<PageSection className={className}>
			<Text>
				<Heading level={2}>Ok, how do you manage user consent? If you're unsure, check your apps section.</Heading>
			</Text>

			<form id='notDetectedCMP' onSubmit={(e) => handleSubmit(e)} className='mt-8'>
				<span style={{ display: 'block' }} className='mb-6'>
					<RadioButton
						isChecked={formState.selectedConsent === UserConsentSelection.JustUpdatedPrivacyPolicy}
						name='cmp-selection'
						label='Updating my privacy policy'
						value={UserConsentSelection.JustUpdatedPrivacyPolicy}
						onChange={(e: any) => {
							const val = e.value as unknown as UserConsentSelection;
							// @ts-ignore
							setFormState({ ...formState, selectedConsent: val, others: '', cmpName: 'privacy', cmpType: 'privacyPolicy' });
							onSelected(true);
						}}
					/>
					<Text className='ml-8 text-gray-500' style={{ fontSize: '12px' }}>
						Updating the wording of your store's policy is most common
					</Text>
					{formState.selectedConsent === UserConsentSelection.JustUpdatedPrivacyPolicy && (
						<Text style={{ marginLeft: '28px' }}>
							Please use this implementation guide to update your privacy policy.
							<p className='text-purple-500'>
								<a
									style={linkStyle}
									rel='noopener noreferrer'
									href='https://rak.app.box.com/s/r5vh311l1yfcdetbkm1zu6qlf8bqwsb8'
									target='_blank'
								>
									View implementation guide
								</a>
							</p>
						</Text>
					)}
				</span>
				<span style={{ display: 'block' }} className='mb-6'>
					<RadioButton
						isChecked={formState.selectedConsent === UserConsentSelection.CMPPlatform}
						name='cmp-selection'
						label='Consent management platform'
						value={UserConsentSelection.CMPPlatform}
						onChange={(e: any) => onSelectionChange(e)}
					/>

					{formState.selectedConsent === UserConsentSelection.CMPPlatform && (
						<>
							<CMPDropdown state={formState} setState={setFormState} values={values} onSelect={onSelected} />
						</>
					)}
					{formState.selectedConsent === UserConsentSelection.CMPPlatform && formState.cmpName && formState.cmpName === 'cmpothers' && (
						<Text style={{ marginLeft: '28px' }}>
							Please use this implementation guide.
							<p className='text-purple-500'>
								<a
									style={linkStyle}
									rel='noopener noreferrer'
									href='https://rak.app.box.com/s/jbj68qegtumzejee9f9cju2u0a1iwpai'
									target='_blank'
								>
									View implementation guide
								</a>
							</p>
						</Text>
					)}
				</span>

				<span style={{ display: 'block' }} className='mb-6'>
					<RadioButton
						isChecked={formState.selectedConsent === UserConsentSelection.CookieConsent}
						name='cmp-selection'
						label='Cookie banner'
						value={UserConsentSelection.CookieConsent}
						onChange={(e: any) => onSelectionChange(e)}
						className='mt-2'
					/>
					{formState.selectedConsent !== UserConsentSelection.CookieConsent && (
						<Text className='ml-8 text-gray-500' style={{ fontSize: '12px' }}>
							E.g. Cookie banner name, cookie banner name etc.
						</Text>
					)}

					{formState.selectedConsent === UserConsentSelection.CookieConsent && (
						<>
							<Select
								value={formState.cmpName}
								hasHiddenLabel
								className='ml-8'
								onChange={(option: any) => {
									setFormState({ ...formState, cmpName: option.value, cmpType: CMP_VALUE.cookie });
									if (option.value === '') {
										onSelected(false);
									} else if (option.value === 'cookieothers') {
										if (formState.others === '') {
											onSelected(false);
										} else {
											onSelected(true);
										}
									} else {
										onSelected(true);
									}
								}}
							>
								<option value=''>Please select</option>
								{values.map((item) => {
									if (item.type === CMP_VALUE.cookie) {
										return (
											<option key={item.type} value={item.name}>
												{item.name}
											</option>
										);
									}
								})}
								<option value='cookieothers'>Other - Please specify</option>
							</Select>
							{formState.cmpName === 'cookieothers' && (
								<Grid className='ml-8'>
									<GridCell width='1/4'>
										<TextInput
											label='Other'
											value={formState.others}
											onChange={(text: any) => {
												setFormState({ ...formState, others: text.value });
												if (text.value === '') {
													onSelected(false);
												} else {
													onSelected(true);
												}
											}}
										/>
									</GridCell>
								</Grid>
							)}
						</>
					)}
					{formState.selectedConsent === UserConsentSelection.CookieConsent && (
						<Text style={{ marginLeft: '28px' }}>
							Please use this implementation guide.
							<p className='text-purple-500'>
								<a
									style={linkStyle}
									rel='noopener noreferrer'
									href='https://rak.app.box.com/s/jbj68qegtumzejee9f9cju2u0a1iwpai'
									target='_blank'
								>
									View implementation guide
								</a>
							</p>
						</Text>
					)}
				</span>
				<span style={{ display: 'block' }} className='mb-6'>
					<RadioButton
						isChecked={formState.selectedConsent === UserConsentSelection.DontHave}
						name='cmp-selection'
						label="I don't have a way to capture consent"
						value={UserConsentSelection.DontHave}
						onChange={(e: any) => {
							const val = e.value as unknown as UserConsentSelection;
							setFormState({
								...formState,
								selectedConsent: val,
								others: '',
								cmpName: 'I don\'t have a way to capture consent',
								cmpType: 'dontHave',
							});
							onSelected(false);
						}}
						className='mt-2'
					/>
					{formState.selectedConsent === UserConsentSelection.DontHave && (
						<>
							<Text style={{ marginLeft: '28px' }}>
								Updating your Privacy Policy is the simplest way to do this. Select the option above to view and download guidelines.
							</Text>
							<Text style={{ marginLeft: '28px' }}>
								Alternatively, install a CMP with IAB framework from the{' '}
								<a style={{ color: '#7141d8' }} rel='noopener noreferrer' href='https://apps.shopify.com/' target='_blank'>
									Shopify App Store
								</a>
								. When you're done, come back here to complete this step.
							</Text>
						</>
					)}
				</span>
				<span style={{ display: 'block' }} className='mb-6'>
					<RadioButton
						isChecked={formState.selectedConsent === UserConsentSelection.NotSure}
						name='cmp-selection'
						label="I'm not sure"
						value={UserConsentSelection.NotSure}
						onChange={(e: any) => {
							const val = e.value as unknown as UserConsentSelection;
							setFormState({ ...formState, selectedConsent: val, others: '', cmpName: 'notSure', cmpType: 'notSure' });
							onSelected(true);
						}}
						className='mt-2'
					/>
					{formState.selectedConsent === UserConsentSelection.NotSure && (
						<>
							<Text style={{ marginLeft: '28px' }}>No problem, our launch specialists can contact you to help out.</Text>
						</>
					)}
				</span>
			</form>
		</PageSection>
	);
};

export default CMPNotDetected;
