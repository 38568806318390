import { CMP_VALUE, UserConsentSelection } from './constants';
import { CMPValue } from './interfaces';

export const getSelectedConsent = (cmpType: string) => {
	if (cmpType) {
		if (cmpType === 'privacyPolicy') {
			return UserConsentSelection.JustUpdatedPrivacyPolicy;
		}
		if (cmpType === 'notSure') {
			return UserConsentSelection.NotSure;
		}
		if (cmpType === CMP_VALUE.iabFramework) {
			return UserConsentSelection.CMPPlatform;
		}
		if (cmpType === CMP_VALUE.cookie) {
			return UserConsentSelection.CookieConsent;
		}
	}
	return undefined;
};

export const getCmpName = (cmpValues: CMPValue[], cmpName: string, cmpType: string) => {
	if (cmpType === CMP_VALUE.iabFramework) {
		return cmpValues.filter((value) => value.name === cmpName).length === 1 ? cmpName : 'cmpothers';
	}
	if (cmpType === CMP_VALUE.cookie) {
		return cmpValues.filter((value) => value.name === cmpName).length === 1 ? cmpName : 'cookieothers';
	}
	return '';
};

export const getOthers = (cmpValues: CMPValue[], cmpName: string, cmpType: string) => {
	if (cmpType === CMP_VALUE.iabFramework || cmpType === CMP_VALUE.cookie) {
		return cmpValues.filter((value) => value.name === cmpName).length === 1 ? '' : cmpName;
	}

	return '';
};

export const processFile = (data: ArrayBuffer, filename: string | null = 'data', contentType: string) => {
	const link = document.createElement('a');
	try {
		const blob = new Blob([data], {
			type: contentType,
		});
		link.href = window.URL.createObjectURL(blob);
		link.setAttribute('download', `${filename}`);
		link.click();
	} catch (error) {
		console.log(error);
	} finally {
		link.remove();
	}
};
