import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'components/Logo';
import AppSwitcher from 'components/AppSwitcher';
import './Header.less';
import { typeOfInstance } from 'components/helpers';
import Navbar from './Navbar';
import { actionShape, linkShape } from './propTypes';

const Header = ({
  title,
  logo,
  logoContainer: LogoContainer,
  links,
  linksAs,
  actions,
  appSwitcher,
}) => {
  return (
    <header className="Header" id="HeaderContainer">
      <div className="Header__container">
        <div className="Header__topBar">
          <div className="Header__topBarContainer">
            <LogoContainer>
              <Logo logoImg={logo} title={title} />
            </LogoContainer>

            {appSwitcher}
          </div>
        </div>

        <Navbar links={links} linksAs={linksAs} actions={actions} />
      </div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(linkShape),
  linksAs: PropTypes.elementType,
  actions: PropTypes.arrayOf(actionShape),
  logo: PropTypes.node,
  logoContainer: PropTypes.elementType,
  appSwitcher: typeOfInstance(AppSwitcher),
};

Header.defaultProps = {
  links: null,
  linksAs: 'a',
  actions: null,
  logo: null,
  logoContainer: 'span',
  appSwitcher: null,
};

export default Header;
