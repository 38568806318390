import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render Prop helper - reuse progress state logic
 * between multiple Loading components.
 *
 * @see https://reactjs.org/docs/render-props.html
 */
class LoadingProgress extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    // @see LoadingBar or LoadingSpinner for prop descriptions
    isComplete: PropTypes.bool.isRequired,
    percentage: PropTypes.number.isRequired,
    initialPercentage: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { currentProgress: this.props.initialPercentage };
  }

  componentDidMount() {
    const { isComplete, percentage } = this.props;

    if (isComplete || percentage) {
      window.requestAnimationFrame(() => {
        this.setProgress(isComplete, percentage);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isComplete, percentage } = this.props;

    const progressChanged =
      prevProps.isComplete !== isComplete ||
      prevProps.percentage !== percentage;

    if (progressChanged) {
      this.setProgress(isComplete, percentage);
    }
  }

  setProgress(isComplete, percentage) {
    const newPercentage = isComplete || percentage > 100 ? 100 : percentage;
    this.setState({ currentProgress: newPercentage });
  }

  render() {
    return this.props.children(this.state.currentProgress);
  }
}

export default LoadingProgress;
