import React from 'react';
import shortid from 'shortid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import './NestedRadio.less';

class NestedRadio extends React.Component {
  static propTypes = {
    /**
     * Secondary Label to be displayed under Label
     */
    subLabel: PropTypes.node,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * NestedRadio label
     */
    label: PropTypes.node.isRequired,

    /**
     * Helper text that displays under the NestedRadio label
     */
    helperText: PropTypes.node,

    /**
     * Change default circle for green check icon
     */
    withIcon: PropTypes.bool,

    /**
     * Renders selected/checked state
     */
    isChecked: PropTypes.bool,

    /**
     * Marks the NestedRadio as required, triggering HTML5 validation in modern browsers
     */
    isRequired: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the NestedRadio is required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Handler to call when the NestedRadio state has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Adds asterisk next to the label to indicate the AdvancedSelect is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * NestedRadio name
     */
    name: PropTypes.string,

    /**
     * NestedRadio ID
     */
    id: PropTypes.string,

    /**
     * NestedRadio value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Add border
     */
    withBorder: PropTypes.bool,

    /** a
     * Tooltip props, the tooltip will render onClick of '?' button
     *
     * @see Tooltip.jsx for prop details
     */
    tooltip: PropTypes.shape({
      heading: PropTypes.node,
      body: PropTypes.node.isRequired,
      position: PropTypes.oneOf([
        'top-left',
        'top',
        'top-right',
        'right',
        'bottom-right',
        'bottom',
        'bottom-left',
        'left',
        'center',
      ]),
      className: PropTypes.string,
    }),
  };

  static defaultProps = {
    subLabel: null,
    id: '',
    className: '',
    isChecked: false,
    isRequired: undefined,
    html5: undefined,
    isDisabled: undefined,
    onChange: () => {},
    helperText: '',
    withIcon: null,
    name: '',
    withBorder: false,
    tooltip: undefined,
    hasAsterisk: false,
    hasHiddenLabel: false,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.id = this.props.id || `NestedRadio-${shortid.generate()}`;
  }

  handleChange(event) {
    event.persist();
    const { onChange, value, name } = this.props;

    onChange({ value, name, event });
  }

  render() {
    const {
      isChecked,
      className,
      label,
      subLabel,
      name,
      value,
      tooltip,
      isDisabled,
      isRequired,
      id,
      html5,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      hasHiddenLabel,
      hasAsterisk,
      withIcon,
      helperText,
      ...rest
    } = this.props;
    const classes = classnames('NestedRadio', className, {
      'NestedRadio--checked': isChecked,
      'NestedCheckbox--tooltip': !!tooltip,
    });

    const labelContainerClasses = classnames('NestedRadio__labelContainer', {
      'NestedRadio__labelContainer--hasSubLabel': !!subLabel,
    });

    return (
      <div
        className={classes}
        onClick={this.handleChange}
        role="presentation"
        {...rest}
      >
        <div className={labelContainerClasses}>
          <div className="NestedRadio__label">
            <input
              className="NestedRadioInput"
              type="radio"
              id={this.id}
              name={name}
              value={value}
              required={isRequired && html5}
              checked={isChecked}
              disabled={isDisabled}
              readOnly
            />

            <span title={label}>{label}</span>
          </div>

          {subLabel && (
            <div title={label} className="NestedRadio__subLabel">
              {subLabel}
            </div>
          )}
        </div>

        {tooltip && (
          <div className="NestedRadio__TooltipWrapper">
            <div className="NestedRadio__TooltipIconWrapper">
              <Tooltip
                heading={tooltip.heading}
                position={tooltip.position || 'right'}
                body={tooltip.body}
                className={tooltip.className}
              >
                {toggleVisibility => (
                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      toggleVisibility();
                    }}
                  >
                    <Icon
                      type="question-solid"
                      className="NestedRadio__TooltipIcon"
                    />
                  </button>
                )}
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NestedRadio;
