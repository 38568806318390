import PropTypes from 'prop-types';

export const linkShape = PropTypes.shape({
  label: PropTypes.node,
  isVisible: PropTypes.bool,
});

export const actionShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  content: PropTypes.elementType.isRequired,
  props: PropTypes.object,
  hideArrow: PropTypes.bool,
});
