/**
 * Counts the amount of filters in a given array (Object.entries type array)
 *
 * @param {array} filters
 */
const countFilters = filters => {
  return filters.reduce((acc, [, filterValue]) => {
    if (Array.isArray(filterValue)) {
      return acc + filterValue.length;
    }

    if (
      filterValue === null ||
      (typeof filterValue === 'string' && filterValue === '') ||
      (typeof filterValue === 'boolean' && filterValue === false)
    ) {
      return acc;
    }

    if (Array.isArray(filterValue)) {
      return acc + filterValue.length;
    }

    return acc + 1;
  }, 0);
};

export default countFilters;
