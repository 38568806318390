import React from 'react';
import { Icon, Text } from '@blueprints2/core';

interface MidCheckboxProps {
	isActive: boolean;
	name: string;
	mid: string;
	type: string;
	country: string;
	onMidSelected: Function;
}

const MidCheckbox = (props: MidCheckboxProps) => {
	const { isActive, name, mid, type, country, onMidSelected } = props;

	return (
		<span
			style={{ display: 'flex', paddingTop: 30, cursor: isActive ? 'inherit' : 'pointer', marginLeft: 20 }}
			onClick={() => {
				if (!isActive) {
					onMidSelected(mid);
				}
			}}
		>
			<span>
				{isActive ? (
					<Icon type='checkmark-solid' className='w-4 h-8 text-green-500' />
				) : (
					<svg height='36' width='18'>
						<circle cx='9' cy='18' stroke='black' strokeWidth='1' r='8' fill='white' />
					</svg>
				)}
			</span>
			<span style={{ fontSize: '12px', marginLeft: 10 }}>
				<Text variation='strong' style={{ fontSize: '12px', marginBottom: 0, textTransform: 'capitalize' }}>
					{name} ({country.toUpperCase()})
				</Text>
				<Text style={{ fontSize: '12px', marginBottom: 0 }}>
					MID: {mid} / <span style={{ textTransform: 'capitalize' }}>{type}</span>
				</Text>
			</span>
		</span>
	);
};

export default MidCheckbox;
