import React, { memo } from 'react';
import './PromotionalBannerWrapper.less';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PromotionalBannerWrapper = memo(({ children, className }) => {
  return (
    <div className={classnames('PromotionalBannerWrapper', className)}>
      {children}
    </div>
  );
});

PromotionalBannerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PromotionalBannerWrapper.defaultProps = {
  className: '',
};

export default PromotionalBannerWrapper;
