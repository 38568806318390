import { console } from '../components/common';
import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import { HOME_STATUSES_CLEAR, THIRD_PARTY_DETAILS_GET, THIRD_PARTY_STATUS_GET } from '../constants/action-types';
import { ThirdPartyRequest } from '../constants/interfaces';

export default {
	getStatus: (errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.thirdPartyApp.status);
			dispatch({ type: THIRD_PARTY_STATUS_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while getting 3rd party app status: ' + err.response.data.message);
			errorCallback(err.response.status);
		}
	},

	getDetails: (errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.thirdPartyApp.get);
			dispatch({ type: THIRD_PARTY_DETAILS_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while getting onboarding status: ' + err.response.data.message);
			errorCallback(err.response.status);
		}
	},
	saveDetails: (request: ThirdPartyRequest, errorCallback: Function, processingCallback: Function) => async (dispatch: Dispatch) => {
		try {
			await ShopifyAPi.post(API.V1.thirdPartyApp.saveDetails, request);
			processingCallback(false);
		} catch (err: any) {
			console.log('Error while saving third party request: ' + err.response.data.message);
			processingCallback(true);
		}
	},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: HOME_STATUSES_CLEAR }),
};
