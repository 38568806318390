import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';

import { defineMessages, injectIntl } from 'react-intl';

import InputLabel from 'components/InputLabel';
import Icon from 'components/Icon';
import InputLabelWithIcon from '../InputLabelWithIcon';

import './SearchBar.less';

class SearchBar extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Input name
     */
    name: PropTypes.string,

    /**
     * Input ID
     */
    id: PropTypes.string,

    /**
     * Controlled content of the input
     */
    value: PropTypes.string,

    /**
     * Placeholder text
     */
    placeholder: PropTypes.node,

    /**
     * Helper text that can be used to provide context or hints
     */
    helperText: PropTypes.node,

    /**
     * Input label
     */
    label: PropTypes.node,

    /**
     * If true, displays the label instead of visually hiding it
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Renders a smaller TextInput
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger TextInput
     */
    isLarge: PropTypes.bool,

    /**
     * Marks the SearchBar as required, triggering HTML5 validation in modern browsers
     */
    isRequired: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Triggers an action whenever the input's onChange event fires
     *
     * @param {Object} args - {value, event}
     */
    onChange: PropTypes.func,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    id: '',
    name: '',
    value: '',
    placeholder: '',
    helperText: '',
    label: 'Search',
    hasHiddenLabel: true,
    isSmall: false,
    isLarge: false,
    isRequired: false,
    isDisabled: false,
    isValid: false,
    onChange: () => {},
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
  });

  constructor(props) {
    super(props);
    this.state = {};

    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.id = this.props.id || `SearchBar-${shortid.generate()}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    // This allows parent components to dynamically update input content via the value="" prop
    return value !== state.value && value !== state.previousValue
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  onChange(event) {
    event.persist();
    const { value } = event.target;

    this.setState({ value }, () => {
      this.props.onChange({ value, event });
    });
  }

  onClear(event) {
    event.target.value = ''; // eslint-disable-line no-param-reassign
    this.onChange(event);
  }

  render() {
    const { value } = this.state;
    const {
      className,
      id,
      name,
      placeholder,
      helperText,
      label,
      hasHiddenLabel,
      isSmall,
      isLarge,
      isRequired,
      isDisabled,
      onChange,
      intl,
      isValid,
      ...rest
    } = this.props;

    const messages = defineMessages({
      placeholder: {
        defaultMessage: 'Search',
        description: 'SearchBar input placeholder',
      },
      ariaLabel: {
        defaultMessage: 'Clear search',
        description: 'SearchBar cancel ariaLabel',
      },
      validated: {
        defaultMessage: 'Validated',
        description: 'validated',
      },
    });

    const canClear = value && value.length > 0 && !isDisabled;

    const classes = classnames('SearchBar__element', className, {
      'SearchBar__element--disabled': isDisabled,
      'SearchBar__element--small': isSmall,
      'SearchBar__element--large': isLarge,
      'SearchBar__element--valid': isValid,
    });
    const iconClasses = classnames('SearchBar__icon', {
      'SearchBar__icon--disabled': isDisabled,
      'SearchBar__icon--small': isSmall,
      'SearchBar__icon--large': isLarge,
    });

    const clearClasses = classnames('SearchBar__clearButton', {
      'SearchBar__clearButton--small': isSmall,
      'SearchBar__clearButton--large': isLarge,
    });

    const iconTitle = intl.formatMessage(this.messages.validated);
    const iconType = 'checkmark-solid';

    return (
      <div className="SearchBar">
        <InputLabel htmlFor={this.id} isHidden={hasHiddenLabel}>
          {label}
        </InputLabel>

        <div className="SearchBar__wrapper">
          <input
            {...rest}
            className={classes}
            placeholder={
              placeholder || intl.formatMessage(messages.placeholder)
            }
            value={value}
            id={this.id}
            name={name || this.id}
            onChange={this.onChange}
            required={isRequired}
            disabled={isDisabled}
          />

          <Icon type="search" className={iconClasses} />
          {canClear && (
            <button
              type="button"
              className={clearClasses}
              aria-label={intl.formatMessage(messages.ariaLabel)}
              onClick={this.onClear}
            >
              <Icon type="close-solid" />
            </button>
          )}
        </div>
        {isValid && (
          <InputLabelWithIcon
            iconTitle={iconTitle}
            iconType={iconType}
            helperText={helperText}
            iconClasses="SearchBar__icon__validation SearchBar__icon--valid"
          />
        )}
      </div>
    );
  }
}

export default injectIntl(SearchBar);
