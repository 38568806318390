import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import Icon from 'components/Icon';

import './ThreeDotMenu.less';
import PopoverMenu from 'components/PopoverMenu';

class ThreeDotMenu extends Component {
  messages = defineMessages({
    openOptions: {
      defaultMessage: 'Open',
      description: 'open',
    },
  });

  constructor(props) {
    super(props);

    this.state = { visible: false };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.anchor = React.createRef();
  }

  open() {
    this.setState({ visible: true });
  }

  close() {
    this.setState({ visible: false });
  }

  render() {
    const { options, intl } = this.props;
    const { visible } = this.state;

    return (
      <div className="ThreeDotMenu">
        <button
          type="button"
          className="ThreeDotMenu__trigger"
          aria-label={intl.formatMessage(this.messages.openOptions)}
          onClick={this.open}
          ref={this.anchor}
        >
          <Icon type="dots-horizontal-triple" />
        </button>
        <PopoverMenu
          visible={visible}
          close={this.close}
          options={options}
          anchor={this.anchor}
        />
      </div>
    );
  }
}

ThreeDotMenu.propTypes = {
  /**
   * options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      hasSeparator: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
  ).isRequired,

  /**
   * Prop from react-intl HOC
   *
   * @ignore
   */
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(ThreeDotMenu);
