import React from 'react';
import PropTypes from 'prop-types';

import 'focus-visible/dist/focus-visible';

import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';

import { getBrowserLanguage, getTranslations } from '../../i18n/helpers';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/ru';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/th';
import '@formatjs/intl-pluralrules/locale-data/vi';
import '@formatjs/intl-pluralrules/locale-data/hi';
import '@formatjs/intl-pluralrules/locale-data/ms';
import '@formatjs/intl-pluralrules/locale-data/id';
import '@formatjs/intl-pluralrules/locale-data/tl';
import '@formatjs/intl-pluralrules/locale-data/ko';
import '@formatjs/intl-pluralrules/locale-data/ja';
import '@formatjs/intl-pluralrules/locale-data/zh';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/ru';
import '@formatjs/intl-relativetimeformat/locale-data/pt';
import '@formatjs/intl-relativetimeformat/locale-data/th';
import '@formatjs/intl-relativetimeformat/locale-data/vi';
import '@formatjs/intl-relativetimeformat/locale-data/hi';
import '@formatjs/intl-relativetimeformat/locale-data/ms';
import '@formatjs/intl-relativetimeformat/locale-data/id';
import '@formatjs/intl-relativetimeformat/locale-data/ko';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/zh';

const cache = createIntlCache();

const BlueprintsProvider = ({ children, intl }) => {
  const selectedLanguage = (intl && intl.locale) || getBrowserLanguage();
  const messages = getTranslations(selectedLanguage, intl && intl.messages);

  const bpIntl = createIntl(
    {
      locale: selectedLanguage,
      messages,
    },
    cache,
  );

  return <RawIntlProvider value={bpIntl}>{children}</RawIntlProvider>;
};

BlueprintsProvider.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * React-intl object for cache, locale and messages
   */
  intl: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

BlueprintsProvider.defaultProps = {
  children: null,
  intl: null,
};

export default BlueprintsProvider;
