import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { createPortalContainer, createPortal } from 'components/helpers';

import './DataTableDragger.less';

class DataTableDragger extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable component
     */
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    columnWidths: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    reset: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
      draggedColumn: -1,
      draggingOver: -1,

      sortedColumns: props.columns,
      columnWidths: props.columnWidths,
    };

    this.dragPortal = createPortalContainer();
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragMove = this.onDragMove.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onKeyboardDrag = this.onKeyboardDrag.bind(this);
  }

  componentDidMount() {
    document.body.appendChild(this.dragPortal);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isDragging, draggedColumn, draggingOver } = this.state;

    if (prevState.isDragging !== isDragging) {
      if (isDragging) {
        document.body.addEventListener('mouseup', this.onDragEnd);
        document.body.addEventListener('touchend', this.onDragEnd);
        document.body.addEventListener('mousemove', this.onDragMove);
        document.body.addEventListener('touchmove', this.onDragMove, {
          passive: false,
        });
      } else {
        document.body.removeEventListener('mouseup', this.onDragEnd);
        document.body.removeEventListener('touchend', this.onDragEnd);
        document.body.removeEventListener('mousemove', this.onDragMove);
        document.body.removeEventListener('touchmove', this.onDragMove);

        this.setState({ draggedColumn: -1, draggingOver: -1 });
        this.onColumnReorder(draggedColumn, draggingOver);
      }
    }

    const { reset, columns, columnWidths } = this.props;

    if (prevProps.reset !== reset || prevProps.columns !== columns) {
      this.setState({ sortedColumns: columns });
    }
    if (prevProps.columnWidths !== columnWidths) {
      this.setState({ columnWidths });
    }
  }

  componentWillUnmount() {
    if (document.body.contains(this.dragPortal)) {
      document.body.removeChild(this.dragPortal);
    }

    document.body.removeEventListener('mouseup', this.onDragEnd);
    document.body.removeEventListener('touchend', this.onDragEnd);
    document.body.removeEventListener('mousemove', this.onDragMove);
    document.body.removeEventListener('touchmove', this.onDragMove);
  }

  /**
   * Events
   */

  onDragStart(event) {
    if (event.touches) event.preventDefault();

    const dragButton = event.target;
    const header = this.findClosestTag(dragButton, 'th');
    const table = this.findClosestTag(header, 'table');
    const dimensions = header.getBoundingClientRect();

    this.setState({
      isDragging: true,
      draggedColumn: header.cellIndex,
      dragPreview: this.renderDragPreview(header),
      styles: {
        top: dimensions.top,
        left: dimensions.left,
        width: dimensions.width,
      },
      offsets: {
        top: dragButton.offsetTop + 8,
        left: dragButton.offsetLeft + 8,
        // 8px / .5rem - hard-coded from DataTableDragger's padding
      },
      tableContainer: table,
    });
  }

  onDragMove(event) {
    const {
      isDragging,
      styles: { width },
      offsets,
      tableContainer,
    } = this.state;

    if (!isDragging) return;
    if (event.touches) event.preventDefault();

    // Anchor the preview to the current mouse or touch position
    const positionY = event.touches ? event.touches[0].clientY : event.clientY;
    const positionX = event.touches ? event.touches[0].clientX : event.clientX;
    const styles = {
      top: positionY - offsets.top,
      left: positionX - offsets.left,
      width,
    };

    // Determine what column the preview is currently over, in order to show the blue indicator
    const dragTarget = event.touches
      ? document.elementFromPoint(positionX, positionY)
      : event.target;
    let draggingOver = -1;
    if (tableContainer.contains(dragTarget)) {
      const currentCell = this.findClosestTag(dragTarget, ['th', 'td']);
      draggingOver = currentCell ? currentCell.cellIndex : -1;
    }

    this.setState({ styles, draggingOver });
  }

  onDragEnd() {
    this.setState({ isDragging: false });
  }

  onKeyboardDrag(event) {
    // Listen for Shift+Arrow keys
    if (!event.shiftKey) return;

    const isLeftArrow = event.keyCode === 37;
    const isRightArrow = event.keyCode === 39;
    if (!isLeftArrow && !isRightArrow) return;

    const dragButton = event.target;
    const header = this.findClosestTag(dragButton, 'th');

    const fromIndex = header.cellIndex;
    const toIndex = isLeftArrow ? fromIndex - 1 : fromIndex + 1;

    this.onColumnReorder(fromIndex, toIndex);
  }

  /**
   * Reorder logic
   */

  onColumnReorder(fromIndex, toIndex) {
    if (!toIndex || !fromIndex) return; // Don't reorder 0 (the first column), also ignore undefined
    if (fromIndex < 0 || toIndex < 0) return; // The column was dragged outside the scope of the table (i.e. drag cancelled)
    if (fromIndex === toIndex) return; // Don't need to reorder when it's the same index
    if (toIndex > this.props.columns.length - 1) return; // Don't reorder out of bounds

    this.setState(({ sortedColumns, columnWidths }) => {
      const updatedSort = Array.from(sortedColumns);
      updatedSort.splice(toIndex, 0, updatedSort.splice(fromIndex, 1)[0]);

      const updatedWidths = Array.from(columnWidths);
      if (columnWidths.length)
        updatedWidths.splice(toIndex, 0, updatedWidths.splice(fromIndex, 1)[0]);

      return {
        sortedColumns: updatedSort,
        columnWidths: updatedWidths,
      };
    });
  }

  /**
   * Rendering
   */

  /**
   * Recursive helper to find the closest parent of a DOM node with the specified tag
   *
   * @param {node} element - HTML DOM node to check
   * @param {string|array} target - Name of HTML tag we're searching for
   *                                e.g. 'table', or ['th', 'td']
   */
  findClosestTag(element, target) {
    const elementTag = ((element || {}).tagName || '').toLowerCase();

    if (!elementTag) return false; // Invalid element
    if (elementTag === 'body') return false; // Cannot traverse further
    if (elementTag === target || target.indexOf(elementTag) !== -1)
      return element; // Found a tag match

    return this.findClosestTag(element.parentNode, target); // Otherwise, keep traversing upwards
  }

  /**
   * Create the drag preview (a HTML clone of the column that we're dragging)
   *
   * Note: Due to <table>'s semi-automatic width/height behavior, we need
   * to manually freeze both the width of the column & the height of each row
   * via inline styles
   *
   * @param {node} header - HTML <th> element that's currently being dragged
   */
  renderDragPreview(header) {
    // Traverse upwards to get the parent table DOM element
    const table = this.findClosestTag(header, 'table');
    const head = table.querySelector('thead');
    const body = table.querySelector('tbody');
    const foot = table.querySelector('tfoot');

    // Identify the column index we want, and only clone those cells
    const { cellIndex } = header;
    const cells = [];
    const rows = body.getElementsByTagName('tr');
    [...rows].forEach(row => {
      const node = row.childNodes[cellIndex];
      const height = node.offsetHeight;
      const rowClasses = row.className;

      cells.push({ node, height, rowClasses });
    });

    const renderTableFoot = () => {
      const footRow = foot.getElementsByTagName('tr')[0];
      const footNode = footRow.childNodes[cellIndex];
      const footHeight = footNode.offsetHeight;
      const footRowClasses = footRow.className;

      return (
        <tfoot>
          <tr
            className={footRowClasses}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: footNode.outerHTML }}
            style={{ height: footHeight }}
          />
        </tfoot>
      );
    };

    // Don't render pinned styling in drag previews - causes visual bugs
    const tableClasses = table.className
      .replace('Table--pinnedHeader', '')
      .replace('Table--pinnedFirstColumn', '');

    // Create the drag preview template
    /* eslint-disable react/no-danger */
    return (
      <table className={tableClasses}>
        <thead className={head.className}>
          <tr
            className={header.parentNode.className}
            dangerouslySetInnerHTML={{ __html: header.outerHTML }}
          />
        </thead>
        <tbody className={body.className}>
          {cells.map((cell, i) => (
            <tr
              className={cell.rowClasses}
              dangerouslySetInnerHTML={{ __html: cell.node.outerHTML }}
              style={{ height: cell.height }}
              key={`row${i}-cell${cellIndex}`} // eslint-disable-line react/no-array-index-key
            />
          ))}
        </tbody>
        {foot && renderTableFoot()}
      </table>
    );
    /* eslint-enable react/no-danger */
  }

  /**
   * Pass drag button JSX to child DataTableHeader
   */
  renderDragButton() {
    return (
      <button
        type="button"
        className="DataTable__headerButton DataTable__headerButton--drag DataTable__dragAnchor"
        aria-label="Reorder this column"
        aria-describedby="screenReaderMessage-dragAnchor"
        onKeyDown={this.onKeyboardDrag}
        onMouseDown={this.onDragStart}
        onTouchStart={this.onDragStart}
      />
    );
  }

  render() {
    const {
      sortedColumns,
      columnWidths,
      isDragging,
      draggedColumn,
      draggingOver,
      dragPreview,
      styles,
    } = this.state;

    const tableClass = { 'DataTable--dragging': isDragging };
    const previewClasses = classnames('DataTableDragger', {
      'DataTableDragger--visible': isDragging,
    });

    return (
      <Fragment>
        {this.props.render({
          draggedColumn,
          draggingOver,
          renderDragger: this.renderDragButton(),
          dragClass: tableClass,
          columns: sortedColumns,
          columnWidths,
        })}
        {createPortal(
          <Fragment>
            <div className={previewClasses} style={styles}>
              {dragPreview}
            </div>
            <span
              className="DataTable-screenReaderMessages"
              id="screenReaderMessage-dragAnchor"
              // TODO: i18n strings? Or let browsers auto-translate this text?
            >
              Press Shift and left arrow key to reorder this column backwards by
              one. Press Shift and the right arrow key to reorder this column
              forwards by one.
            </span>
          </Fragment>,
          this.dragPortal,
        )}
      </Fragment>
    );
  }
}

export default DataTableDragger;
