import { AnyAction } from 'redux';
import { APP_CALLBACK_CLEAR, APP_CALLBACK_GET } from '../constants/action-types';

const initialState = {
	success: null,
	host: '',
};

export const appCallback = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case APP_CALLBACK_GET: {
			return {
				...state,
				success: action.data.success,
				host: action.data.host,
			};
		}
		case APP_CALLBACK_CLEAR:
			return initialState;
		default:
			return state;
	}
};
