import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './Truncate.less';

const Truncate = ({ children, lineClamp, textSize, lineHeight, className }) => {
  return (
    <div
      className={classnames('Truncated', className)}
      style={{
        WebkitLineClamp: lineClamp,
        lineHeight,
        height: `${lineClamp * textSize * lineHeight}rem`,
      }}
    >
      {children}
    </div>
  );
};

Truncate.propTypes = {
  /**
   * Element line height for general height calculations
   */
  lineHeight: PropTypes.number,

  /**
   * Element text size for general height calculations
   */
  textSize: PropTypes.number,

  /**
   * Amount of lines
   */
  lineClamp: PropTypes.number,

  /**
   * Child text/nodes
   */
  children: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Truncate.defaultProps = {
  textSize: 1,
  lineClamp: 1,
  lineHeight: 1.5,
  className: '',
  children: undefined,
};

export default Truncate;
