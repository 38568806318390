import { SPIState } from '../constants/interfaces';
import { AnyAction } from 'redux';
import { SPI_CLEAR, SPI_CREATE, SPI_GET } from '../constants/action-types';

const initialState: SPIState = {
	isScriptPlaced: undefined,
};

export const spi = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case SPI_GET:
		case SPI_CREATE: {
			return {
				...state,
				isScriptPlaced: action.data,
			};
		}
		case SPI_CLEAR:
			return initialState;
		default:
			return state;
	}
};
