import React from 'react';
import PropTypes from 'prop-types';

const DefaultAlert = props => {
  const { children, className, icon } = props;

  return (
    <div className={className} role="alert">
      {icon}
      <div className="Alert__content">{children}</div>
    </div>
  );
};

DefaultAlert.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Renders an Icon component
   */
  icon: PropTypes.node,
};

DefaultAlert.defaultProps = {
  icon: null,
  className: '',
};

export default DefaultAlert;
