import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingSpinner } from 'components/Loading';

import Icon from 'components/Icon';
import './PopoverAction.less';

const PopoverAction = props => {
  const {
    children,
    isActive,
    isDisabled,
    onClick,
    icon,
    className,
    isLoading,
    ...rest
  } = props;

  const classes = classnames('PopoverAction', className, {
    'PopoverAction--active': isActive,
    'PopoverAction--disabled': isDisabled || isLoading,
    'PopoverAction--loading': isLoading && !icon,
  });

  const renderWithIcon = () => {
    if (isLoading && icon) {
      return (
        <span className="PopoverAction__icon">
          <LoadingSpinner color="white" size="sm" />
        </span>
      );
    }
    if (isLoading) {
      return <LoadingSpinner color="white" size="sm" />;
    }
    return (
      <span className="PopoverAction__icon">
        <Icon type={icon} />
      </span>
    );
  };

  return (
    <button
      type="button"
      className={classes}
      onClick={isDisabled || isLoading ? null : onClick}
      {...rest}
    >
      {(icon || isLoading) && renderWithIcon()}
      <span
        className={classnames({
          'mr-2': isLoading,
        })}
      >
        {children}
      </span>
    </button>
  );
};

PopoverAction.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Displays active circle indicator
   */
  isActive: PropTypes.bool,

  /**
   * Renders disabled and unclickable
   */
  isDisabled: PropTypes.bool,

  /**
   * Renders loading and unclickable
   */
  isLoading: PropTypes.bool,

  /**
   * Function to trigger on click. All other events and props can also be passed in.
   */
  onClick: PropTypes.func,

  /**
   * Renders an icon when specified by class name
   */
  icon: PropTypes.string,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

PopoverAction.defaultProps = {
  isActive: false,
  isDisabled: false,
  onClick: () => {},
  icon: null,
  className: '',
  isLoading: false,
};

export default PopoverAction;
