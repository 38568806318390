import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

import Visibility from 'components/Visibility';
import Positioning from 'components/Positioning';
import './HelperTooltip.less';

class HelperTooltip extends PureComponent {
  static propTypes = {
    /**
     * Child that the HelperTooltip will be anchored to
     */
    children: PropTypes.node.isRequired,

    /**
     * Content of HelperTooltip
     */
    text: PropTypes.node.isRequired,

    /**
     * Position of HelperTooltip
     */
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),

    /**
     * Visibility preference
     */
    visibility: PropTypes.oneOf(['default', 'always', 'initial']),

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * When true - show tooltip arrow
     */
    withArrow: PropTypes.bool,

    forceHideTooltip: PropTypes.bool,
  };

  static defaultProps = {
    position: 'top',
    visibility: 'default',
    className: '',
    withArrow: true,
    forceHideTooltip: false,
  };

  constructor(props) {
    super(props);

    this.alwaysVisible = this.props.visibility === 'always';
    this.initiallyVisible = this.props.visibility === 'initial';
    const isVisible = this.alwaysVisible || this.initiallyVisible;

    this.state = {
      isVisible,
      recalculatePosition: 0,
      position: props.position,
    };

    this.onPositionFlip = this.onPositionFlip.bind(this);
    this.onPositionMove = this.onPositionMove.bind(this);
    this.onResize = this.onResize.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);

    this.container = React.createRef();
  }

  componentDidMount() {
    this.forceUpdate();

    /**
     * NOTE: This is a bit of a hack to force the
     * visible tooltips to be placed in the
     * correct position after target & tooltip
     * elements are rendered. This should only happen
     * for always or initially visible tooltips
     */
    if (this.state.isVisible) {
      this.timeout = setTimeout(() => {
        this.setState(prevState => ({
          recalculatePosition: prevState.recalculatePosition + 1,
        }));
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    const { position, forceHideTooltip } = this.props;

    if (forceHideTooltip) {
      this.setState({ isVisible: false });
    }

    if (prevProps.position !== position) {
      this.setState({ position, arrowMove: null });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onPositionFlip(position) {
    this.setState({ position });
  }

  onPositionMove(offset, direction) {
    this.setState({ arrowMove: { [`margin${direction}`]: offset } });
  }

  onResize(targetIsVisible) {
    /**
     * We only need to recalculate position onResize if
     * the tooltip is always visible or the tooltip is
     * initially visible and still visible, otherwise
     * we recalculate tooltip position on hover
     */
    if (
      targetIsVisible &&
      (this.alwaysVisible || (this.initiallyVisible && this.state.isVisible))
    ) {
      this.setState(prevState => ({
        recalculatePosition: prevState.recalculatePosition + 1,
      }));
    }
  }

  showTooltip() {
    if (!this.alwaysVisible && !this.state.isVisible) {
      this.setState({ isVisible: true, recalculatePosition: true });
    }
  }

  hideTooltip() {
    if (!this.alwaysVisible) {
      this.setState({ isVisible: false, recalculatePosition: false });
    }
  }

  render() {
    const { className, text, withArrow, children } = this.props;
    const { isVisible, position, recalculatePosition, arrowMove } = this.state;

    const tooltipClasses = classnames(
      'HelperTooltip bg-red-500',
      `HelperTooltip--${position}`,
      className,
      { 'HelperTooltip--visible': isVisible },
    );

    const arrowClasses = classnames(
      'HelperTooltip__arrow bg-red-500',
      `HelperTooltip__arrow--${position}`,
      className,
    );

    return (
      <Visibility
        scrollCheck
        resizeCheck
        container={this.container.current}
        onChange={this.onResize}
      >
        <div
          className="HelperTooltipWrapper"
          ref={this.container}
          onMouseEnter={this.showTooltip}
          onMouseLeave={this.hideTooltip}
        >
          {children}

          <Positioning
            anchor={this.container.current}
            position={position}
            onPositionFlip={this.onPositionFlip}
            onPositionMove={this.onPositionMove}
            rerender={recalculatePosition}
          >
            <div className={overrideTailwindClasses(tooltipClasses)}>
              {text}
              {withArrow && (
                <span
                  className={overrideTailwindClasses(arrowClasses)}
                  style={arrowMove}
                />
              )}
            </div>
          </Positioning>
        </div>
      </Visibility>
    );
  }
}

export default HelperTooltip;
