import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getComponentName } from 'components/helpers';
import Icon from 'components/Icon';

import './Accordion.less';

class Accordion extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Child nodes
     */
    children: PropTypes.node.isRequired,

    /**
     * Renders a smaller Accordion
     */
    isSmall: PropTypes.bool,

    /**
     * Whether or not the accordion is expanded on page load.
     * If false, the accordion will be collapsed
     */
    initiallyExpanded: PropTypes.bool,

    /**
     * Accordion title
     */
    heading: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: '',
    isSmall: false,
    initiallyExpanded: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.initiallyExpanded,
      isOpenToggle: props.initiallyExpanded,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.afterTransition = this.afterTransition.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderList = this.renderList.bind(this);

    this.content = React.createRef();
  }

  componentDidMount() {
    const content = this.content.current;
    const { initiallyExpanded } = this.props;

    if (content && !initiallyExpanded) {
      content.style.height = 0;
    }
  }

  handleClick(event) {
    event.preventDefault();
    const { isOpen } = this.state;

    if (isOpen) {
      this.collapseContent();
      this.setState({ isOpenToggle: false });
    } else {
      this.setState({ isOpen: true, isOpenToggle: true }, () =>
        this.expandContent(),
      );
    }
  }

  handleKeyDown(event) {
    // This is essentially an accessibility polyfill for IE/Edge,
    // which do not currently support summary/details behavior
    if (!('open' in document.createElement('details'))) {
      if (event.which === 13 || event.which === 32) {
        event.preventDefault();
        this.handleClick(event);
      }
    }
  }

  collapseContent() {
    const content = this.content.current;

    requestAnimationFrame(() => {
      content.style.height = `${content.scrollHeight}px`;
      content.style.overflow = 'hidden';

      requestAnimationFrame(() => {
        content.style.height = 0;
      });
    });
  }

  expandContent() {
    const content = this.content.current;

    content.style.height = `${content.scrollHeight}px`;
    content.style.overflow = 'hidden'; // Prevent scrollbars during transition
  }

  afterTransition() {
    const { isOpenToggle } = this.state;
    const content = this.content.current;

    if (isOpenToggle) {
      // Remove the inline style once the height transition is done,
      // so the section height doesn't remain static if the content changes
      content.style.height = null;
      content.style.overflow = null;
    } else {
      this.setState({ isOpen: false });
    }
  }

  renderIcon() {
    const { isOpenToggle } = this.state;
    const { className, isSmall } = this.props;
    const iconClasses = classnames('Accordion__icon', className, {
      'Accordion__icon--small': isSmall,
      'Accordion__icon--open': isOpenToggle,
    });

    return <Icon type="chevron-outline-down" className={iconClasses} />;
  }

  renderList() {
    const { className, children, isSmall } = this.props;
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return null;

      const isAccordion = getComponentName(child) === 'Accordion';
      const props = isAccordion ? { isSmall: true } : {};

      const classes = classnames(className, {
        Accordion__item: !isAccordion,
        'Accordion__item--small': !isAccordion && isSmall,
        Accordion__wrapper: isAccordion,
      });

      return <div className={classes}>{React.cloneElement(child, props)}</div>;
    });
  }

  render() {
    const { isOpen } = this.state;
    const { className, heading, isSmall } = this.props;
    const classes = classnames('Accordion', className, {
      'Accordion--small': isSmall,
    });
    const toggleClasses = classnames('Accordion__toggle', className, {
      'Accordion__toggle--small': isSmall,
    });
    const headingClasses = classnames('Accordion__heading', className, {
      'Accordion__heading--small': isSmall,
    });

    const listClasses = classnames('Accordion__list', className);

    return (
      <details className={classes} open={isOpen}>
        <summary
          className={toggleClasses}
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          role="button"
          tabIndex="0"
        >
          <div className="Accordion__headingWrapper">
            {this.renderIcon()}
            <span className={headingClasses}>{heading}</span>
          </div>
        </summary>
        <div
          className={listClasses}
          hidden={!isOpen}
          ref={this.content}
          onTransitionEnd={this.afterTransition}
        >
          {this.renderList()}
        </div>
      </details>
    );
  }
}

export default Accordion;
