import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';

import Icon from 'components/Icon';

import './NotificationItem.less';

class NotificationItem extends React.Component {
  static propTypes = {
    /**
     * id
     */
    id: PropTypes.string.isRequired,

    /**
     * title
     */
    title: PropTypes.node.isRequired,

    /**
     * icon
     */
    icon: PropTypes.string.isRequired,

    /**
     * isRead
     */
    isRead: PropTypes.bool,

    /**
     * timestamp
     */
    timestamp: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]).isRequired,

    /**
     * body
     */
    body: PropTypes.node,

    /**
     * onClick
     */
    onClick: PropTypes.func,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  messages = defineMessages({
    showMore: {
      defaultMessage: 'Show more',
      description: 'NotificationItem show message',
    },
    hide: {
      defaultMessage: 'Hide',
      description: 'NotificationItem hide message',
    },
  });

  static defaultProps = {
    isRead: false,
    body: null,
    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.state = { isShowingMore: false, isOverflowing: false };

    this.bodyWrapper = React.createRef();
    this.showMore = this.showMore.bind(this);
    this.hideMore = this.hideMore.bind(this);
  }

  componentDidMount() {
    this.checkOverflow();
  }

  checkOverflow() {
    /**
     * @see https://stackoverflow.com/questions/9333379/javascript-css-check-if-overflow
     */
    const wrapper = this.bodyWrapper.current;

    const { scrollHeight, clientHeight } = wrapper;
    // adding +1 to avoid a bug in Firefox on MAC where scroll is 1 pixel larger even when no scroll is needed
    const isOverflowing = scrollHeight > clientHeight + 1;

    this.setState({ isOverflowing });
  }

  showMore() {
    this.setState({ isShowingMore: true });
  }

  hideMore() {
    this.setState({ isShowingMore: false });
  }

  render() {
    const {
      id,
      title,
      icon,
      isRead,
      timestamp,
      body,
      onClick,
      intl,
    } = this.props;
    const { isShowingMore, isOverflowing } = this.state;

    const showHide = isShowingMore
      ? {
          message: intl.formatMessage(this.messages.hide),
          onClick: this.hideMore,
          icon: 'chevron-up',
        }
      : {
          message: intl.formatMessage(this.messages.showMore),
          onClick: this.showMore,
          icon: 'chevron-down',
        };

    return (
      <div
        className={classnames('NotificationItem', `NotificationItem--${id}`, {
          'NotificationItem--isUnread': !isRead,
        })}
        onKeyDown={() => {
          onClick(id);
        }}
        onClick={() => {
          onClick(id);
        }}
        role="button"
        tabIndex="0"
      >
        <div className="NotificationItem__UnreadDot" />
        <Icon className="NotificationItem__Icon" type={icon} />

        <div>
          <div>
            <span className="NotificationItem__Title">{title}</span>

            <span className="NotificationItem__Timestamp">
              {moment(timestamp).fromNow()}
            </span>
          </div>

          <div
            ref={this.bodyWrapper}
            className={classnames('NotificationItem__Body', {
              'NotificationItem__Body--show-more':
                isOverflowing && isShowingMore,
            })}
          >
            {body}
          </div>

          {isOverflowing && (
            <button
              type="button"
              className="NotificationItem__ShowMore"
              aria-label={showHide.message}
              onClick={showHide.onClick}
            >
              {showHide.message}
              <Icon
                className="NotificationItem__ShowMore__Icon"
                type={showHide.icon}
              />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(NotificationItem);
