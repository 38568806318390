import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';
import { defineMessages, injectIntl } from 'react-intl';
import InputLabelWithIcon from '../InputLabelWithIcon';

import './Switch.less';

class Switch extends PureComponent {
  static propTypes = {
    /**
     * Renders a smaller switch
     */
    isSmall: PropTypes.bool,

    /**
     * Switch ID
     */
    id: PropTypes.string,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Switch label
     */
    label: PropTypes.node,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Controls whether or not the Switch is checked
     */
    isChecked: PropTypes.bool,

    /**
     * Marks the Switch as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the Switch is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the Switch is required
     */
    html5: PropTypes.bool,

    /**
     * Renders a disabled Switch
     */
    isDisabled: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Handler to call when the Switch state has changed
     *
     * @param {Object} args - {name, value, isChecked, event}
     */
    onChange: PropTypes.func,

    /**
     * Switch value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Switch name
     */
    name: PropTypes.string.isRequired,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,
  };

  static defaultProps = {
    id: '',
    className: '',
    label: '',
    isChecked: undefined,
    isRequired: undefined,
    hasAsterisk: true,
    html5: true,
    isDisabled: undefined,
    isSmall: false,
    hasHiddenLabel: false,
    helperText: '',
    onChange: () => {},
    value: '',
    isValid: false,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked !== undefined ? props.isChecked : false,
    };

    this.id = this.props.id || `Switch-${shortid.generate()}`;
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { isChecked } = props;
    // if the isChecked prop changed and is different than the internal state
    return isChecked !== state.isChecked && isChecked !== state.prevIsChecked
      ? { isChecked, prevIsChecked: isChecked }
      : { prevIsChecked: isChecked };
  }

  onChange(event) {
    event.persist();
    const { onChange, value, name } = this.props;

    this.setState(
      ({ isChecked }) => ({
        isChecked: !isChecked,
      }),
      () => {
        const { isChecked } = this.state;
        onChange({ event, isChecked, value, name });
      },
    );
  }

  render() {
    const { isChecked } = this.state;
    const {
      className,
      label,
      hasHiddenLabel,
      helperText,
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      isSmall,
      value,
      name,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      isChecked: isCheckedProp,
      id,
      isValid,
      intl,
      ...rest
    } = this.props;

    const classes = classnames('Switch', className, {
      'Switch--disabled': isDisabled,
      'Switch--checked': isChecked,
      'Switch--small': isSmall,
      'Switch--valid': isValid,
    });
    const toggleClasses = classnames('Switch__toggler', {
      'Switch__toggler--active': isChecked,
    });
    const labelClasses = classnames('Switch__label', {
      'Switch__label--hidden': hasHiddenLabel,
      'Switch__label--asterisk': hasAsterisk && isRequired,
    });

    const iconTitle = intl.formatMessage(this.messages.validated);
    const iconType = 'checkmark-solid';
    const iconClasses = classnames('Switch__icon', {
      'Switch__icon--valid': isValid,
    });

    return (
      <Fragment>
        <input
          className="SwitchInput"
          type="checkbox"
          checked={isChecked}
          required={isRequired && html5}
          disabled={isDisabled}
          onChange={this.onChange}
          value={value || this.id}
          name={name}
          id={this.id}
        />
        <label {...rest} htmlFor={this.id} className={classes}>
          <span className={labelClasses}>{label}</span>

          <svg className="Switch__canvas" viewBox="0 0 48 24">
            <rect
              className="Switch__background"
              width="100%"
              height="100%"
              rx="12"
            />
            <circle
              className={toggleClasses}
              cx="8"
              cy="9"
              r="10"
              transform="translate(4, 3)"
            />
          </svg>
        </label>
        {isValid && (
          <InputLabelWithIcon
            iconTitle={iconTitle}
            iconType={iconType}
            helperText={helperText}
            iconClasses={iconClasses}
          />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(Switch);
