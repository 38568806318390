import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Positioning from 'components/Positioning';

class DefaultPopover extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent Popover component
     */
    children: PropTypes.node.isRequired,
    anchor: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    popoverRef: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    position: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    customXOffset: PropTypes.number,
    /**
     * Passed internally from parent FocusablePopover
     */
    getContentRef: PropTypes.func,
  };

  static defaultProps = {
    anchor: null,
    popoverRef: null,
    getContentRef: () => {},
    customXOffset: 0,
  };

  constructor(props) {
    super(props);
    this.state = { position: props.position };

    this.onPositionFlip = this.onPositionFlip.bind(this);
    this.onPositionMove = this.onPositionMove.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { position, anchor } = this.props;
    const positionChanged = prevProps.position !== position;
    const anchorChanged = prevProps.anchor && prevProps.anchor !== anchor;

    if (positionChanged || anchorChanged) {
      this.setState({ position, arrowMove: null });
    }
  }

  /**
   * When Positioning reverses due to being out of bounds, update our position
   * accordingly so that arrows remain pointing in the correct direction
   */
  onPositionFlip(position) {
    this.setState({ position });
  }

  /**
   * When Positioning moves due to being out of bounds, offset the arrow
   * (via marginLeft/marginTop) as well so that the arrow remains on the anchor
   */
  onPositionMove(offset, direction) {
    this.setState({ arrowMove: { [`margin${direction}`]: offset } });
  }

  render() {
    const {
      children,
      anchor,
      isVisible,
      className,
      getContentRef,
      popoverRef,
      customXOffset,
    } = this.props;
    const { position, arrowMove } = this.state;

    const classes = classnames('Popover', `Popover--${position}`, className, {
      'Popover--visible': isVisible,
    });
    const arrowClasses = classnames(
      'Popover__arrow',
      `Popover__arrow--${position}`,
    );

    return (
      <Positioning
        anchor={anchor}
        popoverRef={popoverRef}
        position={position}
        onPositionFlip={this.onPositionFlip}
        onPositionMove={this.onPositionMove}
        rerender={isVisible}
        customXOffset={customXOffset}
      >
        <div className={classes} aria-hidden={!isVisible} aria-live="polite">
          <div className={arrowClasses} style={arrowMove} role="presentation" />
          <div className="Popover__content" ref={getContentRef} tabIndex="-1">
            {children}
          </div>
        </div>
      </Positioning>
    );
  }
}

export default DefaultPopover;
