import React from 'react';
import Heading from 'components/Heading';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import classnames from 'classnames';

import './AdvancedSwitcherGroup.less';

const AdvancedSwitcherGroup = React.memo(props => {
  const {
    groupTitle,
    groupName,
    groupCountText,
    buttonText,
    children,
    onClickAction,
    iconType,
    className,
    hasGroupAction,
    ...rest
  } = props;

  const classes = classnames('AdvancedSwitcherGroup', className);

  return (
    <div className={classes}>
      <div className="AdvancedSwitcherGroup__head">
        <div className="AdvancedSwitcherGroup__wrapper">
          <Heading level={4} title={groupTitle || groupName}>
            {groupName}
          </Heading>
          {groupCountText && (
            <div className="AdvancedSwitcherGroup__count">
              <span className="AdvancedSwitcherGroup__text">
                {groupCountText}
              </span>
            </div>
          )}
        </div>
        {hasGroupAction && (
          <div>
            <span className="AdvancedSwitcherGroup__text hidden sm:inline-block md:inline-block lg:inline-block">
              {buttonText}
            </span>
            <button type="button" onClick={onClickAction} {...rest}>
              <Icon
                type={iconType}
                className="AdvancedSwitcherGroup__buttonIcon"
              />
            </button>
          </div>
        )}
      </div>
      <div className="AdvancedSwitcherGroup__radioGroup">{children}</div>
    </div>
  );
});

AdvancedSwitcherGroup.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node,

  /**
   * The name of the group
   */
  groupName: PropTypes.node,

  /**
   * Group title (groupName by default)
   */
  groupTitle: PropTypes.string,

  /**
   * Text near the settings button
   */
  buttonText: PropTypes.node,

  /**
   * Handler to call when the click on the button in group
   */
  onClickAction: PropTypes.func,

  /**
   * Text under the group name
   */
  groupCountText: PropTypes.node,

  /**
   * Additional classes
   */
  className: PropTypes.string,

  /**
   * Icon type
   */
  iconType: PropTypes.string,

  /**
   * Displays an action button for the current group
   */
  hasGroupAction: PropTypes.bool,
};

AdvancedSwitcherGroup.defaultProps = {
  groupName: '',
  buttonText: '',
  groupCountText: '',
  onClickAction: () => {},
  iconType: 'cog',
  children: null,
  className: '',
  hasGroupAction: true,
  groupTitle: undefined,
};

export default AdvancedSwitcherGroup;
