import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Heading.less';

const Heading = props => {
  const { children, level, className, ...rest } = props;

  const Component = `h${level}`;

  const classes = classnames('Heading', className, {
    [`Heading--${level}`]: level,
  });

  return (
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
};

Heading.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Heading level
   */
  level: PropTypes.oneOf([1, 2, 3, 4]),

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Heading.defaultProps = {
  level: 1,
  className: '',
};

export default Heading;
