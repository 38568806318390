import React from 'react';
import PropTypes from 'prop-types';

import { DefaultPopover, FocusablePopover, DismissiblePopover } from './states';
import './Popover.less';

const Popover = React.forwardRef((props, ref) => {
  if (props.hasAutoDismiss) return <DismissiblePopover ref={ref} {...props} />;
  if (props.hasAutoFocus) return <FocusablePopover ref={ref} {...props} />;
  return <DefaultPopover ref={ref} {...props} />;
});

Popover.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * React ref of the container that the popover is anchored to
   *
   * @see https://reactjs.org/docs/refs-and-the-dom.html
   */
  anchor: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

  /**
   * Position of popover
   */
  position: PropTypes.oneOf([
    'top-left',
    'top',
    'top-right',
    'right',
    'bottom-right',
    'bottom',
    'bottom-left',
    'left',
    'center',
  ]),

  /**
   * Visibility of popover. This logic should be handled within your own component state,
   * toggled by your own events (e.g. onClick, onMouseOver, etc.)
   */
  isVisible: PropTypes.bool,

  /**
   * Enables keyboard-accessible focus for Popovers with interactable children.
   * See the 'Auto Focus' section below for more details.
   */
  hasAutoFocus: PropTypes.bool,

  /**
   * Allows the Popover to self-dismiss when the user clicks outside the Popover or presses the Esc key.
   * See the 'Auto Dismiss' section below and `onDismiss` prop for more details.
   */
  hasAutoDismiss: PropTypes.bool,

  /**
   * Used by `hasAutoDismiss` Popovers only. This callback fires when a Popover self-dismisses, and
   * must be used to ensure that your `isVisible` state does not fall out of sync.
   */
  onDismiss: PropTypes.func,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Additional X offset
   */
  customXOffset: PropTypes.number,
};

Popover.defaultProps = {
  anchor: null,
  position: 'bottom',
  isVisible: false,
  hasAutoFocus: false,
  hasAutoDismiss: false,
  onDismiss: () => {},
  className: '',
  customXOffset: 0,
};

export default Popover;
