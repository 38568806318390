import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Tab extends React.Component {
  static propTypes = {
    /**
     * Child text/nodes
     */
    children: PropTypes.node.isRequired,

    /**
     * Additional classes to render
     */
    className: PropTypes.string,

    /**
     * Tab index. Passed internally by parent Tabs component
     *
     * @ignore
     */
    index: PropTypes.number,

    /**
     * Sets disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Sets selected state
     */
    isSelected: PropTypes.bool,

    /**
     * Accessibility enhancement. Informs screen readers which DOM element is controlled by this tab.
     * The target prop must match the ID of the element that will be made active when the tab is selected
     */
    target: PropTypes.string,

    /**
     * Sets a callback when the tab is selected
     */
    onSelection: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    index: null,
    isDisabled: false,
    isSelected: false,
    target: null,
    onSelection: () => {},
  };

  constructor(props) {
    super(props);

    this.clicked = false;
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSelected && !prevProps.isSelected && !this.clicked) {
      this.handleSelectionChange();
    }

    this.clicked = false;
  }

  handleSelectionChange() {
    const { isDisabled, onSelection, index } = this.props;

    this.clicked = true;
    if (!isDisabled && onSelection) {
      onSelection(index);
    }
  }

  render() {
    const {
      children,
      className,
      isDisabled,
      isSelected,
      // The following props are only destructured so they don't end up in ...rest
      target,
      index,
      onSelection,
      ...rest
    } = this.props;

    const classes = classnames('Tab', className, {
      'Tab--disabled': isDisabled,
      'Tab--active': isSelected && !isDisabled,
    });

    return (
      <li role="presentation" className={classes}>
        <button
          {...rest}
          type="button"
          role="tab"
          aria-selected={isSelected}
          aria-controls={target}
          className="Tab__button"
          onClick={this.handleSelectionChange}
          disabled={isDisabled}
        >
          {children}
        </button>
      </li>
    );
  }
}
export default Tab;
