import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const StarEmptyIcon = ({ className }) => (
  <svg
    className={classnames('StarEmptyIcon', className)}
    viewBox="0 0 502 477"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#DDE2E6" fillRule="nonzero">
      <polygon
        id="Path"
        points="501.28 182.37 335.26 147.33 250.64 0.27 250.64 407.77 405.54 477.01 387.56 308.29"
      />
      <polygon
        id="Path"
        points="166.02 147.33 0 182.37 113.72 308.29 95.74 477.01 250.64 407.77 250.64 0.27"
      />
    </g>
  </svg>
);

StarEmptyIcon.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

StarEmptyIcon.defaultProps = {
  className: '',
};

export const StarFullIcon = ({ className }) => (
  <svg
    className={classnames('StarFullIcon', className)}
    viewBox="0 0 502 477"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" fill="none">
      <polygon
        id="Path"
        fill="#FFCA37"
        points="501.28 182.37 335.26 147.33 250.64 0.27 250.64 407.77 405.54 477.01 387.56 308.29"
      />
      <polygon
        id="Path"
        fill="#FFD769"
        points="166.02 147.33 0 182.37 113.72 308.29 95.74 477.01 250.64 407.77 250.64 0.27"
      />
    </g>
  </svg>
);

StarFullIcon.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

StarFullIcon.defaultProps = {
  className: '',
};

export const StarHalfIcon = ({ className }) => (
  <svg
    className={classnames('StarHalfIcon', className)}
    viewBox="0 0 502 477"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" fill="none">
      <polygon
        id="Path"
        fill="#DDE2E6"
        points="501.28 182.37 335.26 147.33 250.64 0.27 250.64 407.77 405.54 477.01 387.56 308.29"
      />
      <polygon
        id="Path"
        fill="#FFD769"
        points="166.02 147.33 0 182.37 113.72 308.29 95.74 477.01 250.64 407.77 250.64 0.27"
      />
    </g>
  </svg>
);

StarHalfIcon.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

StarHalfIcon.defaultProps = {
  className: '',
};
