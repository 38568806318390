/* eslint-disable react/forbid-prop-types */
import React, { PureComponent, useCallback } from 'react';
import ReactJoyride from 'react-joyride';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import Text from 'components/Text';
import './CoachMark.less';

class CoachMark extends PureComponent {
  static propTypes = {
    /**
     * See https://docs.react-joyride.com/props for more info
     */
    callback: PropTypes.func,
    run: PropTypes.bool.isRequired,
    disableScrolling: PropTypes.bool,
    continuous: PropTypes.bool,
    showProgress: PropTypes.bool,
    floaterProps: PropTypes.object,
    styles: PropTypes.object,
    tooltipComponent: PropTypes.object,
    disablePageScroll: PropTypes.bool,

    /**
     * See https://docs.react-joyride.com/step for more info
     */
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        target: PropTypes.string.isRequired,
        title: PropTypes.node,
        content: PropTypes.node.isRequired,
        disableBeacon: PropTypes.bool,
        customButton: PropTypes.node,
        closeButtonText: PropTypes.node,
        skipButtonText: PropTypes.node,
        onClick: PropTypes.func,
        nextButtonText: PropTypes.node,
        placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
        showCheckbox: PropTypes.bool,
        useSkipForCustom: PropTypes.bool,
      }),
    ).isRequired,
  };

  static defaultProps = {
    ...ReactJoyride.defaultProps,
    disableScrolling: true,
    continuous: true,
    showProgress: true,
    floaterProps: undefined,
    styles: undefined,
    disablePageScroll: false,
    tooltipComponent: undefined,
  };

  static defaultStyles = {
    spotlight: {
      borderColor: '#7141D8',
      borderWidth: '0.25rem',
      borderRadius: 30,
    },
  };

  static defaultFloaterProps = {
    hideArrow: true,
    disableAnimation: true,
    showCloseButton: false,
  };

  componentDidUpdate(prevProps) {
    const { disablePageScroll, run } = this.props;

    if (disablePageScroll) {
      if (!prevProps.run && run) {
        document.body.classList.add('Blanket--overflowHidden');
      }
      if (prevProps.run && !run) {
        document.body.classList.remove('Blanket--overflowHidden');
      }
    }
  }

  componentWillUnmount() {
    const { disablePageScroll } = this.props;

    if (disablePageScroll) {
      document.body.classList.remove('Blanket--overflowHidden');
    }
  }

  defaultTooltip = () => {
    return ({
      continuous,
      index,
      size,
      isLastStep,
      step,
      primaryProps,
      skipProps,
      tooltipProps,
      closeProps,
    }) => {
      const { onClick: closePropsOnclick, ...closePropsRest } = closeProps;

      const { onClick: skipPropsOnclick, ...skipPropsRest } = skipProps;
      const {
        onClick: primaryPropsOnclick,
        ...primaryPropsRest
      } = primaryProps;

      const {
        hideCloseButton,
        closeButtonText,
        skipButtonText,
        nextButtonText,
        showProgress,
        title,
        content,
        showCheckbox = false,
        checkboxOnChange = () => {},
        checkboxLabel = 'Do not show this again',
        customButton,
        onClick = () => {},
        useSkipForCustom,
      } = step;
      const { close, next, skip } = step.locale;

      const getCustomButton = useCallback(() => {
        if (useSkipForCustom) {
          return React.cloneElement(customButton, {
            ...closePropsRest,
            onClick: e => {
              if (customButton.props.onClick) {
                customButton.props.onClick(e);
              }
              closePropsOnclick(e);
            },
          });
        }
        return customButton;
      }, [useSkipForCustom]);

      return (
        <div className="CoachMark" {...tooltipProps}>
          {step.title && <Text className="CoachMark--title">{title}</Text>}
          {step.content && (
            <Text className="CoachMark--content">{content}</Text>
          )}
          {showCheckbox && (
            <Checkbox
              label={checkboxLabel}
              className="CoachMark--checkbox"
              onChange={checkboxOnChange}
            />
          )}
          <div className="CoachMark--footer">
            {showProgress && (
              <Text className="CoachMark--progress">
                {index + 1} of {size}
              </Text>
            )}

            <div className="CoachMark--buttons">
              {continuous && !isLastStep && !customButton && (
                <Button
                  isSmall
                  isTertiary
                  className="CoachMark--skip"
                  {...skipPropsRest}
                  onClick={e => {
                    onClick(e);
                    skipPropsOnclick(e);
                  }}
                >
                  {skipButtonText || skip}
                </Button>
              )}
              {customButton && getCustomButton()}
              {!hideCloseButton && isLastStep && (
                <Button
                  isSmall
                  {...skipPropsRest}
                  onClick={e => {
                    onClick(e);
                    skipPropsOnclick(e);
                  }}
                >
                  {closeButtonText || close}
                </Button>
              )}
              {!isLastStep && continuous && (
                <Button
                  isSmall
                  {...primaryPropsRest}
                  onClick={e => {
                    onClick(e);
                    primaryPropsOnclick(e);
                  }}
                >
                  {nextButtonText || next}
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    };
  };

  render() {
    const {
      floaterProps,
      styles,
      tooltipComponent,
      // stepIndex is not used
      // eslint-disable-next-line react/prop-types
      stepIndex,
      ...rest
    } = this.props;

    return (
      <ReactJoyride
        floaterProps={floaterProps || CoachMark.defaultFloaterProps}
        styles={styles || CoachMark.defaultStyles}
        tooltipComponent={tooltipComponent || this.defaultTooltip()}
        {...rest}
      />
    );
  }
}

export default CoachMark;
