export const API = {
	V1: {
		pixelVerification: {
			get: '/api/v1/pixelverification/get',
			create: '/api/v1/pixelverification/create',
			url: '/api/v1/pixelverification/url',
			getStatus: '/api/v1/pixelverification/status',
		},
		onboardingStep: {
			get: '/api/v1/onboardingstep/get',
			create: '/api/v1/onboardingstep/create',
		},
		scripts: {
			create: '/api/v1/scripts/create',
			get: '/api/v1/scripts/get',
			status: {
				get: '/api/v1/scripts/status',
				post: '/api/v1/scripts',
			},
		},
		product: {
			file: 'api/v1/product/feed/file',
		},
		configuration: {
			orderTrackingField: '/api/v1/configuration/order-tracking-field',
			orderCancellation: '/api/v1/configuration/orders/cancellation',
			productFeed: '/api/v1/configuration/product/feed',
		},
		ran: {
			get: '/api/v1/ran/get',
		},
		shopify: {
			callback: '/callback',
			onboarding: {
				status: '/api/v1/onboarding/status',
			},
		},
		shopifyApp: {
			appHome: '/api/v1/shopify/app',
			callBack: '/api/v1/shopify/callback',
		},
		cmp: {
			getData: '/api/v1/cmp',
			status: '/api/v1/cmp/status',
			store: '/api/v1/cmp/status/store',
			getUSPApiStatus: '/api/v1/cmp/uspapistatus',
			merchantWebsite: '/api/v1/cmp/merchantwebsite',
			values: '/api/v1/cmp/values',
		},
		merchant: {
			get: '/api/v1/merchant',
			update: '/api/v1/merchant',
		},
		additionalScript: {
			get: '/api/v1/additionalscripts/get',
			reviewStatus: '/api/v1/additionalscripts/status',
			getStatus: '/api/v1/additionalscripts/status',
		},
		thirdPartyApp: {
			get: '/api/v1/third-party/app',
			status: '/api/v1/third-party/app/status',
			saveDetails: '/api/v1/third-party/app',
		},
	},
};
