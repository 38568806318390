import { AnyAction } from 'redux';
import { PixelVerificationState } from '../constants/interfaces';
import {
	PIXEL_VERIFICATION_CLEAR,
	PIXEL_VERIFICATION_GET,
	PIXEL_VERIFICATION_STATUS_GET,
	PIXEL_VERIFICATION_URL_GET,
} from '../constants/action-types';

const initialState: PixelVerificationState = {
	pixelVerification: {
		orderNumber: '',
		url: '',
		pixelTrackingStatus: undefined,
		message: '',
	},
};

export const pixelVerification = (state = initialState, action: AnyAction) => {
	const pixelVerificationForUpdate = { ...state.pixelVerification };
	switch (action.type) {
		case PIXEL_VERIFICATION_STATUS_GET: {
			pixelVerificationForUpdate.pixelTrackingStatus = action.data.pixelTrackingStatus;
			pixelVerificationForUpdate.message = action.data.message;
			return {
				...state,
				pixelVerification: pixelVerificationForUpdate,
			};
		}
		case PIXEL_VERIFICATION_GET: {
			pixelVerificationForUpdate.orderNumber = action.data.orderNumber;
			return {
				...state,
				pixelVerification: pixelVerificationForUpdate,
			};
		}
		case PIXEL_VERIFICATION_URL_GET: {
			pixelVerificationForUpdate.url = action.data.url;
			return {
				...state,
				pixelVerification: pixelVerificationForUpdate,
			};
		}
		case PIXEL_VERIFICATION_CLEAR:
			return initialState;
		default:
			return state;
	}
};
