import { AnyAction } from 'redux';
import { APP_HOME_RESPONSE_CLEAR, APP_HOME_RESPONSE_GET } from '../constants/action-types';

const initialState = {
	redirectLocation: '',
};

export const appHome = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case APP_HOME_RESPONSE_GET: {
			return {
				...state,
				redirectLocation: action.data.redirectLocation,
			};
		}
		case APP_HOME_RESPONSE_CLEAR:
			return initialState;
		default:
			return state;
	}
};
