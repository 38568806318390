import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/Heading';
import Tag from 'components/Tag';

const FilterTags = ({
  inputMap,
  activeFilters,
  onTagDismiss,
  labelDefault,
  labelWithFilters,
  className,
}) => {
  const filters = Object.entries(activeFilters)
    .map(([key, value]) => {
      const input = inputMap[key];
      if (!input || (!value && value !== 0)) return null;

      let filter = value;
      // Transmog all values to arrays, to make dealing with
      // array values from CheckboxGroup more consistent
      if (!Array.isArray(filter)) filter = [filter];

      // Handle value->label map
      filter = filter.map(label => {
        if (input.isBoolean) return input.label;
        if (input.hasOptions) return input.options[label];
        return label;
      });

      // Handle taxonomy tag
      const taxonomy = input.taxonomy || (!input.isBoolean ? input.label : '');

      return filter.map((label, index) => (
        <Tag
          className={className}
          isDismissible={!input.isRequired}
          onDismiss={() => onTagDismiss(key, value[index])}
          key={`${taxonomy}:${label}`}
        >
          {taxonomy && <span className="FilterTag__text">{taxonomy}: </span>}
          {label}
        </Tag>
      ));
    })
    .filter(tag => tag && tag.length);

  return (
    <Fragment>
      {(labelDefault || labelWithFilters) && (
        <Heading level={3}>
          {!filters.length ? labelDefault : labelWithFilters}
        </Heading>
      )}
      {filters}
    </Fragment>
  );
};

/**
 * Passed internally from parent Filter component
 */
FilterTags.propTypes = {
  inputMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  activeFilters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onTagDismiss: PropTypes.func.isRequired,
  labelDefault: PropTypes.node.isRequired,
  labelWithFilters: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FilterTags.defaultProps = {
  className: '',
};

export default FilterTags;
