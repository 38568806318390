import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TableCell.less';

const TableFooter = ({
  children,
  isSelected,
  isCondensed,
  hasHover,
  className,
  ...rest
}) => {
  const classes = classnames('Table__TableFooter', className, {
    'Table__TableFooter--condensed': isCondensed,
    'Table__TableFooter--selected': isSelected,
    'Table__TableFooter--hoverable': hasHover,
  });

  // Note: the actual <tfoot> tag is set by the parent Table
  return (
    <tr {...rest} className={classes}>
      {children}
    </tr>
  );
};

TableFooter.propTypes = {
  /**
   * Child nodes (e.g. TableHeaderCell, TableDataCell)
   */
  children: PropTypes.node.isRequired,

  /**
   * Highlights the row. Can be passed in manually on select
   */
  isSelected: PropTypes.bool,

  /**
   * Renders a more condensed variant
   */
  isCondensed: PropTypes.bool,

  /**
   * Determines if the row highlights on hover
   */
  hasHover: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

TableFooter.defaultProps = {
  isSelected: false,
  isCondensed: false,
  hasHover: false,
  className: '',
};

export default TableFooter;
