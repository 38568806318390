import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TableCell.less';

const TableHeaderCell = ({
  children,
  colSpan,
  rowSpan,
  scope,
  className,
  ...rest
}) => {
  const classes = classnames('Table__TableHeader', className);

  return (
    <th {...rest} {...{ colSpan, rowSpan, scope }} className={classes}>
      {children}
    </th>
  );
};

TableHeaderCell.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Table columns to span, for when the header applies to multiple columns
   */
  colSpan: PropTypes.number,

  /**
   * Table rows to span, for when the header applies to multiple rows
   */
  rowSpan: PropTypes.number,

  /**
   * Helps screen readers interpret tables. "col" means the header is for a column and "row" means it's for a row
   */
  scope: PropTypes.oneOf(['col', 'row']),

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

TableHeaderCell.defaultProps = {
  colSpan: 1,
  rowSpan: 1,
  scope: 'col',
  className: '',
};

export default TableHeaderCell;
