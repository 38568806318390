module.exports = {
  theme: {
    colors: {
      transparent: 'transparent',

      white: '#ffffff',
      black: '#000000',

      gray: {
        100: '#f4f4f4',
        200: '#dde2e6',
        300: '#bbc7cd',
        400: '#99abb4',
        500: '#788f9c',
        600: '#596b74',
        700: '#3b474d',
      },

      red: {
        100: '#f9eef2',
        200: '#f4cdd4',
        300: '#eb9caa',
        400: '#e16a7f',
        500: '#d73955',
        600: '#b5253e',
        700: '#881b2f',
      },

      indigo: {
        100: '#edf2fc',
        200: '#dae5f9',
        300: '#baccf3',
        400: '#9fb4e7',
        500: '#6d98ed',
        600: '#2f6de5',
        700: '#123c90',
      },

      green: {
        100: '#e7f4d5',
        200: '#e1f1ca',
        300: '#c5e497',
        400: '#a8d763',
        500: '#8bca2f',
        600: '#689723',
        700: '#456418',
      },

      teal: {
        100: '#d3f3f5',
        200: '#c9f1f3',
        300: '#93e4e8',
        400: '#5dd6dc',
        500: '#28c9d1',
        600: '#1d969c',
        700: '#126468',
      },

      purple: {
        100: '#f5f2fc',
        200: '#dbcff5',
        300: '#b8a0eb',
        400: '#936fe1',
        500: '#7141d8',
        600: '#4e23ab',
        700: '#391a7c',
      },

      violet: {
        100: '#f3eaf5',
        200: '#e1c7e6',
        300: '#c491cd',
        400: '#a75ab4',
        500: '#8a239c',
        600: '#6e1c7d',
        700: '#53155e',
      },

      pink: {
        100: '#f9eef2',
        200: '#f0d1dc',
        300: '#e2a3ba',
        400: '#d47598',
        500: '#c64876',
        600: '#972f55',
        700: '#7c2646',
      },

      orange: {
        100: '#ffebcb',
        200: '#ffe7bf',
        300: '#ffcf80',
        400: '#ffb741',
        500: '#ffa002',
        600: '#ce8000',
        700: '#985f00',
      },

      crimson: {
        100: '#fcecec',
        200: '#f6c4c4',
        300: '#ee8a8a',
        400: '#e64f4f',
        500: '#de1515',
        600: '#b21111',
        700: '#850d0d',
      },
    },

    /*
    |-----------------------------------------------------------------------------
    | Border colors (custom)
    |-----------------------------------------------------------------------------
    */
    borderColor: theme =>
      Object.assign(theme('colors'), {
        default: theme('colors.gray.200', 'currentColor'),
      }),

    /*
    |-----------------------------------------------------------------------------
    | Box Shadow Colors (custom)
    |-----------------------------------------------------------------------------
    |
    | Class name: .box-shadow{?-size}-{color}
    |
    */
    boxShadows: {
      colors: [
        'gray-400',
        'gray-300',
        'gray-200',
        'gray-100',
        'disabled-gray',
        'disabled-gray-dark',
        'red-400',
        'red-500',
        'red-600',
        'red-700',
        'green-400',
        'indigo-400',
        'indigo-500',
        'purple-600',
      ],
      sizes: {
        default: '0 .2rem',
        sm: '0 .075rem',
      },
    },

    /*
    |-----------------------------------------------------------------------------
    | Reverse Box Shadow colors (custom)
    |-----------------------------------------------------------------------------
    |
    | (Not being used yet. Leaving code for possible future use)
    | Class name: .-box-shadow{?-size}-{color}
    |
    */
    reverseBoxShadows: {
      colors: [],
      sizes: {},
    },

    /*
    |-----------------------------------------------------------------------------
    | Coordinates (custom)
    |-----------------------------------------------------------------------------
    |
    | Class name: .pin-{location}-{size}
    |
    */
    coordinates: {
      locations: {
        t: 'top',
        r: 'right',
        b: 'bottom',
        l: 'left',
      },
      sizes: {
        half: '50%',
        full: '100%',
        '2px': '2px',
        '0': '0',
        '1': '0.25rem',
        '2': '0.5rem',
        '3': '0.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '8': '2rem',
        '10': '2.5rem',
        '12': '3rem',
        '14': '3.5rem',
        '16': '4rem',
        '24': '6rem',
        '32': '8rem',
        '48': '12rem',
        '64': '16rem',
      },
    },

    /*
    |-----------------------------------------------------------------------------
    | Negative Coordinates (custom)
    |-----------------------------------------------------------------------------
    |
    | Class name: .-pin-{location}-{size}
    |
    */
    negativeCoordinates: {
      locations: {
        t: 'top',
        r: 'right',
        b: 'bottom',
        l: 'left',
      },
      sizes: {
        '1': '0.25rem',
        '2': '0.5rem',
        '3': '0.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '8': '2rem',
        '10': '2.5rem',
        '12': '3rem',
        '14': '3.5rem',
        '16': '4rem',
        '24': '6rem',
        '32': '8rem',
        '48': '12rem',
        '64': '16rem',
      },
    },

    fontFamily: {
      sans: ['Noto Sans', 'sans-serif'],
      serif: ['serif'],
      mono: [
        'Menlo',
        'Monaco',
        'Consolas',
        'Liberation Mono',
        'Courier New',
        'monospace',
      ],
    },

    fontSize: {
      half: '50%', // Depends of the inherit
      xxs: '.625rem', // 10px
      xs: '.75rem', // 12px
      sm: '.875rem', // 14px
      base: '1rem', // 16px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
      '2xl': '1.75rem', // 28px
      '3xl': '2rem', // 32px
      '4xl': '2.5rem', // 40px
      '5xl': '3rem', // 48px
    },

    /*
    |-----------------------------------------------------------------------------
    | Transitions (custom)
    |-----------------------------------------------------------------------------
    |
    | These are the ReX defined easing curves and speeds available for transitions.
    | The default value is 'standard' for each. All properties will be transitioned
    | with these classes, but that can be overridden with the 'transition-property'
    | property in your component css
    |
    | Class name: .transition-{easing?}-{duration?}
    |
    */
    transitions: {
      easings: {
        linear: 'linear',
        deceleration: 'cubic-bezier(0, 0, 0.55, 1)',
        acceleration: 'cubic-bezier(0.45, 0, 1, 1)',
      },
      durations: {
        slow: '200ms',
        medium: '133ms',
        fast: '67ms',
      },
      properties: {
        opacity: 'opacity',
        visibility: 'visibility',
        transform: 'transform',
        color: 'color',
        background: 'background-color',
        fill: 'fill',
        all: 'all',
      },
    },
    /*
    |-----------------------------------------------------------------------------
    | Logo widths
    |-----------------------------------------------------------------------------
    */
    logoWidths: {
      sm: '140px',
      md: '240px',
      lg: '352px',
    },

    extend: {
      maxWidth: {
        '7xl': '80rem',
      },
      zIndex: {
        '-1': -1,
        100: 100,
      },
    },
  },
  variants: {},
  corePlugins: {},
  plugins: [],
};
