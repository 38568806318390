import React from 'react';
import SearchBar from 'components/SearchBar';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { Button } from 'components/Button';

import './AdvancedSwitcherHeader.less';

const AdvancedSwitcherHeader = ({
  onSubmit,
  onClose,
  hasCloseBtn,
  searchBarProps,
  hasSearchBtn,
  onClick,
  isBtnDisabled,
}) => {
  const handleOnSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className="AdvancedSwitcherHeader">
      <div className="flex items-center w-full">
        <form
          className="AdvancedSwithcerHeader--search"
          onSubmit={handleOnSubmit}
        >
          <SearchBar
            className={
              hasSearchBtn ? 'AdvancedSwithcerHeader--search__noBorder' : ''
            }
            {...searchBarProps}
          />
        </form>
        {hasSearchBtn && (
          <Button
            className="AdvancedSwitcherHeader__searchBtn"
            onClick={onClick}
            isDisabled={isBtnDisabled}
            type="submit"
          >
            <Icon type="search" />
          </Button>
        )}
      </div>
      {hasCloseBtn && (
        <button className="flex" type="button" onClick={onClose}>
          <span className="AdvancedSwitcherHeader__closeBtn">Close</span>
          <Icon type="close" />
        </button>
      )}
    </div>
  );
};

AdvancedSwitcherHeader.propTypes = {
  /**
   * Function on submit
   */
  onSubmit: PropTypes.func,

  /**
   * When true - show search button
   */
  hasSearchBtn: PropTypes.bool,

  /**
   * When true - show close btn
   */
  hasCloseBtn: PropTypes.bool,

  /**
   * Function to be triggered when the drawer closes itself
   */
  onClose: PropTypes.func,

  /**
   * Functions to be triggered when the user clicks on the search button
   */
  onClick: PropTypes.func,

  /**
   * When true - disable search button
   */
  isBtnDisabled: PropTypes.bool,

  /**
   * SearchBar props
   */
  searchBarProps: PropTypes.shape({
    onChange: PropTypes.func,
    placeholder: PropTypes.node,
    value: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    helperText: PropTypes.node,
    label: PropTypes.node,
    hasHiddenLabel: PropTypes.bool,
    isSmall: PropTypes.bool,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }),
};

AdvancedSwitcherHeader.defaultProps = {
  onSubmit: () => {},
  hasSearchBtn: false,
  isBtnDisabled: false,
  onClick: () => {},
  onClose: () => {},
  hasCloseBtn: false,
  searchBarProps: {
    onChange: () => {},
    placeholder: '',
    value: '',
    id: '',
    name: '',
    helperText: '',
    label: '',
    hasHiddenLabel: true,
    isSmall: false,
    isRequired: false,
    isDisabled: false,
  },
};

export default AdvancedSwitcherHeader;
