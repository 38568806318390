import React, { Fragment } from 'react';
import { CheckboxComponent } from 'components/Checkbox/Checkbox';
import classnames from 'classnames';
import './MultiSelectBrick.less';
import PropTypes from 'prop-types';

class MultiSelectBrick extends CheckboxComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Checkbox ID
     */
    id: PropTypes.string,

    /**
     * Checkbox name
     */
    name: PropTypes.string,

    /**
     * Checkbox label
     */
    label: PropTypes.node,

    /**
     * Controls whether or not the Checkbox is checked
     */
    isChecked: PropTypes.bool,

    /**
     * Renders a disabled Checkbox
     */
    isDisabled: PropTypes.bool,

    /**
     * Handler to call when the Checkbox state has changed
     *
     * @param {Object} args - {name, value, isChecked, event}
     */

    onChange: PropTypes.func,

    /**
     * Checkbox value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    className: '',
    id: '',
    name: '',
    label: '',
    isChecked: undefined,
    isDisabled: undefined,
    onChange: () => {},
    value: '',
  };

  render() {
    const { isChecked } = this.state;
    const {
      className,
      label,
      isDisabled,
      name,
      value,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      html5,
      isChecked: isCheckedProp,
      id,
      isIndeterminate,
      helperText,
      hasHiddenLabel,
      hasAsterisk,
      isRequired,
      isValid,
      withBorder,
      ...rest
    } = this.props;
    const classes = classnames('MultiSelectBrick', className, {
      'MultiSelectBrick--disabled': isDisabled,
      'MultiSelectBrick--checked': isChecked,
    });

    return (
      <Fragment>
        <input
          className="CheckboxInput"
          type="checkbox"
          id={this.id}
          name={name}
          value={value || this.id}
          checked={isChecked}
          disabled={isDisabled}
          onChange={this.onChange}
        />
        <label htmlFor={this.id} {...rest} className={classes}>
          {label}
        </label>
      </Fragment>
    );
  }
}

export default MultiSelectBrick;
