import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { consoleWarnWithLink } from 'components/helpers';
import './CardBody.less';

const CardBody = props => {
  const { children, showFallback, fallbackText } = props;
  const classes = classnames('CardBody', {
    Card__fallback: showFallback,
  });

  if (showFallback && !fallbackText) {
    consoleWarnWithLink({
      link: 'CardBody',
      warning: `(Card/CardBody) needs fallbackText if showFallback is true.`,
    });
  }

  return (
    <div className={classes}>{showFallback ? fallbackText : children}</div>
  );
};

CardBody.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * Determines whether to show fallback
   */
  showFallback: PropTypes.bool,

  /**
   * Text or node to show if showFallback is true
   */
  fallbackText: PropTypes.node,
};

CardBody.defaultProps = {
  children: null,
  showFallback: false,
  fallbackText: null,
};

export default CardBody;
