import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import './SmoothWrapper.less';

const SmoothWrapper = ({ children, isStickyCheck }) => {
  const headerContainer = document.getElementById('HeaderContainer');
  const headerOffset = headerContainer && headerContainer.offsetHeight;
  const ref = useRef(null);
  const mainWrapperRef = useRef(null);
  const wrapperRef = useRef(null);

  const [isSticky, setIsSticky] = useState(false);

  const helperWrappClasses = classnames({
    'SmoothWrapper--helperWrapper': isSticky,
  });

  const handleScroll = useCallback(() => {
    if (ref.current && wrapperRef.current) {
      if (window.scrollY > wrapperRef.current.offsetTop - headerOffset) {
        ref.current.classList.add('SmoothWrapper--sticky');
        mainWrapperRef.current.classList.add('WrapperSm');
        setIsSticky(true);
      } else {
        ref.current.classList.remove('SmoothWrapper--sticky');
        mainWrapperRef.current.classList.remove('WrapperSm');
        setIsSticky(false);
      }
    }
  }, []);

  const classes = classnames('bg-white SmoothWrapper');

  useEffect(() => {
    if (isStickyCheck) {
      isStickyCheck(isSticky);
    }
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  return (
    <>
      <div ref={wrapperRef} />
      <div ref={mainWrapperRef}>
        <div className={helperWrappClasses}>
          <div className={classes} ref={ref}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

SmoothWrapper.defaultProps = {
  children: null,
  isStickyCheck: null,
};

SmoothWrapper.propTypes = {
  children: PropTypes.node,
  isStickyCheck: PropTypes.func,
};

export default SmoothWrapper;
