import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/Button';
import Popover from 'components/Popover';

class FilterPopover extends PureComponent {
  /**
   * Passed internally from parent Filter component
   */
  static propTypes = {
    children: PropTypes.node.isRequired,
    isPopoverOpen: PropTypes.bool.isRequired,
    togglePopover: PropTypes.func.isRequired,
    closePopover: PropTypes.func.isRequired,
    withIcon: PropTypes.bool,
    customBtnText: PropTypes.node,
    isSmallBtn: PropTypes.bool,
    isLargeBtn: PropTypes.bool,
    className: PropTypes.string,
    iconType: PropTypes.string,
  };

  static defaultProps = {
    withIcon: true,
    customBtnText: null,
    isSmallBtn: true,
    isLargeBtn: false,
    className: '',
    iconType: 'add-solid',
  };

  popoverAnchor = React.createRef();

  render() {
    const {
      className,
      isPopoverOpen,
      togglePopover,
      closePopover,
      withIcon,
      iconType,
      customBtnText,
      children,
      isSmallBtn,
      isLargeBtn,
    } = this.props;

    return (
      <Fragment>
        <Button
          className={className}
          isSmall={isSmallBtn}
          isLarge={isLargeBtn}
          isSecondary
          icon={withIcon ? iconType : ''}
          onClick={togglePopover}
          ref={this.popoverAnchor}
        >
          {customBtnText || (
            <FormattedMessage
              defaultMessage="Set filters"
              description="FilterPopover set filters button"
            />
          )}
        </Button>
        <Popover
          className="FilterPopover"
          position="bottom-left"
          anchor={this.popoverAnchor.current}
          isVisible={isPopoverOpen}
          hasAutoFocus
          hasAutoDismiss
          onDismiss={closePopover}
        >
          {children}
        </Popover>
      </Fragment>
    );
  }
}

export default FilterPopover;
