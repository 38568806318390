import { AnyAction } from 'redux';
import { THIRD_PARTY_CLEAR, THIRD_PARTY_DETAILS_GET, THIRD_PARTY_STATUS_GET } from '../constants/action-types';
import { ThirdPartyState } from '../constants/interfaces';

const initialState: ThirdPartyState = {
	state: {
		status: null,
		appName: '',
		thirdPartyAppStatus: null,
	},
};

export const thirdParty = (state = initialState, action: AnyAction) => {
	const stateForUpdate = { ...state.state };
	switch (action.type) {
		case THIRD_PARTY_STATUS_GET: {
			stateForUpdate.thirdPartyAppStatus = action.data.thirdPartyAppStatus;
			return {
				...state,
				state: stateForUpdate,
			};
		}
		case THIRD_PARTY_DETAILS_GET: {
			stateForUpdate.status = action.data.status;
			stateForUpdate.appName = action.data.appName;
			return {
				...state,
				state: stateForUpdate,
			};
		}
		case THIRD_PARTY_CLEAR:
			return initialState;
		default:
			return state;
	}
};
