import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import Icon from 'components/Icon';

import './Builder.less';

class Builder extends React.PureComponent {
  static propTypes = {
    /**
     * Accepts either basic child nodes to multiply, OR:
     *
     * A [render prop](https://reactjs.org/docs/render-props.html) function that passes back
     * the Builder's buttons and current row count. This allows devs to handle
     * handle their own content iteration and any custom handling needed.
     *
     * @param {func} buttons - Call buttons(i) with the current row index
     * @param {func} count - The current total number of rows to display
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

    /**
     * Number of rows to initialize or update the Builder with
     */
    count: PropTypes.number,

    /**
     * Minimum number of rows allowed at any time
     */
    min: PropTypes.number,

    /**
     * Maximum number of rows allowed at any time
     */
    max: PropTypes.number,

    /**
     * Function called when a user adds a row
     *
     * @param {obj} args - { index, count, event }
     * @param {number} args.index - The index of the newly added row
     * @param {number} args.count - The new total number rows in the Builder
     * @param {obj} args.event - The button click event
     */
    onAdd: PropTypes.func,

    /**
     * Function called when a user removes a row
     *
     * @param {obj} args - { index, count, event }
     * @param {number} args.index - The index of the removed row
     * @param {number} args.count - The new total number rows in the Builder
     * @param {obj} args.event - The button click event
     */
    onDelete: PropTypes.func,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Passed from react-intl HOC
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    count: 1,
    min: 1,
    max: null,
    onAdd: () => {},
    onDelete: () => {},
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = { count: this.getCount(props) };

    this.onAdd = this.onAdd.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentDidUpdate(prevProps) {
    let { count } = this.state;
    const countChanged = prevProps.count !== this.props.count;

    const { min, max } = this.props;
    const minChanged = prevProps.min !== min;
    const maxChanged = prevProps.max !== max;

    if (countChanged || minChanged || maxChanged) {
      if (countChanged) count = this.props.count; // eslint-disable-line prefer-destructuring

      this.setState({ count: this.getCount({ min, max, count }) });
    }
  }

  // eslint-disable-next-line react/sort-comp
  getCount(props) {
    const { min, max } = props;
    let { count } = props;

    // Don't allow the current count to be above or
    // below the set minimum/maximum at any time
    if (count < min) count = min;
    if (max && count > max) count = max;

    return count;
  }

  onAdd(event, prevIndex) {
    event.persist();

    this.setState(({ count: prevCount }) => {
      const count = prevCount + 1;
      const index = prevIndex + 1;

      this.props.onAdd({ count, index, event });
      return { count };
    });
  }

  onDelete(event, index) {
    event.persist();

    this.setState(({ count: prevCount }) => {
      const count = prevCount - 1;

      this.props.onDelete({ count, index, event });
      return { count };
    });
  }

  renderButtons(index) {
    const { count } = this.state;
    const { min, max, intl } = this.props;

    const isAtMax = max && count >= max;
    const isAtMin = count <= min;
    const addButtonClasses = classnames('Builder__action', {
      'Builder__action--disabled': isAtMax,
    });
    const minusButtonClasses = classnames('Builder__action', {
      'Builder__action--disabled': isAtMin,
    });

    return (
      <div className="Builder__actions">
        <button
          disabled={isAtMin}
          className={minusButtonClasses}
          onClick={event => this.onDelete(event, index)}
          type="button"
        >
          <Icon
            type="minus-solid"
            title={intl.formatMessage({
              defaultMessage: 'Remove this row',
              description: 'Builder remove button text',
            })}
          />
        </button>
        <button
          disabled={isAtMax}
          className={addButtonClasses}
          onClick={event => this.onAdd(event, index)}
          type="button"
        >
          <Icon
            type="add-solid"
            title={intl.formatMessage({
              defaultMessage: 'Add new row',
              description: 'Builder add button text',
            })}
          />
        </button>
      </div>
    );
  }

  render() {
    const { count } = this.state;
    // prettier-ignore
    const {
      children,
      className,
      count: countProp, min, max, onAdd, onDelete, intl, // Pull these out so they don't end up in ...rest
      ...rest
    } = this.props;

    const classes = classnames('Builder', className);

    // Render prop
    if (typeof children === 'function') {
      return (
        <div className={classes} {...rest}>
          {children(this.renderButtons, count)}
        </div>
      );
    }

    // If devs prefer not to use the render prop, we multiply their content for them
    /* eslint-disable react/no-array-index-key */
    return (
      <div className={classes} {...rest}>
        {[...Array(count)].map((e, i) => (
          <div className="Builder__row" key={`BuilderRow-${i}`}>
            {children}
            {this.renderButtons(i)}
          </div>
        ))}
      </div>
    );
  }
}

export default injectIntl(Builder);
