import React from 'react';
import { Grid, GridCell, Header, Heading, Logo, Text } from '@blueprints2/core';
import AppMain from '../main';
import AppFooter from '../footer';

interface ErrorProps {
	status: number;
	message?: string;
	headingText: string;
	headingUnderlineWidth: string;
}

const Error = (props: ErrorProps) => {
	const { status, message } = props;

	console.log(status, 'status');

	if (status === 403) {
		return (
			<>
				<Header hasOverlap>
					<Logo title='Shopify Merchant Onboarding' />
				</Header>
				<AppMain>
					<Text>
						<Heading className='text-purple-600' level={1}>
							We were unable to find your account in our system
						</Heading>
					</Text>
					<Text>
						<Heading level={3} className='mt-12'>
							If you believe that you already have a Merchant Account with Rakuten Advertising, please{' '}
							<a className='text-purple-600' href='mailto:usadvsupport@rakuten.com' target='_blank' rel='noreferrer'>
								contact our support team
							</a>
							, (usadvsupport at rakuten dot com) for assistance. <br />
							<br />
							If you do not yet have an account, please{' '}
							<a className='text-purple-600' href='https://rakutenadvertising.com/form/' target='_blank' rel='noreferrer'>
								contact our sales team
							</a>{' '}
							to get started.
						</Heading>
					</Text>
				</AppMain>
				<AppFooter />
			</>
		);
	}

	return (
		<>
			<Header hasOverlap>
				<Logo title='Shopify Merchant Onboarding' />
			</Header>
			<AppMain>
				<Grid gutter='1' className='justify-center w-full mt-32'>
					<Grid className='my-auto'>
						<GridCell>
							<svg width='43' height='44' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M3.33107 19.5H20.6688L12.0003 4.17901L3.33107 19.5ZM22.3653 19.4535C22.7583 20.148 22.243 21 21.4278 21H2.57205C1.7568 21 1.24155 20.148 1.63455 19.4535L11.0628 2.79C11.266 2.43 11.6328 2.25 12.0003 2.25C12.367 2.25 12.7338 2.43 12.9378 2.79L22.3653 19.4535Z'
								/>
								<path fillRule='evenodd' clipRule='evenodd' d='M11.25 14.25H12.75V7.5H11.25V14.25Z' />
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M10.875 16.875C10.875 17.496 11.379 18 12 18C12.621 18 13.125 17.496 13.125 16.875C13.125 16.254 12.621 15.75 12 15.75C11.379 15.75 10.875 16.254 10.875 16.875Z'
								/>
							</svg>
						</GridCell>
						<GridCell>
							<Text>
								<Heading variant='strong' className='text-red-600' level={1}>
									{message}
								</Heading>
							</Text>
						</GridCell>
					</Grid>
				</Grid>
			</AppMain>
			<AppFooter />
		</>
	);
};

export default Error;
