import { AdditionalScriptState } from '../constants/interfaces';
import { AnyAction } from 'redux';
import {
	ADDITIONAL_SCRIP_CLEAR,
	ADDITIONAL_SCRIP_GET,
	ADDITIONAL_SCRIP_STATUS_GET,
	ADDITIONAL_SCRIPT_REVIEW_STATUS,
} from '../constants/action-types';

const initialState: AdditionalScriptState = {
	script: {
		codeSnippet: 'some thing wrong while fetching script',
		adminCheckoutPageLink: undefined,
		additionalScriptStatus: undefined,
		isCopyClicked: false,
		isGotoAdminClicked: false,
		isCodeExpanded: false,
		isDynamicExpanded: false,
		redirect: false,
		isFormSubmitted: false,
	},
};

export const additionalScript = (state = initialState, action: AnyAction) => {
	const scriptForUpdate = { ...state.script };
	switch (action.type) {
		case ADDITIONAL_SCRIP_GET: {
			scriptForUpdate.codeSnippet = action.data.additionalScript;
			scriptForUpdate.adminCheckoutPageLink = action.data.adminCheckoutPageLink;
			return {
				...state,
				script: scriptForUpdate,
			};
		}
		case ADDITIONAL_SCRIP_STATUS_GET: {
			scriptForUpdate.additionalScriptStatus = action.data.additionalScriptStatus;
			return {
				...state,
				script: scriptForUpdate,
			};
		}
		case ADDITIONAL_SCRIPT_REVIEW_STATUS: {
			scriptForUpdate.redirect = action.data.redirect;
			return {
				...state,
				script: scriptForUpdate,
			};
		}
		case ADDITIONAL_SCRIP_CLEAR:
			return initialState;
		default:
			return state;
	}
};
