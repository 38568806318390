import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import shortid from 'shortid';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InputLabelWithIcon from '../InputLabelWithIcon';

import './Checkbox.less';

export class Checkbox extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Checkbox ID
     */
    id: PropTypes.string,

    /**
     * Checkbox name
     */
    name: PropTypes.string,

    /**
     * Checkbox label
     */
    label: PropTypes.node,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Controls whether or not the Checkbox is checked
     */
    isChecked: PropTypes.bool,

    /**
     * Controls whether or not the Checkbox is checked
     */
    isIndeterminate: PropTypes.bool,

    /**
     * Marks the Checkbox as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the Checkbox is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the Checkbox is required
     */
    html5: PropTypes.bool,

    /**
     * Renders a disabled Checkbox
     */
    isDisabled: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Handler to call when the Checkbox state has changed
     *
     * @param {Object} args - {name, value, isChecked, event}
     */
    onChange: PropTypes.func,

    /**
     * Checkbox value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Add border
     */
    withBorder: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    id: '',
    name: '',
    label: '',
    isChecked: undefined,
    isIndeterminate: false,
    isRequired: undefined,
    hasAsterisk: true,
    html5: true,
    isDisabled: undefined,
    hasHiddenLabel: false,
    helperText: '',
    onChange: () => {},
    value: '',
    isValid: false,
    withBorder: false,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked !== undefined ? props.isChecked : false,
    };

    this.id = this.props.id || `Checkbox-${shortid.generate()}`;
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { isChecked } = props;
    // if the isChecked prop changed and is different than the internal state
    return isChecked !== state.isChecked && isChecked !== state.prevIsChecked
      ? { isChecked, prevIsChecked: isChecked }
      : { prevIsChecked: isChecked };
  }

  onChange(event) {
    event.persist();
    const { onChange, value, name } = this.props;

    this.setState(
      ({ isChecked }) => ({
        isChecked: !isChecked,
      }),
      () => {
        const { isChecked } = this.state;
        onChange({ event, isChecked, value, name });
      },
    );
  }

  render() {
    const { isChecked } = this.state;
    const {
      className,
      label,
      hasHiddenLabel,
      helperText,
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      name,
      value,
      withBorder,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      isChecked: isCheckedProp,
      isIndeterminate: isIndeterminateProp,
      id,
      isValid,
      intl,
      ...rest
    } = this.props;
    const isIndeterminate = !isChecked && this.props.isIndeterminate;

    const classes = classnames('Checkbox', className, {
      'Checkbox--disabled': isDisabled,
      'Checkbox--checked': isChecked,
      'Checkbox--indeterminate': isIndeterminate,
      'Checkbox--valid': isValid,
      'RadioButton--border': withBorder,
    });
    const labelClasses = classnames('Checkbox__label', {
      'Checkbox__label--hidden': hasHiddenLabel,
      'Checkbox__label--asterisk': hasAsterisk && isRequired,
    });

    const iconTitle = intl.formatMessage(this.messages.validated);
    const iconType = 'checkmark-solid';
    const iconClasses = classnames('Checkbox__icon', {
      'Checkbox__icon--valid': isValid,
    });

    return (
      <Fragment>
        <input
          className="CheckboxInput"
          type="checkbox"
          id={this.id}
          name={name}
          value={value || this.id}
          checked={isChecked}
          required={isRequired && html5}
          disabled={isDisabled}
          onChange={this.onChange}
        />
        <label {...rest} htmlFor={this.id} className={classes}>
          <svg className="Checkbox__canvas" viewBox="0 0 24 24">
            <rect
              className="Checkbox__background"
              width="100%"
              height="100%"
              rx="4"
            />
            {isChecked && (
              <path
                className="Checkbox__mark"
                transform="translate(6, 8)"
                d="M11.752.219C11.46-0.073 11.007-.073 10.715.219L4.219 7.173 1.241 4.18C1.095 4.026.92 3.949.715 3.965.526 3.965.336 4.057.204 4.21.073
      4.348 0 4.548 0 4.748 0 4.947.088 5.147.234 5.285L3.737 8.785C3.869 8.923 4.058 9 4.248 9 4.453 9 4.642 8.923 4.774 8.785L11.781 1.309C11.927
      1.155 12 .956 12 .741 11.971.541 11.898.357 11.752.219Z"
              />
            )}
            {isIndeterminate && (
              <path
                className="Checkbox__mark"
                d="M5.4 10.5V12.5H18.5V10.5H5.4Z"
              />
            )}
            <path
              className="Checkbox__border"
              d="M2 3.99 2 20.01C2 21.11 2.89 22 3.99 22L20.01 22C21.11 22 22 21.11 22 20.01L22 3.99C22 2.89 21.11 2 20.01 2L3.99 2C2.89 2 2 2.89 2 3.99ZM0
              3.99C0 1.786 1.784 0 3.99 0L20.01 0C22.214 0 24 1.784 24 3.99L24 20.01C24 22.214 22.216 24 20.01 24L3.99 24C1.786 24 0 22.216 0 20.01L0 3.99Z"
            />
          </svg>

          <span className={labelClasses}>{label}</span>
        </label>

        {isValid && (
          <InputLabelWithIcon
            iconTitle={iconTitle}
            iconType={iconType}
            helperText={helperText}
            iconClasses={iconClasses}
          />
        )}
      </Fragment>
    );
  }
}

export { Checkbox as CheckboxComponent };
export default injectIntl(Checkbox);
