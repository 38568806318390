import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@blueprints2/core/lib/core.css';
import '@blueprints2/core/lib/utilities.css';
import './index.css';
import store from './store/index';
import { setUpHttpInterceptors } from './http/interceptors';
import { Provider } from 'react-redux';
import ShopifyAPi from './http/shopify';
import { AuthProvider } from './utils/AuthProvider';
import * as FullStory from '@fullstory/browser';

const { REACT_APP_BASE_URL } = process.env;

setUpHttpInterceptors([{ api: ShopifyAPi, corsUrl: REACT_APP_BASE_URL }]);

FullStory.init({
	orgId: 'CT4RP',
	debug: false,
	host: 'fullstory.com',
	script: 'edge.fullstory.com/s/fs.js',
	namespace: 'FS',
	devMode: process.env.NODE_ENV === 'development' || REACT_APP_BASE_URL?.indexOf('qa') !== -1,
	recordOnlyThisIFrame: true,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<AuthProvider>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</AuthProvider>
	</Provider>
);
