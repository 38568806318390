import { HomeStatusesState } from '../constants/interfaces';
import { AnyAction } from 'redux';
import {
	HOME_ORDER_CANCELLATION_UPDATE,
	HOME_PRODUCT_FEED_UPDATE,
	HOME_STATUSES_CLEAR,
	HOME_STATUSES_GET,
} from '../constants/action-types';

const initialState: HomeStatusesState = {
	statuses: null,
};

export const home = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case HOME_STATUSES_GET: {
			return {
				...state,
				statuses: action.data,
			};
		}
		case HOME_PRODUCT_FEED_UPDATE: {
			return {
				...state,
				statuses: { ...state.statuses, ...action.data },
			};
		}
		case HOME_ORDER_CANCELLATION_UPDATE: {
			return {
				...state,
				statuses: { ...state.statuses, ...action.data },
			};
		}
		case HOME_STATUSES_CLEAR:
			return initialState;
		default:
			return state;
	}
};
