import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class DataTableColumnVisibility extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable component
     */
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    orderedColumns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    reset: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      visibleColumns: this.resetVisibleColumns(),
    };

    this.onColumnHide = this.onColumnHide.bind(this);
    this.onColumnsToggle = this.onColumnsToggle.bind(this);
    this.convertMapToFormData = this.convertMapToFormData.bind(this);
    this.convertFormDataToMap = this.convertFormDataToMap.bind(this);

    this.getConfigButtonRef = el => (this.configButton = el);
  }

  componentDidUpdate(prevProps) {
    const { reset, columns, orderedColumns } = this.props;

    if (prevProps.reset !== reset) {
      this.onUserReset();
    }
    if (prevProps.columns !== columns) {
      this.updateVisibleColumns();
    } else if (prevProps.orderedColumns !== orderedColumns) {
      const visibleColumns = new Map(this.state.visibleColumns);
      this.onColumnsToggle(visibleColumns); // Re-calculate visible column bounds
    }
  }

  /**
   * Events
   */

  /**
   * Fires from individual column dropdown arrows / Hide Column tooltips
   * @param {obj} column - an obj in the columns prop array
   */
  onColumnHide(column) {
    const { visibleColumns } = this.state;

    const updatedVisibility = new Map(visibleColumns);
    updatedVisibility.set(column, { isHidden: true });

    this.setVisibleColumnBounds(updatedVisibility);
    this.setState({ visibleColumns: updatedVisibility });

    // Restore access/keyboard focus to the Table after a column is individually hidden
    if (this.configButton) setTimeout(() => this.configButton.focus());
  }

  /**
   * Fires on form submit from config button / column visibility flyout
   * Uses the map helpers below to convert a form obj to a Map
   * @param {Map} visibleColumns
   */
  onColumnsToggle(visibleColumns) {
    this.setVisibleColumnBounds(visibleColumns);
    this.setState({ visibleColumns });
  }

  /**
   * Resetting
   */
  /* eslint-disable react/sort-comp */

  onUserReset() {
    this.setState({ visibleColumns: this.resetVisibleColumns() });
  }

  resetVisibleColumns() {
    const visibleColumns = new Map();
    this.setVisibleColumnBounds(visibleColumns);
    return visibleColumns;
  }

  updateVisibleColumns() {
    // Whenever columns changes (e.g. a filter, new dataset), we want to
    // persist valid hidden columns while removing columns that no longer exist
    const { columns } = this.props;
    const { visibleColumns } = this.state;

    const updatedVisibility = new Map();

    columns.forEach(column => {
      const { isHidden } = visibleColumns.get(column) || {};
      if (isHidden) updatedVisibility.set(column, { isHidden });
    });

    this.setVisibleColumnBounds(updatedVisibility);
    this.setState({ visibleColumns: updatedVisibility });
  }

  /**
   * Visible Column boundary helpers
   *
   * We're finding the *last* and *second/next* visible column indexes,
   * (not *first*, because that column isn't hide-able). We then pass
   * that down to DataTable's JSX, which uses that info to set a custom
   * class which overrides certain CSS border bugs (that appear due to
   * :last/:first-child not working because columns are present but hidden).
   */

  setVisibleColumnBounds(visibleColumns) {
    const next = this.findNextVisibleColumn(visibleColumns);
    const last = this.findLastVisibleColumn(visibleColumns);

    visibleColumns.set('nextVisibleColumn', next);
    visibleColumns.set('lastVisibleColumn', last);
  }

  findLastVisibleColumn(visibleColumns) {
    const { orderedColumns: columns } = this.props;

    for (let i = columns.length - 1; i > 0; i--) {
      const { isHidden } = visibleColumns.get(columns[i]) || {};
      if (!isHidden) return i;
    }
    return false;
  }

  findNextVisibleColumn(visibleColumns) {
    const { orderedColumns: columns } = this.props;

    // Note that i starts at 1, not 0 - we want the column NEXT to the first column,
    // which has some special CSS around its vertical border/divider
    for (let i = 1; i < columns.length; i++) {
      const { isHidden } = visibleColumns.get(columns[i]) || {};
      if (!isHidden) return i;
    }
    return false;
  }

  /**
   * Map <-> Object conversion helpers (visibleColumns <-> formData)
   * Used by DataTableHeading / onColumnsToggle
   */

  /**
   * @returns {obj} - e.g. { '1': false, '2': true, '3': false }
   */
  convertMapToFormData() {
    const { orderedColumns: columns } = this.props;
    const { visibleColumns } = this.state;
    const formData = {};

    columns.forEach((column, i) => {
      const { isHidden } = visibleColumns.get(column) || {};
      formData[i] = !isHidden;
    });

    return formData;
  }

  /**
   * @param {obj} formData  - e.g. { '0': false }
   * @returns {Map}         - e.g. [[columns[1], { isHidden: true }]]
   */
  convertFormDataToMap(formData) {
    const { orderedColumns: columns } = this.props;
    const visibilityMap = new Map();

    Object.entries(formData).forEach(([index, isVisible]) => {
      visibilityMap.set(columns[Number(index)], { isHidden: !isVisible });
    });

    return visibilityMap;
  }

  /**
   * Rendering
   */

  render() {
    const { visibleColumns } = this.state;

    return this.props.render({
      visibleColumns,
      onColumnHide: this.onColumnHide,
      onColumnsToggle: this.onColumnsToggle,
      visibilityMapHelpers: {
        convertMapToFormData: this.convertMapToFormData,
        convertFormDataToMap: this.convertFormDataToMap,
      },
      getConfigButton: this.getConfigButtonRef,
    });
  }
}

export default DataTableColumnVisibility;
