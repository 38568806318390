import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CMPFormState, CMPValue, StatusRequest } from '../../constants/interfaces';
import { Redirect } from 'react-router-dom';
import CcpaAPI from '../../actions/ccpa';
import { Grid, Heading, PageSection, Text } from '@blueprints2/core';
import CMPDropdown from './cmp-dropdown';
import { getCmpName, getOthers } from '../../constants/functions';
import { UserConsentSelection } from '../../constants/constants';

interface CMPDetectedProps {
	onSelected: Function;
	onFormSubmitted: Function;
	cmpName: string;
	cmpType: string;
	values: CMPValue[];
}

const CMPDetected = (props: CMPDetectedProps) => {
	const { onSelected, onFormSubmitted, cmpType, cmpName, values } = props;
	const [formState, setFormState] = useState({
		selectedConsent: UserConsentSelection.CMPPlatform,
		cmpName: getCmpName(values, cmpName, cmpType),
		cmpType: cmpType,
		others: getOthers(values, cmpName, cmpType),
		redirect: false,
	} as CMPFormState);

	const dispatch = useDispatch();

	const redirectCallback = () => {
		setFormState({ ...formState, redirect: true });
	};

	const saveCMPStatus = async () => {
		const request = {
			name: formState.cmpName === 'cmpothers' ? formState.others : formState.cmpName,
			type: formState.cmpType,
			value: formState.cmpName,
			detected: true,
		} as StatusRequest;
		onFormSubmitted(true);
		await dispatch(CcpaAPI.saveCMPStatus(request, redirectCallback, onFormSubmitted));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		if (form.checkValidity() && formState.selectedConsent !== undefined) {
			saveCMPStatus();
		}
		event.preventDefault();
		event.stopPropagation();
	};

	if (formState.redirect) {
		return <Redirect to={{ pathname: '/home' }} />;
	}

	return (
		<PageSection>
			<Text>
				<Heading level={2}>It looks like you’re using a Consent Management Platform. Please tell us which.</Heading>
			</Text>

			<form id='detectedCMP' onSubmit={(e) => handleSubmit(e)} className='mt-8'>
				<Grid className='justify-center' style={{ display: 'grid' }}>
					<CMPDropdown values={values} state={formState} setState={setFormState} onSelect={onSelected} />
				</Grid>
			</form>
		</PageSection>
	);
};

export default CMPDetected;
