import { AnyAction } from 'redux';
import { AUTH_CLEAR, AUTH_DATA_ADD, AUTH_HOST_ADD, AUTH_SHOP_ORIGIN_ADD } from '../constants/action-types';

const initialState = {
	authShopOrigin: null,
	host: null,
};

export const auth = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case AUTH_SHOP_ORIGIN_ADD: {
			return {
				...state,
				authShopOrigin: action.data.shopOrigin,
			};
		}
		case AUTH_HOST_ADD: {
			return {
				...state,
				host: action.data.host,
			};
		}
		case AUTH_DATA_ADD: {
			return {
				...state,
				authShopOrigin: action.data.shopOrigin,
				host: action.data.host,
			};
		}
		case AUTH_CLEAR:
			return initialState;
		default:
			return state;
	}
};
