import React from 'react';
import shortid from 'shortid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import './IconRadio.less';

class IconRadio extends React.Component {
  static propTypes = {
    /**
     * Secondary Label to be displayed under Label
     */
    subLabel: PropTypes.node,

    /**
     * Secondary Label to be displayed under Label
     */
    icon: PropTypes.string,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * IconRadio label
     */
    label: PropTypes.node.isRequired,

    /**
     * Helper text that displays under the IconRadio label
     */
    helperText: PropTypes.node,

    /**
     * Change default circle for green check icon
     */
    withIcon: PropTypes.bool,

    /**
     * Renders selected/checked state
     */
    isChecked: PropTypes.bool,

    /**
     * Marks the IconRadio as required, triggering HTML5 validation in modern browsers
     */
    isRequired: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the IconRadio is required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Handler to call when the IconRadio state has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * IconRadio name
     */
    name: PropTypes.string,

    /**
     * IconRadio ID
     */
    id: PropTypes.string,

    /**
     * IconRadio value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Add border
     */
    withBorder: PropTypes.bool,

    /**
     * Tooltip props, the tooltip will render onClick of '?' button
     *
     * @see Tooltip.jsx for prop details
     */
    tooltip: PropTypes.shape({
      heading: PropTypes.node,
      body: PropTypes.node.isRequired,
      position: PropTypes.oneOf([
        'top-left',
        'top',
        'top-right',
        'right',
        'bottom-right',
        'bottom',
        'bottom-left',
        'left',
        'center',
      ]),
      className: PropTypes.string,
    }),
  };

  static defaultProps = {
    subLabel: '',
    id: '',
    className: '',
    isChecked: false,
    isRequired: undefined,
    html5: undefined,
    isDisabled: undefined,
    helperText: '',
    withIcon: null,
    name: '',
    withBorder: false,
    icon: '',
    tooltip: undefined,
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.id = this.props.id || `IconRadio-${shortid.generate()}`;
  }

  handleChange(event) {
    event.persist();
    const { onChange, value, name } = this.props;

    onChange({ value, name, event });
  }

  render() {
    const {
      isChecked,
      className,
      label,
      icon,
      name,
      value,
      tooltip,
      isRequired,
      html5,
      isDisabled,
      id,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      ...rest
    } = this.props;
    const classes = classnames('IconRadio', className, {
      'IconRadio--checked': isChecked,
    });

    return (
      <div
        className={classes}
        onClick={this.handleChange}
        role="presentation"
        {...rest}
      >
        <input
          className="IconRadioInput"
          type="radio"
          id={this.id}
          name={name}
          value={value}
          required={isRequired && html5}
          checked={isChecked}
          disabled={isDisabled}
        />

        {tooltip && (
          <div className="IconRadio__TooltipWrapper">
            <Tooltip
              heading={tooltip.heading}
              position={tooltip.position || 'top-left'}
              body={tooltip.body}
              className={tooltip.className}
            >
              {toggleVisibility => (
                <button
                  className="IconRadio__TooltipIconWrapper"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    toggleVisibility();
                  }}
                >
                  <Icon
                    type="question-solid"
                    className="IconRadio__TooltipIcon"
                  />
                </button>
              )}
            </Tooltip>
          </div>
        )}

        {icon && <Icon className="IconRadio__icon" type={icon} />}

        <div className="IconRadio__labelContainer">
          <label title={label} className="IconRadio__label" htmlFor={this.id}>
            {label}
          </label>
        </div>
      </div>
    );
  }
}

export default IconRadio;
