import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import classnames from 'classnames';
import shortid from 'shortid';

import { Button } from 'components/Button';
import Icon from 'components/Icon';
import './AdvancedSearchBar.less';

class AdvancedSearchBar extends PureComponent {
  static propTypes = {
    /**
     * Input name
     */
    inputName: PropTypes.string,

    /**
     * Input ID
     */
    inputID: PropTypes.string,

    /**
     * Select name
     */
    selectName: PropTypes.string,

    /**
     * Select ID
     */
    selectID: PropTypes.string,

    /**
     * Input placeholder
     */
    inputPlaceholder: PropTypes.node,

    /**
     * Select placeholder
     */
    selectPlaceholder: PropTypes.node,

    /**
     * A callback function that is triggered on click of search button
     *
     * @param {object} args - {selectedOption, searchInputValue}
     */
    onSearch: PropTypes.func.isRequired,

    /**
     * Child nodes, option elements expected
     */
    children: PropTypes.node,

    /**
     * Sets an option for select menu
     */
    selectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Content of the textInput
     */
    textValue: PropTypes.string,

    /**
     * Renders input disabled state
     */
    isInputDisabled: PropTypes.bool,

    /**
     * Renders select disabled state
     */
    isSelectDisabled: PropTypes.bool,

    /**
     * Renders small variant
     */
    isSmall: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Sets focus
     */
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    selectValue: '',
    selectPlaceholder: '',
    inputPlaceholder: '',
    inputName: '',
    inputID: '',
    selectName: '',
    selectID: '',
    textValue: '',
    isInputDisabled: false,
    isSelectDisabled: false,
    isSmall: false,
    children: null,
    autoFocus: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectValue,
      searchInputValue: props.textValue,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.finder = this.finder.bind(this);

    this.inputID = this.props.inputID || `TextInput-${shortid.generate()}`;
    this.inputID = this.props.selectID || `Select-${shortid.generate()}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { textValue, selectValue } = props;

    // This allows parent components to dynamically update input content via the value="" prop
    const select =
      selectValue !== state.selectedOption &&
      selectValue !== state.previousSelectValue
        ? { selectedOption: selectValue, previousSelectValue: selectValue }
        : { previousSelectValue: selectValue };

    const text =
      textValue !== state.searchInputValue &&
      textValue !== state.previousTextValue
        ? { searchInputValue: textValue, previousTextValue: textValue }
        : { previousTextValue: textValue };

    return { ...select, ...text };
  }

  onInputChange(event) {
    const { value } = event.target;
    this.setState({ searchInputValue: value });
  }

  onSelectChange(event) {
    const { value } = event.target;
    this.setState({ selectedOption: value });
  }

  /**
   * Function to search a selected option name.
   * @function finder
   * @param children - option elements.
   * @returns {option name}
   */
  finder(elements) {
    if (elements && elements.length) {
      const item = elements.find(
        el =>
          el.props &&
          el.props.value.toString() === this.state.selectedOption.toString(),
      );
      return item.props.label ? item.props.label : item.props.children;
    }
    return '';
  }

  render() {
    const {
      children,
      onSearch,
      inputPlaceholder,
      selectPlaceholder,
      inputName,
      selectName,
      isSelectDisabled,
      isInputDisabled,
      isSmall,
      intl,
      autoFocus,
    } = this.props;

    const messages = defineMessages({
      inputPlaceholder: {
        defaultMessage: 'Search',
        description: 'search',
      },
      selectPlaceholder: {
        defaultMessage: 'Select',
        description: 'select',
      },
      ariaLabel: {
        defaultMessage: 'Search',
        description: 'search',
      },
    });

    const selectPlaceholderValue =
      selectPlaceholder && selectPlaceholder.length
        ? selectPlaceholder
        : intl.formatMessage(messages.selectPlaceholder);

    const select = children ? (
      <div
        className={classnames('AdvancedSearchBar__select', {
          'AdvancedSearchBar__select--disabled': isSelectDisabled,
          'AdvancedSearchBar__select--small': isSmall,
        })}
      >
        <div className="AdvancedSearchBar__select--textWrapper">
          <div className="AdvancedSearchBar__select--textContainer">
            {this.state.selectedOption
              ? this.finder(children)
              : selectPlaceholderValue}
          </div>
        </div>

        <select
          disabled={isSelectDisabled}
          className={classnames('AdvancedSearchBar__select--selectEl', {
            'AdvancedSearchBar__select--disabled': isSelectDisabled,
          })}
          value={this.state.selectedOption}
          onChange={this.onSelectChange}
          placeholder={selectPlaceholderValue}
          id={this.selectID}
          name={selectName}
        >
          <option value="" disabled>
            {selectPlaceholderValue}
          </option>
          {children}
        </select>
      </div>
    ) : null;

    const isDisabled = isInputDisabled && (isSelectDisabled || !select);

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div
          className={classnames('AdvancedSearchBar', {
            'AdvancedSearchBar--small': isSmall,
          })}
        >
          <div
            className={classnames('AdvancedSearchBar__formWrapper', {
              'AdvancedSearchBar__formWrapper--disabled': isDisabled,
              'AdvancedSearchBar__formWrapper--small': isSmall,
            })}
          >
            {select}
            <input
              className={classnames('AdvancedSearchBar__input', {
                'AdvancedSearchBar__input--disabled': isInputDisabled,
                'AdvancedSearchBar__input--small': isSmall,
              })}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              placeholder={
                inputPlaceholder ||
                intl.formatMessage(messages.inputPlaceholder)
              }
              onChange={this.onInputChange}
              value={this.state.searchInputValue}
              id={this.inputID}
              name={inputName}
              disabled={isInputDisabled}
            />
          </div>
          <Button
            className={classnames('AdvancedSearchBar__button', {
              'AdvancedSearchBar__button--small': isSmall,
            })}
            onClick={() =>
              onSearch({
                searchInputValue: this.state.searchInputValue,
                selectedOption: this.state.selectedOption,
              })
            }
            aria-label={intl.formatMessage(messages.ariaLabel)}
            type="submit"
            isDisabled={isDisabled}
          >
            <Icon
              className={classnames({
                'AdvancedSearchBar__icon--small': isSmall,
              })}
              type="search"
            />
          </Button>
        </div>
      </form>
    );
  }
}

export default injectIntl(AdvancedSearchBar);
