import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import Icon from 'components/Icon';
import Heading from 'components/Heading';
// import Text from 'components/Text';
import { Button } from 'components/Button';

class DrawerModal extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  messages = defineMessages({
    confirmationMessage: {
      defaultMessage: 'Are you sure you want to close?',
      description: 'Drawer Modal message',
    },
    confirm: {
      defaultMessage: 'Confirm',
      description: 'confirm',
    },
    cancel: {
      defaultMessage: 'Cancel',
      description: 'cancel',
    },
  });

  render() {
    const { isVisible, onClose, onConfirm, intl } = this.props;

    return (
      <Modal className="DrawerModal" isVisible={isVisible} onClose={onClose}>
        <ModalHeader>
          <Icon className="DrawerModal__Icon" type="exclamation-solid" />
          <Heading level={3}>
            {intl.formatMessage(this.messages.confirmationMessage)}
          </Heading>
        </ModalHeader>

        <ModalBody />

        <ModalFooter>
          <Button onClick={onConfirm}>
            {intl.formatMessage(this.messages.confirm)}
          </Button>
          <Button isTertiary onClick={onClose}>
            {intl.formatMessage(this.messages.cancel)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default injectIntl(DrawerModal);
