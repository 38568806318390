import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';

import { defineMessages, injectIntl } from 'react-intl';

import Icon from 'components/Icon';
import InputLabel from 'components/InputLabel';
import HelperText from 'components/HelperText';

import './TextArea.less';

class TextArea extends PureComponent {
  static propTypes = {
    /**
     * Content of the TextArea
     */
    value: PropTypes.string,

    /**
     * TextArea name
     */
    name: PropTypes.string.isRequired,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Renders a smaller TextArea
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger TextArea
     */
    isLarge: PropTypes.bool,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Marks the TextArea as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the TextArea is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the TextArea is required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * TextArea label
     */
    label: PropTypes.node.isRequired,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Placeholder text
     */
    placeholder: PropTypes.node,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * TextArea ID
     */
    id: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * A function that should trigger an action whenever the TextArea's
     * onChange event fires.
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Whether the TextArea can be resized by the user
     */
    canResize: PropTypes.bool,

    /**
     * Specify the visible width of the TextArea
     */
    numCols: PropTypes.number,

    /**
     * Specify the visible height of the TextArea
     */
    numRows: PropTypes.number,

    /**
     * The character limit of the text box. Falsey value means no character limit.
     */
    charLimit: PropTypes.number,
  };

  static defaultProps = {
    hasError: false,
    isSmall: false,
    isLarge: false,
    isValid: false,
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    hasHiddenLabel: false,
    helperText: '',
    placeholder: '',
    className: '',
    id: '',
    value: '',
    canResize: false,
    numCols: null,
    numRows: null,
    charLimit: null,
    onChange: () => {},
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
    charLimit: {
      defaultMessage: 'Maximum {charLimit} characters',
      description: 'TextArea maximum character limit label',
    },
  });

  constructor(props) {
    super(props);
    this.state = {};

    this.onChange = this.onChange.bind(this);
    this.id = this.props.id || `TextArea-${shortid.generate()}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    // This allows parent components to dynamically update input content via the value="" prop
    return value !== state.value && value !== state.previousValue
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  onChange(event) {
    event.persist();
    const { onChange, name } = this.props;
    const { value } = event.target;

    this.setState({ value }, () => {
      onChange({ name, value, event });
    });
  }

  render() {
    const { value } = this.state;
    const {
      hasError: propError,
      isSmall,
      isLarge,
      isValid,
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      hasHiddenLabel,
      label,
      helperText: propHelperText,
      placeholder,
      className,
      id,
      canResize,
      numCols,
      numRows,
      name,
      charLimit,
      intl,
      ...rest
    } = this.props;

    const isAtCharLimit =
      charLimit && (value ? value.length > charLimit : false);
    const hasError = propError || isAtCharLimit;

    const helperTextContent = isAtCharLimit
      ? intl.formatMessage(this.messages.charLimit, {
          charLimit,
        })
      : propHelperText;

    const classes = classnames('TextArea__element', className, {
      'TextArea__element--error': hasError,
      'TextArea__element--valid': isValid,
      'TextArea__element--disabled': isDisabled,
      'TextArea__element--small': isSmall,
      'TextArea__element--large': isLarge,
      'TextArea__element--resizable': canResize,
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('TextArea__icon', {
      'TextArea__icon--error': hasError,
      'TextArea__icon--valid': isValid,
    });

    return (
      <div className="TextArea">
        <InputLabel
          htmlFor={this.id}
          isHidden={hasHiddenLabel}
          hasAsterisk={hasAsterisk && isRequired}
        >
          {label}
        </InputLabel>

        <div className="TextArea__childrenContainer">
          <textarea
            required={isRequired && html5}
            {...rest}
            cols={numCols}
            rows={numRows}
            placeholder={placeholder}
            className={classes}
            value={value}
            onChange={this.onChange}
            disabled={isDisabled}
            id={this.id}
            name={name}
          />

          <div
            className="TextArea__helperWrapper"
            aria-live="polite"
            role="status"
          >
            {hasIcon && (
              <Icon
                title={intl.formatMessage(iconTitle)}
                className={iconClasses}
                type={iconType}
              />
            )}
            {(helperTextContent || charLimit) && (
              <HelperText
                isLarge={isLarge}
                hasError={hasError}
                className="TextArea__helperText"
              >
                <span>{helperTextContent}</span>
                {charLimit && (
                  <span className="TextArea__charLimit">
                    {`${value ? value.length : 0}/${charLimit}`}
                  </span>
                )}
              </HelperText>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(TextArea);
