import './PulsedCheckMark.less';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import React from 'react';

const PulsedCheckMark = ({ className }) => {
  return (
    <div className={classnames('PulsedCheckMark__wrapper', className)}>
      <div className="pulse" />
      <svg className="PulsedCheckMark__canvas" viewBox="0 0 48 48">
        <rect
          className="PulsedCheckMark__background"
          width="100%"
          height="100%"
          rx="24"
        />
        <path
          className="PulsedCheckMark__icon"
          d="M17.1 2.9l-.2-.2c-4-3.9-10.4-3.7-14.2.2-3.8 4-3.7 10.3.2 14.1 3.9 3.7 10 3.7 13.9 0 4-3.8 4.1-10.1.3-14.1zM9 13.4L5.3 9.7l1.4-1.4L9 10.6l4.3-4.3 1.4 1.4L9 13.4z"
        />
      </svg>
    </div>
  );
};

PulsedCheckMark.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

PulsedCheckMark.defaultProps = {
  className: '',
};

export default PulsedCheckMark;
