import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './PageSection.less';

const PageSection = props => {
  const { children, className, ...rest } = props;

  const classes = classnames('PageSection', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

PageSection.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

PageSection.defaultProps = {
  className: '',
};

export default PageSection;
