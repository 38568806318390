import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getComponentName } from 'components/helpers';
import ThreeDotMenu from 'components/ThreeDotMenu';
import ExpandableCard from './states';

import './Card.less';

const categorizeChildren = children =>
  React.Children.toArray(children).reduce(
    (acc, child) => {
      switch (getComponentName(child)) {
        case 'CardMedia':
          acc.media = child;
          break;

        case 'CardExpandableContent':
          acc.expandableContent = child;
          break;

        case 'CardFooter':
          acc.footer = child;
          break;

        case 'CardBanner':
          acc.topSection = child;
          break;

        default:
          acc.children.push(child);
      }

      return acc;
    },
    {
      media: null,
      expandableContent: null,
      footer: null,
      topSection: null,
      children: [],
    },
  );

class Card extends PureComponent {
  static propTypes = {
    /**
     * Child nodes
     */
    children: PropTypes.node.isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Renders card media and the rest of the content in a row on `md` breakpoint
     */
    hasMobileRow: PropTypes.bool,

    /**
     * Optional handler to call when entire card area is clicked
     */
    onClick: PropTypes.func,

    /**
     * Option to include a hover effect on mouse over of card
     */
    hoveredEffect: PropTypes.bool,

    /**
     * When true, adds an border class which indicates that
     * this card is currently active e.g. when card been clicked for highlighting further information
     */
    isActive: PropTypes.bool,

    /**
     * menu options
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.node.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    ),
  };

  static defaultProps = {
    className: '',
    hasMobileRow: false,
    onClick: null,
    isActive: false,
    hoveredEffect: true,
    options: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ isHovered: true });
  }

  onMouseLeave() {
    this.setState({ isHovered: false });
  }

  render() {
    const {
      className,
      hasMobileRow,
      onClick,
      isActive,
      hoveredEffect,
      options,
      ...rest
    } = this.props;
    const { isHovered } = this.state;
    const {
      media,
      expandableContent,
      footer,
      topSection,
      children,
    } = categorizeChildren(this.props.children);

    const Component = expandableContent ? ExpandableCard : 'div';

    const expandableContentProps = expandableContent
      ? {
          label: expandableContent.props.label,
          details: expandableContent.props.children,
        }
      : null;

    const cardClasses = classnames(
      'Card',
      {
        'Card--hasMobileRow': hasMobileRow,
        'Card--clickable': onClick && !isActive,
        'Card--active': isActive,
        'Card--isHovered': isHovered && hoveredEffect,
        'Card--noTopPadding': topSection,
      },
      className,
    );

    return (
      <Component
        {...expandableContentProps}
        {...rest}
        className={cardClasses}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="Card__container">
          {topSection}

          {media}

          <div className="Card__children">{children}</div>
        </div>

        <div className="CardFooter__container">
          <div>{footer}</div>
          {options.length > 0 && <ThreeDotMenu options={options} />}
        </div>
      </Component>
    );
  }
}

export default Card;
