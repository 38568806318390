import React, { useState } from 'react';
import { Route, RouteProps, Switch, withRouter } from 'react-router-dom';
import Welcome from '../welcome';
import Home from '../home';
import CCPA from '../ccpa';
import SPI from '../spi';
import AdditionalScript from '../additional-script';
import ThirdParty from '../third-party';
import Error from '../error';
import NavigationBar from '../navigation';
import AppMain from '../main';
import AppFooter from '../footer';
import PrivateRoute from './PrivateRoute';
import AppBridge from '../app-bridge/AppBridge';
import AppHome from '../app-home/AppHome';
import AppCallback from '../app-callback/AppCallback';
import PixelVerification from '../pixel-verification/PixelVerification';
import PreInstall from '../preinstall';

const Router = (props: RouteProps) => {
	const [httpStatusState, setHttpStatusState] = useState<number | undefined>(undefined);

	return (
		<Switch>
			<Route exact path='/app' component={() => <AppHome />} />
			<Route exact path='/callback' component={() => <AppCallback />} />

			<Route exact path='/preinstall' component={() => <PreInstall />} />

			<PrivateRoute path='/welcome' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppMain>
						<Welcome />
					</AppMain>
					<AppFooter />
				</>
			</PrivateRoute>
			<PrivateRoute path='/home' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<Home onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppFooter />
				</>
			</PrivateRoute>
			<PrivateRoute path='/ccpa' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppMain>
						<CCPA onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					</AppMain>
					<AppFooter />
				</>
			</PrivateRoute>
			<PrivateRoute path='/spi' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppMain>
						<SPI onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					</AppMain>
					<AppFooter />
				</>
			</PrivateRoute>
			<PrivateRoute path='/tracking' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppMain>
						<AdditionalScript onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					</AppMain>
					<AppFooter />
				</>
			</PrivateRoute>
			<PrivateRoute path='/pixel' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppMain>
						<PixelVerification onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					</AppMain>
					<AppFooter />
				</>
			</PrivateRoute>
			<PrivateRoute path='/checkout-apps' httpStatus={httpStatusState}>
				<>
					<NavigationBar onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					<AppMain>
						<ThirdParty onHttpStatusChange={(httpStatus: number) => setHttpStatusState(httpStatus)} />
					</AppMain>
					<AppFooter />
				</>
			</PrivateRoute>
			<Route
				path='/shopify/appbridge/:scopes/:apiKey/:shopOrigin/:state'
				component={(props: any) => (
					<AppBridge
						scopes={props.match.params.scopes}
						apiKey={props.match.params.apiKey}
						shopOrigin={props.match.params.shopOrigin}
						state={props.match.params.state}
					/>
				)}
			/>
			<Route path='/error/unauthorized'>
				<Error status={401} message={'Authorization Failed!'} headingText='Error' headingUnderlineWidth='40px' />
			</Route>
			<Route path='/error/forbidden'>
				<Error status={403} headingText='Error' headingUnderlineWidth='40px' />
			</Route>
			<Route path='/error/server_error'>
				<Error status={500} message={'Something wrong, Try later!'} headingText='Error' headingUnderlineWidth='40px' />
			</Route>
		</Switch>
	);
};

export default withRouter(Router);
