import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { typeOfInstance, getComponentName } from 'components/helpers';
import ScrollIndicator from 'components/ScrollIndicator';

import Tab from './Tab';
import './Tabs.less';

class Tabs extends PureComponent {
  static propTypes = {
    /**
     * Child Tab nodes
     */
    children: typeOfInstance(Tab).isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Accessibility enhancement. Describes the purpose of the set of tabs to screen readers
     */
    label: PropTypes.node,

    /**
     * Handler to call when the Tabs state has changed
     *
     * @arg Object - { selection }
     */
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    label: null,
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.setSelection = this.setSelection.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.updatedInternally) return { updatedInternally: false };

    let initialSelection = -1;
    React.Children.map(props.children, (child, index) => {
      if (child && child.props && child.props.isSelected) {
        initialSelection = index;
      }
    });

    return { selection: initialSelection, updatedInternally: false };
  }

  setSelection(selection) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ selection, updatedInternally: true }, () => {
      this.props.onChange({ selection });
    });
  }

  renderChildrenWithProps() {
    return React.Children.map(this.props.children, (child, index) => {
      const childName = getComponentName(child);
      if (childName !== 'Tab') return null;

      return React.cloneElement(child, {
        index,
        isSelected: this.state.selection === index,
        onSelection: this.setSelection,
      });
    });
  }

  render() {
    const {
      className,
      label,
      // The following props are only destructured so they don't end up in ...rest
      children,
      onChange,
      ...rest
    } = this.props;

    const classes = classnames('Tabs', className);

    return (
      <ScrollIndicator {...rest} direction="horizontal" className={classes}>
        <ul className="Tabs__list" aria-label={label} role="tablist">
          {this.renderChildrenWithProps()}
        </ul>
      </ScrollIndicator>
    );
  }
}

export default Tabs;
