import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import { ADDITIONAL_SCRIP_CLEAR, CCPA_DATA_GET, CCPA_SHOW_ERROR, CCPA_UPDATE_STEP } from '../constants/action-types';
import { StatusRequest } from '../constants/interfaces';

export default {
	getCMPData: (loading: Function, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.cmp.getData);
			dispatch({ type: CCPA_DATA_GET, data: response.data });
			loading(false);
		} catch (err: any) {
			console.error(`Error while getting cmp data: ${err.response.data.message}`);
			loading(false);
			errorCallback(err.response.status);
		}
	},
	getUSPApiStatus: (updateCallback: Function, loadingCallback: Function, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.cmp.getUSPApiStatus);
			if (response.data.status === '1') {
				await updateCallback(5.1);
				loadingCallback(false);
			} else {
				await updateCallback(5.2);
				loadingCallback(false);
			}
		} catch (err: any) {
			if (err.response.status === 404) {
				console.log('No uspapi detail found this shop');
				await updateCallback(5.2);
				await loadingCallback(false);
			} else {
				console.error(`Error occurred while getting uspapi details: ${err.response.data.message}`);
				loadingCallback(false);
				if (err.response.status === 500) {
					dispatch({ type: CCPA_SHOW_ERROR, data: { alertMessage: `Error: ${err.response.data.message}`, showAlert: true } });
				} else {
					errorCallback(err.response.status);
				}
			}
		}
	},
	updateStep: (currentStep: number) => async (dispatch: Dispatch) => {
		try {
			await ShopifyAPi.post(API.V1.onboardingStep.create, { lastAccessedStep: currentStep });
			dispatch({ type: CCPA_UPDATE_STEP, data: { lastAccessedStep: currentStep } });
		} catch (err: any) {
			console.error(`Error occurred while storing last accessed step: ${err.response.data.message}`);
		}
	},
	saveCMPStatus: (cmpStatusRequest: StatusRequest, redirectCallback: Function, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			await ShopifyAPi.post(API.V1.cmp.store, cmpStatusRequest);
			redirectCallback();
		} catch (err: any) {
			errorCallback(false);
			console.error(`Error occurred while storing cmp status: ${err.response.data.message}`);
		}
	},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: ADDITIONAL_SCRIP_CLEAR }),
};
