import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const WizardStage = props => {
  const {
    children,
    title,
    isComplete,
    className,
    cancelBtnText,
    nextBtnText,
    prevBtnText,
    beforeGoToNextStage,
    ...rest
  } = props;
  const classes = classnames('Wizard__stage', className);

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};

WizardStage.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Stage title
   */
  title: PropTypes.node.isRequired,

  /**
   * Used to determine whether the user can continue on to the next stage in the Wizard
   */
  isComplete: PropTypes.bool.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Optional callback which fires between the change of the current stage to the next
   */
  beforeGoToNextStage: PropTypes.func,

  /**
   * Optional text or React element to rename the Cancel button
   */
  cancelBtnText: PropTypes.node,

  /**
   * Optional text or React element to rename the Next button
   */
  nextBtnText: PropTypes.node,

  /**
   * Optional text or React element to rename the Previous button
   */
  prevBtnText: PropTypes.node,
};

WizardStage.defaultProps = {
  className: '',
  nextBtnText: '',
  cancelBtnText: '',
  prevBtnText: '',
  beforeGoToNextStage: () => {},
};

export default WizardStage;
