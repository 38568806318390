import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Image from 'components/Image';
import './CardMedia.less';

const CardMedia = props => {
  const {
    src,
    fallback,
    alt,
    size,
    objectFit,
    tag,
    to,
    href,
    newWindow,
  } = props;
  const classes = classnames('CardMedia', `CardMedia--${size}`);
  const imgClasses = classnames('CardMedia__Image', `object-${objectFit}`);

  return (
    <div className={classes}>
      <Image
        className={imgClasses}
        src={src}
        fallback={fallback}
        alt={alt}
        to={to}
        href={href}
        newWindow={newWindow}
      />

      {tag && <div className="CardMedia__tag">{tag}</div>}
    </div>
  );
};

CardMedia.propTypes = {
  /**
   * Image path
   */
  src: PropTypes.string.isRequired,

  /**
   * Fallback element
   */
  fallback: PropTypes.node,

  /**
   * Alt attribute for image
   */
  alt: PropTypes.node,

  /**
   * Background image size
   */
  size: PropTypes.oneOf(['sm', 'md']),

  /**
   * Background image object fit
   */
  objectFit: PropTypes.oneOf([
    'contain',
    'cover',
    'fill',
    'none',
    'scale-down',
  ]),

  /**
   * Information tag is positioned on right corner of the image. Information tag should be used with `Tag` component.
   */
  tag: PropTypes.node,

  /**
   * React Router link location
   *
   * - Optional **to** attribute for Image
   *
   * @see https://reacttraining.com/react-router/web/api/Link
   */

  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),

  /**
   * Link URL
   *
   * - Optional **href** attribute for Image
   */
  href: PropTypes.string,

  /**
   * Opens the Image link in a new window
   */
  newWindow: PropTypes.bool,
};

CardMedia.defaultProps = {
  fallback: null,
  alt: '',
  size: 'md',
  tag: '',
  objectFit: 'cover',
  href: '',
  to: '',
  newWindow: false,
};

export default CardMedia;
