import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AdvancedSwitcherHeader from './AdvancedSwitcherHeader';
import AdvancedSwitcherFooter from './AdvancedSwitcherFooter';

import './AdvancedSwitcher.less';

const AdvancedSwitcher = React.memo(
  ({
    onSubmit,
    onClick,
    hasSearchBtn,
    searchBarProps,
    hasCloseBtn,
    onClose,
    footerLinks,
    className,
    children,
    ...rest
  }) => {
    const classes = classnames('AdvancedSwitcher', className);

    return (
      <div className={classes}>
        <AdvancedSwitcherHeader
          searchBarProps={searchBarProps}
          hasCloseBtn={hasCloseBtn}
          onClose={onClose}
          onSubmit={onSubmit}
          onClick={onClick}
          hasSearchBtn={hasSearchBtn}
          {...rest}
        />
        <div className="AdvancedSwitcherBody">{children}</div>
        {footerLinks.length > 0 && (
          <AdvancedSwitcherFooter links={footerLinks} />
        )}
      </div>
    );
  },
);

AdvancedSwitcher.propTypes = {
  /**
   * When true - show close btn
   */
  hasCloseBtn: PropTypes.bool,

  /**
   * Function to be triggered when you click on the close button
   */
  onClose: PropTypes.func,

  /**
   * SearchBar props(search placeholder, onChange handler)
   */
  searchBarProps: PropTypes.shape({
    onChange: PropTypes.func,
    placeholder: PropTypes.node,
    value: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    helperText: PropTypes.node,
    label: PropTypes.node,
    hasHiddenLabel: PropTypes.bool,
    isSmall: PropTypes.bool,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }),

  /**
   * Footer links props, contain:
   * to, href, onClick: () => {}, text,
   */
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    }),
  ),

  /**
   * Additional classes
   */
  className: PropTypes.string,

  /**
   * Child text/nodes
   */
  children: PropTypes.node,

  /**
   * Function on submit
   */
  onSubmit: PropTypes.func,

  /**
   * When true - show search button
   */
  hasSearchBtn: PropTypes.bool,

  /**
   * Functions to be triggered when the user clicks on the search button
   */
  onClick: PropTypes.func,
};

AdvancedSwitcher.defaultProps = {
  onSubmit: () => {},
  hasSearchBtn: false,
  onClick: () => {},
  hasCloseBtn: false,
  onClose: () => {},
  searchBarProps: {
    onChange: () => {},
    placeholder: '',
    value: '',
    id: '',
    name: '',
    helperText: '',
    label: '',
    hasHiddenLabel: true,
    isSmall: false,
    isRequired: false,
    isDisabled: false,
  },
  footerLinks: [],
  className: '',
  children: null,
};

export default AdvancedSwitcher;
