import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';

import Drawer from 'components/Drawer';
import Heading from 'components/Heading';
import ThreeDotMenu from 'components/ThreeDotMenu';
import NotificationItem from './NotificationItem';

import './Notifications.less';

class Notifications extends React.Component {
  static propTypes = {
    /**
     * offsetElementID
     */
    offsetElementID: PropTypes.string,

    /**
     * notifications
     */
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        isRead: PropTypes.bool.isRequired,
        title: PropTypes.node.isRequired,
        icon: PropTypes.string.isRequired,
        timestamp: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.instanceOf(moment),
        ]).isRequired,
        body: PropTypes.node,
      }),
    ).isRequired,

    /**
     * fallback
     */
    fallback: PropTypes.node,

    /**
     * options
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    ),

    /**
     * onNotificationRead
     */
    onNotificationRead: PropTypes.func.isRequired,

    /**
     * isVisible
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * onClose
     */
    onClose: PropTypes.func.isRequired,

    /**
     * When true, drawer will display Modal to confirm when user tries to close
     */
    confirmClose: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  messages = defineMessages({
    notificationsHeader: {
      defaultMessage: 'Notifications',
      description: 'Notifications header',
    },
  });

  static defaultProps = {
    offsetElementID: null,
    options: [],
    fallback: null,
    confirmClose: false,
  };

  constructor(props) {
    super(props);

    this.closeNotifications = this.closeNotifications.bind(this);
  }

  closeNotifications() {
    this.props.onClose();
  }

  renderOptions() {
    const { options } = this.props;

    return options.length > 0 ? <ThreeDotMenu options={options} /> : null;
  }

  render() {
    const {
      notifications,
      fallback,
      offsetElementID,
      onNotificationRead,
      isVisible,
      confirmClose,
      intl,
    } = this.props;

    const headerOffset = !offsetElementID ? {} : { offsetElementID };

    return (
      <Drawer
        isVisible={isVisible}
        onClose={this.closeNotifications}
        confirmClose={confirmClose}
        {...headerOffset}
      >
        <div className="Notifications">
          <div className="Notifications__Header">
            <Heading level={2} className="Notifications__Heading">
              {intl.formatMessage(this.messages.notificationsHeader)}
            </Heading>

            {this.renderOptions()}
          </div>

          <div className="Notifications__Body">
            {notifications.length === 0 && fallback}

            {notifications.length > 0 &&
              notifications.map(notification => (
                <NotificationItem
                  key={notification.id}
                  {...notification}
                  onClick={
                    !notification.isRead
                      ? () => {
                          onNotificationRead(notification.id);
                        }
                      : undefined
                  }
                />
              ))}
          </div>
        </div>
      </Drawer>
    );
  }
}

export default injectIntl(Notifications);
