import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Text.less';

const Text = props => {
  const { children, className, variation, ...rest } = props;

  const classes = classnames('Text', className, {
    [`Text--${variation}`]: variation,
  });

  return (
    <p className={classes} {...rest}>
      {children}
    </p>
  );
};

Text.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Text variations to choose from
   */
  variation: PropTypes.oneOf(['sm', 'strong']),
};

Text.defaultProps = {
  className: '',
  variation: null,
};

export default Text;
