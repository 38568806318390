import React from 'react';
import shortid from 'shortid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import { defineMessages, injectIntl } from 'react-intl';
import Tooltip from 'components/Tooltip';
import InputLabelWithIcon from '../InputLabelWithIcon';
import './NestedCheckbox.less';

class NestedCheckbox extends React.Component {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * NexstedCheckbox label
     */
    label: PropTypes.node.isRequired,

    /**
     * Secondary Label to be displayed under Label
     */
    subLabel: PropTypes.node,

    /**
     * Input name
     */
    name: PropTypes.string.isRequired,

    /**
     * Content of the input
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Marks the NexstedCheckbox as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the NexstedCheckbox is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the NestedCheckbox is required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled state
     */
    isDisabled: PropTypes.bool,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * A function that should trigger an action whenever the input's
     * onChange event fires.
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Input ID
     */
    id: PropTypes.string,

    /**
     * Renders selected/checked state
     */
    isChecked: PropTypes.bool,

    /**
     * Controls whether or not the Checkbox is checked
     */
    isIndeterminate: PropTypes.bool,

    /**
     * Tooltip props, the tooltip will render onClick of '?' button
     *
     * @see Tooltip.jsx for prop details
     */
    tooltip: PropTypes.shape({
      heading: PropTypes.node,
      body: PropTypes.node.isRequired,
      position: PropTypes.oneOf([
        'top-left',
        'top',
        'top-right',
        'right',
        'bottom-right',
        'bottom',
        'bottom-left',
        'left',
        'center',
      ]),
      className: PropTypes.string,
    }),

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,
  };

  static defaultProps = {
    subLabel: null,
    className: '',
    id: '',
    isChecked: false,
    isIndeterminate: false,
    isRequired: undefined,
    hasAsterisk: true,
    html5: true,
    isDisabled: undefined,
    hasHiddenLabel: false,
    helperText: '',
    onChange: () => {},
    value: '',
    tooltip: undefined,
    isValid: false,
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked !== undefined ? props.isChecked : false,
    };

    this.id = this.props.id || `NestedCheckbox-${shortid.generate()}`;
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    event.persist();
    const { onChange, value, name } = this.props;

    this.setState(
      ({ isChecked }) => ({
        isChecked: !isChecked,
      }),
      () => {
        const { isChecked } = this.state;
        onChange({ event, isChecked, value, name });
      },
    );
  }

  render() {
    const { isChecked } = this.state;
    const {
      className,
      label,
      subLabel,
      name,
      value,
      tooltip,
      isRequired,
      isDisabled,
      html5,
      id,
      // The following props are only destructured so they don't end up in ...rest
      onChange,
      isChecked: isCheckedProp,
      isIndeterminate,
      helperText,
      hasHiddenLabel,
      hasAsterisk,
      isValid,
      intl,
      ...rest
    } = this.props;
    const classes = classnames('NestedCheckbox', className, {
      'NestedCheckbox--checked': isChecked,
      'NestedCheckbox--valid': isValid,
      'NestedCheckbox--required': isRequired,
      'NestedCheckbox--tooltip': !!tooltip,
    });

    const labelContainerClasses = classnames('NestedCheckbox__labelContainer', {
      'NestedCheckbox__labelContainer--hasSubLabel': !!subLabel,
    });

    const iconTitle = intl.formatMessage(this.messages.validated);
    const iconType = 'checkmark-solid';
    const iconClasses = classnames('NestedCheckbox__icon', {
      'NestedCheckbox__icon--valid ml-4': isValid,
    });

    return (
      <div className={classes} role="presentation" {...rest}>
        <div className={labelContainerClasses}>
          <div className="NestedCheckbox__label">
            <input
              className="NestedCheckboxInput"
              type="checkbox"
              id={this.id}
              name={name}
              value={value || this.id}
              checked={isChecked}
              required={isRequired && html5}
              disabled={isDisabled}
              onChange={this.onChange}
            />

            <span title={label}>{label}</span>
          </div>

          {subLabel && (
            <div title={subLabel} className="NestedCheckbox__subLabel">
              {subLabel}
            </div>
          )}
        </div>

        {tooltip && (
          <div className="NestedCheckbox__TooltipWrapper">
            <div className="NestedCheckbox__TooltipIconWrapper">
              <Tooltip
                heading={tooltip.heading}
                position={tooltip.position || 'right'}
                body={tooltip.body}
                className={tooltip.className}
              >
                {toggleVisibility => (
                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      toggleVisibility();
                    }}
                  >
                    <Icon
                      type="question-solid"
                      className="NestedCheckbox__TooltipIcon"
                    />
                  </button>
                )}
              </Tooltip>
            </div>
          </div>
        )}

        {isValid && (
          <InputLabelWithIcon
            iconTitle={iconTitle}
            iconType={iconType}
            helperText={helperText}
            iconClasses={iconClasses}
            intl={intl}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(NestedCheckbox);
