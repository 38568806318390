import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Based on: https://github.com/joshwnj/react-visibility-sensor
 *
 * @TODO: This has fallen out of date with the above repo and probably
 * contains features that we don't need. It might be worth considering
 * rewriting this without unused cruft and with more BP2-specific defaults
 */

export default class Visibility extends PureComponent {
  static propTypes = {
    /**
     * Callback function fired onChange event
     */
    onChange: PropTypes.func,

    /**
     * Toggles state of visibility
     */
    isActive: PropTypes.bool,

    /**
     * Listen to onScroll event
     */
    scrollCheck: PropTypes.bool,

    /**
     * Number of ms delay to check on scroll
     */
    scrollDelay: PropTypes.number,

    /**
     * Number of ms to throttle when to check scroll event again
     */
    scrollThrottle: PropTypes.number,

    /**
     * Listen to onResize event
     */
    resizeCheck: PropTypes.bool,

    /**
     * Number of ms delay to check on resize
     */
    resizeDelay: PropTypes.number,

    /**
     * Number of ms to throttle when to check resize event again
     */
    resizeThrottle: PropTypes.number,

    /**
     * Toggles management of checking intervals
     */
    intervalCheck: PropTypes.bool,

    /**
     * Number of ms for an interval delay
     */
    intervalDelay: PropTypes.number,

    /**
     * DOM node that wraps around the elements we are determining visibility of
     */
    container: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

    /**
     * Children we are controlling visibility of
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    onChange: null,
    isActive: true,
    scrollCheck: false,
    scrollDelay: 250,
    scrollThrottle: -1,
    resizeCheck: false,
    resizeDelay: 250,
    resizeThrottle: -1,
    intervalCheck: true,
    intervalDelay: 100,
    container: null,
    children: React.createElement('span'),
  };

  constructor(props) {
    super(props);

    this.debounceCheck = {};
    this.interval = null;

    this.check = this.check.bind(this);
  }

  componentDidMount() {
    this.check();

    if (this.props.isActive) this.startWatching();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      this.startWatching();
      this.forceUpdate();
    }
    if (!this.props.isActive && prevProps.isActive) {
      this.stopWatching();
    }
  }

  componentWillUnmount() {
    this.stopWatching();
  }

  getContainer() {
    return this.props.container || window;
  }

  addEventListener(target, event, delay, throttle) {
    let timeout;

    const later = () => {
      timeout = null;
      this.check();
    };

    const func =
      throttle > -1
        ? () => {
            if (!timeout) timeout = setTimeout(later, throttle || 0);
          }
        : () => {
            clearTimeout(timeout);
            timeout = setTimeout(later, delay || 0);
          };

    const info = {
      target,
      fn: () => {
        func();
        const isVisible = this.check();
        if (this.props.onChange) this.props.onChange(isVisible);
      },
      getLastTimeout: () => timeout,
    };

    target.addEventListener(event, info.fn);
    this.debounceCheck[event] = info;
  }

  startWatching() {
    if (this.props.intervalCheck) {
      this.interval = setInterval(this.check, this.props.intervalDelay);
    }

    if (this.props.scrollCheck) {
      this.addEventListener(
        this.getContainer(),
        'scroll',
        this.props.scrollDelay,
        this.props.scrollThrottle,
      );
    }

    if (this.props.resizeCheck) {
      this.addEventListener(
        this.getContainer(),
        'resize',
        this.props.resizeDelay,
        this.props.resizeThrottle,
      );
    }

    this.check();
  }

  stopWatching() {
    if (
      typeof this.debounceCheck === 'object' &&
      Object.keys(this.debounceCheck).length
    ) {
      // clean up event listeners and their debounce callers
      Object.keys(this.debounceCheck).forEach(debounceEvent => {
        const debounceInfo = this.debounceCheck[debounceEvent];
        clearTimeout(debounceInfo.getLastTimeout());
        debounceInfo.target.removeEventListener(debounceEvent, debounceInfo.fn);
        this.debounceCheck[debounceEvent] = null;
      });
    }

    this.debounceCheck = {};

    if (this.interval) this.interval = clearInterval(this.interval);
  }

  /**
   * Check if the element is within the visible viewport
   */
  check() {
    // if the component has rendered to null, don't update visibility
    if (!this.props.container) return false;

    const containerDOMRect = this.props.container.getBoundingClientRect();
    const { top, left, bottom, right } = containerDOMRect;
    const containerRect = { top, left, bottom, right };

    const visibilityRect = {
      top: containerRect.top >= 0,
      left: containerRect.left >= 0,
      bottom:
        containerRect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight),
      right:
        containerRect.right <=
        (window.innerWidth || document.documentElement.clientWidth),
    };

    const isVisible =
      visibilityRect.top &&
      visibilityRect.left &&
      visibilityRect.bottom &&
      visibilityRect.right;

    return isVisible;
  }

  render() {
    return React.Children.only(this.props.children);
  }
}
