import blueprintsTranslations from './translations/compiled-lang';

/**
 * i18n helpers
 *
 * @see https://medium.freecodecamp.org/setting-up-internationalization-in-react-from-start-to-finish-6cb94a7af725
 */

/**
 * Get user's language. Different browsers have the user locale defined
 * on different fields on the `window.navigator` object, so we make sure
 * to account for these differences by checking all of them
 *
 * @param {obj} [testNavigator] - optionally allows passing in a custom obj for tests
 */
const getBrowserLanguage = testNavigator => {
  const navigator = testNavigator || window.navigator;

  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  );
};

/**
 * Strip region codes from locales
 *
 * @param {string} locale - e.g., 'en-US' becomes just 'en'
 */
const stripRegionCode = locale =>
  typeof locale === 'string' ? locale.toLowerCase().split('-')[0] : null;

/**
 * Merge Blueprints translations with client app translations,
 * while allowing devs to override our translations if needed
 *
 * @param {obj} clientTranslations - react-intl map of translated strings, @example translations.js
 */
const inheritTranslations = clientTranslations => {
  if (!clientTranslations) return blueprintsTranslations;

  const mergedMap = { ...blueprintsTranslations };

  Object.keys(clientTranslations).forEach(clientKey => {
    const noRegionKey = stripRegionCode(clientKey);
    const blueprintsKey =
      !blueprintsTranslations[clientKey] && blueprintsTranslations[noRegionKey]
        ? noRegionKey
        : clientKey;

    mergedMap[clientKey] = {
      ...blueprintsTranslations[blueprintsKey],
      ...clientTranslations[clientKey],
    };
  });

  return mergedMap;
};

/**
 * Return translations to be used in IntlProvider, based on provided language code
 * and available language codes in blueprintsTranslations and messages
 *
 * @param {string} language - e.g., 'en-US'
 * @param {obj} messages - react-intl map of translated strings, @example translations.js
 */
const getTranslations = (language, messages) => {
  const translations = inheritTranslations(messages);

  // Try full locale, try locale without region code, try top-level keys
  return (
    translations[language] ||
    translations[stripRegionCode(language)] ||
    translations
  );
};

export {
  getBrowserLanguage,
  stripRegionCode,
  inheritTranslations,
  getTranslations,
};
