import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/Icon';

import './ModalHeader.less';

const ModalHeader = props => {
  const { children, className, icon } = props;

  const classes = classnames('ModalHeader', className, {
    'ModalHeader--hasChildren': children,
  });

  return (
    <div className={classes}>
      {icon && (
        <div className="ModalHeader__Icon--wrapper">
          <Icon
            className="ModalHeader__Icon--default"
            type={typeof icon === 'string' ? icon : 'hand-stop'}
          />
        </div>
      )}
      {children}
    </div>
  );
};

ModalHeader.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Icon type
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ModalHeader.defaultProps = {
  children: null,
  className: '',
  icon: null,
};

export default ModalHeader;
