import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import focusTrap from 'focus-trap';

import Icon from 'components/Icon';
import Positioning from 'components/Positioning';
import Visibility from 'components/Visibility';
import './Tooltip.less';

class Tooltip extends PureComponent {
  static propTypes = {
    /**
     * Child function that renders the anchored element that the
     * tooltip will be attached to and takes `toggleVisibility` as it's argument
     */
    children: PropTypes.func.isRequired,

    /**
     * Heading text
     */
    heading: PropTypes.node,

    /**
     * Body text/nodes
     */
    body: PropTypes.node.isRequired,

    /**
     * Position of tooltip
     */
    position: PropTypes.oneOf([
      'top-left',
      'top',
      'top-right',
      'right',
      'bottom-right',
      'bottom',
      'bottom-left',
      'left',
      'center',
    ]),

    /**
     *
     */
    initiallyVisible: PropTypes.bool,

    /**
     *
     */
    trapFocus: PropTypes.bool,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Function to be triggered when the "Got It" button is clicked
     */
    onClickGotIt: PropTypes.func,

    /**
     * "Got It" button text
     */
    gotItBtnText: PropTypes.string,
  };

  static defaultProps = {
    position: 'bottom',
    heading: null,
    initiallyVisible: false,
    trapFocus: false,
    className: '',
    onClickGotIt: () => {},
    gotItBtnText: '',
  };

  messages = defineMessages({
    closeButton: {
      defaultMessage: 'Close modal',
      description: 'Modal close ariaLabel',
    },
    gotIt: {
      defaultMessage: 'Got it',
      description: 'Affirmative close tooltip message',
    },
  });

  constructor(props) {
    super(props);

    this.state = {
      position: props.position,
      isVisible: this.props.initiallyVisible,
      recalculatePosition: 0,
    };

    this.onPositionFlip = this.onPositionFlip.bind(this);
    this.onPositionMove = this.onPositionMove.bind(this);
    this.onResize = this.onResize.bind(this);
    this.getContentRef = this.getContentRef.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.onClickGotIt = this.onClickGotIt.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.containerRef = React.createRef();
    this.tooltipRef = React.createRef();
    this.tooltipWrapperRef = React.createRef();
    this.tooltipOverlayRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);

    if (this.tooltipOverlayRef.current) {
      this.tooltipOverlayRef.current.addEventListener('mousedown', e =>
        e.stopPropagation(),
      );
    }

    if (this.props.initiallyVisible && this.props.trapFocus) {
      setTimeout(() => this.focusTrap.activate(), 100);
    }

    this.forceUpdate();

    /**
     * NOTE: This is a bit of a hack to force the
     * visible tooltips to be placed in the
     * correct position after target & tooltip
     * elements are rendered. This should only happen
     * for always or initially visible tooltips
     */
    if (this.state.isVisible) {
      this.timeout = setTimeout(() => {
        this.setState(prevState => ({
          recalculatePosition: prevState.recalculatePosition + 1,
        }));
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { position } = this.props;
    const { isVisible, container } = this.state;

    const positionChanged = prevProps.position !== position;
    const containerChanged =
      prevState.container && prevState.container !== container;

    if (positionChanged || containerChanged) {
      this.setState({ position, arrowMove: null });
    }

    if (!this.props.trapFocus) {
      return;
    }

    const isOpening = !prevState.isVisible && isVisible;
    const isClosing = prevState.isVisible && !isVisible;
    const isMoving = prevState.container !== container && isVisible;

    if (isOpening || isMoving) {
      // focusTrap will auto-focus into the first focusable element within the tooltip,
      // and prevent keyboard users from accidentally tabbing outside the tooltip
      setTimeout(() => this.focusTrap.activate(), 100);
      // setTimeout ensures that the tooltip is done positioning/being shown
      // before focusing into it - otherwise, .focus() sometimes scrolls/jumps
      // to an incorrect position (happens if the page content shifted)
    }
    if (isClosing) {
      // focusTrap will attempt to auto-focus back onto item that toggled the Tooltip
      this.focusTrap.deactivate();
    }
  }

  componentWillUnmount() {
    if (this.props.trapFocus && this.focusTrap) this.focusTrap.deactivate();
    this.willUnmount = true;
    document.removeEventListener('mousedown', this.handleClick);
    clearTimeout(this.timeout);
  }

  /**
   * When Positioning reverses due to being out of bounds, update our position
   * accordingly so that arrows remain pointing in the correct direction
   */
  onPositionFlip(position) {
    this.setState({ position });
  }

  /**
   * When Positioning moves due to being out of bounds, offset the arrow
   * (via marginLeft/marginTop) as well so that the arrow remains on the anchor
   */
  onPositionMove(offset, direction) {
    if (this.state.isVisible) {
      this.setState({ arrowMove: { [`margin${direction}`]: offset } });
    }
  }

  onResize(targetIsVisible) {
    /**
     * We only need to recalculate position onResize if
     * the tooltip is always visible or the tooltip is
     * initially visible and still visible, otherwise
     * we recalculate tooltip position on hover
     */
    if (targetIsVisible && this.state.isVisible) {
      this.setState(prevState => ({
        recalculatePosition: prevState.recalculatePosition + 1,
      }));
    }
  }

  onClickGotIt(e) {
    this.props.onClickGotIt(e);
    this.hideTooltip(e)
  }

  getContentRef(el) {
    this.setState({ contentRef: el });
  }

  handleClick(e) {
    if (this.tooltipRef.current.contains(e.target)) {
      return;
    }

    // if click outside Tooltip
    this.hideTooltip(e);
  }

  toggleVisibility(visibilityValue) {
    if (!this.state.isVisible && visibilityValue !== false) {
      this.setState({ recalculatePosition: true });
    }

    if (this.state.isVisible && visibilityValue !== true) {
      this.setState({ recalculatePosition: false });
    }

    if (visibilityValue !== undefined) {
      this.setState({ isVisible: visibilityValue });
    } else {
      this.setState(({ isVisible }) => ({ isVisible: !isVisible }));
    }
  }

  hideTooltip(e) {
    if (this.state.isVisible) {
      if (!this.props.trapFocus) {
        e.preventDefault();
      }

      this.setState({
        isVisible: false,
        recalculatePosition: false,
      });
    }
  }

  createFocusTrap() {
    if (this.focusTrap || !this.props.trapFocus) return; // Already initialized or not necessary

    const { contentRef } = this.state;
    if (contentRef) {
      // @see https://github.com/davidtheclark/focus-trap
      this.focusTrap = focusTrap(contentRef, {
        clickOutsideDeactivates: false,
        allowOutsideClick: true,
        fallbackFocus: contentRef,
        onDeactivate: this.hideTooltip,
      });
    }
  }

  render() {
    const { intl, heading, body, className, children } = this.props;
    const { position, arrowMove, isVisible, recalculatePosition } = this.state;

    if (this.props.trapFocus) {
      this.createFocusTrap();
    }

    const classes = classnames('Tooltip', `Tooltip--${position}`, className, {
      'Tooltip--visible': isVisible,
    });
    const arrowClasses = classnames(
      'Tooltip__arrow',
      `Tooltip__arrow--${position}`,
    );
    const borderClasses = classnames(
      'Tooltip__border',
      `Tooltip__border--${position}`,
    );

    const tooltipClasses = classnames('Tooltip__Overlay', {
      Tooltip__OverlayShow: isVisible,
    });

    return (
      <div
        role="presentation"
        ref={this.tooltipWrapperRef}
        onClick={e => {
          e.preventDefault();
          if (this.tooltipWrapperRef.current.contains(e.target)) {
            e.stopPropagation();
          }
        }}
      >
        <Visibility
          scrollCheck
          resizeCheck
          container={this.containerRef.current}
          onChange={this.onResize}
        >
          <div>
            <div
              className={tooltipClasses}
              ref={this.tooltipOverlayRef}
              onClick={this.hideTooltip}
              role="presentation"
            />
            <div className="TooltipWrapper" ref={this.containerRef}>
              {children(this.toggleVisibility, this.tooltipRef.current)}
              <Positioning
                anchor={this.containerRef.current}
                position={position}
                onPositionFlip={this.onPositionFlip}
                onPositionMove={this.onPositionMove}
                horizontalOffset={24}
                rerender={
                  isVisible &&
                  this.containerRef.current !== null &&
                  recalculatePosition
                }
              >
                <div
                  ref={this.tooltipRef}
                  className={classes}
                  aria-hidden={!isVisible}
                  aria-live="polite"
                >
                  <div
                    className={arrowClasses}
                    style={arrowMove}
                    role="presentation"
                  />
                  <div
                    className="Tooltip__content"
                    ref={this.getContentRef}
                    onClick={e => e.stopPropagation()}
                    role="presentation"
                    tabIndex="-1"
                  >
                    <div className={borderClasses} role="presentation" />
                    {heading ? (
                      <Fragment>
                        <button
                          type="button"
                          className="Tooltip__close"
                          aria-label={intl.formatMessage(
                            this.messages.closeButton,
                          )}
                          onClick={this.hideTooltip}
                        >
                          <Icon type="close" />
                        </button>
                        <div className="Tooltip__headingContainer">
                          {heading}
                        </div>
                      </Fragment>
                    ) : null}
                    {body ? (
                      <div className="Tooltip__bodyContainer">{body}</div>
                    ) : null}

                    <div className="Tooltip__gotItContainer">
                      <button
                        type="button"
                        className="Tooltip__gotIt"
                        aria-label={this.props.gotItBtnText || intl.formatMessage(this.messages.gotIt)}
                        onClick={this.onClickGotIt}
                      >
                        {this.props.gotItBtnText || intl.formatMessage(this.messages.gotIt)}
                      </button>
                    </div>
                  </div>
                </div>
              </Positioning>
            </div>
          </div>
        </Visibility>
      </div>
    );
  }
}

export default injectIntl(Tooltip);
