import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/Icon';
import { defineMessages, injectIntl } from 'react-intl';

import { linkShape } from './propTypes';

import './MobileNav.less';

export const MobileNavBurger = ({ isOpen, onClick, label }) => {
  return (
    <button
      className={classnames('MobileNavBurger', {
        'MobileNavBurger--open': isOpen,
      })}
      onClick={onClick}
      type="button"
    >
      <span className="MobileNavBurger__menuIcon" role="presentation">
        <span className="MobileNavBurger__hamburger" />
      </span>

      <span className="MobileNavBurger__label">{label}</span>
    </button>
  );
};

MobileNavBurger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

const MobileSubNav = ({ links, linksAs, isOpen, closeMenu }) => {
  const routClassName = classnames('MobileSubNav', {
    'MobileSubNav--open': isOpen,
  });

  return (
    <ul className={routClassName}>
      {links.map(link => (
        <MobileNavLink
          key={link.label}
          as={linksAs}
          link={link}
          closeMenu={closeMenu}
          className="MobileSubNav__item"
          linkClassName="MobileSubNav__link"
        />
      ))}
    </ul>
  );
};

MobileSubNav.propTypes = {
  links: PropTypes.arrayOf(linkShape),
  linksAs: PropTypes.elementType,
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
};

MobileSubNav.defaultProps = {
  links: [],
  linksAs: 'a',
  isOpen: false,
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Opens in new window',
    description: 'TextLink new window icon label',
  },
});

const MobileNavLink = injectIntl(
  ({
    intl,
    as: Component,
    link,
    isOpen,
    closeMenu,
    onToggle,
    className,
    linkClassName,
  }) => {
    if (link.isVisible === false) {
      return null;
    }

    const hasNav = !!(
      link &&
      link.props &&
      ('href' in link.props || 'to' in link.props)
    );

    const isNew = link.newWindow === true && !('to' in link.props);
    const newWindowProps = isNew ? { target: '_blank', rel: 'noopener' } : {};

    const linkComponentClassName = classnames(linkClassName, {
      [`${linkClassName}--active`]: link.isActive,
      [`${linkClassName}--navless`]: !hasNav,
    });

    const linkProps = {
      onClick: hasNav
        ? e => {
            e.stopPropagation();
            closeMenu();
          }
        : () => {},
      ...link.props,
      ...newWindowProps,
    };

    const wrapperProps = link.links
      ? {
          onClick: e => {
            e.preventDefault();
            onToggle(isOpen ? null : link);
          },
          ...newWindowProps,
        }
      : {};

    return (
      <li className={className}>
        <div className="MobileNav__linkWrapper" {...wrapperProps}>
          <Component className={linkComponentClassName} {...linkProps}>
            <span className="MobileNav__label">
              {link.label}

              {isNew && link.hideNewWindowIcon !== true && (
                <Icon
                  type="external-link"
                  className="NavLinks__linkIcon NavLinks__linkIcon--newWindow"
                  title={intl.formatMessage(messages.title)}
                />
              )}
            </span>
          </Component>

          {link.links && (
            <span>
              <Icon
                type="arrow-nav"
                className={classnames('MobileNav__linkIcon', {
                  'MobileNav__linkIcon--open': isOpen,
                })}
              />
            </span>
          )}
        </div>

        {link.links && (
          <MobileSubNav
            links={link.links}
            linksAs={Component}
            isOpen={isOpen}
            closeMenu={closeMenu}
          />
        )}
      </li>
    );
  },
);

MobileNavLink.propTypes = {
  as: PropTypes.elementType,
  link: linkShape.isRequired,
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
};

MobileNavLink.defaultProps = {
  as: 'a',
  isOpen: false,
  onToggle: () => {},
  className: 'MobileNav__item',
  linkClassName: 'MobileNav__link',
};

const MobileNav = ({ links, linksAs, closeMenu }) => {
  const [currOpen, setCurrOpen] = useState(null);

  useEffect(() => {
    const activeLink = links.findIndex(link => {
      if (link.isActive) {
        return true;
      }

      return (
        link.links && link.links.find(subLink => subLink.isActive) !== undefined
      );
    });

    setCurrOpen(activeLink);
  }, [links]);

  const handleToggle = link => {
    if (!link) {
      setCurrOpen(null);
    }

    setCurrOpen(links.indexOf(link));
  };

  return (
    <div className="MobileNav">
      <ul className="MobileNav__items">
        {links.map((link, index) => (
          <MobileNavLink
            key={link.label}
            as={link.as || linksAs}
            link={link}
            closeMenu={closeMenu}
            isOpen={index === currOpen}
            onToggle={handleToggle}
          />
        ))}
      </ul>
    </div>
  );
};

MobileNav.propTypes = {
  links: PropTypes.arrayOf(linkShape),
  linksAs: PropTypes.elementType,
  closeMenu: PropTypes.func.isRequired,
};

MobileNav.defaultProps = {
  links: [],
  linksAs: 'a',
};

export default MobileNav;
