import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Image.less';

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      srcError: false,
    };
  }

  onError = () => {
    if (!this.state.srcError) {
      this.setState({
        srcError: true,
      });
    }
  };

  render() {
    const { className, alt, src, fallback, to, href, newWindow } = this.props;
    const { srcError } = this.state;

    const imageClassNames = classnames('Image', className);

    const isNew = newWindow && !to;
    const newWindowProps = isNew ? { target: '_blank', rel: 'noopener' } : {};
    const linkProps = to ? { to } : { href, ...newWindowProps };
    const hasLink = to || href;

    const LinkComponent = to ? Link : 'a';

    const ImageComponent = () =>
      srcError ? (
        <div className={imageClassNames}>{fallback}</div>
      ) : (
        <img
          className={imageClassNames}
          src={src}
          alt={alt}
          onError={this.onError}
        />
      );

    return hasLink ? (
      <LinkComponent {...linkProps} className="ImageWrapper">
        <ImageComponent />
      </LinkComponent>
    ) : (
      <ImageComponent />
    );
  }
}

Image.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Image path
   */
  src: PropTypes.string.isRequired,

  /**
   * Fallback element
   */
  fallback: PropTypes.node,

  /**
   * Alt attribute for image
   */
  alt: PropTypes.node,

  /**
   * React Router link location
   *
   * - Optional **to** attribute
   *
   * @see https://reacttraining.com/react-router/web/api/Link
   */

  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),

  /**
   * Link URL
   *
   * - Optional **href** attribute
   */
  href: PropTypes.string,

  /**
   * Opens the Image link in a new window
   */
  newWindow: PropTypes.bool,
};

Image.defaultProps = {
  className: '',
  alt: '',
  fallback: null,
  href: '',
  to: '',
  newWindow: false,
};

export default Image;
