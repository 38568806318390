import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ReactResizeDetector from 'react-resize-detector';

import {
  getDOMNode,
  createPortalContainer,
  createPortal,
} from 'components/helpers';

import './Positioning.less';

export class Positioning extends PureComponent {
  static propTypes = {
    /**
     * Child nodes (e.g. Tooltip, Popover)
     */
    children: PropTypes.node.isRequired,

    /**
     * React ref of the container that this positioned content is anchored to
     * @see https://reactjs.org/docs/refs-and-the-dom.html
     */
    anchor: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

    /**
     * Position of this anchored content
     */
    position: PropTypes.oneOf([
      'top-left',
      'top',
      'top-right',
      'right',
      'bottom-right',
      'bottom',
      'bottom-left',
      'left',
      'center',
    ]),

    /**
     * Horizontal offset, used to center the arrow on the expected anchor
     */
    horizontalOffset: PropTypes.number,

    /**
     * Allows parent to force recalculating position based on a state of their
     * choice (e.g. onHover / visibility).
     *
     * Rerendering only occurs when the flag passed in is different from the
     * previous value and is not falsy - e.g. alternating between true/false
     * or incrementing a counter for each rerender.
     */
    rerender: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

    /**
     * Callback that allows parent to respond correctly when Positioning needs
     * to flip or change the `position` prop due to the positioned element
     * going out of document bounds (e.g. by updating arrow classes).
     */
    onPositionFlip: PropTypes.func,

    /**
     * Callback that allows parent to respond correctly when Positioning needs
     * to offset the positioned element due to going out of document bounds
     * (e.g. by updating arrow offsets via inline styles).
     */
    onPositionMove: PropTypes.func,

    /**
     * Custom X Offset
     */
    customXOffset: PropTypes.number,

    popoverRef: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  };

  static defaultProps = {
    anchor: null,
    position: 'top',
    horizontalOffset: 0,
    rerender: false,
    onPositionFlip: () => {},
    onPositionMove: () => {},
    popoverRef: undefined,
    customXOffset: 0,
  };

  constructor(props) {
    super(props);
    this.state = { style: {}, isMounted: false };

    this.positionedEl = React.createRef();
    this.portalNode = createPortalContainer();
    this.rerender = this.rerender.bind(this);
  }

  componentDidMount() {
    document.body.appendChild(this.portalNode);
    this.setState({ isMounted: true });
    this.setPositionStyles();
  }

  componentDidUpdate(prevProps, prevState) {
    const { anchor, rerender, position } = this.props;

    const updatedAnchor = prevProps.anchor !== anchor;
    const updatedPopover = prevProps.position !== position;

    const isMounted = prevState.isMounted && this.state.isMounted;
    const shouldRerender =
      isMounted && rerender && prevProps.rerender !== rerender;

    if (updatedAnchor || shouldRerender || updatedPopover) this.rerender();
  }

  componentWillUnmount() {
    if (this.portalNode && document.body.contains(this.portalNode)) {
      document.body.removeChild(this.portalNode);
    }
  }

  /**
   * Set top/left CSS positions
   */
  setPositionStyles() {
    /**
     * ThreeDotMenu has auto calculation
     */
    const useCheckOverflow =
      !this.props.popoverRef ||
      (this.props.popoverRef && !this.props.popoverRef.current);

    const { anchor } = this.props;
    if (!anchor || !this.positionedEl) return false;

    const anchorEl = getDOMNode(anchor);
    if (!anchorEl) {
      console.warn('The anchor you passed in does not have a DOM node to position to.'); // prettier-ignore
      return false;
    }

    /**
     * Get element dimensions and target positions
     */
    this.anchorRect = anchorEl.getBoundingClientRect();
    this.positionedRect = this.positionedEl.current.getBoundingClientRect();

    const { body, documentElement } = document;
    const scrollTop =
      window.pageYOffset || documentElement.scrollTop || body.scrollTop || 0;
    const scrollLeft =
      window.pageXOffset || documentElement.scrollLeft || body.scrollLeft || 0;
    const clientTop = documentElement.clientTop || body.clientTop || 0;
    const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

    this.target = {
      top: this.anchorRect.top + scrollTop - clientTop,
      left: this.anchorRect.left + scrollLeft - clientLeft,
    };

    /**
     * Catch positions that are going out of document bounds & need to be reversed
     */
    let { position } = this.props;
    this.isXAnchored = position === 'left' || position === 'right';

    this.page = {
      width: documentElement.scrollWidth,
      height: documentElement.scrollHeight,
    };
    position = this.checkOverflowPosition(position);

    /**
     * Main logic/math for calculating left/x and top/y positions
     */
    /* eslint-disable prefer-destructuring */
    let transform = '';
    let transformOffset = 0;

    let top = null;

    switch (position) {
      case 'top':
      case 'top-left':
      case 'top-right':
        top = this.target.top;
        transform += 'translateY(-100%)';
        break;
      case 'bottom':
      case 'bottom-left':
      case 'bottom-right':
        top = this.target.top + this.anchorRect.height;
        break;
      case 'left':
      case 'right':
        top = this.target.top + this.anchorRect.height / 2;
        transform += 'translateY(-50%)';
        transformOffset = this.positionedRect.height / 2;
        break;
      case 'center':
      default:
        top = this.target.top + this.anchorRect.height / 2;
        transform += 'translateY(-100%)';
        break;
    }

    let left = null;

    switch (position) {
      case 'top-left':
      case 'bottom-left':
        left = this.target.left - this.props.horizontalOffset;
        break;
      case 'left':
        left = this.target.left;
        transform += 'translateX(-100%)';
        break;
      case 'right':
        left = this.target.left + this.anchorRect.width;
        break;
      case 'top-right':
      case 'bottom-right':
        left =
          this.target.left +
          this.anchorRect.width +
          this.props.horizontalOffset +
          this.props.customXOffset;
        transform += 'translateX(-100%)';
        transformOffset = this.positionedRect.width;
        break;
      case 'top':
      case 'bottom':
      case 'center':
      default:
        left =
          this.target.left +
          this.anchorRect.width / 2 +
          this.props.customXOffset;
        transform += 'translateX(-50%)';
        transformOffset = this.positionedRect.width / 2;
        break;
    }

    /**
     * Catch false negative Jest test failures for components that contain
     * Positioning, use mount(), and aren't mocking getBoundingClientRect
     */
    if (Number.isNaN(top) || Number.isNaN(left)) return false;

    /**
     * Catch non-flipped positions that are going out of document bounds & can be moved
     */

    if (useCheckOverflow) {
      if (this.isXAnchored) {
        top = this.checkOverflowOffset(top, transformOffset, 'Top');
      } else {
        left = this.checkOverflowOffset(left, transformOffset, 'Left');
      }
    }

    /**
     * Set final position styles
     */
    const style = { top, left, transform, display: 'inline-table' };
    return this.setState({ style });
  }

  /**
   * Catches primary positions that are going out of bounds & need to be flipped.
   * e.g. If the element is top-anchored and there isn't enough space above the
   * element, its position needs to be flipped so its bottom-anchored instead.
   *
   * @param {string} position - e.g. 'top', 'left', 'top-left'
   */
  checkOverflowPosition(position) {
    const { target, anchorRect, positionedRect, page } = this;
    let newPosition = position;

    if (this.isXAnchored) {
      // Check if the positioned element is going to go offscreen horizontally
      const isOverflowingLeft = target.left - positionedRect.width < 0;
      const rightBound = target.left + anchorRect.width + positionedRect.width;
      const isOverflowingRight = page.width && rightBound - page.width > 0;

      // Flip left/right positions
      if (isOverflowingLeft) {
        if (position === 'left' || position === 'bottom-left')
          newPosition = 'right';
      } else if (isOverflowingRight) {
        if (
          position === 'right' ||
          position === 'bottom' ||
          position === 'bottom-right'
        )
          newPosition = 'left';
      }
    } else {
      // Check if the positioned element is going to go offscreen vertically
      const isOverflowingTop = target.top - positionedRect.height < 0;
      const bottomBound =
        target.top + anchorRect.height + positionedRect.height;
      const isOverflowingBottom = page.height && bottomBound > page.height;

      // Flip top/bottom (including corners like bottom-left)
      if (isOverflowingTop) {
        if (position.includes('top'))
          newPosition = position.replace('top', 'bottom');
      } else if (isOverflowingBottom) {
        if (position.includes('bottom'))
          newPosition = position.replace('bottom', 'top');
      }
    }

    if (position !== newPosition) {
      // Let parent components know if we've internally corrected our position,
      // so that they can, e.g., update arrow classes as necessary
      this.props.onPositionFlip(newPosition);
    }
    return newPosition;
  }

  /**
   * Catches secondary position coordinates that are going out of bounds & can be
   * offset to remain within bounds. This check only on the non-flipped axis -
   * e.g., if position is 'top', its primary axis is Y, and offsetting will only
   * occur on the X axis/left coordinate.
   *
   * @param {number} position - Left or top coordinate/position
   * @param {number} transform - CSS transform offset
   * @param {string} direction - The axis/direction we're checking in - either 'Left' or 'Top'.
   *                             The string is capitalized so that we can easily use it for, e.g.
   *                             setting 'marginLeft'/'marginTop' in React inline styles
   */
  checkOverflowOffset(position, transform, direction) {
    const { anchorRect, page } = this;
    const dimension = direction === 'Top' ? 'height' : 'width';

    // Calculate whether the element is going to overflow out of bounds, and by how much
    const target = position - transform; // Account for CSS transform offset
    const overflow = target + anchorRect[dimension] - page[dimension];

    let delta;
    let newPosition = position;

    // Negative overflow - left or top
    if (target < 0) {
      delta = target;
      newPosition = transform;
    }
    // Positive overflow - right or bottom
    else if (overflow > 0) {
      delta = overflow;
      newPosition -= overflow;
    }

    if (delta) {
      // Let parent components know if we've internally corrected our position,
      // so that they can, e.g., update arrow offsets as necessary
      this.props.onPositionMove(delta, direction);
    }

    return newPosition;
  }

  rerender() {
    this.setState({ style: {} }, this.setPositionStyles);
  }

  render() {
    return createPortal(
      <div
        className="Positioning"
        style={this.state.style}
        ref={this.positionedEl}
      >
        <div ref={this.props.popoverRef}>
          {this.props.children}

          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.rerender}
            skipOnMount
          />
        </div>
      </div>,
      this.portalNode,
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Positioning popoverRef={ref} {...props} />
));
