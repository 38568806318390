import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import SearchBar from 'components/SearchBar';
import Form from 'components/Form';
import PopoverApply from 'components/PopoverApply';
import ScrollIndicator from 'components/ScrollIndicator';

import FilterFormSearch from './FilterFormSearch';

class FilterForm extends PureComponent {
  /**
   * Passed internally from parent Filter component
   */
  static propTypes = {
    children: PropTypes.node.isRequired,
    hasSearch: PropTypes.bool.isRequired,
    activeFilters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onFormSubmit: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    isClosed: PropTypes.bool.isRequired,
    togglePopover: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isSmallBtn: PropTypes.bool,
    customTextApplyBtn: PropTypes.node,
    customTextCancelBtn: PropTypes.node,
  };

  static defaultProps = {
    isSmallBtn: true,
    customTextApplyBtn: null,
    customTextCancelBtn: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      resetForm: 0,
    };

    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    // When re-opening the popover, reset all form/accordion state
    if (prevProps.isClosed && !this.props.isClosed) {
      this.setState(({ resetForm }) => ({
        searchTerm: '',
        resetForm: resetForm + 1,
      }));
    }
  }

  onSearch({ value }) {
    this.setState({ searchTerm: value });
  }

  render() {
    const {
      hasSearch,
      activeFilters,
      onFormSubmit,
      onFormChange,
      togglePopover,
      children,
      intl,
      isSmallBtn,
      customTextApplyBtn,
      customTextCancelBtn,
    } = this.props;
    const { searchTerm, resetForm } = this.state;

    const messages = defineMessages({
      placeholder: {
        defaultMessage: 'Find filter',
        description: 'FilterForm searchbar placeholder',
      },
      label: {
        defaultMessage: 'Find filter',
        description: 'FilterForm searchbar label',
      },
    });

    return (
      <Fragment>
        {hasSearch && (
          <SearchBar
            onChange={this.onSearch}
            value={searchTerm}
            label={intl.formatMessage(messages.label)}
            placeholder={intl.formatMessage(messages.placeholder)}
          />
        )}
        <Form
          onSubmit={onFormSubmit}
          onChange={onFormChange}
          values={activeFilters}
          key={resetForm}
          className={searchTerm ? 'FilterPopover__searching' : ''}
        >
          <ScrollIndicator
            direction="vertical"
            className="FilterPopover__content"
          >
            <FilterFormSearch searchTerm={searchTerm}>
              {children}
            </FilterFormSearch>
          </ScrollIndicator>
          <PopoverApply
            isSmallBtn={isSmallBtn}
            className="FilterPopover__apply"
            onCancel={togglePopover}
            customTextApplyBtn={customTextApplyBtn}
            customTextCancelBtn={customTextCancelBtn}
          />
        </Form>
      </Fragment>
    );
  }
}

export default injectIntl(FilterForm);
