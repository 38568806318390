import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../common';
import { Alert, Button, Grid, GridCell, Heading, Icon, Text, TextLink } from '@blueprints2/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IAdditionalScript, MerchantMid } from '../../constants/interfaces';
import './AdditionalScript.css';
import beautify from 'xml-beautifier';
import AdditionalScriptAPI from '../../actions/additional-script';
import { Store } from '../../store';
import { MerchantRecordType, StepStatus } from '../../constants/constants';

interface AdditionalScriptProps {
	onHttpStatusChange?: Function;
}

const AdditionalScript = (props: AdditionalScriptProps) => {
	const { onHttpStatusChange } = props;
	const { script } = useSelector((state: Store) => state.additionalScript);
	const { merchant } = useSelector((state: Store) => state.merchant);
	const [state, setState] = useState({} as IAdditionalScript);
	const dispatch = useDispatch();

	const errorCallback = (status: number) => {
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const redirectCallback = (redirect: boolean) => {
		setState({ ...state, redirect: redirect, isFormSubmitted: false });
	};

	const getAdditionalScriptStatus = async () => {
		await dispatch(AdditionalScriptAPI.getAdditionalScriptStatus(errorCallback));
	};

	const getAdditionalScript = async () => {
		await dispatch(AdditionalScriptAPI.getAdditionalScript(errorCallback));
	};

	const reviewStatus = async (status: number) => {
		setState({ ...state, isFormSubmitted: true });
		await dispatch(AdditionalScriptAPI.reviewStatus(status, errorCallback, redirectCallback));
	};

	const handleClick = () => {
		const isNonAffiliate =
			merchant.mids &&
			merchant.mids.filter((m: MerchantMid) => m.mid === merchant.mid && m.recordType === MerchantRecordType.AFFILIATE).length === 0;
		reviewStatus(isNonAffiliate ? 1 : 2);
	};

	const isDisabled = () => {
		if (state.additionalScriptStatus !== undefined) {
			if (state.additionalScriptStatus.valueOf() === StepStatus.InReview.valueOf()) {
				return true;
			}
			return state.additionalScriptStatus.valueOf() === StepStatus.Completed.valueOf();
		}
		return false;
	};

	const getAlert = () => {
		let alertElem;
		if (state.additionalScriptStatus !== undefined) {
			if (state.additionalScriptStatus.valueOf() === StepStatus.InReview.valueOf()) {
				alertElem = (
					<Alert isDismissible={false} type='warning' icon='hourglass' className='mb-5'>
						In review
					</Alert>
				);
			} else if (state.additionalScriptStatus.valueOf() === StepStatus.Completed.valueOf()) {
				alertElem = (
					<Alert isDismissible={false} type='success' icon='checkmark-outline' className='mb-5'>
						Complete
					</Alert>
				);
			} else if (state.additionalScriptStatus.valueOf() === StepStatus.Failed.valueOf()) {
				alertElem = (
					<Alert isDismissible={false} type='danger' icon='exclamation-solid' className='mb-5'>
						Failed
					</Alert>
				);
			}
		}
		return alertElem;
	};

	const renderCodeSnippetCode = () => {
		let codeSnippetElem;
		if (state.isCodeExpanded) {
			const xml = beautify(state.codeSnippet);
			codeSnippetElem = (
				<>
					<Grid className='mt-4'>
						<GridCell key={1} width='1/12' className='text-center'>
							<div
								className='icon'
								onClick={() => {
									setState({ ...state, isCodeExpanded: false });
								}}
							>
								<Icon type='chevron-outline-up' className='w-6 h-8 text-purple-500' />
							</div>
						</GridCell>
						<GridCell key={2} width='10/12' className='pt-1'>
							<Heading level={4}>Hide code snippet</Heading>
						</GridCell>
					</Grid>
					<Grid className='mt-4'>
						<GridCell key={2} width='9/12' className='pt-1'>
							<code>
								<Text className='pl-12'>{xml}</Text>
							</code>
						</GridCell>
					</Grid>
				</>
			);
		} else {
			codeSnippetElem = (
				<>
					<Grid className='mt-4'>
						<GridCell key={1} width='1/12' className='text-center'>
							<div
								className='icon'
								onClick={() => {
									setState({ ...state, isCodeExpanded: true });
								}}
							>
								<Icon type='chevron-outline-down' className='w-6 h-8 text-purple-500' />
							</div>
						</GridCell>
						<GridCell key={2} width='10/12' className='pt-1'>
							<Heading level={4}>View code snippet</Heading>
						</GridCell>
					</Grid>
				</>
			);
		}
		return codeSnippetElem;
	};

	const getLink = () => {
		return (
			<Button style={{ height: '1rem' }} isDisabled={!state.isCopyClicked} isSmall>
				<TextLink
					className='buttonLink'
					newWindow
					href={state.adminCheckoutPageLink}
					rel='noopener noreferrer'
					onClick={() => {
						setState({ ...state, isGotoAdminClicked: true });
					}}
				>
					Go to settings
				</TextLink>
			</Button>
		);
	};

	const renderDynamicCommision = () => {
		let dynamicElem;
		if (state.isDynamicExpanded) {
			dynamicElem = (
				<>
					<Grid className='mt-4'>
						<GridCell key={1} width='1/12' className='text-center'>
							<div
								className='icon'
								onClick={() => {
									setState({ ...state, isDynamicExpanded: false });
								}}
							>
								<Icon type='chevron-outline-up' className='w-6 h-8 text-purple-500' />
							</div>
						</GridCell>
						<GridCell key={2} width='10/12' className='pt-1'>
							<Heading level={4}>Hide tracking additional data?</Heading>
						</GridCell>
						<Grid className='mt-4'>
							<Text className='pl-12'>
								Tracking additional data allows you to take advantage of Rakuten's Dynamic Commissioning features. Dynamic Commissioning
								allows you to offer variable commissioning rates across a variety of elements, efficiently control costs, and manage your
								margins. By offering different commission structures in line with your objectives, publishers have the transparency and
								incentive to better support these goals, which builds stronger relationships and leads to lucrative results for both
								parties. The fields tracked with this additional script are: Product Category, Product Brand, Customer-facing Order ID,
								Consumer Status (New or Returning), and whether an item is on sale or not.
							</Text>
						</Grid>
					</Grid>
				</>
			);
		} else {
			dynamicElem = (
				<>
					<Grid className='mt-6 pb-6'>
						<GridCell key={1} width='1/12' className='text-center'>
							<div
								className='icon'
								onClick={() => {
									setState({ ...state, isDynamicExpanded: true });
								}}
							>
								<Icon type='chevron-outline-down' className='w-6 h-8 text-purple-600' />
							</div>
						</GridCell>
						<GridCell key={2} width='10/12' className='pt-1'>
							<Heading level={4}>What is the benefit of tracking additional data?</Heading>
						</GridCell>
					</Grid>
				</>
			);
		}
		return dynamicElem;
	};

	useEffect(() => {
		getAdditionalScriptStatus();
		getAdditionalScript();
		return () => {
			dispatch(AdditionalScriptAPI.clearState());
		};
	}, []);

	useEffect(() => {
		setState(script);
	}, [script]);

	if (state.redirect) {
		return <Redirect to='/home' />;
	}

	if (state.additionalScriptStatus === undefined) {
		return <Spinner />;
	}

	return (
		<>
			{getAlert()}
			<Grid>
				<Heading level={2}>
					We need to add a code snippet to your store so that you can track additional data to be used in commissioning and reporting.
					Please follow these steps:
				</Heading>
			</Grid>
			<Grid className='mt-8'>
				<Heading level={4}>STEP 1</Heading>
			</Grid>
			<Grid>
				<Text>
					{' '}
					Click this button to copy the code snippet
					<br />
					<CopyToClipboard text={state.codeSnippet}>
						<Button
							icon={state.isCopyClicked ? 'checkmark' : ''}
							isDisabled={isDisabled()}
							isSmall
							style={{ height: '1rem' }}
							onClick={() => {
								setState({ ...state, isCopyClicked: true });
							}}
						>
							{!state.isCopyClicked ? 'Copy code' : 'Code copied'}
						</Button>
					</CopyToClipboard>
				</Text>
			</Grid>
			<Grid className='mt-4'>
				<Heading level={4}>STEP 2</Heading>
			</Grid>
			<Grid>
				<Text>
					Now paste the code snippet into the 'Additional Scripts' box in your store's settings. It can go before or after any code there
					already.
					<br />
					{getLink()}
				</Text>
			</Grid>
			<Grid className='mt-4'>
				<Heading level={4}>STEP 3</Heading>
			</Grid>
			<Grid>
				<Text>Click save on the settings page, come back here and click Done.</Text>
			</Grid>
			<Grid className='mt-6 justify-end'>
				<TextLink to='/home'>
					<Button isTertiary>Cancel</Button>
				</TextLink>

				<Button
					isDisabled={!state.isCopyClicked || !state.isGotoAdminClicked}
					onClick={() => handleClick()}
					isLoading={state.isFormSubmitted}
				>
					<span className='buttonLink'>Done</span>
				</Button>
			</Grid>
			{renderCodeSnippetCode()}
			{renderDynamicCommision()}
		</>
	);
};

export default AdditionalScript;
