import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PlaceholderStart: {
    defaultMessage: 'Start Date',
    description: 'DatePicker start placeholder',
  },
  PlaceholderEnd: {
    defaultMessage: 'End Date',
    description: 'DatePicker end placeholder',
  },
  LastSevenDays: {
    defaultMessage: 'Last 7 days',
    description: 'DatePicker last seven days option',
  },
  LastThirtyDays: {
    defaultMessage: 'Last 30 days',
    description: 'DatePicker last thirty days option',
  },
  LastNinetyDays: {
    defaultMessage: 'Last 90 days',
    description: 'DatePicker last ninety days option',
  },
  YearToDate: {
    defaultMessage: 'Year to date',
    description: 'DatePicker year to date option',
  },
  CustomRange: {
    defaultMessage: 'Custom range',
    description: 'DatePicker custom range option',
  },
  chooseAvailableStartDate: {
    defaultMessage: 'Choose start date. {date}',
    description: 'DatePicker start choosing ariaLabel',
  },
  chooseAvailableEndDate: {
    defaultMessage: 'Choose end date. {date}',
    description: 'DatePicker end choosing ariaLabel',
  },
  focusStartDate: {
    defaultMessage: 'Select a date range',
    description: 'DatePicker icon ariaLabel',
  },
  clearDates: {
    defaultMessage: 'Clear dates',
    description: 'DatePicker clear dates ariaLabel',
  },
  dateIsSelectedAsStartDate: {
    defaultMessage: 'Selected as start date. {date}',
    description: 'DatePicker start chosen ariaLabel',
  },
  dateIsSelectedAsEndDate: {
    defaultMessage: 'Selected as end date. {date}',
    description: 'DatePicker end chosen ariaLabel',
  },
});

const phrases = intl => ({
  chooseAvailableStartDate: ({ date }) =>
    intl.formatMessage(messages.chooseAvailableStartDate, { date }),
  chooseAvailableEndDate: ({ date }) =>
    intl.formatMessage(messages.chooseAvailableEndDate, { date }),
  focusStartDate: intl.formatMessage(messages.focusStartDate),

  dateIsSelectedAsStartDate: ({ date }) =>
    intl.formatMessage(messages.dateIsSelectedAsStartDate, { date }),
  dateIsSelectedAsEndDate: ({ date }) =>
    intl.formatMessage(messages.dateIsSelectedAsEndDate, { date }),
  clearDates: intl.formatMessage(messages.clearDates),

  PlaceholderStart: intl.formatMessage(messages.PlaceholderStart),
  PlaceholderEnd: intl.formatMessage(messages.PlaceholderEnd),

  LastSevenDays: intl.formatMessage(messages.LastSevenDays),
  LastThirtyDays: intl.formatMessage(messages.LastThirtyDays),
  LastNinetyDays: intl.formatMessage(messages.LastNinetyDays),
  YearToDate: intl.formatMessage(messages.YearToDate),
  CustomRange: intl.formatMessage(messages.CustomRange),
});

export default phrases;
