import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shortid from 'shortid';
import { injectIntl, defineMessages } from 'react-intl';

import { typeOfInstance, getComponentName } from 'components/helpers';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';

import './RadioButtons.less';
import RadioButton from './RadioButton';
import AdvancedRadioButton from '../AdvancedRadioButton/AdvancedRadioButton';

class RadioButtons extends PureComponent {
  static propTypes = {
    /**
     * Child RadioButton nodes
     */
    children: PropTypes.oneOfType([
      typeOfInstance(RadioButton),
      typeOfInstance(AdvancedRadioButton),
    ]).isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Marks the RadioButtons as required, triggering HTML5 validation in modern browsers
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the RadioButtons are required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the RadioButtons are required
     */
    html5: PropTypes.bool,

    /**
     * Renders disabled RadioButtons
     */
    isDisabled: PropTypes.bool,

    /**
     * Renders inline RadioButtons
     */
    isInline: PropTypes.bool,

    /**
     * Label text for RadioButtons
     */
    label: PropTypes.node.isRequired,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * Radio group name, creates a group context
     */
    name: PropTypes.string.isRequired,

    /**
     * Selected value of the group
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * RadioButtons ID
     */
    id: PropTypes.string,

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Handler to call when the RadioButtons' state has changed
     *
     * @param {Object} args - {name, value, event}
     */
    onChange: PropTypes.func,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    id: '',
    className: '',
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    isInline: false,
    hasHiddenLabel: false,
    value: null,
    isValid: false,
    hasError: false,
    helperText: '',
    onChange: () => {},
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
  });

  constructor(props) {
    super(props);
    this.state = { value: props.value, childTypes: '', buttons: null };

    this.renderChildrenWithProps = this.renderChildrenWithProps.bind(this);
    this.setRadioButtonSelection = this.setRadioButtonSelection.bind(this);
    this.id = this.props.id || `RadioButtons-${shortid.generate()}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    return value !== state.value && value !== state.previousValue
      ? { value, previousValue: value }
      : { previousValue: value };
  }

  componentDidMount() {
    const buttons = this.renderChildrenWithProps();
    this.setState({ buttons });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.children !== this.props.children ||
      prevProps.value !== this.props.value ||
      prevState.value !== this.state.value
    ) {
      const buttons = this.renderChildrenWithProps();
      this.setState({ buttons });
    }
  }

  setRadioButtonSelection({ value, event }) {
    const { name, onChange } = this.props;

    this.setState({ value }, () => {
      onChange({ value, name, event });
    });
  }

  renderChildrenWithProps() {
    const {
      children,
      isRequired,
      html5,
      isDisabled,
      isInline,
      name,
    } = this.props;

    let childTypes = '';

    const mappedChildren = React.Children.map(children, child => {
      const childName = getComponentName(child);
      if (
        childName !== 'AdvancedRadioButton' &&
        childName !== 'RadioButton' &&
        childName !== 'NestedRadio' &&
        childName !== 'IconRadio'
      )
        return null;

      if (!childTypes) {
        childTypes = childName;
      } else if (childTypes !== childName) {
        console.warn(
          'Multiple radio children types detected, it is recommended to use one type of radio within RadioButtons.',
        );
      }

      const childDisabled = child.props.isDisabled;
      const childRequired = child.props.isRequired;
      const childHtml5 = child.props.html5;

      const radioButton = React.cloneElement(child, {
        name,
        isRequired: childRequired !== undefined ? childRequired : isRequired,
        html5: childHtml5 !== undefined ? childHtml5 : html5,
        isDisabled: childDisabled !== undefined ? childDisabled : isDisabled,
        isChecked: this.state.value === child.props.value,
        onChange: this.setRadioButtonSelection,
      });
      const classes = classnames('RadioButtons__wrapper', {
        'RadioButtons__wrapper--inline': isInline,
      });

      return <div className={classes}>{radioButton}</div>;
    });

    if (childTypes !== this.state.childTypes) {
      this.setState({ childTypes });
    }

    return mappedChildren;
  }

  render() {
    const {
      name,
      label,
      hasHiddenLabel,
      className,
      isValid,
      hasError,
      helperText,
      // The following props are only destructured so they don't end up in ...rest
      children,
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      isInline,
      onChange,
      id,
      value,
      intl,
      ...rest
    } = this.props;

    const { childTypes } = this.state;

    const wrapperClasses = classnames(
      className,
      'RadioButtons__wrapper',
      `RadioButtons__wrapper--${childTypes}`,
      {
        'RadioButtons__wrapper--error': hasError,
        'RadioButtons__wrapper--valid': isValid,
      },
    );

    const fieldsetClasses = classnames(className, 'RadioButtons__fieldset', {
      'RadioButtons__fieldset--error': hasError,
      'RadioButtons__fieldset--valid': isValid,
    });

    const labelClasses = classnames('RadioButtons__legend', {
      'RadioButtons__legend--hidden': hasHiddenLabel,
      'RadioButtons__legend--asterisk': hasAsterisk && isRequired,
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('RadioButtons__icon', {
      'RadioButtons__icon--error': hasError,
      'RadioButtons__icon--valid': isValid,
    });

    return (
      <div className={wrapperClasses}>
        <fieldset
          className={fieldsetClasses}
          id={this.id}
          name={name}
          {...rest}
        >
          <legend className={labelClasses}>{label}</legend>
          {this.state.buttons}
        </fieldset>
        <div
          className="RadioButtons__helperWrapper"
          aria-live="polite"
          role="status"
        >
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText hasError={hasError}>{helperText}</HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(RadioButtons);
