import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PaginationButton = ({ onClick, isActive, pageNumber }) => {
  const classNames = classnames('Pagination__number', {
    'Pagination__number--active': isActive,
  });
  const label = isActive ? `Page ${pageNumber}` : `Go to page ${pageNumber}`;

  return (
    <button
      className={classNames}
      type="button"
      aria-label={label}
      aria-current={isActive}
      onClick={event => onClick({ pageNumber, event })}
    >
      {pageNumber}
    </button>
  );
};

PaginationButton.propTypes = {
  /**
   * Handler that is called when the PaginationButton is clicked
   *
   * @param {Object} args - {pageNumber, event}
   */
  onClick: PropTypes.func,

  /**
   * sets the PaginationButton to the active state
   */
  isActive: PropTypes.bool,

  /**
   * Number to show in the content of the PaginationButton (and pass to onClick)
   */
  pageNumber: PropTypes.number.isRequired,
};

PaginationButton.defaultProps = {
  onClick: () => {},
  isActive: false,
};

export default PaginationButton;
