import React, { useEffect, useState } from 'react';
import { TextLink } from '@blueprints2/core';
import { withRouter } from 'react-router-dom';
import { SHOP } from '../../constants/constants';
import './PreInstall.css';
// @ts-ignore
import Logo from './ralogolarge.png';

const PreInstall = (props: any) => {
	const [installUrlState, setInstallUrlState] = useState({ url: '' });

	useEffect(() => {
		if (props.location && props.location.search) {
			const shopName = new URLSearchParams(props.location.search).get(SHOP);
			if (shopName && /[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com[\/]?/.test(shopName)) {
				setInstallUrlState({ url: `${process.env.REACT_APP_BASE_URL}/app?shop=${shopName}` });
			}
		}
	}, []);

	return (
		<div className='main'>
			<div className='logo-wrapper'>
				<img className='logo' alt='Rakuten Advertising' src={Logo} />
			</div>
			<div>
				<p>
					The Rakuten Advertising app is provided to you for the sole purpose of implementing a measurement tool in your Shopify shop in
					support of the advertising programs that you run through the Rakuten Advertising platform. Your use of the Rakuten Advertising app
					is subject to the General Terms and Conditions located at{' '}
					<a className='policy-url' href='https://rakutenadvertising.com/legal-notices/terms-and-conditions'>
						https://rakutenadvertising.com/legal-notices/terms-and-conditions
					</a>
					.
				</p>
				<p>
					Data collected by this measurement tool is subject to our Service Privacy Policy located at{' '}
					<a className='policy-url' href='https://rakutenadvertising.com/legal-notices/services-privacy-policy'>
						https://rakutenadvertising.com/legal-notices/services-privacy-policy
					</a>
				</p>
			</div>

			<TextLink
				className={installUrlState.url ? 'button-link' : 'button-link-disabled'}
				href={installUrlState.url}
				rel='noopener noreferrer'
			>
				Accept &amp; Install
			</TextLink>
		</div>
	);
};

export default withRouter(PreInstall);
