import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './GridCell.less';

const GridCell = ({
  children,
  gutter,
  width,
  md,
  lg,
  xl,
  className,
  ...rest
}) => {
  function collectClasses(sizes) {
    let shorthand = false;

    return Object.keys(sizes).reduce((classes, mod) => {
      const size = sizes[mod];
      if (!size) return classes;

      if (['auto', 'initial'].includes(size)) {
        classes.push(`${mod}flex-${size}`);
        shorthand = true;

        return classes;
      }

      classes.push(`${mod}w-${size}`);

      if (shorthand) {
        classes.push(`${mod}flex-none`);
        shorthand = false;
      }

      return classes;
    }, []);
  }

  const classes = classnames(
    'Grid__GridCell',
    className,
    collectClasses({
      '': width,
      'md:': md,
      'lg:': lg,
      'xl:': xl,
    }),
    {
      [`px-${gutter}`]: gutter,
    },
  );

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};

/**
 * Props documentation
 */
const widths = [
  '1/2',
  '1/3',
  '2/3',
  '1/4',
  '3/4',
  '1/5',
  '2/5',
  '3/5',
  '4/5',
  '1/6',
  '5/6',
  'initial',
  'auto',
  'full',
];

GridCell.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * Sets a gutter padding class (passed down by parent Grid)
   *
   * @ignore
   */
  gutter: PropTypes.string,

  /**
   * Defines a base width
   */
  width: PropTypes.oneOf(widths).isRequired,

  /**
   * Defines a width applied at medium screen sizes and above
   */
  md: PropTypes.oneOf(widths),

  /**
   * Defines a width applied at large screen sizes and above
   */
  lg: PropTypes.oneOf(widths),

  /**
   * Defines a width applied at extra large screen sizes
   */
  xl: PropTypes.oneOf(widths),

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

GridCell.defaultProps = {
  children: null,
  gutter: null,
  md: null,
  lg: null,
  xl: null,
  className: '',
};

export default GridCell;
