import React from 'react';
import { Footer } from '@blueprints2/core';

const AppFooter = () => {
	const footerStyle = {
		backgroundImage: 'radial-gradient(circle at -8% 77%, #4e23ab, #8a239c 49%, #c64876 100%)',
		height: 3,
	};
	const linkStyle = {
		fontSize: 12,
		color: '#7141d8',
		fontWeight: 500,
	};
	return (
		<div style={{ marginTop: '5rem' }}>
			<div style={footerStyle}></div>
			<Footer>
				<ul>
					<li>
						<a style={linkStyle} href='https://rakutenadvertising.com/legal-notices/terms-and-conditions/' target='_blank' rel='noreferrer'>
							Affiliate Terms
						</a>
					</li>
					<li>
						<a
							style={linkStyle}
							href='https://rakutenadvertising.com/legal-notices/services-privacy-policy/'
							target='_blank'
							rel='noreferrer'
						>
							Service Privacy Policy
						</a>
					</li>
				</ul>
			</Footer>
		</div>
	);
};

export default AppFooter;
