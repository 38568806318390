import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './List.less';

const List = props => {
  const { children, isOrdered, className } = props;

  const classes = classnames('List', className);

  if (isOrdered) {
    return <ol className={classes}>{children}</ol>;
  }

  return <ul className={classes}>{children}</ul>;
};

List.propTypes = {
  /**
   * Child nodes (e.g. ListItem)
   */
  children: PropTypes.node.isRequired,

  /**
   * Specifies whether list is ordered or not
   */
  isOrdered: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

List.defaultProps = {
  isOrdered: false,
  className: '',
};

export default List;
