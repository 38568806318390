import React from 'react';
import { CardFooter, CardSection } from 'components/Card';
import PropTypes from 'prop-types';

const AppSwitcherCardFooter = React.memo(({ footer, closeWindow }) => (
  <CardFooter>
    <CardSection isFullWidth />
    {footer.map((item, index) => (
      <div
        aria-hidden="true"
        className="AppSwitcher__card_footerWrapper"
        key={item.key ? item.key : `AppSwitcher__footerRaw_${index}`}
        onClick={() => closeWindow(item.closeOnClick)}
      >
        {item.node}
      </div>
    ))}
  </CardFooter>
));

AppSwitcherCardFooter.propTypes = {
  closeWindow: PropTypes.func,
  footer: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      node: PropTypes.node.isRequired,
    }),
  ),
};

AppSwitcherCardFooter.defaultProps = {
  footer: [],
  closeWindow: PropTypes.func,
};

export default AppSwitcherCardFooter;
