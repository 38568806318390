import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CardSection.less';

const CardSection = props => {
  const { isFullWidth } = props;

  const classes = classnames('CardSection', {
    'CardSection--fullWidth': isFullWidth,
  });

  return <div className={classes} />;
};

CardSection.propTypes = {
  /**
   * Renders a full width section divider
   */
  isFullWidth: PropTypes.bool,
};

CardSection.defaultProps = {
  isFullWidth: false,
};

export default CardSection;
