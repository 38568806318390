import { defineMessages } from 'react-intl';

const messages = defineMessages({
  keyboardShortcuts: {
    defaultMessage: 'Keyboard shortcuts',
    description: 'DatePicker keyboard shortcuts title',
  },
  showKeyboardShortcutsPanel: {
    defaultMessage: 'Open the keyboard shortcuts panel.',
    description: 'DatePicker keyboard shortcuts open ariaLabel',
  },
  hideKeyboardShortcutsPanel: {
    defaultMessage: 'Close the keyboard shortcuts panel.',
    description: 'DatePicker keyboard shortcuts close ariaLabel',
  },
  openThisPanel: {
    defaultMessage: 'Open this panel.',
    description: 'DatePicker keyboard shortcuts close ariaLabel',
  },
  enterKey: {
    defaultMessage: 'Enter key',
    description: '"enter" key',
  },
  leftArrowRightArrow: {
    defaultMessage: 'Right and left arrow keys',
    description: '"right" and "left" arrow keys',
  },
  upArrowDownArrow: {
    defaultMessage: 'Up and down arrow keys',
    description: '"up" and "down" arrow keys',
  },
  pageUpPageDown: {
    defaultMessage: 'Page up and page down keys',
    description: '"page up" and "page down" arrow keys',
  },
  homeEnd: {
    defaultMessage: 'Home and end keys',
    description: '"home" and "end" keys',
  },
  escape: {
    defaultMessage: 'Escape key',
    description: '"escape" key',
  },
  questionMark: {
    defaultMessage: 'Question mark',
    description: '"question mark"',
  },
  selectFocusedDate: {
    defaultMessage: 'Select the date in focus',
    description: 'DatePicker date focus ariaLabel',
  },
  moveFocusByOneDay: {
    defaultMessage: 'Move backward (left) and forward (right) by one day.',
    description: 'DatePicker move day instructions',
  },
  moveFocusByOneWeek: {
    defaultMessage: 'Move backward (up) and forward (down) by one week.',
    description: 'DatePicker move week instructions',
  },
  moveFocusByOneMonth: {
    defaultMessage: 'Switch months.',
    description: 'DatePicker move month instructions',
  },
  moveFocustoStartAndEndOfWeek: {
    defaultMessage: 'Go to the first or last day of a week.',
    description: 'DatePicker week first/last instructions',
  },
  returnFocusToInput: {
    defaultMessage: 'Return to the date input field.',
    description: 'DatePicker return to date ariaLabel',
  },
});

const phrases = intl => ({
  keyboardShortcuts: intl.formatMessage(messages.keyboardShortcuts),
  showKeyboardShortcutsPanel: intl.formatMessage(
    messages.showKeyboardShortcutsPanel,
  ),
  hideKeyboardShortcutsPanel: intl.formatMessage(
    messages.hideKeyboardShortcutsPanel,
  ),
  openThisPanel: intl.formatMessage(messages.openThisPanel),
  enterKey: intl.formatMessage(messages.enterKey),
  leftArrowRightArrow: intl.formatMessage(messages.leftArrowRightArrow),
  upArrowDownArrow: intl.formatMessage(messages.upArrowDownArrow),
  pageUpPageDown: intl.formatMessage(messages.pageUpPageDown),
  homeEnd: intl.formatMessage(messages.homeEnd),
  escape: intl.formatMessage(messages.escape),
  questionMark: intl.formatMessage(messages.questionMark),
  selectFocusedDate: intl.formatMessage(messages.selectFocusedDate),
  moveFocusByOneDay: intl.formatMessage(messages.moveFocusByOneDay),
  moveFocusByOneWeek: intl.formatMessage(messages.moveFocusByOneWeek),
  moveFocusByOneMonth: intl.formatMessage(messages.moveFocusByOneMonth),
  moveFocustoStartAndEndOfWeek: intl.formatMessage(
    messages.moveFocustoStartAndEndOfWeek,
  ),
  returnFocusToInput: intl.formatMessage(messages.returnFocusToInput),
});

export default phrases;
