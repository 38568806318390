import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';

import { deprecatedProp } from 'components/helpers';
import Icon from 'components/Icon';
import { Button } from 'components/Button';
import Text from 'components/Text';
import Heading from 'components/Heading';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';

import './ConfirmationModal.less';

class ConfirmationModal extends PureComponent {
  static propTypes = {
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Sets the isVisible state of the Modal from parent
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * The function that is triggered on click of 'Confirm'.
     * Returning a promise with 'Resolve' will trigger the successful state, and a 'Reject' will trigger the error state
     */
    callback: PropTypes.func.isRequired,

    /**
     * Function to be triggered when the modal is closed
     *
     * @param {boolean} modalConfirmed - returns whether the modal either confirmed successfully or failed/cancelled
     */
    onClose: PropTypes.func,

    /**
     * @deprecated in `4.0.0`, use `onClose` instead
     */
    modalClosed: (props, deprecatedName, componentName) =>
      deprecatedProp({
        props,
        deprecatedName,
        newName: 'onClose',
        isRequired: true,
        versionNumber: 'v4.0.0',
        componentName,
        propType: 'function',
      }),

    /**
     * Icon displayed in default state
     */
    hasDefaultIcon: PropTypes.bool,

    /**
     * The header text/node for the default state
     */
    header: PropTypes.node.isRequired,

    /**
     * The body text/node for the default state
     */
    body: PropTypes.node,

    /**
     * The confirmation button text in default state
     */
    confirmButtonText: PropTypes.node,

    /**
     * Is confirmation button disabled
     */
    isConfirmEnabled: PropTypes.bool,

    /**
     * The header text/node for the success state
     */
    successHeader: PropTypes.node,

    /**
     * The body text/node for the success state
     */
    successBody: PropTypes.node,

    /**
     * The header text/node for the error state
     */
    errorHeader: PropTypes.node,

    /**
     * The body text/node for the error state
     */
    errorBody: PropTypes.node,

    /**
     * Display an option to retry the `callback` in the error state
     */
    canTryAgain: PropTypes.bool,

    /**
     * The time before the modal closes itself on Success state
     */
    secondsBeforeClose: PropTypes.number,

    /**
     * Additional content to the footer, e.g: checkbox or additional button
     */
    footerNode: PropTypes.node,

    /**
     * When true - show the text "Close" near the close icon
     */
    closeWithText: PropTypes.bool,

    /**
     * When true - show footer with full width (when need to show shadow)
     */
    footerFullWidth: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    hasDefaultIcon: true,
    body: '',
    confirmButtonText: '',
    isConfirmEnabled: true,
    successHeader: '',
    successBody: '',
    errorHeader: '',
    errorBody: '',
    canTryAgain: true,
    modalClosed: undefined,
    onClose: undefined,
    secondsBeforeClose: 1.4,
    footerNode: null,
    closeWithText: false,
    footerFullWidth: false,
  };

  constructor(props) {
    super(props);

    this.initialState = {
      isFetching: false,
      isSuccessful: false,
      hasError: false,
      clientWidth: null,
      hasBodyScroll: false,
    };

    this.state = this.initialState;

    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.modalBodyRef = React.createRef();
    this.modalRef = React.createRef();
  }

  componentDidUpdate() {
    if (
      this.modalBodyRef &&
      this.modalBodyRef.current &&
      this.modalBodyRef.current.scrollWrapper &&
      this.modalBodyRef.current.scrollWrapper.current
    ) {
      if (
        this.modalBodyRef.current.scrollWrapper.current.scrollHeight &&
        this.modalBodyRef.current.scrollWrapper.current.clientHeight &&
        this.modalBodyRef.current.scrollWrapper.current.scrollHeight >
          this.modalBodyRef.current.scrollWrapper.current.clientHeight
      ) {
        this.setState({ hasBodyScroll: true });
      }

      if (
        this.modalBodyRef.current.scrollWrapper.current.offsetParent &&
        this.modalBodyRef.current.scrollWrapper.current.offsetParent &&
        this.modalBodyRef.current.scrollWrapper.current.offsetParent &&
        this.modalBodyRef.current.scrollWrapper.current.offsetParent.clientWidth
      ) {
        this.setState({
          clientWidth: this.modalBodyRef.current.scrollWrapper.current
            .offsetParent.clientWidth,
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClose() {
    const onClose =
      this.props.modalClosed === undefined
        ? this.props.onClose
        : this.props.modalClosed;

    onClose(this.state.isSuccessful);
    clearTimeout(this.timeout);
    this.reset();
  }

  reset() {
    this.setState(this.initialState);
  }

  async handleConfirm() {
    this.setState({ isFetching: true });

    try {
      await this.props.callback();
      this.setState({ isSuccessful: true, isFetching: false });

      this.timeout = setTimeout(() => {
        this.handleClose();
      }, this.props.secondsBeforeClose * 1000);
    } catch (error) {
      this.setState({ hasError: true, isFetching: false });
      throw error;
    }
  }

  renderHeader(header) {
    if (!header) return '';

    return typeof header === 'string' ? (
      <Heading level={2}>{header}</Heading>
    ) : (
      header
    );
  }

  renderBody(body) {
    if (!body) return '';

    return typeof body === 'string' ? <Text>{body}</Text> : body;
  }

  renderDefaultState() {
    const {
      hasDefaultIcon,
      header,
      body,
      footerFullWidth,
      footerNode,
    } = this.props;

    return (
      <Fragment>
        <ModalHeader>
          {hasDefaultIcon && (
            <div className="ConfirmationModal__Icon--wrapper">
              <div className="ConfirmationModal__Icon--background" />
              <Icon
                className="ConfirmationModal__Icon ConfirmationModal__Icon--default"
                type="exclamation-solid"
              />
            </div>
          )}
          {this.renderHeader(header)}
        </ModalHeader>

        <ModalBody ref={this.modalBodyRef} className="ConfirmationModal__body">
          {this.renderBody(body)}
        </ModalBody>

        <ModalFooter
          className={classnames('ConfirmationModal__footer', {
            'justify-between': footerNode,
            'ConfirmationModal__Scroll--shadow -ml-5':
              this.state.hasBodyScroll && footerFullWidth,
            'ConfirmationModal__footer--paddings': footerFullWidth,
          })}
          style={
            footerFullWidth
              ? {
                  width: `${this.state.clientWidth / 16}rem`,
                }
              : {}
          }
        >
          {footerNode && footerNode}
          <div>
            {this.renderCancelButton()}
            {this.renderConfirmButton()}
          </div>
        </ModalFooter>
      </Fragment>
    );
  }

  renderSuccessState() {
    const { successHeader, successBody } = this.props;

    const header = successHeader || (
      <Heading level={2}>
        <FormattedMessage
          defaultMessage="Success"
          description="ConfirmationModal success message"
        />
      </Heading>
    );

    return (
      <Fragment>
        <ModalHeader>
          <Icon
            className="ConfirmationModal__Icon ConfirmationModal__Icon--success"
            type="checkmark"
          />
          {this.renderHeader(header)}
        </ModalHeader>

        <ModalBody ref={this.modalBodyRef} className="ConfirmationModal__body">
          {this.renderBody(successBody)}
        </ModalBody>

        <ModalFooter />
      </Fragment>
    );
  }

  renderErrorState() {
    const {
      errorHeader,
      errorBody,
      canTryAgain,
      footerFullWidth,
      footerNode,
    } = this.props;

    const header = errorHeader || (
      <Heading level={2}>
        <FormattedMessage
          defaultMessage="Error"
          description="ConfirmationModal error message title"
        />
      </Heading>
    );

    const fallbackMessage = (
      <Text>
        {canTryAgain && (
          <FormattedMessage
            defaultMessage="Please try again."
            description="ConfirmationModal error message body, try again"
          />
        )}
        {canTryAgain && ' '}
        <FormattedMessage
          defaultMessage="Unfortunately something has gone wrong. If this problem persists please contact our support team."
          description="ConfirmationModal error message body, not try again"
        />
      </Text>
    );

    const body = errorBody || fallbackMessage;

    const tryAgainButtons = (
      <Fragment>
        {this.renderCancelButton()}
        {this.renderTryAgainButton()}
      </Fragment>
    );
    const buttons = canTryAgain ? tryAgainButtons : this.renderGiveUpButton();
    return (
      <Fragment>
        <ModalHeader>
          <Icon
            className="ConfirmationModal__Icon ConfirmationModal__Icon--error"
            type="close"
          />
          {this.renderHeader(header)}
        </ModalHeader>

        <ModalBody ref={this.modalBodyRef} className="ConfirmationModal__body">
          {this.renderBody(body)}
        </ModalBody>

        <ModalFooter
          className={classnames('ConfirmationModal__footer', {
            'justify-between': footerNode,
            'ConfirmationModal__Scroll--shadow': this.state.hasBodyScroll,
            'ConfirmationModal__footer--paddings': footerFullWidth,
          })}
        >
          {buttons}
        </ModalFooter>
      </Fragment>
    );
  }

  renderCancelButton() {
    const { isFetching } = this.state;
    return (
      <Button isTertiary onClick={this.handleClose} isDisabled={isFetching}>
        <FormattedMessage defaultMessage="Cancel" description="cancel" />
      </Button>
    );
  }

  renderConfirmButton() {
    const { isFetching } = this.state;

    const text = this.props.confirmButtonText || (
      <FormattedMessage defaultMessage="Confirm" description="confirm" />
    );

    return (
      <Button
        isPrimary
        onClick={this.handleConfirm}
        isLoading={isFetching}
        isDisabled={!this.props.isConfirmEnabled}
      >
        {text}
      </Button>
    );
  }

  renderTryAgainButton() {
    const { isFetching } = this.state;
    return (
      <Button isPrimary onClick={this.handleConfirm} isLoading={isFetching}>
        <FormattedMessage defaultMessage="Try Again" description="try again" />
      </Button>
    );
  }

  renderGiveUpButton() {
    return (
      <Button isPrimary onClick={this.handleClose}>
        <FormattedMessage defaultMessage="Okay" description="okay" />
      </Button>
    );
  }

  render() {
    const { isVisible, className, closeWithText, footerFullWidth } = this.props;
    const { isFetching, isSuccessful, hasError, hasBodyScroll } = this.state;

    let modalContent = this.renderDefaultState();
    if (hasError) modalContent = this.renderErrorState();
    else if (isSuccessful) modalContent = this.renderSuccessState();

    const confirmationModalClasses = classnames('ConfirmationModal', className);
    return (
      <Modal
        className={confirmationModalClasses}
        isVisible={isVisible}
        onClose={this.handleClose}
        isDismissible={!isFetching}
        closeWithText={closeWithText}
        modalContentClasses={classnames({
          'ConfirmationModal--footerFullWidth': footerFullWidth,
          'ConfirmationModal--hasBodyScroll': hasBodyScroll,
        })}
      >
        {modalContent}
      </Modal>
    );
  }
}

export default injectIntl(ConfirmationModal);
