import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TooltipSeparator.less';

const TooltipSeparator = props => {
  const { className, ...rest } = props;
  const classes = classnames('TooltipSeparator', className);

  return <hr className={classes} {...rest} />;
};

TooltipSeparator.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

TooltipSeparator.defaultProps = {
  className: '',
};

export default TooltipSeparator;
