import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';

import 'react-dates/initialize';
import { DateRangePicker as ImportedDateRangePicker } from 'react-dates';
import moment from 'moment';

import InputLabel from 'components/InputLabel';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';
import DatePickerNav from './DatePickerNav';

import setStartDateWidth from './setFluidWidth';
import isMobile from './isMobile';
import phrases from './i18n/phrases';
import './DatePicker.less';

class DateRangePicker extends PureComponent {
  static propTypes = {
    /**
     * An object containing the `startDate` and `endDate` values.
     *
     * `startDate`/`endDate` accept either a JS Date object or a Moment object.
     */
    value: PropTypes.shape({
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
      ]),
    }),

    /**
     * Allows setting your own custom date range presets.
     *
     * See the "Custom date range presets" example in the documentation below.
     */
    customPresets: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.node.isRequired,
        startDate: PropTypes.shape({ _isAMomentObject: PropTypes.bool })
          .isRequired,
        endDate: PropTypes.shape({ _isAMomentObject: PropTypes.bool })
          .isRequired,
      }),
    ),

    /**
     * Disables the "Custom range" preset option, which is displayed by default.
     */
    showCustomRange: PropTypes.bool,

    /**
     * Hide keyboard shortcuts panel
     */
    hideKeyboardShortcutsPanel: PropTypes.bool,

    /**
     * DateRangePicker name/ID. Must be unique per DateRangePicker.
     */
    name: PropTypes.string.isRequired,

    /**
     * Marks the DateRangePicker as required
     */
    isRequired: PropTypes.bool,

    /**
     * Adds asterisk next to the label when the DateRangePicker is required
     */
    hasAsterisk: PropTypes.bool,

    /**
     * Controls whether to trigger HTML5 validation when the DateRangePicker is required
     */
    html5: PropTypes.bool,

    /**
     * Disables the DateRangePicker
     */
    isDisabled: PropTypes.bool,

    /**
     * Renders a smaller DateRangePicker
     */
    isSmall: PropTypes.bool,

    /**
     * Renders a larger DateRangePicker
     */
    isLarge: PropTypes.bool,

    /**
     * Visually hides the label, while remaining accessible to screen readers
     */
    hasHiddenLabel: PropTypes.bool,

    /**
     * DateRangePicker label
     */
    label: PropTypes.node.isRequired,

    /**
     * Placeholder text. Defaults to "Start Date" and "End Date" (with
     * translations for all Blueprints-supported localizations) if not specified
     */
    placeholder: PropTypes.shape({
      startDate: PropTypes.node,
      endDate: PropTypes.node,
    }),

    /**
     * Helper text that can be used to provide context or
     * show dynamic validation error or success messages
     */
    helperText: PropTypes.node,

    /**
     * Renders validated state
     */
    isValid: PropTypes.bool,

    /**
     * Renders error state
     */
    hasError: PropTypes.bool,

    /**
     * Callback to fire when the DateRangePicker's value changes
     *
     * @param {object} args - {name, value}
     * @param {string} args.name - The name given to the DateRangePicker
     * @param {object} args.value - {startDate, endDate}
     * @param {Date} args.value.startDate - A JS [Date object](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date). Returns null on invalid or empty dates.
     * @param {Date} args.value.endDate - See above
     */
    onChange: PropTypes.func,

    /**
     * Optionally restrict user-selectable dates. This expects a function that evaluates to either true or
     * false depending on the day, typically using Moment.js. See the "Advanced react-dates" section
     * below for an example.
     *
     * By default, all dates are selectable in DateRangePicker.
     */
    isOutsideRange: PropTypes.func,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    value: {},
    customPresets: undefined,
    hideKeyboardShortcutsPanel: false,
    showCustomRange: true,
    isOutsideRange: () => false, // Allows all dates / dates in the past to be selected
    isRequired: false,
    hasAsterisk: true,
    html5: true,
    isDisabled: false,
    isSmall: false,
    isLarge: false,
    hasHiddenLabel: false,
    placeholder: {},
    isValid: false,
    hasError: false,
    helperText: '',
    className: '',
    onChange: () => {},
  };

  messages = defineMessages({
    validated: {
      defaultMessage: 'Validated',
      description: 'validated',
    },
    error: {
      defaultMessage: 'Error',
      description: 'error',
    },
  });

  constructor(props) {
    super(props);
    this.state = { activePreset: 'customRange' };

    moment.locale(props.intl.locale);
    this.dateFormat = 'll';
    this.phrases = phrases(props.intl);

    // Developer-input moment objs need to be re-localized -
    // otherwise they'll inherit from older global settings
    this.formatDateProps = value => {
      if (!value) return null;

      const date = moment(value);
      date.format(this.dateFormat);
      date.locale(props.intl.locale);

      return date;
    };

    if (props.value.startDate)
      this.state.startDate = this.formatDateProps(props.value.startDate);
    if (props.value.endDate)
      this.state.endDate = this.formatDateProps(props.value.endDate);

    const today = moment();
    this.defaultPresets = [
      {
        key: 'last7Days',
        text: this.phrases.LastSevenDays,
        startDate: moment(today).subtract(1, 'week'),
        endDate: today,
      },
      {
        key: 'last30Days',
        text: this.phrases.LastThirtyDays,
        startDate: moment(today).subtract(1, 'month'),
        endDate: today,
      },
      {
        key: 'last90Days',
        text: this.phrases.LastNinetyDays,
        startDate: moment(today).subtract(90, 'days'),
        endDate: today,
      },
      {
        key: 'yearToDate',
        text: this.phrases.YearToDate,
        startDate: moment(today).startOf('year'),
        endDate: today,
      },
    ];

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderCustomNav = this.renderCustomNav.bind(this);
    this.renderPresetsPanel = this.renderPresetsPanel.bind(this);
  }

  componentDidMount() {
    setStartDateWidth(`${this.props.name}-startDate`);
    this.setPresets();
  }

  componentDidUpdate(prevProps, prevState) {
    const { value, name, customPresets, showCustomRange } = this.props;
    if (prevProps.value.startDate !== value.startDate) {
      this.setState({ startDate: this.formatDateProps(value.startDate) });
    }
    if (prevProps.value.endDate !== value.endDate) {
      this.setState({ endDate: this.formatDateProps(value.endDate) });
    }

    if (
      prevProps.customPresets !== customPresets ||
      prevProps.showCustomRange !== showCustomRange
    ) {
      this.setPresets();
    }

    if (prevState.startDate !== this.state.startDate) {
      setStartDateWidth(`${name}-startDate`);
    }
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  onDatesChange({ startDate, endDate, activePreset = 'customRange' }) {
    const { name, onChange } = this.props;
    const startDateValue = startDate ? startDate._d : null; // eslint-disable-line no-underscore-dangle
    const endDateValue = endDate ? endDate._d : null; // eslint-disable-line no-underscore-dangle
    const value = { startDate: startDateValue, endDate: endDateValue };

    onChange({ name, value });
    this.setState({ startDate, endDate, activePreset });

    if (!startDate) setStartDateWidth(`${name}-startDate`);
  }

  setPresets() {
    const { customPresets, showCustomRange } = this.props;
    let presets = customPresets || this.defaultPresets;

    if (showCustomRange && presets.length) {
      const customRange = {
        key: 'customRange',
        text: this.phrases.CustomRange,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };

      presets = [...presets, customRange];
    }

    this.setState({ presets });
  }

  selectPreset({ key, startDate, endDate }) {
    const leaveCalendarOpen = key === 'customRange' ? 'startDate' : undefined;

    this.onDatesChange({ startDate, endDate, activePreset: key });
    this.setState({ focusedInput: leaveCalendarOpen });
  }

  renderPresetsPanel() {
    const { activePreset, presets } = this.state;
    // Allow devs to hide preset panel by passing an empty arrray
    if (!presets.length) return null;

    return (
      <div className="PresetDatesPanel">
        {presets.map(preset => (
          <button
            key={preset.key}
            type="button"
            className={classnames('PresetDatesPanel__preset', {
              'PresetDatesPanel__preset--active': preset.key === activePreset,
              'PresetDatesPanel__preset--custom': preset.key === 'customRange',
            })}
            onClick={() => this.selectPreset(preset)}
          >
            {preset.text}
          </button>
        ))}
      </div>
    );
  }

  renderCustomNav({ month, onMonthSelect, onYearSelect }) {
    return (
      <DatePickerNav
        month={month}
        onMonthSelect={onMonthSelect}
        onYearSelect={onYearSelect}
      />
    );
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    const {
      isRequired,
      hasAsterisk,
      html5,
      isDisabled,
      isSmall,
      isLarge,
      className,
      name,
      label,
      placeholder,
      isValid,
      hasError,
      helperText,
      hasHiddenLabel,
      isOutsideRange,
      // The following props are only destructured so they don't end up in ...rest
      showCustomRange,
      customPresets,
      onChange,
      value,
      intl,
      hideKeyboardShortcutsPanel,
      ...rest
    } = this.props;

    const classes = classnames('DatePicker DatePicker--range', className, {
      'DatePicker--small': isSmall,
      'DatePicker--large': isLarge,
      'DatePicker--focused': focusedInput,
      'DatePicker--error': hasError,
      'DatePicker--valid': isValid,
    });

    const hasIcon = hasError || isValid;
    const iconTitle = hasError ? this.messages.error : this.messages.validated;
    const iconType = hasError ? 'exclamation-solid' : 'checkmark-solid';
    const iconClasses = classnames('DatePicker__icon', {
      'DatePicker__icon--error': hasError,
      'DatePicker__icon--valid': isValid,
    });

    return (
      <div className={classes}>
        <InputLabel
          htmlFor={`${name}-startDate`}
          isHidden={hasHiddenLabel}
          hasAsterisk={hasAsterisk && isRequired}
        >
          {label}
        </InputLabel>
        <ImportedDateRangePicker
          {...rest}
          // Required props
          startDate={startDate}
          startDateId={`${name}-startDate`}
          endDate={endDate}
          endDateId={`${name}-endDate`}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          // Presentation
          block
          withFullScreenPortal={isMobile}
          numberOfMonths={1}
          daySize={32}
          showDefaultInputIcon
          // Custom elements/navigation
          customArrowIcon={
            <span className="DatePicker--range__separator">-</span>
          }
          renderMonthElement={this.renderCustomNav}
          calendarInfoPosition={isMobile ? 'bottom' : 'before'}
          renderCalendarInfo={this.renderPresetsPanel}
          hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel || isMobile}
          // i18n
          displayFormat={this.dateFormat}
          phrases={this.phrases}
          startDatePlaceholderText={
            placeholder.startDate || this.phrases.PlaceholderStart
          }
          endDatePlaceholderText={
            placeholder.endDate || this.phrases.PlaceholderEnd
          }
          // Behavior
          required={isRequired && html5}
          disabled={isDisabled}
          isOutsideRange={isOutsideRange}
        />
        <div
          className="DatePicker__helperWrapper"
          aria-live="polite"
          role="status"
        >
          {hasIcon && (
            <Icon
              title={intl.formatMessage(iconTitle)}
              className={iconClasses}
              type={iconType}
            />
          )}
          {helperText && (
            <HelperText isLarge={isLarge} hasError={hasError}>
              {helperText}
            </HelperText>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(DateRangePicker);
