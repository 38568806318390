import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { requireEitherProp } from 'components/helpers';
import TextLink from 'components/TextLink';

import './Footer.less';

const FooterLink = ({ children, href, to, newWindow, className, ...rest }) => {
  const LinkComponent = to ? Link : TextLink;
  const linkProps = to ? { to } : { href, newWindow };

  return (
    <li className="Footer__link">
      <LinkComponent {...linkProps} className={className} {...rest}>
        {children}
      </LinkComponent>
    </li>
  );
};

FooterLink.propTypes = {
  /**
   * Link text
   */
  children: PropTypes.string.isRequired,

  /**
   * React Router link location
   *
   * - Either **href** or **to** is required
   *
   * @see https://reacttraining.com/react-router/web/api/Link
   */
  to: requireEitherProp('to', 'href'),

  /**
   * Link URL
   *
   * - Either **href** or **to** is required
   */
  href: requireEitherProp('to', 'href'),

  /**
   * Opens the link in a new window and shows a new window icon
   */
  newWindow: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

FooterLink.defaultProps = {
  href: '',
  to: '',
  newWindow: false,
  className: '',
};

export default FooterLink;
