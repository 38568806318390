import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getComponentName, inputTypes } from 'components/helpers';

class FilterFormSearch extends PureComponent {
  /**
   * Passed internally from parent FilterForm component
   */
  static propTypes = {
    children: PropTypes.node.isRequired,
    searchTerm: PropTypes.string.isRequired,
  };

  findChildrenFromSearch(children) {
    const { searchTerm } = this.props;
    const { allInputs } = inputTypes;

    return React.Children.map(children, child => {
      const componentType = getComponentName(child);

      // Search for input elements nested within child components/wrappers
      if (!allInputs.includes(componentType)) {
        return this.searchThroughGrandchildren(child, componentType);
      }

      // Handle the searching/filtering logic for whether an input should be shown
      return this.searchThroughInputs(searchTerm, child, componentType);
    });
  }

  searchThroughGrandchildren(child, componentType) {
    let grandChildren = [];
    const props = {};

    // Recurse through grandchildren to find matching nested inputs
    if (child && child.props && child.props.children) {
      grandChildren = this.findChildrenFromSearch(child.props.children);
      grandChildren = grandChildren.filter(grandchild => grandchild !== null);

      props.children = grandChildren.length ? grandChildren : null;
    }

    // Only render if this parent element contains a match
    if (props.children) {
      // Auto expand accordions with matches
      if (componentType === 'Accordion') props.initiallyExpanded = true;

      return React.cloneElement(child, props);
    }
    return null;
  }

  searchThroughInputs(searchTerm, child, componentType) {
    const search = searchTerm.toLowerCase();
    let isLabelMatch = false;
    let isOptionMatch = false;
    let grandchildMatch = false;

    // Searching/matching by label should be enough for most inputs
    const { label } = child.props;
    isLabelMatch = label && label.toLowerCase().includes(search);

    // RadioButtons should also check through child RadioButton labels
    if (componentType === 'RadioButtons') {
      const radioChildren = this.findChildrenFromSearch(child.props.children);
      if (radioChildren.length) isOptionMatch = true;
    }

    // Select should also check through child option text
    if (componentType === 'Select') {
      const options = React.Children.map(child.props.children, option => {
        if (option.type !== 'option') return null;

        const optionText = option.props.children;
        if (typeof optionText !== 'string') return null;

        return optionText.toLowerCase().includes(search);
      }).filter(match => !!match);

      if (options.length) isOptionMatch = true;
    }

    // CheckboxGroup should show the entire group if the group label is a match,
    // but if searching through children, it should NOT show the entire group - only individual Checkbox matches
    if (componentType === 'CheckboxGroup') {
      grandchildMatch = this.searchThroughGrandchildren(child, componentType);
    }

    if (isLabelMatch || isOptionMatch) {
      return React.cloneElement(child);
    }
    if (grandchildMatch) {
      return grandchildMatch;
    }
    return null;
  }

  render() {
    const { searchTerm, children } = this.props;
    if (!searchTerm) return children;

    const foundChildren = this.findChildrenFromSearch(children);
    const noChildren = (
      <div className="FilterPopover__noResults">No filters found.</div>
    );

    return foundChildren.length ? foundChildren : noChildren;
  }
}

export default FilterFormSearch;
