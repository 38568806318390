import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from 'components/Text';

import './FooterLinksGroup.less';

const FooterLinksGroup = ({ children, linksGroupName, className }) => {
  const classes = classnames('FooterLinks__group', className);

  return (
    <div className={classes}>
      <Text className="FooterLinks__title">{linksGroupName}</Text>
      <ul className="Footer__links">{children}</ul>
    </div>
  );
};

FooterLinksGroup.propTypes = {
  /**
   * Child nodes - e.g. FooterLink
   */
  children: PropTypes.node,

  /**
   * Customize the default group name
   */
  linksGroupName: PropTypes.string,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

FooterLinksGroup.defaultProps = {
  linksGroupName: '',
  className: '',
  children: null,
};

export default FooterLinksGroup;
