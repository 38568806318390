import './ListCard.less';
import React from 'react';
import Image from 'components/Image';
import Heading from 'components/Heading';
import ThreeDotMenu from 'components/ThreeDotMenu';
import { Button } from 'components/Button';
import classnames from 'classnames';
import Truncate from 'components/Truncate';
import PropTypes from 'prop-types';
import { isMobile } from '../helpers';

const ListCard = ({
  className,
  imgUrl,
  imgFallback,
  title,
  titleOnClick,
  subTitleOnClick,
  subTitle,
  body,
  footer,
  listCardStatus,
  threeDotMenuOptions,
  withPurpleBorder,
  ...rest
}) => {
  return (
    <div
      className={classnames(className, 'ListCard', {
        'pt-4': !withPurpleBorder && isMobile && !imgUrl,
        'pt-3': imgUrl && isMobile && !withPurpleBorder,
        'pl-8': !imgUrl && isMobile,
        'pl-6': withPurpleBorder && !isMobile,
        'ListCard--border': withPurpleBorder,
      })}
      {...rest}
    >
      <div className="flex flex-grow">
        {imgUrl && imgFallback && (
          <div className="pr-4 md:pr-6">
            <Image
              className="ListCard--logo"
              src={imgUrl}
              fallback={imgFallback}
            />
          </div>
        )}

        <div>
          {title && (
            <div>
              <Button
                className="ListCard--title"
                isTertiary
                onClick={titleOnClick}
              >
                <Heading level={3}>{title}</Heading>
              </Button>
            </div>
          )}
          <div className="mt-2">
            <Button
              className="ListCard--subTitle"
              isTertiary
              onClick={subTitleOnClick}
            >
              <Truncate lineClamp={!isMobile ? 1 : 3}>{subTitle}</Truncate>
            </Button>
          </div>
          {body && <div>{body}</div>}
          {footer && <div className="mt-4">{footer} </div>}
          {listCardStatus && isMobile && (
            <div className="mt-4 -ml-1">{listCardStatus}</div>
          )}
        </div>
      </div>
      {listCardStatus && !isMobile && (
        <div className="ml-3 mr-16">{listCardStatus}</div>
      )}
      {threeDotMenuOptions && (
        <div>
          <ThreeDotMenu options={threeDotMenuOptions} />
        </div>
      )}
    </div>
  );
};

ListCard.defaultProps = {
  className: '',
  imgUrl: '',
  body: null,
  footer: null,
  threeDotMenuOptions: [],
  withPurpleBorder: false,
  imgFallback: null,
};

ListCard.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Image URL
   */
  imgUrl: PropTypes.string,

  /**
   * Image fallback
   */
  imgFallback: PropTypes.node,

  /**
   * Title name
   */
  title: PropTypes.node.isRequired,

  /**
   * Subtitle name
   */
  subTitle: PropTypes.node.isRequired,

  /**
   * body text
   */
  body: PropTypes.node,

  /**
   * footer
   */
  footer: PropTypes.node,

  /**
   * List card status node
   */
  listCardStatus: PropTypes.node.isRequired,

  /**
   * Three dot menu options
   */
  threeDotMenuOptions: PropTypes.arrayOf(PropTypes.shape({})),

  /**
   * When true - show purple border
   */
  withPurpleBorder: PropTypes.bool,

  /**
   * Title on click function
   */
  titleOnClick: PropTypes.func.isRequired,

  /**
   * Subtitle on click function
   */
  subTitleOnClick: PropTypes.func.isRequired,
};

export default ListCard;
