import React, { useEffect, useState } from 'react';
import { Spinner } from '../common';
import { Alert, Button, Grid, GridCell, Heading, Text, TextInput, TextLink } from '@blueprints2/core';
import { Redirect } from 'react-router-dom';
import { StepStatus } from '../../constants/constants';
import { IPixelVerification, MerchantMid } from '../../constants/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import PixelVerificationAPI from '../../actions/pixel-verification';
import { Store } from '../../store';

interface PixelVerificationProps {
	onHttpStatusChange?: Function;
}

const PixelVerification = (props: PixelVerificationProps) => {
	const { onHttpStatusChange } = props;
	const [state, setState] = useState({} as IPixelVerification);
	const { pixelVerification } = useSelector((state: Store) => state.pixelVerification);
	const { merchant } = useSelector((state: Store) => state.merchant);
	const [formState, setFormState] = useState({
		orderName: '',
		redirect: false,
		isLinkClicked: false,
		isDataInput: false,
		isFormSubmitted: false,
		errorMessage: '',
	});
	const dispatch = useDispatch();

	const errorCallback = (status: number, errorMessage: string) => {
		if (status === 401 || status === 500) {
			if (onHttpStatusChange) {
				onHttpStatusChange(status);
			}
		} else if (errorMessage) {
			// @ts-ignore
			setFormState({ ...formState, errorMessage: errorMessage });
		}
	};

	const redirectCallback = (status: number, message: string) => {
		if (status === 201) {
			// @ts-ignore
			setFormState({ ...formState, errorMessage: message });
		} else {
			setFormState({ ...formState, redirect: true });
		}
	};

	const submitCallback = () => {
		setFormState({ ...formState, isFormSubmitted: false });
	};

	const getPixelTrackingStatus = async () => {
		await dispatch(PixelVerificationAPI.getPixelTrackingStatus(merchant.mid, errorCallback));
	};

	const isDisabled = () => {
		if (state.pixelTrackingStatus !== undefined) {
			if (state.pixelTrackingStatus.valueOf() === StepStatus.InReview.valueOf()) {
				return true;
			} else return state.pixelTrackingStatus.valueOf() === StepStatus.Completed.valueOf();
		}
		return false;
	};

	const getUrl = async () => {
		await dispatch(PixelVerificationAPI.getUrl(merchant.mid, errorCallback));
	};

	const getPixelVerification = async () => {
		await dispatch(PixelVerificationAPI.getPixelVerification(merchant.mid, errorCallback));
	};

	const savePixelVerification = async () => {
		const selectedMerchant = merchant.mids.filter((m: MerchantMid) => m.mid === merchant.mid)[0];
		const pixelTrackingRequest = {
			orderName: formState.orderName,
			mid: selectedMerchant.mid,
			country: selectedMerchant.country,
		};
		await dispatch(PixelVerificationAPI.savePixelVerification(pixelTrackingRequest, submitCallback, redirectCallback, errorCallback));
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		if (form.checkValidity()) {
			setFormState({ ...formState, isFormSubmitted: true, errorMessage: '' });
			savePixelVerification();
		}
		event.preventDefault();
		// event.stopPropagation();
	};

	const handleOrderNumberChange = (event: any) => {
		setFormState({ ...formState, isDataInput: event.value !== '', orderName: event.value });
	};

	const getLink = () => {
		return (
			<Button isSmall isDisabled={isDisabled()}>
				<TextLink
					className='buttonLink'
					href={state.url}
					newWindow
					rel='noopener noreferrer'
					onClick={() => {
						setFormState({ ...formState, isLinkClicked: true });
					}}
				>
					Go to my store
				</TextLink>
			</Button>
		);
	};

	const getAlert = () => {
		let alertElem;
		if (state.pixelTrackingStatus !== undefined) {
			if (state.pixelTrackingStatus.valueOf() === StepStatus.InReview.valueOf()) {
				alertElem = (
					<Alert isDismissible={false} type='warning' icon='hourglass' className='mb-5'>
						In review
					</Alert>
				);
			} else if (state.pixelTrackingStatus.valueOf() === StepStatus.Completed.valueOf()) {
				alertElem = (
					<Alert isDismissible={false} type='success' icon='checkmark-outline' className='mb-5'>
						Complete
					</Alert>
				);
			} else if (state.pixelTrackingStatus.valueOf() === StepStatus.Failed.valueOf() && !formState.errorMessage) {
				alertElem = (
					<Alert isDismissible={false} type='danger' icon='exclamation-solid' className='mb-5'>
						Failed
					</Alert>
				);
			}
		}
		return alertElem;
	};

	useEffect(() => {
		getPixelTrackingStatus();
		getUrl();
		return () => {
			dispatch(PixelVerificationAPI.clearState());
		};
	}, []);

	useEffect(() => {
		if (pixelVerification.pixelTrackingStatus !== undefined && state.pixelTrackingStatus !== pixelVerification.pixelTrackingStatus) {
			if (
				pixelVerification.pixelTrackingStatus.valueOf() === StepStatus.Completed.valueOf() ||
				pixelVerification.pixelTrackingStatus.valueOf() === StepStatus.InReview.valueOf()
			) {
				getPixelVerification();
			}
		}
		setState(pixelVerification);
	}, [pixelVerification]);

	if (state.pixelTrackingStatus === undefined) {
		return <Spinner />;
	}

	if (formState.redirect) {
		return <Redirect to='/home' />;
	}

	return (
		<>
			{getAlert()}
			{formState.errorMessage && (
				<Alert isDismissible={false} type='danger' icon='exclamation-solid' className='mb-5'>
					{formState.errorMessage}
				</Alert>
			)}
			<Grid>
				<Heading level={2}>
					To ensure our systems match, we need you to complete a transaction on your store. Please follow these steps:
				</Heading>
			</Grid>
			<Grid className='mt-5'>
				<Heading level={4}> STEP 1 </Heading>
				<Grid>
					<Text>
						Go your store using this button (IMPORTANT: it must be via this button)
						<br />
						{getLink()}
					</Text>
					<Text variation='strong'>Make sure your store's cart is empty</Text>
				</Grid>
			</Grid>

			<Grid className='mt-2'>
				<Heading level={4}> STEP 2 </Heading>
			</Grid>
			<Grid>
				<Text>Add a product to your cart</Text>
			</Grid>
			<Grid className='mt-2'>
				<Heading level={4}> STEP 3 </Heading>
			</Grid>
			<Grid>
				<Text>
					Add a <b>different</b> product to your cart, but this time, add a <b>quantity of two</b>.
				</Text>
			</Grid>
			<Grid className='mt-2'>
				<Heading level={4}> STEP 4 </Heading>
			</Grid>
			<Grid>
				<Text>
					Go to the cart, if you offer order level discounts, such as 10% off entire order, please use one on your test order for discount
					validation
				</Text>
			</Grid>
			<Grid className='mt-2'>
				<Heading level={4}> STEP 5 </Heading>
			</Grid>
			<Grid>
				<Text>Complete the transaction</Text>
			</Grid>
			<Grid className='mt-2'>
				<Heading level={4}> STEP 6 </Heading>
			</Grid>
			<Grid>
				<Text>
					Your store can take <b>up to 3 minutes</b> to sync with us, please take this time before inserting your order ID below
				</Text>
			</Grid>
			<form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: '-1rem' }}>
				<Grid>
					<GridCell key={3} width='1/3'>
						<TextInput
							placeholder='Order ID'
							type='string'
							label=''
							name='orderid'
							value={formState.orderName}
							hasError={!!formState.errorMessage}
							helperText={formState.errorMessage ? formState.errorMessage : ''}
							onChange={(event: any) => handleOrderNumberChange(event)}
							hasHiddenLabel
							isRequired={true}
							isDisabled={isDisabled() || !formState.isLinkClicked}
						/>
					</GridCell>
				</Grid>
				<Grid style={{ marginTop: '15px' }}>
					<Text variation='strong'>When all steps are complete, click Done.</Text>
				</Grid>
				<div className='flex justify-end pb-6'>
					<TextLink to='/home'>
						<Button isTertiary>Cancel</Button>
					</TextLink>
					<Button
						type='submit'
						isDisabled={!formState.isLinkClicked || !formState.isDataInput}
						isLoading={formState.isFormSubmitted && !formState.errorMessage}
					>
						Done
					</Button>
				</div>
			</form>
		</>
	);
};

export default PixelVerification;
