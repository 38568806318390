import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './StarRating.less';
import { StarFullIcon, StarHalfIcon, StarEmptyIcon } from './StarIcons';

const MAX_STAR_VALUE = 5;
const UNIT = {
  RATING: 'rating',
  PERCENTAGE: 'percentage',
};

const buildStars = value => {
  const stars = [];

  // This rounds the rating to the closest integer OR .5 decimal
  const roundedValue = Math.round(value * 2) / 2;

  for (let x = 0; x < MAX_STAR_VALUE; x++) {
    const className = 'Star';

    if (roundedValue - x >= 1) {
      stars.push(<StarFullIcon className={className} key={`StarIcon-${x}`} />);
    } else if (roundedValue - x > 0) {
      stars.push(<StarHalfIcon className={className} key={`StarIcon-${x}`} />);
    } else {
      stars.push(<StarEmptyIcon className={className} key={`StarIcon-${x}`} />);
    }
  }

  return stars;
};

const calculateValue = (value, unit) => {
  let valueCalculated =
    unit === UNIT.PERCENTAGE ? value / (100 / MAX_STAR_VALUE) : value;

  // Round 'value' to two decimal places
  valueCalculated = Math.round(valueCalculated * 100) / 100;

  return valueCalculated;
};

const StarRating = props => {
  const { value, unit, isSmall } = props;

  let valueCalculated = calculateValue(value, unit);

  if (valueCalculated < 0) {
    valueCalculated = 0;
    console.warn(
      `Value below 0 (minimum allowed value) on 'StarRating'. The value will display as 0.`,
    );
  }

  if (valueCalculated > MAX_STAR_VALUE) {
    valueCalculated = MAX_STAR_VALUE;
    console.warn(
      `Value above ${MAX_STAR_VALUE} (maximum allowed value) on 'StarRating'. The value will display as ${MAX_STAR_VALUE}.`,
    );
  }

  return (
    <div
      className={classnames('StarRating', { 'StarRating--small': isSmall })}
      title={`${valueCalculated}`}
    >
      {buildStars(valueCalculated)}
    </div>
  );
};

StarRating.propTypes = {
  /**
   * Value to be displayed
   */
  value: PropTypes.number.isRequired,

  /**
   * Type of value being used
   */
  unit: PropTypes.oneOf([UNIT.RATING, UNIT.PERCENTAGE]),

  /**
   * Renders small StarRating
   */
  isSmall: PropTypes.bool,
};

StarRating.defaultProps = {
  unit: UNIT.RATING,
  isSmall: false,
};

export default StarRating;
