import React, { useEffect, useState } from 'react';
import { Spinner } from '../common';
import { Redirect } from 'react-router-dom';
import { Alert, Button, Grid, GridCell, Heading, RadioButton, TextInput, TextLink } from '@blueprints2/core';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import ThirdPartyAPI from '../../actions/third-party';
import { ThirdPartyRequest } from '../../constants/interfaces';
import { HOME } from '../../constants/constants';

interface ThirdPartyProps {
	onHttpStatusChange?: Function;
}

const ThirdParty = (props: ThirdPartyProps) => {
	const { onHttpStatusChange } = props;
	const { state } = useSelector((state: Store) => state.thirdParty);
	const [formState, setFormState] = useState({
		appName: '',
		thirdPartyAppStatus: null,
		optionSelected: null,
		redirect: false,
		hasError: false,
		errorMessage: '',
		isProcessing: false,
	});

	const dispatch = useDispatch();

	const errorCallback = (status: number) => {
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const processingCallback = (hasError: boolean) => {
		if (hasError) {
			setFormState({
				...formState,
				isProcessing: false,
				hasError: hasError ? hasError : formState.hasError,
				errorMessage: hasError ? 'An error occurred while processing request' : formState.errorMessage,
			});
		} else {
			setFormState({ ...formState, redirect: true });
		}
	};

	const getStatus = async () => {
		await dispatch(ThirdPartyAPI.getStatus(errorCallback));
	};

	const getDetails = async () => {
		await dispatch(ThirdPartyAPI.getDetails(errorCallback));
	};

	const saveDetails = async (request: ThirdPartyRequest) => {
		setFormState({ ...formState, isProcessing: true });
		await dispatch(ThirdPartyAPI.saveDetails(request, errorCallback, processingCallback));
	};

	useEffect(() => {
		getStatus();
		getDetails();
		return () => {
			dispatch(ThirdPartyAPI.clearState());
		};
	}, []);

	useEffect(() => {
		setFormState({
			...formState,
			appName: state.appName,
			// @ts-ignore
			thirdPartyAppStatus: state.thirdPartyAppStatus,
			// @ts-ignore
			optionSelected: formState.optionSelected !== state.status ? state.status : formState.optionSelected,
		});
	}, [state]);

	function handleInput(text: any) {
		const regex = /^[0-9a-zA-Z\s]*$/;
		if (text.value.match(regex) || text.value === '') {
			setFormState({ ...formState, appName: text.value, hasError: false, errorMessage: '' });
		} else {
			setFormState({ ...formState, hasError: true, errorMessage: 'AlphaNumeric Only' });
		}
	}

	const handleSubmit = () => {
		const request = {
			status: formState.optionSelected,
			appName: formState.optionSelected !== 0 ? formState.appName : '',
		};
		saveDetails(request);
	};

	if (formState.thirdPartyAppStatus === null) {
		return <Spinner />;
	}

	if (formState.redirect) {
		return <Redirect to={`/${HOME}`} />;
	}
	return (
		<>
			{formState.thirdPartyAppStatus === 1 && (
				<Alert isDismissible={false} type='success' icon='checkmark-outline' className='mb-5'>
					Complete
				</Alert>
			)}
			<Heading level={2}>Does your store use any third party apps for subscription or checkout?</Heading>
			<Grid className='mt-8'>
				<RadioButton
					isDisabled={formState.thirdPartyAppStatus === 1}
					isChecked={formState.optionSelected === 0}
					name='no'
					label='No (most common)'
					value='0'
					onChange={() => {
						// @ts-ignore
						setFormState({ ...formState, appName: '', optionSelected: 0 });
					}}
				/>
			</Grid>
			<Grid className='mt-8'>
				<RadioButton
					isDisabled={formState.thirdPartyAppStatus === 1}
					isChecked={formState.optionSelected === 1}
					name='yes'
					label='Yes, I use a third party app'
					value='1'
					onChange={() => {
						// @ts-ignore
						setFormState({ ...formState, optionSelected: 1 });
					}}
				/>
			</Grid>
			{formState.optionSelected === 1 && (
				<Grid>
					<GridCell width='2/5'>
						<TextInput
							label=''
							isDisabled={formState.thirdPartyAppStatus === 1}
							hasError={formState.hasError}
							name='appname'
							value={formState.appName}
							onChange={(text: any) => handleInput(text)}
							helperText={formState.hasError ? formState.errorMessage : ''}
						/>
					</GridCell>
				</Grid>
			)}
			<div className='flex justify-end pb-6 mt-24'>
				<TextLink to='/home'>
					<Button isTertiary>Cancel</Button>
				</TextLink>
				<Button
					onClick={() => handleSubmit()}
					isDisabled={
						formState.thirdPartyAppStatus === 1 ||
						formState.optionSelected === null ||
						(formState.optionSelected === 1 && formState.appName.trim().length === 0) ||
						formState.hasError
					}
					isLoading={formState.isProcessing}
				>
					Done
				</Button>
			</div>
		</>
	);
};

export default ThirdParty;
