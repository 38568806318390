/* eslint-disable react/forbid-prop-types */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import { LoadingBar } from 'components/Loading';
import classnames from 'classnames';
import PanelWizardCheckMarks from './PanelWizardCheckMarks';

import './PanelWizard.less';

class PanelWizard extends PureComponent {
  static propTypes = {
    /**
     * See https://docs.react-joyride.com/step for more info
     */
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.node.isRequired,
        id: PropTypes.string.isRequired,
        isComplete: PropTypes.bool,
      }),
    ),

    className: PropTypes.string,

    /**
     * Element ID to provide top offset
     */
    topOffsetElementID: PropTypes.string,

    /**
     * Additional top offset in pixels
     */
    additionalTopOffset: PropTypes.number,

    /**
     * Indicators visibility
     */
    withIndicators: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    topOffsetElementID: '',
    additionalTopOffset: 32,
    steps: [],
    withIndicators: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      topOffset: 0,
    };
  }

  componentDidMount() {
    const { withIndicators, topOffsetElementID } = this.props;

    if (withIndicators) {
      this.getProgress();
    }
    if (topOffsetElementID) this.getTopOffset();
  }

  componentWillReceiveProps() {
    const { withIndicators } = this.props;

    if (withIndicators) {
      this.getProgress();
    }
  }

  getTopOffset() {
    const offsetElement = document.getElementById(
      this.props.topOffsetElementID,
    );

    this.setState({
      topOffset: offsetElement ? offsetElement.clientHeight : 0,
    });
  }

  getProgress() {
    const { steps } = this.props;

    const newProgress =
      100 / (steps.length / steps.filter(step => step.isComplete).length);

    this.setState({
      currentProgress: Math.ceil(newProgress),
    });
  }

  scrollTo(id) {
    if (!id) return;
    const { additionalTopOffset } = this.props;
    const { topOffset } = this.state;

    const element = document.getElementById(id);
    if (!element) return;

    const positionTop = element.offsetTop - (topOffset + additionalTopOffset);
    const scrollOptions = { top: positionTop, behavior: 'smooth' };

    document.body.scrollTo(scrollOptions); // For Safari
    document.documentElement.scrollTo(scrollOptions); // For Chrome, Firefox, IE and Opera
  }

  render() {
    const {
      steps,
      className,
      additionalTopOffset,
      withIndicators,
      ...rest
    } = this.props;
    const { topOffset, currentProgress } = this.state;
    const topOffsetStyle = { style: { top: topOffset + additionalTopOffset } };

    return (
      <div className="PanelWizardContainer" {...rest}>
        <div
          className={classnames('PanelWizard', className)}
          {...topOffsetStyle}
        >
          {withIndicators && (
            <Fragment>
              <div className="PanelWizard__LoadingContainer">
                <LoadingBar
                  className="PanelWizard__LoadingBar"
                  isStatic
                  percentage={currentProgress || 0}
                  color="green"
                />
                <Text className="PanelWizard__Percentage" variation="sm">
                  {`${currentProgress}% complete`}
                </Text>
              </div>
              <div className="PanelWizard__Separator" />
            </Fragment>
          )}

          <div className="PanelWizard__StepsContainer">
            {steps.map(step => (
              <div key={step.id} className="PanelWizard__Step">
                {withIndicators && (
                  <PanelWizardCheckMarks isComplete={step.isComplete} />
                )}
                <Text onClick={() => this.scrollTo(step.id)}>{step.name}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PanelWizard;
