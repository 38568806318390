import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { consoleWarnWithLink, getComponentName } from 'components/helpers';
import Heading from '../Heading';
import './CardHeader.less';

const CardHeader = props => {
  const {
    title,
    showTitleFallback,
    titleFallbackText,
    subHeader,
    showSubHeaderFallback,
    subHeaderFallbackText,
  } = props;

  const subHeaderClasses = classnames('CardHeader__subHeader', {
    Card__fallback: showSubHeaderFallback,
  });

  if (showTitleFallback && !titleFallbackText) {
    consoleWarnWithLink({
      link: 'CardHeader',
      warning: `(Card/CardHeader) needs titleFallbackText if showTitleFallback is true.`,
    });
  }

  if (showSubHeaderFallback && !subHeaderFallbackText) {
    consoleWarnWithLink({
      link: 'CardHeader',
      warning: `(Card/CardHeader) needs subHeaderFallbackText if showSubHeaderFallback is true.`,
    });
  }

  const Title = () => {
    if (!showTitleFallback) return title;

    if (title && title.props && titleFallbackText) {
      const props = { ...title.props };
      props.children = titleFallbackText;

      return React.cloneElement(title, {
        ...props,
        className:
          getComponentName(title) === 'TextLink' ? '' : 'Card__fallback',
      });
    }

    return (
      <Heading level={3} className="Card__fallback">
        {titleFallbackText || ''}
      </Heading>
    );
  };

  return (
    <div className="CardHeader">
      <Title />

      <div className={subHeaderClasses}>
        {showSubHeaderFallback ? subHeaderFallbackText : subHeader}
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  /**
   * Card title should only be used with `TextLink` or `Heading` (level 3) components
   */
  title: PropTypes.node,

  /**
   * Determines whether the title fallback is shown
   */
  showTitleFallback: PropTypes.bool,

  /**
   * Text or node to show if showTitleFallback is true
   */
  titleFallbackText: PropTypes.node,

  /**
   * Card subHeader
   */
  subHeader: PropTypes.node,

  /**
   * Determines whether the subheader fallback is shown
   */
  showSubHeaderFallback: PropTypes.bool,

  /**
   * Text or node to show if showSubHeaderFallback is true
   */
  subHeaderFallbackText: PropTypes.node,
};

CardHeader.defaultProps = {
  title: null,
  showTitleFallback: false,
  titleFallbackText: null,
  subHeader: null,
  showSubHeaderFallback: false,
  subHeaderFallbackText: null,
};

export default CardHeader;
