import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CardSection from '../CardSection';

import './ExpandableCard.less';

class ExpandableCard extends PureComponent {
  static propTypes = {
    /**
     * Child nodes
     */
    children: PropTypes.node.isRequired,

    /**
     * Expand label to activate hover
     */
    label: PropTypes.node.isRequired,

    /**
     * Expanded details nodes
     */
    details: PropTypes.node.isRequired,

    /**
     * Card and additional classes to apply
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };

    this.handleHoverChange = this.handleHoverChange.bind(this);
  }

  handleHoverChange = newValue => {
    this.setState({ isHovered: newValue });
  };

  onMouseEnter = () => {
    this.handleHoverChange(true);
  };

  onMouseLeave = () => {
    this.handleHoverChange(false);
  };

  render() {
    const { isHovered } = this.state;
    const { label, details, children, className } = this.props;

    const classes = classnames(className, {
      'Card--expanded': isHovered,
    });

    const labelClasses = classnames('ExpandableCard__label', {
      invisible: isHovered,
    });

    return (
      <div
        className={classes}
        onMouseLeave={this.onMouseLeave}
        onTouchEnd={this.onMouseLeave}
      >
        {children}

        <div className="ExpandableCard__container">
          {isHovered && (
            <div className="ExpandableCard__content">
              <CardSection />
              {details}
            </div>
          )}
          <div
            className={labelClasses}
            onMouseEnter={this.onMouseEnter}
            onTouchStart={this.onMouseEnter}
          >
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpandableCard;
