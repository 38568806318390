import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormattedMessage } from 'react-intl';

import { Button } from 'components/Button';

import './PopoverApply.less';

const PopoverApply = ({
  onCancel,
  className,
  isSmallBtn,
  customTextApplyBtn,
  customTextCancelBtn,
}) => {
  const classes = classnames('PopoverApply', className);

  return (
    <div className={classes}>
      <Button isSmall={isSmallBtn} isTertiary onClick={onCancel}>
        {customTextApplyBtn || (
          <FormattedMessage
            defaultMessage="Cancel"
            description="PopoverApply cancel message"
          />
        )}
      </Button>
      <Button type="submit" isSmall={isSmallBtn}>
        {customTextCancelBtn || (
          <FormattedMessage
            defaultMessage="Apply"
            description="PopoverApply apply message"
          />
        )}
      </Button>
    </div>
  );
};

PopoverApply.propTypes = {
  /**
   * Function to execute when Cancel button is clicked
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * When false - show big button
   */
  isSmallBtn: PropTypes.bool,

  customTextApplyBtn: PropTypes.node,
  customTextCancelBtn: PropTypes.node,
};

PopoverApply.defaultProps = {
  className: '',
  isSmallBtn: true,
  customTextApplyBtn: null,
  customTextCancelBtn: null,
};

export default PopoverApply;
