import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { SPI_CLEAR, SPI_CREATE, SPI_GET } from '../constants/action-types';
import { API } from '../constants/api';

export default {
	getScript: (onSuccessCallback: Function, onErrorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.scripts.get);
			dispatch({ type: SPI_GET, data: response.data.isSPIScriptPlaced });
			if (!response.data.isSPIScriptPlaced) {
				onSuccessCallback();
			}
		} catch (err: any) {
			console.log('Error while getting script data: ' + +err.response.data.message);
			dispatch({ type: SPI_GET, data: false });
			onErrorCallback(err.response.status);
		}
	},
	createScript: (onErrorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			await ShopifyAPi.post(API.V1.scripts.create, null);
			dispatch({ type: SPI_CREATE, data: true });
		} catch (err: any) {
			console.log('Error while creating script: ' + err.response.data);
			onErrorCallback(err.data.status);
		}
	},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: SPI_CLEAR }),
};
