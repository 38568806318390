import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import STATUSES from 'components/constants';

import { DismissibleTag, DefaultTag } from './states';
import './Tag.less';

const Tag = props => {
  const { className, type, isFilled, isDismissible } = props;

  const classes = classnames(
    'Tag',
    {
      'Tag--success': type === STATUSES.SUCCESS,
      'Tag--warning': type === STATUSES.WARNING,
      'Tag--danger': type === STATUSES.DANGER,
      'Tag--info': type === STATUSES.INFO,
      'Tag--secondary': type === STATUSES.SECONDARY,
      'Tag--filled': isFilled,
    },
    className,
  );
  const Component = isDismissible ? DismissibleTag : DefaultTag;

  return <Component {...props} className={classes} />;
};

Tag.propTypes = {
  /**
   * Renders selected tag variation
   */

  type: PropTypes.oneOf([
    STATUSES.SUCCESS,
    STATUSES.WARNING,
    STATUSES.DANGER,
    STATUSES.INFO,
    STATUSES.SECONDARY,
  ]),

  /**
   * Renders filled tag variation
   */
  isFilled: PropTypes.bool,

  /**
   * Renders a dismissible tag
   */
  isDismissible: PropTypes.bool,

  /**
   * Additional classes to apply
   *
   * @deprecated Will be removed in v4.0
   */
  className: PropTypes.string,
};

Tag.defaultProps = {
  type: STATUSES.INFO,
  isFilled: false,
  isDismissible: true,
  className: '',
};

export default Tag;
