import React from 'react';

const categories = {
  general: 'General Interactions',

  arrows: 'Arrows',
  navigation: 'Navigation',
  settings: 'Settings & Filters',
  notifications: 'Notifications & Validations',
  viewing: 'Viewing & UI',

  charts: 'Visualizations',
  time: 'Date & Time',
  locations: 'Locations',
  shopping: 'Shopping & Payment',
  devices: 'Devices & Electronics',

  users: 'Communities, Emails, & Messaging',
  reactions: 'Reactions & Emotions',
  editing: 'Editing & WYSIWYGs',
  files: 'File Management',

  alias: 'Alias',
  uncategorized: 'Uncategorized',
};

const icons = {
  /**
   * General Interactions
   */

  'app-switcher': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M2.5,15 C3.88071187,15 5,16.1192881 5,17.5 C5,18.8807119 3.88071187,20 2.5,20 C1.11928813,20 0,18.8807119 0,17.5 C0,16.1192881 1.11928813,15 2.5,15 Z M10,15 C11.3807119,15 12.5,16.1192881 12.5,17.5 C12.5,18.8807119 11.3807119,20 10,20 C8.61928813,20 7.5,18.8807119 7.5,17.5 C7.5,16.1192881 8.61928813,15 10,15 Z M17.5,15 C18.8807119,15 20,16.1192881 20,17.5 C20,18.8807119 18.8807119,20 17.5,20 C16.1192881,20 15,18.8807119 15,17.5 C15,16.1192881 16.1192881,15 17.5,15 Z M2.5,7.5 C3.88071187,7.5 5,8.61928813 5,10 C5,11.3807119 3.88071187,12.5 2.5,12.5 C1.11928813,12.5 0,11.3807119 0,10 C0,8.61928813 1.11928813,7.5 2.5,7.5 Z M10,7.5 C11.3807119,7.5 12.5,8.61928813 12.5,10 C12.5,11.3807119 11.3807119,12.5 10,12.5 C8.61928813,12.5 7.5,11.3807119 7.5,10 C7.5,8.61928813 8.61928813,7.5 10,7.5 Z M17.5,7.5 C18.8807119,7.5 20,8.61928813 20,10 C20,11.3807119 18.8807119,12.5 17.5,12.5 C16.1192881,12.5 15,11.3807119 15,10 C15,8.61928813 16.1192881,7.5 17.5,7.5 Z M2.5,0 C3.88071187,0 5,1.11928813 5,2.5 C5,3.88071187 3.88071187,5 2.5,5 C1.11928813,5 0,3.88071187 0,2.5 C0,1.11928813 1.11928813,0 2.5,0 Z M10,0 C11.3807119,0 12.5,1.11928813 12.5,2.5 C12.5,3.88071187 11.3807119,5 10,5 C8.61928813,5 7.5,3.88071187 7.5,2.5 C7.5,1.11928813 8.61928813,0 10,0 Z M17.5,0 C18.8807119,0 20,1.11928813 20,2.5 C20,3.88071187 18.8807119,5 17.5,5 C16.1192881,5 15,3.88071187 15,2.5 C15,1.11928813 16.1192881,0 17.5,0 Z" />
      </svg>
    ),
  },

  search: {
    category: categories.general,
    svg: (
      <svg>
        <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
      </svg>
    ),
  },

  'close-outline': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
      </svg>
    ),
  },

  'close-solid': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM11.4 10l2.83-2.83-1.41-1.41L10 8.59 7.17 5.76 5.76 7.17 8.59 10l-2.83 2.83 1.41 1.41L10 11.41l2.83 2.83 1.41-1.41L11.41 10z" />
      </svg>
    ),
  },

  close: {
    category: categories.general,
    svg: (
      <svg>
        <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
      </svg>
    ),
  },

  'add-outline': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
      </svg>
    ),
  },

  'add-solid': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z" />
      </svg>
    ),
  },

  'minus-outline': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm5-9v2H5V9h10z" />
      </svg>
    ),
  },

  'minus-solid': {
    category: categories.general,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm5-11H5v2h10V9z" />
      </svg>
    ),
  },

  trash: {
    category: categories.general,
    svg: (
      <svg>
        <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
      </svg>
    ),
  },

  edit: {
    category: categories.general,
    svg: (
      <svg>
        <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
      </svg>
    ),
  },

  link: {
    category: categories.general,
    svg: (
      <svg>
        <path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z" />
      </svg>
    ),
  },

  'external-link': {
    category: categories.general,
    svg: (
      <svg>
        <path
          fillRule="evenodd"
          d="M10,0 L10,2.125 L2.125,2.125 L2.125,17.875 L17.875,17.875 L17.875,10 L20,10 L20,20 L0,20 L0,0 L10,0 Z M20,0 L20,8.46902307 L17.4807208,5.94972241 L11.4154913,12 L8,8.5973416 L14,2.50158481 L11.483871,0 L20,0 Z"
        />
      </svg>
    ),
  },

  bug: {
    category: categories.general,
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg">
        <path d="M15.3 14.89l2.77 2.77a1 1 0 0 1 0 1.41 1 1 0 0 1-1.41 0l-2.59-2.58A5.99 5.99 0 0 1 11 18V9.04a1 1 0 0 0-2 0V18a5.98 5.98 0 0 1-3.07-1.51l-2.59 2.58a1 1 0 0 1-1.41 0 1 1 0 0 1 0-1.41l2.77-2.77A5.95 5.95 0 0 1 4.07 13H1a1 1 0 1 1 0-2h3V8.41L.93 5.34a1 1 0 0 1 0-1.41 1 1 0 0 1 1.41 0l2.1 2.1h11.12l2.1-2.1a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.41L16 8.41V11h3a1 1 0 1 1 0 2h-3.07c-.1.67-.32 1.31-.63 1.89zM15 5H5a5 5 0 1 1 10 0z" />
      </svg>
    ),
  },

  /**
   * Arrows
   */

  'chevron-outline-up': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm10 8a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm.7-10.54L14.25 11l-1.41 1.41L10 9.6l-2.83 2.8L5.76 11 10 6.76l.7.7z" />
      </svg>
    ),
  },

  'chevron-outline-down': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-.7 10.54L5.75 9l1.41-1.41L10 10.4l2.83-2.82L14.24 9 10 13.24l-.7-.7z" />
      </svg>
    ),
  },

  'chevron-outline-left': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm8-10a8 8 0 1 0-16 0 8 8 0 0 0 16 0zM7.46 9.3L11 5.75l1.41 1.41L9.6 10l2.82 2.83L11 14.24 6.76 10l.7-.7z" />
      </svg>
    ),
  },

  'chevron-outline-right': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zM2 10a8 8 0 1 0 16 0 8 8 0 0 0-16 0zm10.54.7L9 14.25l-1.41-1.41L10.4 10 7.6 7.17 9 5.76 13.24 10l-.7.7z" />
      </svg>
    ),
  },

  'chevron-up': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
      </svg>
    ),
  },

  'chevron-down': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    ),
  },

  'chevron-left': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
      </svg>
    ),
  },

  'chevron-right': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
      </svg>
    ),
  },

  'arrow-nav': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 14L5 6L15 6L10 14Z" />
      </svg>
    ),
  },

  'arrow-outline-up': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm2 8v5H8v-5H5l5-5 5 5h-3z" />
      </svg>
    ),
  },

  'arrow-outline-down': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2-8V5h4v5h3l-5 5-5-5h3z" />
      </svg>
    ),
  },

  'arrow-outline-left': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm2 0a8 8 0 1 0 16 0 8 8 0 0 0-16 0zm8-2h5v4h-5v3l-5-5 5-5v3z" />
      </svg>
    ),
  },

  'arrow-outline-right': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
      </svg>
    ),
  },

  'arrow-thick-up': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M7 10v8h6v-8h5l-8-8-8 8h5z" />
      </svg>
    ),
  },

  'arrow-thick-down': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M7 10V2h6v8h5l-8 8-8-8h5z" />
      </svg>
    ),
  },

  'arrow-thick-left': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 13h8V7h-8V2l-8 8 8 8v-5z" />
      </svg>
    ),
  },

  'arrow-thick-right': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M10 7H2v6h8v5l8-8-8-8v5z" />
      </svg>
    ),
  },

  'arrow-up': {
    category: categories.arrows,
    svg: (
      <svg>
        <polygon points="9 3.828 2.929 9.899 1.515 8.485 10 0 10.707 .707 18.485 8.485 17.071 9.899 11 3.828 11 20 9 20 9 3.828" />
      </svg>
    ),
  },

  'arrow-down': {
    category: categories.arrows,
    svg: (
      <svg>
        <polygon points="9 16.172 2.929 10.101 1.515 11.515 10 20 10.707 19.293 18.485 11.515 17.071 10.101 11 16.172 11 0 9 0" />
      </svg>
    ),
  },

  'arrow-left': {
    category: categories.arrows,
    svg: (
      <svg>
        <polygon points="3.828 9 9.899 2.929 8.485 1.515 0 10 .707 10.707 8.485 18.485 9.899 17.071 3.828 11 20 11 20 9 3.828 9" />
      </svg>
    ),
  },

  'arrow-right': {
    category: categories.arrows,
    svg: (
      <svg>
        <polygon points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9" />
      </svg>
    ),
  },

  'subdirectory-left': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M18 12v1H8v5l-6-6 6-6v5h8V2h2z" />
      </svg>
    ),
  },

  'subdirectory-right': {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M3.5 13H12v5l6-6-6-6v5H4V2H2v11z" />
      </svg>
    ),
  },

  swap: {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M9 6a4 4 0 1 1 8 0v8h3l-4 4-4-4h3V6a2 2 0 0 0-2-2 2 2 0 0 0-2 2v8a4 4 0 1 1-8 0V6H0l4-4 4 4H5v8a2 2 0 0 0 2 2 2 2 0 0 0 2-2V6z" />
      </svg>
    ),
  },

  backspace: {
    category: categories.arrows,
    svg: (
      <svg>
        <path d="M0 10l7-7h13v14H7l-7-7zm14.41 0l2.13-2.12-1.42-1.42L13 8.6l-2.12-2.13-1.42 1.42L11.6 10l-2.13 2.12 1.42 1.42L13 11.4l2.12 2.13 1.42-1.42L14.4 10z" />
      </svg>
    ),
  },

  /**
   * Navigation
   */

  refresh: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M10 3v2a5 5 0 0 0-3.54 8.54l-1.41 1.41A7 7 0 0 1 10 3zm4.95 2.05A7 7 0 0 1 10 17v-2a5 5 0 0 0 3.54-8.54l1.41-1.41zM10 20l-4-4 4-4v8zm0-12V0l4 4-4 4z" />
      </svg>
    ),
  },

  reload: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M14.66 15.66A8 8 0 1 1 17 10h-2a6 6 0 1 0-1.76 4.24l1.42 1.42zM12 10h8l-4 4-4-4z" />
      </svg>
    ),
  },

  window: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M0 3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm2 2v12h16V5H2z" />
      </svg>
    ),
  },

  'window-new': {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M9 10V8h2v2h2v2h-2v2H9v-2H7v-2h2zM0 3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm2 2v12h16V5H2z" />
      </svg>
    ),
  },

  menu: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    ),
  },

  'navigation-more': {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  'dots-horizontal-double': {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M10 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  'dots-horizontal-triple': {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  home: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z" />
      </svg>
    ),
  },

  dashboard: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z" />
      </svg>
    ),
  },

  login: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M2 2v5h2V4h14v14H4v-3H2v5h18V2z" />
        <path d="M8.1 8.8H1v4.5h7.1V17l6.9-6-6.9-6v3.8z" />
      </svg>
    ),
  },

  logout: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M18 20v-4h-2v2H2V4h14v2h2V2H0v18z" />
        <path d="M13.1 8.8H6v4.5h7.1V17l6.9-6-6.9-6v3.8z" />
      </svg>
    ),
  },

  rakuten: {
    category: categories.navigation,
    svg: (
      <svg>
        <path d="M3.6 19.28L1 17.11h18.1zM6.53 2.95h1.92a2.4 2.4 0 0 1 0 4.8H6.53v-4.8zm0 12.54v-4.8h2.08l3.6 4.8h3.68l-4.35-5.8A5.35 5.35 0 0 0 8.45.03H3.6v15.47h2.94z" />
      </svg>
    ),
  },

  /**
   * Settings & Filters
   */

  cog: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M3.94 6.5L2.22 3.64l1.42-1.42L6.5 3.94c.52-.3 1.1-.54 1.7-.7L9 0h2l.8 3.24c.6.16 1.18.4 1.7.7l2.86-1.72 1.42 1.42-1.72 2.86c.3.52.54 1.1.7 1.7L20 9v2l-3.24.8c-.16.6-.4 1.18-.7 1.7l1.72 2.86-1.42 1.42-2.86-1.72c-.52.3-1.1.54-1.7.7L11 20H9l-.8-3.24c-.6-.16-1.18-.4-1.7-.7l-2.86 1.72-1.42-1.42 1.72-2.86c-.3-.52-.54-1.1-.7-1.7L0 11V9l3.24-.8c.16-.6.4-1.18.7-1.7zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      </svg>
    ),
  },

  wrench: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M6.47 9.8A5 5 0 0 1 .2 3.22l3.95 3.95 2.82-2.83L3.03.41a5 5 0 0 1 6.4 6.68l10 10-2.83 2.83L6.47 9.8z" />
      </svg>
    ),
  },

  sliders: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M17 16v4h-2v-4h-2v-3h6v3h-2zM1 9h6v3H1V9zm6-4h6v3H7V5zM3 0h2v8H3V0zm12 0h2v12h-2V0zM9 0h2v4H9V0zM3 12h2v8H3v-8zm6-4h2v12H9V8z" />
      </svg>
    ),
  },

  flag: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M7.667 12H2v8H0V0h12l.333 2H20l-3 6 3 6H8l-.333-2z" />
      </svg>
    ),
  },

  filter: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M12 12l8-8V0H0v4l8 8v8l4-4v-4z" />
      </svg>
    ),
  },

  tag: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M0 10V2l2-2h8l10 10-10 10L0 10zm4.5-4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
      </svg>
    ),
  },

  'lock-closed': {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
      </svg>
    ),
  },

  'lock-open': {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
      </svg>
    ),
  },

  key: {
    category: categories.settings,
    svg: (
      <svg>
        <path d="M12.26 11.74L10 14H8v2H6v2l-2 2H0v-4l8.26-8.26a6 6 0 1 1 4 4zm4.86-4.62A3 3 0 0 0 15 2a3 3 0 0 0-2.12.88l4.24 4.24z" />
      </svg>
    ),
  },

  /**
   * Notifications
   */

  notification: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M4 8a6 6 0 0 1 4.03-5.67 2 2 0 1 1 3.95 0A6 6 0 0 1 16 8v6l3 2v1H1v-1l3-2V8zm8 10a2 2 0 1 1-4 0h4z" />
      </svg>
    ),
  },

  'notifications-outline': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M6 8v7h8V8a4 4 0 1 0-8 0zm2.03-5.67a2 2 0 1 1 3.95 0A6 6 0 0 1 16 8v6l3 2v1H1v-1l3-2V8a6 6 0 0 1 4.03-5.67zM12 18a2 2 0 1 1-4 0h4z" />
      </svg>
    ),
  },

  announcement: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M3 6c0-1.1.9-2 2-2h8l4-4h2v16h-2l-4-4H5a2 2 0 0 1-2-2H1V6h2zm8 9v5H8l-1.67-5H5v-2h8v2h-2z" />
      </svg>
    ),
  },

  buoy: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M17.16 6.42a8.03 8.03 0 0 0-3.58-3.58l-1.34 2.69a5.02 5.02 0 0 1 2.23 2.23l2.69-1.34zm0 7.16l-2.69-1.34a5.02 5.02 0 0 1-2.23 2.23l1.34 2.69a8.03 8.03 0 0 0 3.58-3.58zM6.42 2.84a8.03 8.03 0 0 0-3.58 3.58l2.69 1.34a5.02 5.02 0 0 1 2.23-2.23L6.42 2.84zM2.84 13.58a8.03 8.03 0 0 0 3.58 3.58l1.34-2.69a5.02 5.02 0 0 1-2.23-2.23l-2.69 1.34zM10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      </svg>
    ),
  },

  'information-outline': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
      </svg>
    ),
  },

  'information-solid': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z" />
      </svg>
    ),
  },

  'question-solid': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z" />
      </svg>
    ),
  },

  'traffic-cone': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M17.4 18H20v2H0v-2h2.6L8 0h4l5.4 18zm-3.2-4H5.8l-1.2 4h10.8l-1.2-4zm-2.4-8H8.2L7 10h6l-1.2-4z" />
      </svg>
    ),
  },

  'exclamation-outline': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
      </svg>
    ),
  },

  'exclamation-solid': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z" />
      </svg>
    ),
  },

  block: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm16.32-4.9L5.09 16.31A8 8 0 0 0 16.32 5.09zm-1.41-1.42A8 8 0 0 0 3.68 14.91L14.91 3.68z" />
      </svg>
    ),
  },

  'hand-stop': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M17 16a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4.01V4a1 1 0 0 1 1-1 1 1 0 0 1 1 1v6h1V2a1 1 0 0 1 1-1 1 1 0 0 1 1 1v8h1V1a1 1 0 1 1 2 0v9h1V2a1 1 0 0 1 1-1 1 1 0 0 1 1 1v13h1V9a1 1 0 0 1 1-1h1v8z" />
      </svg>
    ),
  },

  'checkmark-outline': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
      </svg>
    ),
  },

  'checkmark-solid': {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M17.1 2.9l-.2-.2c-4-3.9-10.4-3.7-14.2.2-3.8 4-3.7 10.3.2 14.1 3.9 3.7 10 3.7 13.9 0 4-3.8 4.1-10.1.3-14.1zM9 13.4L5.3 9.7l1.4-1.4L9 10.6l4.3-4.3 1.4 1.4L9 13.4z" />
      </svg>
    ),
  },

  checkmark: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    ),
  },

  badge: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M10 12a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-3a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm4 2.75V20l-4-4-4 4v-8.25a6.97 6.97 0 0 0 8 0z" />
      </svg>
    ),
  },

  trophy: {
    category: categories.notifications,
    svg: (
      <svg>
        <path d="M15 9a3 3 0 0 0 3-3h2a5 5 0 0 1-5.1 5 5 5 0 0 1-3.9 3.9V17l5 2v1H4v-1l5-2v-2.1A5 5 0 0 1 5.1 11H5a5 5 0 0 1-5-5h2a3 3 0 0 0 3 3V4H2v2H0V2h5V0h10v2h5v4h-2V4h-3v5z" />
      </svg>
    ),
  },

  /**
   * Viewing & UI
   */

  'view-show': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  'view-hide': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z" />
      </svg>
    ),
  },

  'view-tile': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M0 0h9v9H0V0zm2 2v5h5V2H2zm-2 9h9v9H0v-9zm2 2v5h5v-5H2zm9-13h9v9h-9V0zm2 2v5h5V2h-5zm-2 9h9v9h-9v-9zm2 2v5h5v-5h-5z" />
      </svg>
    ),
  },

  'view-list': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M0 3h20v2H0V3zm0 4h20v2H0V7zm0 4h20v2H0v-2zm0 4h20v2H0v-2z" />
      </svg>
    ),
  },

  'view-column': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M12 4H8v12h4V4zm2 0v12h4V4h-4zM6 4H2v12h4V4zM0 2h20v16H0V2z" />
      </svg>
    ),
  },

  'view-carousel': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M16 16v2H4v-2H0V4h4V2h12v2h4v12h-4zM14 5.5V4H6v12h8V5.5zm2 .5v8h2V6h-2zM4 6H2v8h2V6z" />
      </svg>
    ),
  },

  'show-sidebar': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M7 3H2v14h5V3zm2 0v14h9V3H9zM0 3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm3 1h3v2H3V4zm0 3h3v2H3V7zm0 3h3v2H3v-2z" />
      </svg>
    ),
  },

  'screen-full': {
    category: categories.viewing,
    svg: (
      <svg>
        <path d="M2.8 15.8L0 13v7h7l-2.8-2.8 4.34-4.32-1.42-1.42L2.8 15.8zM17.2 4.2L20 7V0h-7l2.8 2.8-4.34 4.32 1.42 1.42L17.2 4.2zm-1.4 13L13 20h7v-7l-2.8 2.8-4.32-4.34-1.42 1.42 4.33 4.33zM4.2 2.8L7 0H0v7l2.8-2.8 4.32 4.34 1.42-1.42L4.2 2.8z" />
      </svg>
    ),
  },

  'zoom-in': {
    category: categories.viewing,
    svg: (
      <svg>
        <path
          fillRule="evenodd"
          d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zM7 7V5h2v2h2v2H9v2H7V9H5V7h2z"
        />
      </svg>
    ),
  },

  'zoom-out': {
    category: categories.viewing,
    svg: (
      <svg>
        <path
          fillRule="evenodd"
          d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zM5 7h6v2H5V7z"
        />
      </svg>
    ),
  },

  /**
   * Visualizations
   */

  chart: {
    category: categories.charts,
    svg: (
      <svg>
        <path d="M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
      </svg>
    ),
  },

  'chart-bar': {
    category: categories.charts,
    svg: (
      <svg>
        <path d="M1 10h3v10H1V10zM6 0h3v20H6V0zm5 8h3v12h-3V8zm5-4h3v16h-3V4z" />
      </svg>
    ),
  },

  'chart-pie': {
    category: categories.charts,
    svg: (
      <svg>
        <path d="M19.95 11A10 10 0 1 1 9 .05V11h10.95zm-.08-2.6H11.6V.13a10 10 0 0 1 8.27 8.27z" />
      </svg>
    ),
  },

  /**
   * Date & Time
   */

  calendar: {
    category: categories.time,
    svg: (
      <svg>
        <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" />
      </svg>
    ),
  },

  'date-add': {
    category: categories.time,
    svg: (
      <svg>
        <path d="M15 2h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h2V0h2v2h6V0h2v2zM3 6v12h14V6H3zm6 5V9h2v2h2v2h-2v2H9v-2H7v-2h2z" />
      </svg>
    ),
  },

  watch: {
    category: categories.time,
    svg: (
      <svg>
        <path d="M11 9h2v2H9V7h2v2zm-5.82 6.08a6.98 6.98 0 0 1 0-10.16L6 0h8l.82 4.92a6.98 6.98 0 0 1 0 10.16L14 20H6l-.82-4.92zM10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" />
      </svg>
    ),
  },

  timer: {
    category: categories.time,
    svg: (
      <svg>
        <path d="M16.32 7.1A8 8 0 1 1 9 4.06V2h2v2.06c1.46.18 2.8.76 3.9 1.62l1.46-1.46 1.42 1.42-1.46 1.45zM10 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12zM7 0h6v2H7V0zm5.12 8.46l1.42 1.42L10 13.4 8.59 12l3.53-3.54z" />
      </svg>
    ),
  },

  time: {
    category: categories.time,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
      </svg>
    ),
  },

  hourglass: {
    category: categories.time,
    svg: (
      <svg>
        <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
      </svg>
    ),
  },

  /**
   * Locations
   */

  network: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm7.75-8a8.01 8.01 0 0 0 0-4h-3.82a28.81 28.81 0 0 1 0 4h3.82zm-.82 2h-3.22a14.44 14.44 0 0 1-.95 3.51A8.03 8.03 0 0 0 16.93 14zm-8.85-2h3.84a24.61 24.61 0 0 0 0-4H8.08a24.61 24.61 0 0 0 0 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 0 1 0-4H2.25a8.01 8.01 0 0 0 0 4zm.82 2a8.03 8.03 0 0 0 4.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 0 0-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 0 0 3.07 6z" />
      </svg>
    ),
  },

  globe: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-2.25a8 8 0 0 0 4-2.46V9a2 2 0 0 1-2-2V3.07a7.95 7.95 0 0 0-3-1V3a2 2 0 0 1-2 2v1a2 2 0 0 1-2 2v2h3a2 2 0 0 1 2 2v5.75zm-4 0V15a2 2 0 0 1-2-2v-1h-.5A1.5 1.5 0 0 1 4 10.5V8H2.25A8.01 8.01 0 0 0 8 17.75z" />
      </svg>
    ),
  },

  'travel-case': {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M14 5h2v14H4V5h2V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1zm3 0h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V5zM3 5v14H2a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h1zm5-1v1h4V4H8z" />
      </svg>
    ),
  },

  translate: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M7.41 9l2.24 2.24-.83 2L6 10.4l-3.3 3.3-1.4-1.42L4.58 9l-.88-.88c-.53-.53-1-1.3-1.3-2.12h2.2c.15.28.33.53.51.7l.89.9.88-.88C7.48 6.1 8 4.84 8 4H0V2h5V0h2v2h5v2h-2c0 1.37-.74 3.15-1.7 4.12L7.4 9zm3.84 8L10 20H8l5-12h2l5 12h-2l-1.25-3h-5.5zm.83-2h3.84L14 10.4 12.08 15z" />
      </svg>
    ),
  },

  map: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M0 0l6 4 8-4 6 4v16l-6-4-8 4-6-4V0zm7 6v11l6-3V3L7 6z" />
      </svg>
    ),
  },

  location: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
      </svg>
    ),
  },

  target: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M17.94 11H13V9h4.94A8 8 0 0 0 11 2.06V7H9V2.06A8 8 0 0 0 2.06 9H7v2H2.06A8 8 0 0 0 9 17.94V13h2v4.94A8 8 0 0 0 17.94 11zM10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20z" />
      </svg>
    ),
  },

  library: {
    category: categories.locations,
    svg: (
      <svg>
        <path d="M0 6l10-6 10 6v2H0V6zm0 12h20v2H0v-2zm2-2h16v2H2v-2zm0-8h4v8H2V8zm6 0h4v8H8V8zm6 0h4v8h-4V8z" />
      </svg>
    ),
  },

  /**
   * Payments & Shopping
   */

  'shopping-cart': {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M4 2h16l-3 9H4a1 1 0 1 0 0 2h13v2H4a3 3 0 0 1 0-6h.33L3 5 2 2H0V0h3a1 1 0 0 1 1 1v1zm1 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm10 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  shopping: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M16 6v2h2l2 12H0L2 8h2V6a6 6 0 1 1 12 0zm-2 0a4 4 0 1 0-8 0v2h8V6zM4 10v2h2v-2H4zm10 0v2h2v-2h-2z" />
      </svg>
    ),
  },

  shop: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z" />
      </svg>
    ),
  },

  apparel: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M7 0H6L0 3v6l4-1v12h12V8l4 1V3l-6-3h-1a3 3 0 0 1-6 0z" />
      </svg>
    ),
  },

  gift: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M14.83 4H20v6h-1v10H1V10H0V4h5.17A3 3 0 0 1 10 .76 3 3 0 0 1 14.83 4zM8 10H3v8h5v-8zm4 0v8h5v-8h-5zM8 6H2v2h6V6zm4 0v2h6V6h-6zM8 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
    ),
  },

  ticket: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M20 12v5H0v-5a2 2 0 1 0 0-4V3h20v5a2 2 0 1 0 0 4zM3 5v10h14V5H3zm7 7.08l-2.92 2.04L8.1 10.7 5.27 8.56l3.56-.08L10 5.12l1.17 3.36 3.56.08-2.84 2.15 1.03 3.4L10 12.09z" />
      </svg>
    ),
  },

  coupon: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M19 4H5.2v.2c0 .1-.1.2-.2.2s-.3 0-.3-.2V4H1c-.6 0-1 .4-1 1v3c1.1 0 2 .9 2 2s-.9 2-2 2v3c0 .6.4 1 1 1h3.7v-.2c0-.1.1-.2.2-.2s.2.1.2.2v.2H19c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zM5.2 14.8c0 .1-.1.2-.2.2s-.2-.1-.2-.2v-.5c-.1-.2 0-.3.2-.3s.2.1.2.2v.6zm0-1.5c0 .1-.1.2-.2.2s-.2-.1-.2-.2v-.5c0-.1.1-.2.2-.2s.2.1.2.2v.5zm0-1.5c0 .1-.1.2-.2.2s-.2-.1-.2-.2v-.5c-.1-.2 0-.3.2-.3s.2.1.2.2v.6zm0-1.5c0 .1-.1.2-.2.2s-.2-.1-.2-.2v-.5c0-.1.1-.2.2-.2s.2.1.2.2v.5zm0-1.5c0 .1-.1.2-.2.2s-.3-.1-.3-.2v-.5c0-.2.1-.3.3-.3s.2.1.2.2v.6zm0-1.5c0 .1-.1.2-.2.2s-.3-.1-.3-.2v-.5c0-.1.1-.2.2-.2s.2.1.2.2v.5zm0-1.5c0 .1-.1.2-.2.2s-.3-.1-.3-.2v-.5c0-.2.1-.3.3-.3s.2.1.2.2v.6z" />
      </svg>
    ),
  },

  'credit-card': {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M18 6V4H2v2h16zm0 4H2v6h16v-6zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm4 8h4v2H4v-2z" />
      </svg>
    ),
  },

  wallet: {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M0 4c0-1.1.9-2 2-2h15a1 1 0 0 1 1 1v1H2v1h17a1 1 0 0 1 1 1v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm16.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
      </svg>
    ),
  },

  'currency-dollar': {
    category: categories.shopping,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z" />
      </svg>
    ),
  },

  /**
   * Devices & Electronics
   */

  'computer-desktop': {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M7 17H2a2 2 0 0 1-2-2V2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2h-5l4 2v1H3v-1l4-2zM2 2v11h16V2H2z" />
      </svg>
    ),
  },

  'computer-laptop': {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M18 16h2v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1h2V4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v12zM4 4v9h12V4H4zm4 11v1h4v-1H8z" />
      </svg>
    ),
  },

  power: {
    category: categories.devices,
    svg: (
      <svg>
        <path
          fillRule="evenodd"
          d="M4.16 4.16l1.42 1.42A6.99 6.99 0 0 0 10 18a7 7 0 0 0 4.42-12.42l1.42-1.42a9 9 0 1 1-11.69 0zM9 0h2v8H9V0z"
        />
      </svg>
    ),
  },

  code: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M.7 9.3l4.8-4.8 1.4 1.42L2.84 10l4.07 4.07-1.41 1.42L0 10l.7-.7zm18.6 1.4l.7-.7-5.49-5.49-1.4 1.42L17.16 10l-4.07 4.07 1.41 1.42 4.78-4.78z" />
      </svg>
    ),
  },

  plugin: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M20 14v4a2 2 0 0 1-2 2h-4v-2a2 2 0 0 0-2-2 2 2 0 0 0-2 2v2H6a2 2 0 0 1-2-2v-4H2a2 2 0 0 1-2-2 2 2 0 0 1 2-2h2V6c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2 2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v4h-2a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2z" />
      </svg>
    ),
  },

  printer: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M4 16H0V6h20v10h-4v4H4v-4zm2-4v6h8v-6H6zM4 0h12v5H4V0zM2 8v2h2V8H2zm4 0v2h2V8H6z" />
      </svg>
    ),
  },

  camera: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M0 6c0-1.1.9-2 2-2h3l2-2h6l2 2h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm10 10a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
      </svg>
    ),
  },

  photo: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm11 9l-3-3-6 6h16l-5-5-2 2zm4-4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
      </svg>
    ),
  },

  'mobile-devices': {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M17 6V5h-2V2H3v14h5v4h3.25H11a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6zm-5.75 14H3a2 2 0 0 1-2-2V2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5.75zM11 8v8h6V8h-6zm3 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
    ),
  },

  tablet: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 0v14h12V2H4zm6 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
    ),
  },

  phone: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z" />
      </svg>
    ),
  },

  'dial-pad': {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M5 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  calculator: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm3 1v2h10V3H5zm0 4v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2zm-8 4v2h2v-2H5zm4 0v2h2v-2H9zm4 0v6h2v-6h-2zm-8 4v2h2v-2H5zm4 0v2h2v-2H9z" />
      </svg>
    ),
  },

  radio: {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M20 9v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h13.8L.74 1.97 1.26.03 20 5.06V9zm-5 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM2 8v2h16V8H2zm1.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm6.5-1a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    ),
  },

  'light-bulb': {
    category: categories.devices,
    svg: (
      <svg>
        <path d="M7 13.33a7 7 0 1 1 6 0V16H7v-2.67zM7 17h6v1.5c0 .83-.67 1.5-1.5 1.5h-3A1.5 1.5 0 0 1 7 18.5V17zm2-5.1V14h2v-2.1a5 5 0 1 0-2 0z" />
      </svg>
    ),
  },

  /**
   * Users & Communities
   */

  user: {
    category: categories.users,
    svg: (
      <svg>
        <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z" />
      </svg>
    ),
  },

  'user-add': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M2 6H0v2h2v2h2V8h2V6H4V4H2v2zm7 0a3 3 0 0 1 6 0v2a3 3 0 0 1-6 0V6zm11 9.14A15.93 15.93 0 0 0 12 13c-2.91 0-5.65.78-8 2.14V18h16v-2.86z" />
      </svg>
    ),
  },

  'user-solid-circle': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7 6v2a3 3 0 1 0 6 0V6a3 3 0 1 0-6 0zm-3.65 8.44a8 8 0 0 0 13.3 0 15.94 15.94 0 0 0-13.3 0z" />
      </svg>
    ),
  },

  'user-solid-square': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M0 2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm7 4v2a3 3 0 1 0 6 0V6a3 3 0 1 0-6 0zm11 9.14A15.93 15.93 0 0 0 10 13c-2.91 0-5.65.78-8 2.14V18h16v-2.86z" />
      </svg>
    ),
  },

  'user-group': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 0 1 7 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 1 1-1.33 7.76 5.96 5.96 0 0 0 0-7.52C12.1.1 12.53 0 13 0z" />
      </svg>
    ),
  },

  conversation: {
    category: categories.users,
    svg: (
      <svg>
        <path d="M17 11v3l-3-3H8a2 2 0 0 1-2-2V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-1zm-3 2v2a2 2 0 0 1-2 2H6l-3 3v-3H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h2v3a4 4 0 0 0 4 4h6z" />
      </svg>
    ),
  },

  'chat-bubble-dots': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z" />
      </svg>
    ),
  },

  'at-symbol': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M13.6 13.47A4.99 4.99 0 0 1 5 10a5 5 0 0 1 8-4V5h2v6.5a1.5 1.5 0 0 0 3 0V10a8 8 0 1 0-4.42 7.16l.9 1.79A10 10 0 1 1 20 10h-.18.17v1.5a3.5 3.5 0 0 1-6.4 1.97zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      </svg>
    ),
  },

  envelope: {
    category: categories.users,
    svg: (
      <svg>
        <path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z" />
      </svg>
    ),
  },

  send: {
    category: categories.users,
    svg: (
      <svg>
        <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
      </svg>
    ),
  },

  reply: {
    category: categories.users,
    svg: (
      <svg>
        <path d="M15 17v-2.99A4 4 0 0 0 11 10H8v5L2 9l6-6v5h3a6 6 0 0 1 6 6v3h-2z" />
      </svg>
    ),
  },

  'reply-all': {
    category: categories.users,
    svg: (
      <svg>
        <path d="M18 17v-2.99A4 4 0 0 0 14 10h-3v5L5 9l6-6v5h3a6 6 0 0 1 6 6v3h-2zM6 6V3L0 9l6 6v-3L3 9l3-3z" />
      </svg>
    ),
  },

  attachment: {
    category: categories.users,
    svg: (
      <svg>
        <path d="M15 3H7a7 7 0 1 0 0 14h8v-2H7A5 5 0 0 1 7 5h8a3 3 0 0 1 0 6H7a1 1 0 0 1 0-2h8V7H7a3 3 0 1 0 0 6h8a5 5 0 0 0 0-10z" />
      </svg>
    ),
  },

  /**
   * Reactions & Emotions
   */

  'thumbs-up': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M11 0h1v3l3 7v8a2 2 0 0 1-2 2H5c-1.1 0-2.31-.84-2.7-1.88L0 12v-2a2 2 0 0 1 2-2h7V2a2 2 0 0 1 2-2zm6 10h3v10h-3V10z" />
      </svg>
    ),
  },

  'thumbs-down': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M11 20a2 2 0 0 1-2-2v-6H2a2 2 0 0 1-2-2V8l2.3-6.12A3.11 3.11 0 0 1 5 0h8a2 2 0 0 1 2 2v8l-3 7v3h-1zm6-10V0h3v10h-3z" />
      </svg>
    ),
  },

  'mood-happy-outline': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" />
      </svg>
    ),
  },

  'mood-happy-solid': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM6.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm7 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm2.16 3H4.34a6 6 0 0 0 11.32 0z" />
      </svg>
    ),
  },

  'mood-neutral-outline': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM7 13h6a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z" />
      </svg>
    ),
  },

  'mood-neutral-solid': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM6.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm7 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM7 13a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2H7z" />
      </svg>
    ),
  },

  'mood-sad-outline': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 6H4.34a6 6 0 0 1 11.32 0z" />
      </svg>
    ),
  },

  'mood-sad-solid': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM6.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm7 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm2.16 6a6 6 0 0 0-11.32 0h11.32z" />
      </svg>
    ),
  },

  heart: {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
      </svg>
    ),
  },

  hot: {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 0s8 7.58 8 12a8 8 0 1 1-16 0c0-1.5.91-3.35 2.12-5.15A3 3 0 0 0 10 6V0zM8 0a3 3 0 1 0 0 6V0z" />
      </svg>
    ),
  },

  'star-full': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
      </svg>
    ),
  },

  'star-outline': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M10,0.5 L13.0901062,6.75456053 L20,7.75760088 L14.9994743,12.6257601 L16.1802124,19.5 L10,16.2545605 L3.81978761,19.5 L5.00052571,12.6257601 L0,7.75760088 L6.90989381,6.75456053 L10,0.5 Z M10,4.5 L8.12726371,8.47741284 L4,9.10606426 L6.98494746,12.155268 L6.27374831,16.5 L10,14.4467447 L13.7262517,16.5 L13.0150525,12.155268 L16,9.10606426 L11.8727363,8.47741284 L10,4.5 Z" />
      </svg>
    ),
  },

  pin: {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M11 12h6v-1l-3-1V2l3-1V0H3v1l3 1v8l-3 1v1h6v7l1 1 1-1v-7z" />
      </svg>
    ),
  },

  bookmark: {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2z" />
      </svg>
    ),
  },

  'bookmark-outline-add': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2zm2 0v15l6-3 6 3V2H4zm5 5V5h2v2h2v2h-2v2H9V9H7V7h2z" />
      </svg>
    ),
  },

  'bookmark-outline': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2zm2 0v15l6-3 6 3V2H4z" />
      </svg>
    ),
  },

  repost: {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M5 4a2 2 0 0 0-2 2v6H0l4 4 4-4H5V6h7l2-2H5zm10 4h-3l4-4 4 4h-3v6a2 2 0 0 1-2 2H6l2-2h7V8z" />
      </svg>
    ),
  },

  share: {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M4 10c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-8zm2 0v8h8v-8h-2V8H8v2H6zm3-6.17V16h2V3.83l3.07 3.07 1.42-1.41L10 0l-.7.7-4.8 4.8 1.42 1.4L9 3.84z" />
      </svg>
    ),
  },

  'share-alt': {
    category: categories.reactions,
    svg: (
      <svg>
        <path d="M5.08 12.16A2.99 2.99 0 0 1 0 10a3 3 0 0 1 5.08-2.16l8.94-4.47a3 3 0 1 1 .9 1.79L5.98 9.63a3.03 3.03 0 0 1 0 .74l8.94 4.47A2.99 2.99 0 0 1 20 17a3 3 0 1 1-5.98-.37l-8.94-4.47z" />
      </svg>
    ),
  },

  /**
   * Editing & WYSIWYGs
   */

  compose: {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
      </svg>
    ),
  },

  clipboard: {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M7.03 2.6a3 3 0 0 1 5.94 0L15 3v1h1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1V3l2.03-.4zM5 6H4v12h12V6h-1v1H5V6zm5-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
    ),
  },

  duplicate: {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2zm4 4v-2h2v2h2v2H8v2H6v-2H4v-2h2z" />
      </svg>
    ),
  },

  'edit-copy': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z" />
      </svg>
    ),
  },

  'edit-cut': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M9.77 11.5l5.34 3.91c.44.33 1.24.59 1.79.59H20L6.89 6.38A3.5 3.5 0 1 0 5.5 8.37L7.73 10 5.5 11.63a3.5 3.5 0 1 0 1.38 1.99l2.9-2.12zM3.5 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM15.1 4.59A3.53 3.53 0 0 1 16.9 4H20l-7.5 5.5L10.45 8l4.65-3.41z" />
      </svg>
    ),
  },

  'edit-paste': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M10.5 20H2a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1V3l2.03-.4a3 3 0 0 1 5.94 0L13 3v1h1a2 2 0 0 1 2 2v1h-2V6h-1v1H3V6H2v12h5v2h3.5zM8 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm2 4h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm0 2v8h8v-8h-8z" />
      </svg>
    ),
  },

  'edit-crop': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M14 16H6a2 2 0 0 1-2-2V6H0V4h4V0h2v14h14v2h-4v4h-2v-4zm0-3V6H7V4h7a2 2 0 0 1 2 2v7h-2z" />
      </svg>
    ),
  },

  'text-decoration': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M12 5h-2v12H8V3h8v2h-2v12h-2V5zM8 3a4 4 0 1 0 0 8V3z" />
      </svg>
    ),
  },

  'stroke-width': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M0 0h20v5H0V0zm0 7h20v4H0V7zm0 6h20v3H0v-3zm0 5h20v2H0v-2z" />
      </svg>
    ),
  },

  'format-text-size': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M16 9v8h-2V9h-4V7h10v2h-4zM8 5v12H6V5H0V3h15v2H8z" />
      </svg>
    ),
  },

  'format-bold': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M3 19V1h8a5 5 0 0 1 3.88 8.16A5.5 5.5 0 0 1 11.5 19H3zm7.5-8H7v5h3.5a2.5 2.5 0 1 0 0-5zM7 4v4h3a2 2 0 1 0 0-4H7z" />
      </svg>
    ),
  },

  'format-italic': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M8 1h9v2H8V1zm3 2h3L8 17H5l6-14zM2 17h9v2H2v-2z" />
      </svg>
    ),
  },

  'format-underline': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M16 9A6 6 0 1 1 4 9V1h3v8a3 3 0 0 0 6 0V1h3v8zM2 17h16v2H2v-2z" />
      </svg>
    ),
  },

  list: {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 4h2v2H1V4zm4 0h14v2H5V4zM1 9h2v2H1V9zm4 0h14v2H5V9zm-4 5h2v2H1v-2zm4 0h14v2H5v-2z" />
      </svg>
    ),
  },

  'list-add': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M15 9h-3v2h3v3h2v-3h3V9h-3V6h-2v3zM0 3h10v2H0V3zm0 8h10v2H0v-2zm0-4h10v2H0V7zm0 8h10v2H0v-2z" />
      </svg>
    ),
  },
  'align-center': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM4 5h12v2H4V5zm0 8h12v2H4v-2z" />
      </svg>
    ),
  },

  'align-justified': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM1 5h18v2H1V5zm0 8h18v2H1v-2z" />
      </svg>
    ),
  },

  'align-left': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM1 5h12v2H1V5zm0 8h12v2H1v-2z" />
      </svg>
    ),
  },

  'align-right': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM7 5h12v2H7V5zm0 8h12v2H7v-2z" />
      </svg>
    ),
  },

  'indent-decrease': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 1h18v2H1V1zm6 8h12v2H7V9zm-6 8h18v2H1v-2zM7 5h12v2H7V5zm0 8h12v2H7v-2zM5 6v8l-4-4 4-4z" />
      </svg>
    ),
  },

  'indent-increase': {
    category: categories.editing,
    svg: (
      <svg>
        <path d="M1 1h18v2H1V1zm6 8h12v2H7V9zm-6 8h18v2H1v-2zM7 5h12v2H7V5zm0 8h12v2H7v-2zM1 6l4 4-4 4V6z" />
      </svg>
    ),
  },

  /**
   * File management
   */

  download: {
    category: categories.files,
    svg: (
      <svg>
        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
      </svg>
    ),
  },

  upload: {
    category: categories.files,
    svg: (
      <svg>
        <path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" />
      </svg>
    ),
  },

  'cloud-upload': {
    category: categories.files,
    svg: (
      <svg>
        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
      </svg>
    ),
  },

  cloud: {
    category: categories.files,
    svg: (
      <svg>
        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1z" />
      </svg>
    ),
  },

  'save-disk': {
    category: categories.files,
    svg: (
      <svg>
        <path d="M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z" />
      </svg>
    ),
  },

  folder: {
    category: categories.files,
    svg: (
      <svg>
        <path d="M0 4c0-1.1.9-2 2-2h7l2 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
      </svg>
    ),
  },

  'folder-outline': {
    category: categories.files,
    svg: (
      <svg>
        <path d="M0 4c0-1.1.9-2 2-2h7l2 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2 2v10h16V6H2z" />
      </svg>
    ),
  },

  'folder-outline-add': {
    category: categories.files,
    svg: (
      <svg>
        <path d="M0 4c0-1.1.9-2 2-2h7l2 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2 2v10h16V6H2zm7 4V8h2v2h2v2h-2v2H9v-2H7v-2h2z" />
      </svg>
    ),
  },

  document: {
    category: categories.files,
    svg: (
      <svg>
        <path d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z" />
      </svg>
    ),
  },

  'document-add': {
    category: categories.files,
    svg: (
      <svg>
        <path d="M9 10V8h2v2h2v2h-2v2H9v-2H7v-2h2zm-5 8h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z" />
      </svg>
    ),
  },
};

/**
 * Aliases
 *
 * These help provide a smooth dev-friendly transition from our old ReX iconfont to our new library.
 * Generally, we don't want to go overboard here - let's keep this small & to commonly used icons.
 */

icons.check = { category: categories.alias, svg: icons.checkmark.svg };
icons.delete = { category: categories.alias, svg: icons.trash.svg };
icons.save = { category: categories.alias, svg: icons['save-disk'].svg };

export { icons, categories };
