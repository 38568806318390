import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import HelperText from 'components/HelperText';

import './InputLabelWithIcon.less';

const InputLabelWithIcon = props => {
  const { helperText, iconType, iconTitle, iconClasses } = props;

  return (
    <div className="InputLabelWithIcon" aria-live="polite" role="status">
      <Icon title={iconTitle} className={iconClasses} type={iconType} />
      <HelperText>{helperText}</HelperText>
    </div>
  );
};

InputLabelWithIcon.propTypes = {
  /**
   * Helper text that can be used to provide context or
   * show dynamic validation error or success messages
   */
  helperText: PropTypes.string,

  /**
   * Icon type
   */
  iconType: PropTypes.string,

  /**
   * Title for the Icon
   */
  iconTitle: PropTypes.string,

  /**
   * Classes for Icons
   */
  iconClasses: PropTypes.string,
};

InputLabelWithIcon.defaultProps = {
  helperText: '',
  iconType: '',
  iconTitle: '',
  iconClasses: '',
};

export default InputLabelWithIcon;
