const TRUNCATE_HEADER_OPTIONS = {
  mobile: {
    lineClamp: 4,
    lineHeight: 2.6,
  },
  laptop: {
    lineClamp: 3,
    lineHeight: 1.75,
  },
  desktop: {
    lineClamp: 2,
    lineHeight: 2.6,
  },
};

export default TRUNCATE_HEADER_OPTIONS;
