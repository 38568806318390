import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './DatePickerNav.less';

const messages = defineMessages({
  ariaPreviousMonth: {
    defaultMessage: 'Move backward to switch to the previous month',
    description: 'DatePicker previous month ariaLabel',
  },
  ariaNextMonth: {
    defaultMessage: 'Move forward to switch to the next month',
    description: 'DatePicker next month ariaLabel',
  },
  ariaPreviousYear: {
    defaultMessage: 'Move backward to switch to the previous year',
    description: 'DatePicker previous year ariaLabel',
  },
  ariaNextYear: {
    defaultMessage: 'Move forward to switch to the next year',
    description: 'DatePicker next year ariaLabel',
  },
});

const DatePickerNav = ({ month, onMonthSelect, onYearSelect, intl }) => (
  <div className="DatePicker__nav">
    <div className="DatePicker__monthNav">
      <button
        type="button"
        aria-label={intl.formatMessage(messages.ariaPreviousMonth)}
        className="DatePicker__navArrow DatePicker__navArrow--previous"
        onClick={() => onMonthSelect(month, month.month() - 1)}
      />
      <div className="DatePicker__monthTitle">{month.format('MMMM')}</div>
      <button
        type="button"
        aria-label={intl.formatMessage(messages.ariaNextMonth)}
        className="DatePicker__navArrow DatePicker__navArrow--next"
        onClick={() => onMonthSelect(month, month.month() + 1)}
      />
    </div>

    <div className="DatePicker__yearNav">
      <button
        type="button"
        aria-label={intl.formatMessage(messages.ariaPreviousYear)}
        className="DatePicker__navArrow DatePicker__navArrow--previous"
        onClick={() => onYearSelect(month, month.year() - 1)}
      />
      {month.year()}
      <button
        type="button"
        aria-label={intl.formatMessage(messages.ariaNextYear)}
        className="DatePicker__navArrow DatePicker__navArrow--next"
        onClick={() => onYearSelect(month, month.year() + 1)}
      />
    </div>
  </div>
);

DatePickerNav.propTypes = {
  month: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(DatePickerNav);
