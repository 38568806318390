import React from 'react';
import PropTypes from 'prop-types';

const DefaultTag = props => {
  const { children, className } = props;

  return (
    <div className={className}>
      <div className="Tag__content">{children}</div>
    </div>
  );
};

DefaultTag.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string.isRequired,
};

export default DefaultTag;
