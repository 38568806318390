import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { typeOfInstance } from 'components/helpers';

import TableHeaderCell from './TableHeaderCell';
import './TableRow.less';

const TableHeader = ({ children, isCondensed, className }) => {
  const classes = classnames('Table__TableRow', className, {
    'Table__TableRow--condensed': isCondensed,
  });

  // Note: the actual <thead> tag is set by the parent Table
  return <tr className={classes}>{children}</tr>;
};

TableHeader.propTypes = {
  /**
   * Child TableHeaderCell nodes
   */
  children: typeOfInstance(TableHeaderCell).isRequired,

  /**
   * Renders a more condensed variant
   */
  isCondensed: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  isCondensed: false,
  className: '',
};

export default TableHeader;
