import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'components/Card';
import './AppSwitcher.less';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  DottedMenu,
  AppSwitcherCardHeader,
  AppSwitcherCardBody,
  AppSwitcherCardFooter,
} from './components';

const AppSwitcher = React.memo(
  ({
    className,
    header,
    subHeader,
    navigation,
    footer,
    isLoading,
    loadingSpinnerColor,
    ...rest
  }) => {
    const cardWrapperRef = useRef(null);
    const dotMenuWrapperRef = useRef(null);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const appSwitcherHandler = e => {
      e.preventDefault();
      setModalIsOpen(prevState => !prevState);
    };

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        const isOutsideClick =
          cardWrapperRef.current &&
          !cardWrapperRef.current.contains(event.target) &&
          dotMenuWrapperRef.current &&
          !dotMenuWrapperRef.current.contains(event.target);

        if (modalIsOpen && isOutsideClick) {
          setModalIsOpen(false);
        }
      }
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [cardWrapperRef, modalIsOpen, dotMenuWrapperRef]);

    const closeWindow = closeOnClick => {
      /**
       * Close modal if closeOnClick wasn't defined or its value is true
       */
      if (
        modalIsOpen &&
        (typeof closeOnClick === 'undefined' || closeOnClick)
      ) {
        setModalIsOpen(false);
      }
    };

    return (
      <div
        {...rest}
        aria-hidden="true"
        className={classnames(className, 'AppSwitcher__wrapper')}
        onClick={appSwitcherHandler}
      >
        <div ref={dotMenuWrapperRef}>
          <DottedMenu modalIsOpen={modalIsOpen} />
        </div>
        <div
          aria-hidden="true"
          ref={cardWrapperRef}
          className={`AppSwitcher__card absolute z-10 pin-r-0 pin-t-10 ${
            modalIsOpen ? 'block' : 'hidden'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <Card hasMobileRow>
            <AppSwitcherCardHeader header={header} subHeader={subHeader} />
            {navigation.length || isLoading ? (
              <AppSwitcherCardBody
                closeWindow={closeWindow}
                navigation={navigation}
                isLoading={isLoading}
                loadingSpinnerColor={loadingSpinnerColor}
              />
            ) : (
              <div />
            )}
            <AppSwitcherCardFooter closeWindow={closeWindow} footer={footer} />
          </Card>
        </div>
      </div>
    );
  },
);

AppSwitcher.propTypes = {
  /**
   * Additional classes to render
   */
  className: PropTypes.string,

  /**
   * Sets loader indicator color
   * (red, white or green)
   *
   */
  loadingSpinnerColor: PropTypes.oneOf(['red', 'white', 'green']),

  /**
   * Sets loader visibility
   */
  isLoading: PropTypes.bool,

  /**
   * Sets header
   */
  header: PropTypes.node,

  /**
   * Sets subheader
   */
  subHeader: PropTypes.node,

  /**
   * Sets footer elements
   */
  footer: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      node: PropTypes.node.isRequired,
      closeOnClick: PropTypes.bool,
    }),
  ),

  /**
   * Sets navigation elements
   */
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      closeOnClick: PropTypes.bool,
      key: PropTypes.string,
      href: PropTypes.string,
      to: PropTypes.string,
      header: PropTypes.node.isRequired,
      description: PropTypes.node.isRequired,
      className: PropTypes.string,
      icon: PropTypes.shape,
    }),
  ),
};

AppSwitcher.defaultProps = {
  className: '',
  loadingSpinnerColor: 'white',
  isLoading: false,
  header: '',
  subHeader: '',
  footer: [],
  navigation: [],
};

export default AppSwitcher;
