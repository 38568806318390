import React from 'react';
import PropTypes from 'prop-types';

import './CardFooter.less';

const CardFooter = props => {
  const { children } = props;

  return <div className="CardFooter">{children}</div>;
};

CardFooter.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,
};

export default CardFooter;
