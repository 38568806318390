import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ButtonGroup.less';

const ButtonGroup = props => {
  const { children, isSecondary, isSmall, className } = props;
  const classes = classnames('ButtonGroup', className);

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { isSecondary, isSmall }),
  );

  return <div className={classes}>{childrenWithProps}</div>;
};

ButtonGroup.propTypes = {
  /**
   * Child Button nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Renders secondary Buttons
   */
  isSecondary: PropTypes.bool,

  /**
   * Renders small Buttons
   */
  isSmall: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

ButtonGroup.defaultProps = {
  isSmall: false,
  isSecondary: false,
  className: '',
};

export default ButtonGroup;
