import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import './Thumbnail.less';

const Thumbnail = ({ file, showImageThumbnail = false }) => {
  const [src, setSrc] = useState('');
  const showThumbnail = showImageThumbnail && file.type.includes('image');

  const thumbnailClasses = classnames('Thumbnail', {
    Thumbnail__icon: !showThumbnail,
    Thumbnail__image: showThumbnail,
  });

  useEffect(() => {
    if (showThumbnail) {
      setSrc(URL.createObjectURL(file));
    }
  }, []);

  useEffect(() => {
    return () => {
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  }, [src]);

  return (
    <div className={thumbnailClasses}>
      {showThumbnail ? (
        <img src={src} alt={file.name} />
      ) : (
        <Icon type="document" className="h-8 w-8" />
      )}
    </div>
  );
};

Thumbnail.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  showImageThumbnail: PropTypes.bool,
};

Thumbnail.defaultProps = {
  showImageThumbnail: false,
};

export default Thumbnail;
