import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Heading from 'components/Heading';

import './PageTitle.less';

const PageTitle = props => {
  const { children, className, ...rest } = props;

  const classes = classnames('PageTitle', className);

  return (
    <div className={classes}>
      <Heading level={3} {...rest}>
        {children}
      </Heading>
    </div>
  );
};

PageTitle.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

PageTitle.defaultProps = {
  className: '',
};

export default PageTitle;
