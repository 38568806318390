import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Grid.less';

const Grid = ({ children, gutter, className, ...rest }) => {
  const classes = classnames('Grid', className, {
    [`-mx-${gutter}`]: gutter,
  });

  function renderChildren() {
    if (!gutter) return children;

    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;
      return React.cloneElement(child, { gutter });
    });
  }

  return (
    <div {...rest} className={classes}>
      {renderChildren()}
    </div>
  );
};

/**
 * Unfortunately, react-styleguidist/docgen does not currently properly
 * parse arrays parsed from Object.keys() or imported from other files,
 * and will instead print a single unhelpful variable name instead of
 * an oneOf enum list. To get around this, we need to manually list
 * paddings for now instead of pulling it from our tailwind.js config.
 *
 * @see https://github.com/styleguidist/react-styleguidist/issues/986
 * - it's possible this may be fixed in an upcoming release.
 */
const paddings = ['0', '1', '2', '3', '4', '6', '8', '10', '12', 'px'];

Grid.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Defines a gutter padding class.
   */
  gutter: PropTypes.oneOf(paddings),

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Grid.defaultProps = {
  gutter: null,
  className: '',
};

export default Grid;
