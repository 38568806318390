import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ModalFooter.less';

const ModalFooter = props => {
  const { children, className, ...rest } = props;

  const classes = classnames('ModalFooter', className, {
    'ModalFooter--hasChildren': children,
  });

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

ModalFooter.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

ModalFooter.defaultProps = {
  children: null,
  className: '',
};

export default ModalFooter;
