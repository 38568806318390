import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ReactResizeDetector from 'react-resize-detector';

import './ScrollIndicator.less';

class ScrollIndicator extends PureComponent {
  static propTypes = {
    /**
     * Scroll direction to show shadow indicators for
     */
    direction: PropTypes.oneOf(['horizontal', 'vertical', 'both']).isRequired,

    /**
     * Scrolling child component
     */
    children: PropTypes.node.isRequired,

    /**
     * Additional classes to apply, e.g. max-h-screen
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      isOverflowingX: false,
      isAtLeftBoundary: true,
      isAtRightBoundary: false,
      isOverflowingY: false,
      isAtTopBoundary: true,
      isAtBottomBoundary: false,
    };

    this.scrollWrapper = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.checkOverflow();
  }

  checkOverflow() {
    /**
     * @see https://stackoverflow.com/questions/9333379/javascript-css-check-if-overflow
     */
    const { direction } = this.props;
    const wrapper = this.scrollWrapper.current;

    if (direction === 'horizontal' || direction === 'both') {
      const { scrollWidth, clientWidth } = wrapper;
      const isOverflowingX = scrollWidth > clientWidth;

      this.setState({ isOverflowingX });
    }
    if (direction === 'vertical' || direction === 'both') {
      const { scrollHeight, clientHeight } = wrapper;
      const isOverflowingY = scrollHeight > clientHeight;

      this.setState({ isOverflowingY });
    }
  }

  handleScroll() {
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements
     * @TODO: Consider adding a debounce helper for performance
     */
    const { direction } = this.props;
    const wrapper = this.scrollWrapper.current;

    if (direction === 'horizontal' || direction === 'both') {
      const { scrollLeft, scrollWidth, clientWidth } = wrapper;

      const isAtLeftBoundary = scrollLeft === 0;
      const isAtRightBoundary = scrollWidth - scrollLeft === clientWidth;

      this.setState({ isAtLeftBoundary, isAtRightBoundary });
    }
    if (direction === 'vertical' || direction === 'both') {
      const { scrollTop, scrollHeight, clientHeight } = wrapper;

      const isAtTopBoundary = scrollTop === 0;
      const isAtBottomBoundary = scrollHeight - scrollTop === clientHeight;

      this.setState({ isAtTopBoundary, isAtBottomBoundary });
    }
  }

  render() {
    const { direction, children, className, ...rest } = this.props;
    const {
      isOverflowingX,
      isAtRightBoundary,
      isAtLeftBoundary,
      isOverflowingY,
      isAtTopBoundary,
      isAtBottomBoundary,
    } = this.state;

    const classes = classnames('ScrollWrapper', className, {
      'ScrollWrapper--both': direction === 'both',
      'ScrollWrapper--horizontal': direction === 'horizontal',
      'ScrollWrapper--scrollLeft': !isAtLeftBoundary,
      'ScrollWrapper--scrollRight': isOverflowingX && !isAtRightBoundary,
      'ScrollWrapper--vertical': direction === 'vertical',
      'ScrollWrapper--scrollTop': !isAtTopBoundary,
      'ScrollWrapper--scrollBottom': isOverflowingY && !isAtBottomBoundary,
    });

    return (
      <div
        ref={this.scrollWrapper}
        onScroll={this.handleScroll}
        className={classes}
        {...rest}
      >
        {children}
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={() => {
            this.checkOverflow();
            this.handleScroll({});
          }}
        />
      </div>
    );
  }
}

export default ScrollIndicator;
