import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ThreeDotMenu from 'components/ThreeDotMenu';
import './MobileCard.less';

class MobileCard extends PureComponent {
  static propTypes = {
    /**
     * header
     */
    keys: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    /**
     * values of each column
     */
    values: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    /**
     * Additional classes to apply
     */
    className: PropTypes.string,
    /**
     * control three dot menu
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.node.isRequired,
        icon: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        hasSeparator: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
      }),
    ),
  };

  static defaultProps = {
    className: '',
    options: [],
  };

  render() {
    const { keys, values, className, options } = this.props;
    const classes = classnames('MobileCard flex', className);
    const subTableClasses = classnames('flex-grow');
    const keyClasses = classnames('MobileCardKey');
    const valueClasses = classnames('MobileCardValue');

    return (
      <div className={classes}>
        <tbody className={subTableClasses}>
          {keys.map((key, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              <th className={keyClasses}>{key}</th>
              <th className={valueClasses}>{values[i]}</th>
            </tr>
          ))}
        </tbody>

        {options.length > 0 && <ThreeDotMenu options={options} />}
      </div>
    );
  }
}

export default MobileCard;
