import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './HelperText.less';

const HelperText = props => {
  const { children, hasError, isLarge, className, ...rest } = props;

  const classes = classnames('HelperText', className, {
    'HelperText--error': hasError,
    'HelperText--large': isLarge,
  });

  return (
    <span {...rest} className={classes}>
      {children}
    </span>
  );
};

HelperText.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Renders an error state when true
   */
  hasError: PropTypes.bool,

  /**
   * Renders a larger text when true
   */
  isLarge: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

HelperText.defaultProps = {
  hasError: false,
  isLarge: false,
  className: '',
};

export default HelperText;
