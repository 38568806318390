import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Footer.less';

const Footer = ({ children, text, className }) => {
  const classes = classnames('Footer', className);

  return (
    <footer className={classes}>
      <div className="Footer__content">
        <div className="Footer__inner">{children}</div>
        <div className="Footer__text">{text}</div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  /**
   * Child nodes - e.g. FooterLinksGroup
   */
  children: PropTypes.node,

  /**
   * Customize the default footer legalese
   */
  text: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Footer.defaultProps = {
  text: 'Copyright © Rakuten Corporation. All rights reserved',
  className: '',
  children: null,
};

export default Footer;
