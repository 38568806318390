import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import MerchantAPI from '../../actions/merchant';
import { Drawer, Header, Icon } from '@blueprints2/core';
import './NavigationBar.css';
import { Merchant, MerchantUpdateRequest } from '../../constants/interfaces';
import MidCheckbox from './components/MidCheckbox';
import { Spinner } from '../common';
import { Redirect } from 'react-router-dom';
import * as FullStory from '@fullstory/browser';

interface NavigationBarProps {
	onHttpStatusChange?: Function;
}

const NavigationBar = (props: NavigationBarProps) => {
	const { onHttpStatusChange } = props;
	const { merchant } = useSelector((state: Store) => state.merchant);
	// @ts-ignore
	const [state, setState] = useState({ merchant: undefined as Merchant, showDrawer: false, isLoading: false, redirect: false });
	const dispatch = useDispatch();
	const redirectRef = useRef(false);

	const errorCallback = (status: number) => {
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const redirectCallback = () => {
		redirectRef.current = true;
	};

	const getMerchant = async () => {
		await dispatch(MerchantAPI.getMerchant(errorCallback));
	};

	const updateMerchant = async (merchantUpdateRequest: MerchantUpdateRequest) => {
		await dispatch(MerchantAPI.updateMerchant(merchantUpdateRequest, redirectCallback, errorCallback));
	};

	const onMidSelected = (mid: string) => {
		if (mid) {
			setState({ ...state, isLoading: true });
			updateMerchant({ mid: mid, previousMid: merchant.mid });
		}
	};

	const getMerchantFullName = () => {
		if (state.merchant == undefined) {
			return '';
		}
		const selectedMid = state.merchant.mids.filter((m) => m.mid === state.merchant.mid)[0];
		return (
			<span style={{ display: 'contents' }}>
				<span className='mid-text text-gray-500'>
					<span style={{ fontWeight: 700 }}>
						{state.merchant.name} {selectedMid.country ? `(${selectedMid.country.toUpperCase()})` : 'N/A'}
					</span>
					<br />
					{`MID: ${state.merchant.mid} / ${selectedMid.recordType.toString().toLowerCase()}`}
				</span>
				{state.merchant.mids.length > 1 && <Icon type={state.showDrawer ? 'chevron-up' : 'chevron-down'} />}
			</span>
		);
	};

	useEffect(() => {
		if (state.redirect) {
			redirectRef.current = false;
			setState({ ...state, redirect: false });
		}
	}, [state.redirect]);

	useEffect(() => {
		if (!!merchant && !!merchant.mids && merchant.mids.length > 0) {
			setState({ merchant: merchant, showDrawer: false, isLoading: false, redirect: redirectRef.current });
		}
		FullStory.setUserVars({ mid: merchant ? merchant.mid : undefined });
	}, [merchant]);

	useEffect(() => {
		getMerchant();
		return () => {
			dispatch(MerchantAPI.clearMerchant());
		};
	}, []);

	if (state.redirect && redirectRef.current) {
		return <Redirect to={{ pathname: '/home' }} />;
	}

	return (
		<Header
			title={'Shopify Merchant Onboarding'}
			actions={[
				{
					key: 'merchant',
					// @ts-ignore
					content: ({ onToggle, isActive }) => (
						<React.Fragment>
							<span
								className={!state.merchant ? '' : state.merchant.mids.length > 1 ? 'mid-text-navigation' : 'mid-text-navigation-disabled'}
								onClick={onToggle}
							>
								{getMerchantFullName()}
							</span>
							<Drawer isVisible={isActive} onClose={() => onToggle()}>
								{state.merchant &&
									state.merchant.mids.map((midData) => {
										return (
											<MidCheckbox
												key={`mid-${midData.mid}`}
												isActive={midData.mid === state.merchant.mid}
												name={state.merchant.name}
												mid={midData.mid}
												type={midData.recordType.toString().toLowerCase()}
												country={midData.country}
												onMidSelected={onMidSelected}
											/>
										);
									})}
							</Drawer>
						</React.Fragment>
					),
				},
			]}
		>
			{state.isLoading && <Spinner />}
		</Header>
	);
};

export default NavigationBar;
