import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FocusablePopover from './FocusablePopover';

class DismissiblePopover extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent Popover component
     */
    isVisible: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isVisible: props.isVisible };

    this.hidePopover = this.hidePopover.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.onDismiss);
    this.willUnmount = true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Add a delay to prevent clicks on the original toggle
    // from immediately double firing & re-toggling the popover
    if (prevState.wasRecentlyUpdated) return null;

    // Only run the following logic on prop update
    if (prevState.isStateUpdate) return { isStateUpdate: false };

    // Update state if it's different from the new prop
    if (nextProps.isVisible !== prevState.isVisible) {
      return { isVisible: nextProps.isVisible };
    }

    return null;
  }

  hidePopover() {
    // If the popover's already closed, no need to continue
    if (!this.state.isVisible) return;

    this.setState({
      isVisible: false,
      isStateUpdate: true,
      wasRecentlyUpdated: true,
    });
    this.onDismiss = setTimeout(() => {
      if (this.willUnmount) return;

      this.setState({
        isStateUpdate: true,
        wasRecentlyUpdated: false,
      });
      this.props.onDismiss(false);
    }, 200);
  }

  render() {
    const { isVisible } = this.state;

    return (
      <FocusablePopover
        {...this.props}
        isVisible={isVisible}
        onDeactivate={this.hidePopover}
      />
    );
  }
}

export default DismissiblePopover;
