import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getComponentName } from 'components/helpers';

import { getFieldValue, isValidField, unmapState } from './helpers';

class Form extends PureComponent {
  static propTypes = {
    /**
     * Child nodes - e.g., TextInput, TextArea, Select, RadioButtons, Checkbox
     */
    children: PropTypes.node.isRequired,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Initial values to pass to form elements
     */
    values: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    /**
     * Function to call when form is submitted
     *
     * @param {obj} event - The parent form submit event
     * @param {obj} values - Object containing all submitted form input values
     */
    onSubmit: PropTypes.func,

    /**
     * Function to call when form inputs are changed
     *
     * @param {obj} event - The child input change event
     * @param {obj} values - Object containing all submitted form input values
     */
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    values: {},
    onSubmit: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { isInitializing: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { isInitializing } = state;

    const mapState = values => {
      return Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = { value, isControlled: undefined };
        return acc;
      }, {});
    };

    const values = isInitializing
      ? mapState(props.values)
      : { ...state.values };

    const prevValues = isInitializing ? {} : { ...state.values };

    const mapChildrenFields = children => {
      React.Children.forEach(children, child => {
        if (!React.isValidElement(child)) return;

        if (!isValidField(child)) {
          mapChildrenFields(child.props.children);
          return;
        }

        const { name } = child.props;
        const [
          isControlled,
          controlledValue,
          uncontrolledValue,
        ] = getFieldValue(child, values);

        if (isControlled || uncontrolledValue !== undefined) {
          values[name] = {
            isControlled,
            value: isControlled ? controlledValue : uncontrolledValue,
          };
        }
      });
    };

    mapChildrenFields(props.children);
    if (
      props.onChange &&
      JSON.stringify(values) !== JSON.stringify(prevValues)
    ) {
      props.onChange(
        (state && state.onChangeEvent) || null,
        unmapState({ values }),
      );
    }

    return { values, isInitializing: false };
  }

  componentWillUnmount() {
    this.state = undefined;
  }

  handleReset = ({ components }) => {
    const { values } = this.state;
    if (components && components.length > 0) {
      components.forEach(name => {
        const { isControlled } = (values && values[name]) || {};
        values[name] = { value: '', isControlled };
      });
      this.setState({ values });
    }
  };

  handleChange({ name, value, onChange, event }) {
    if (onChange) onChange(event);

    const { values } = this.state;
    const { isControlled } = (values && values[name]) || {};
    values[name] = { value, isControlled };

    this.setState({ values, onChangeEvent: event });
  }

  renderChildrenWithProps(children) {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;

      const componentType = getComponentName(child);
      // Input elements nested within child components/wrappers
      // should still work with their ultimate parent Form
      if (!isValidField(child)) {
        return React.cloneElement(child, {
          onReset: components =>
            this.handleReset({
              onReset: child.props.onReset,
              components,
            }),
          children: this.renderChildrenWithProps(child.props.children),
        });
      }

      const isCheckbox = ['Checkbox', 'Switch'].includes(componentType);
      const controllingProp = isCheckbox ? 'isChecked' : 'value';
      const isAdvancedSelect = ['AdvancedSelect'].includes(componentType);
      const [isControlled, controlledValue, uncontrolledValue] = getFieldValue(
        child,
        this.state.values,
      );

      const props = {
        onChange: event =>
          this.handleChange({
            name: event.name,
            value: isAdvancedSelect ? event.event : event[controllingProp],
            onChange: child.props.onChange,
            event,
          }),
        [controllingProp]: isControlled ? controlledValue : uncontrolledValue,
      };

      return React.cloneElement(child, props);
    });
  }

  render() {
    const { onSubmit, className, children } = this.props;
    const classes = classnames('Form', className);

    return (
      <form
        className={classes}
        onSubmit={e => onSubmit(e, unmapState(this.state))}
      >
        {this.renderChildrenWithProps(children)}
      </form>
    );
  }
}

export default Form;
