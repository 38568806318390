import React from 'react';
import { LoadingSpinner } from 'components/Loading';
import { CardSection, CardBody } from 'components/Card';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Text from 'components/Text';

const AppSwitcherCardBody = React.memo(
  ({ navigation, loadingSpinnerColor, isLoading, closeWindow }) => (
    <CardBody>
      <CardSection isFullWidth />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingSpinner color={loadingSpinnerColor} />
        </div>
      ) : (
        navigation.map((link, index) => {
          const { href, to, text, type, icon, e, className, ...rest } = link;
          const LinkComponent = to ? Link : 'a';
          const linkProps = to ? { ...rest, to } : { href };
          linkProps.className = `${className} TextLink text-xl break-words`;

          return (
            <div
              className="AppSwitcher__cardSection_item"
              key={link.id ? link.id : `AppSwitcher__bodyRaw_${index}`}
            >
              <LinkComponent
                {...linkProps}
                onClick={() => closeWindow(link.closeOnClick)}
              >
                {icon && <Icon type={icon} className="Navigation__icon" />}
                {link.header}
              </LinkComponent>
              <Text className="text-gray-700 text-xs break-words">
                {link.description}
              </Text>
            </div>
          );
        })
      )}
    </CardBody>
  ),
);

AppSwitcherCardBody.propTypes = {
  closeWindow: PropTypes.func,
  isLoading: PropTypes.bool,
  loadingSpinnerColor: PropTypes.oneOf(['red', 'white', 'green']),
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      href: PropTypes.string,
      to: PropTypes.string,
      header: PropTypes.node.isRequired,
      description: PropTypes.node.isRequired,
      className: PropTypes.string,
      icon: PropTypes.shape,
      closeOnClick: PropTypes.bool,
    }),
  ),
};
AppSwitcherCardBody.defaultProps = {
  closeWindow: PropTypes.func,
  isLoading: false,
  loadingSpinnerColor: 'white',
  navigation: [],
};

export default AppSwitcherCardBody;
