import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { TableRow, TableDataCell, TableHeaderCell } from 'components/Table';
import ThreeDotMenu from 'components/ThreeDotMenu';

import './DataTableBody.less';

const DataTableBody = props => {
  const {
    data,
    columns,
    visibleColumns,
    draggedColumn,
    draggingOver,
    renderResizer,
    renderCheckbox,
    isRowSelected,
    customColumnCheckbox,
    threeDotMenuOptions,
    intl,
    ...rest
  } = props;

  return (
    <Fragment>
      {data.map((row, j) => (
        <TableRow
          isSelected={isRowSelected(row)}
          key={`tr-${row.id || j}`}
          {...rest}
        >
          {columns.map((column, i) => {
            const { isHidden } = visibleColumns.get(column) || {};
            const hasOptions = threeDotMenuOptions.length > 0;
            const isFirstCell = i === 0;
            const isLastCell = i === columns.length - 1;
            const isOptionsCell = hasOptions && isLastCell;

            const cellData = row[column.dataKey];
            const { align, valign, withCustomCheckbox = false } = column;

            const formattedData =
              typeof column.format === 'function'
                ? column.format(cellData, row)
                : String(cellData);

            const options =
              hasOptions &&
              threeDotMenuOptions.map(option => ({
                ...option,
                onClick: () => option.onClick(row),
              }));

            const classes = classnames({
              // Border workaround classes
              'Table__TableData--nextVisibleColumn':
                i === visibleColumns.get('nextVisibleColumn'),
              'Table__TableData--lastVisibleColumn':
                i === visibleColumns.get('lastVisibleColumn'),

              // Drag classes
              'Table__TableData--dragged': i === draggedColumn,
              'Table__TableData--draggingOver':
                i && i !== draggedColumn && i === draggingOver,
              'Table__TableData--draggingNextToFirst':
                isFirstCell && i + 1 === draggingOver,

              // Alignment
              [`Table__TableData--${align}`]: align,
              [`Table__TableData--${valign}`]: valign,
            });

            let cell;
            if (isFirstCell) {
              cell = (
                <TableHeaderCell
                  scope="row"
                  className={classes}
                  key={`td-${column.dataKey || i}`}
                >
                  {!customColumnCheckbox && renderCheckbox(row)}
                  {formattedData}
                  {renderResizer(i)}
                </TableHeaderCell>
              );
            } else if (isOptionsCell) {
              cell = (
                <TableDataCell
                  className={classes}
                  key={`td-${column.dataKey || i}`}
                >
                  <div className="flex justify-center">
                    <div className="flex-shrink-0">
                      <ThreeDotMenu options={options} />
                    </div>
                  </div>
                </TableDataCell>
              );
            } else
              cell = (
                <TableDataCell
                  className={classes}
                  hidden={isHidden}
                  key={`td-${column.dataKey || i}`}
                >
                  {withCustomCheckbox &&
                    customColumnCheckbox &&
                    renderCheckbox(row)}
                  {formattedData}
                  {renderResizer(i)}
                </TableDataCell>
              );

            return cell;
          })}
        </TableRow>
      ))}
    </Fragment>
  );
};

DataTableBody.propTypes = {
  /**
   * Passed internally from parent DataTable component
   */
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  visibleColumns: PropTypes.instanceOf(Map).isRequired,
  draggedColumn: PropTypes.number.isRequired,
  draggingOver: PropTypes.number.isRequired,
  renderResizer: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
  isRowSelected: PropTypes.func.isRequired,
  customColumnCheckbox: PropTypes.bool,
  threeDotMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      hasSeparator: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * Passed from react-intl HOC
   */
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

  // Note: ...rest will primarily pass props inherited from Table (e.g. isCondensed, hasHover)
};

DataTableBody.defaultProps = {
  customColumnCheckbox: false,
};

export default injectIntl(DataTableBody);
