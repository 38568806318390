/* eslint-disable react/prop-types */
import Popover from 'components/Popover';
import { PopoverActions, PopoverAction } from 'components/PopoverActions';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import PopoverSeparator from 'components/PopoverSeparator';
import PropTypes, { bool } from 'prop-types';

const PopoverMenu = ({ options, visible, close, anchor }) => {
  const [position, setPosition] = useState('bottom');
  const popoverRef = useRef();

  useEffect(() => {
    const detectElementOverflow = () => {
      if (popoverRef && popoverRef.current) {
        const element = popoverRef.current.parentNode;

        const bounding = element.getBoundingClientRect();

        const out = {};
        out.top = bounding.top < 0;
        out.left = bounding.left < 0;
        out.bottom =
          bounding.bottom >
          (window.innerHeight || document.documentElement.clientHeight);
        out.right =
          bounding.right >
          (window.innerWidth || document.documentElement.clientWidth);
        out.any = out.top || out.left || out.bottom || out.right;

        let currentPosition;

        if (out.any) {
          if (out.bottom) {
            currentPosition = 'top';
          }
          if (out.top) {
            currentPosition = 'bottom';
          }
          if (out.right) {
            currentPosition = 'left';
          }
          if (out.left) {
            currentPosition = 'right';
          }
        } else {
          currentPosition = 'bottom';
        }

        setPosition(currentPosition);
      }
    };

    window.addEventListener('resize', detectElementOverflow);

    return () => {
      window.removeEventListener('resize', detectElementOverflow);
    };
  }, []);

  return (
    <Popover
      anchor={anchor.current}
      popoverRef={popoverRef}
      position={position}
      isVisible={visible}
      hasAutoFocus
      hasAutoDismiss
      onDismiss={close}
    >
      <PopoverActions className="Popover__actions">
        {options.map(
          (
            { name, icon, isDisabled, onClick, hasSeparator, isLoading },
            index,
          ) => (
            <Fragment key={index.toString()}>
              {hasSeparator && <PopoverSeparator />}
              <PopoverAction
                isDisabled={isDisabled}
                isLoading={isLoading}
                icon={icon}
                onClick={e => {
                  e.stopPropagation();
                  close();
                  onClick();
                }}
              >
                {name}
              </PopoverAction>
            </Fragment>
          ),
        )}
      </PopoverActions>
    </Popover>
  );
};

PopoverMenu.propTypes = {
  close: PropTypes.func.isRequired,
  visible: bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      hasSeparator: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
  ).isRequired,
};

export default PopoverMenu;
