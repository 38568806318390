import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';

import './TextTooltip.less';

const TextTooltip = ({
  text,
  position,
  bodyText,
  headingText,
  className,
  initiallyVisible,
  onClickGotIt,
  gotItBtnText,
  ...rest
}) => {
  const classes = classnames('TextTooltip', className);

  return (
    <div className={classes}>
      <Tooltip
        position={position}
        initiallyVisible={initiallyVisible}
        heading={headingText}
        body={bodyText}
        onClickGotIt={onClickGotIt}
        gotItBtnText={gotItBtnText}
        {...rest}
      >
        {toggleVisibility => (
          <div
            className="TextTooltip--text"
            onClick={toggleVisibility}
            role="presentation"
          >
            {text}
          </div>
        )}
      </Tooltip>
    </div>
  );
};

TextTooltip.propTypes = {
  /* Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Main text
   */
  text: PropTypes.string.isRequired,

  /**
   * Heading text
   */
  headingText: PropTypes.node,

  /**
   * Position of tooltip
   */
  position: PropTypes.oneOf([
    'top-left',
    'top',
    'top-right',
    'right',
    'bottom-right',
    'bottom',
    'bottom-left',
    'left',
    'center',
  ]),

  /**
   *
   */
  initiallyVisible: PropTypes.bool,

  /**
   * Body text/nodes
   */
  bodyText: PropTypes.node.isRequired,

  /**
   * Function to be triggered when the "Got It" button is clicked
   */
  onClickGotIt: PropTypes.func,

  /**
   * "Got It" button text
   */
  gotItBtnText: PropTypes.string,
};

TextTooltip.defaultProps = {
  className: '',
  position: 'top',
  initiallyVisible: false,
  headingText: '',
  gotItBtnText: '',
  onClickGotIt: () => {},
};

export default TextTooltip;
