import React, { CSSProperties, Fragment, useRef } from 'react';
import { Icon, Popover, PopoverAction, PopoverActions } from '@blueprints2/core';

interface CustomPopoverProps {
	spanStyle?: CSSProperties;
	children: any;
	show: boolean;
	position?: string;
	className: string;
	onShow: Function;
	onDismiss: Function;
	iconClassName: string;
	iconType?: string;
	actionsClassName?: string;
}

const CustomPopover = (props: CustomPopoverProps) => {
	const { spanStyle, children, show, position, className, onShow, onDismiss, iconClassName, iconType, actionsClassName } = props;
	const popoverAnchor = useRef();

	return (
		<Fragment>
			<span
				style={spanStyle ? spanStyle : undefined}
				onClick={() => onShow()}
				// @ts-ignore
				ref={popoverAnchor}
			>
				<Icon className={iconClassName} type={iconType ? iconType : 'information-outline'} />
			</span>
			<Popover
				className={className}
				anchor={popoverAnchor.current}
				isVisible={show}
				position={position}
				hasAutoFocus
				hasAutoDismiss
				onDismiss={onDismiss}
			>
				{children}
				<PopoverActions className={actionsClassName ? actionsClassName : 'popover-actions'}>
					<PopoverAction onClick={() => onDismiss()}>Got it</PopoverAction>
				</PopoverActions>
			</Popover>
		</Fragment>
	);
};

export default CustomPopover;
