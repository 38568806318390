import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { TableDataCell, TableFooter, TableHeaderCell } from 'components/Table';

import './DataTableFooter.less';

const DataTableFooter = props => {
  const {
    footer,
    columns,
    visibleColumns,
    draggedColumn,
    draggingOver,
    renderResizer,
    intl,
    ...rest
  } = props;

  const isFunction = component => {
    return typeof component === 'function';
  };

  const isElement = element => {
    return React.isValidElement(element);
  };

  const getFormattedValue = cellData => {
    if (cellData === undefined) return null;
    if (isFunction(cellData)) return cellData();
    if (isElement(cellData)) return cellData;
    return String(cellData);
  };

  return (
    <Fragment>
      <TableFooter key="tr-footer-dataTable" {...rest}>
        {columns.map((column, i) => {
          const isFirstCell = i === 0;
          const { isHidden } = visibleColumns.get(column) || {};
          const cellData = footer[column.dataKey];
          const formattedData = getFormattedValue(cellData);
          const { align, valign } = column;

          const classes = classnames({
            // Border workaround classes
            'Table__TableData--nextVisibleColumn':
              i === visibleColumns.get('nextVisibleColumn'),
            'Table__TableData--lastVisibleColumn':
              i === visibleColumns.get('lastVisibleColumn'),

            // Drag classes
            'Table__TableData--dragged': i === draggedColumn,
            'Table__TableData--draggingOver':
              i && i !== draggedColumn && i === draggingOver,
            'Table__TableData--draggingNextToFirst':
              isFirstCell && i + 1 === draggingOver,

            // Alignment
            [`Table__TableData--${align}`]: align,
            [`Table__TableData--${valign}`]: valign,
          });

          return isFirstCell ? (
            <TableHeaderCell
              scope="row"
              className={classes}
              key={`td-footer-${column.dataKey || i}`}
            >
              {formattedData}
              {renderResizer(i)}
            </TableHeaderCell>
          ) : (
            <TableDataCell
              className={classes}
              hidden={isHidden}
              key={`td-footer-${column.dataKey || i}`}
            >
              {formattedData}
              {renderResizer(i)}
            </TableDataCell>
          );
        })}
      </TableFooter>
    </Fragment>
  );
};

DataTableFooter.propTypes = {
  /**
   * Passed internally from parent DataTable component
   */
  footer: PropTypes.objectOf(PropTypes.any).isRequired,
  columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  visibleColumns: PropTypes.instanceOf(Map).isRequired,
  draggedColumn: PropTypes.number.isRequired,
  draggingOver: PropTypes.number.isRequired,
  renderResizer: PropTypes.func.isRequired,
  /**
   * Passed from react-intl HOC
   */
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

  // Note: ...rest will primarily pass props inherited from Table (e.g. isCondensed, hasHover)
};

DataTableFooter.defaultProps = {};
export default injectIntl(DataTableFooter);
