import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Spinner } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import AppCallbackAPI from '../../actions/app-callback';
import { AUTH_HOST_ADD } from '../../constants/action-types';
import { AuthContext } from '../../utils/AuthProvider';

const AppCallback = (props: any) => {
	const { authenticated } = useContext(AuthContext);
	const params = new URLSearchParams(props.location.search);
	const { success, host } = useSelector((state: Store) => state.appCallback);
	const [successState, setSuccessState] = useState<boolean | null>(null);
	const dispatch = useDispatch();

	const invokeCallback = async (params: string) => {
		await dispatch(AppCallbackAPI.invokeCallback(params));
	};

	useEffect(() => {
		invokeCallback(params.toString());
		return () => {
			dispatch(AppCallbackAPI.clearState());
		};
	}, []);

	useEffect(() => {
		if (!authenticated) {
			dispatch({ type: AUTH_HOST_ADD, data: { host: host } });
			// sessionStorage.setItem(HOST_KEY, host);
		}
		setSuccessState(success);
	}, [success]);

	if (successState === null) {
		return <Spinner />;
	}
	if (successState) {
		return <Redirect to={{ pathname: '/welcome' }} />;
	}

	return <Redirect to={{ pathname: '/error/unauthorized' }} />;
};

export default withRouter(AppCallback);
