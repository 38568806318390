import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DataTableSorting from './DataTableSorting';
import DataTablePagination from './DataTablePagination';
import DataTableSelection from './DataTableSelection';
import DataTableResizer from './DataTableResizer';
import DataTableDragger from './DataTableDragger';
import DataTableColumnVisibility from './DataTableColumnVisibility';

class DataTableControllers extends PureComponent {
  static propTypes = {
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    defaultSort: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    paginationOptions: PropTypes.arrayOf(PropTypes.number),
    canSelect: PropTypes.bool.isRequired,
    hideCheckbox: PropTypes.func.isRequired,
    showCheckboxAlternative: PropTypes.func.isRequired,
    checkboxAlternative: PropTypes.node,
    onSelect: PropTypes.func.isRequired,
    reset: PropTypes.number.isRequired,
    customLabelText: PropTypes.node,
    hasHiddenLabel: PropTypes.bool,
    fallbackMessage: PropTypes.node,
    threeDotMenuOptions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    customLabelText: undefined,
    hasHiddenLabel: true,
    fallbackMessage: null,
    paginationOptions: [25, 50, 100],
    checkboxAlternative: null,
  };

  render() {
    const {
      data,
      columns,
      defaultSort,
      paginationOptions,
      canSelect,
      hideCheckbox,
      showCheckboxAlternative,
      checkboxAlternative,
      onSelect,
      reset,
      render,
      fallbackMessage,
      threeDotMenuOptions,
    } = this.props;

    let cols = columns;
    if (threeDotMenuOptions.length > 0) {
      cols = [...columns, { align: 'center' }];
    }

    /**
     * Note: We are NOT mutating nested data objects within the data OR sortedData array.
     * We SHOULD avoid mutating OR cloning said objects, by keeping separate maps/objs
     * that handle tracking additional data that we want to store.
     *
     * @see visibleColumns or DataTableSelection's selectedData Map for examples
     */
    return (
      <DataTableSorting
        data={data}
        columns={cols}
        defaultSort={defaultSort}
        reset={reset}
        render={sorted => (
          <DataTablePagination
            fallbackMessage={fallbackMessage}
            paginationOptions={paginationOptions}
            data={sorted.data}
            reset={reset}
            render={paginated => (
              <DataTableSelection
                canSelect={canSelect}
                hideCheckbox={hideCheckbox}
                showCheckboxAlternative={showCheckboxAlternative}
                checkboxAlternative={checkboxAlternative}
                onSelect={onSelect}
                customLabelText={this.props.customLabelText}
                hasHiddenLabel={this.props.hasHiddenLabel}
                data={data}
                paginatedData={paginated.data}
                currentPagination={paginated.currentPagination}
                reset={reset}
                render={selected => (
                  <DataTableResizer
                    reset={reset}
                    render={resized => (
                      <DataTableDragger
                        columns={cols}
                        columnWidths={resized.columnWidths}
                        reset={reset}
                        render={dragged => (
                          <DataTableColumnVisibility
                            columns={cols}
                            orderedColumns={dragged.columns}
                            reset={reset}
                            render={visible =>
                              // To see what each render prop is passing down as params,
                              // view each file's render() > this.props.render() args
                              render({
                                ...sorted, // output sorted.data overrides props.data
                                ...paginated, // output paginated.data overrides sorted.data
                                ...selected,
                                ...resized,
                                ...dragged, // dragged.columnWidths overrides resized.columnWidths
                                ...visible,
                              })
                            }
                          />
                        )}
                      />
                    )}
                  />
                )}
              />
            )}
          />
        )}
      />
    );
  }
}

export default DataTableControllers;
