import React from 'react';
import PropTypes from 'prop-types';

const PaginationDots = ({ location }) => (
  <span className="Pagination__dots" data-location={location}>
    ...
  </span>
);

PaginationDots.propTypes = {
  /**
   * sets the PaginationButton to the active state
   */
  location: PropTypes.oneOf(['prev', 'next']).isRequired,
};

export default PaginationDots;
