import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { linkShape, actionShape } from './propTypes';
import MobileNav, { MobileNavBurger } from './MobileNav';
import NavLinks from './NavLinks';
import NavbarActions from './NavbarActions';

import { isMobile } from '../helpers';

import './Navbar.less';

const messages = defineMessages({
  menuLabel: {
    defaultMessage: 'Menu',
  },
});

const checkIsVisible = arr =>
  arr && arr.length > 0 && !arr.every(item => item.isVisible === false);

const checkAnyArrays = arr => arr && arr.length >= 0;

const Navbar = ({ links, linksAs, actions, intl }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [linksVisible, setLinksVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [isEmptyLinks, setIsEmptyLinks] = useState(false);
  const [isEmptyActions, setIsEmptyActions] = useState(false);

  const handleResize = () => {
    if (!isMobile) {
      setMobileNavOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLinksVisible(checkIsVisible(links));
    setActionsVisible(checkIsVisible(actions));
    setIsEmptyActions(checkAnyArrays(actions));
    setIsEmptyLinks(checkAnyArrays(links));
  }, [links, actions]);

  if (!isEmptyLinks && !isEmptyActions) {
    return null;
  }

  return (
    <nav className="Navbar">
      <div className="Navbar__container">
        {linksVisible ? (
          <Fragment>
            <MobileNavBurger
              isOpen={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
              label={intl.formatMessage(messages.menuLabel)}
            />

            <NavLinks links={links} linksAs={linksAs} />
          </Fragment>
        ) : (
          <div />
        )}
        {actionsVisible ? (
          <NavbarActions
            actions={actions}
            onToggle={() => setMobileNavOpen(false)}
          />
        ) : (
          <div />
        )}
        {mobileNavOpen && (
          <MobileNav
            closeMenu={() => setMobileNavOpen(false)}
            links={links}
            linksAs={linksAs}
          />
        )}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  links: PropTypes.arrayOf(linkShape),
  linksAs: PropTypes.elementType,
  actions: PropTypes.arrayOf(actionShape),
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

Navbar.defaultProps = {
  links: null,
  linksAs: 'a',
  actions: null,
};

export default injectIntl(Navbar);
