import { Api } from '../constants/interfaces';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { HOST_KEY, NO_AUTH_URLS, SHOP_ORIGIN_KEY, UNAUTHORIZED } from '../constants/constants';
import { AUTH_CLEAR, AUTH_HOST_ADD, AUTH_SHOP_ORIGIN_ADD } from '../constants/action-types';
import store from '../store/index';

export const setUpHttpInterceptors = (httpApis: Api[]) => {
	httpApis.forEach((api) => {
		api.api.interceptors.request.use(
			async (config) => {
				if (NO_AUTH_URLS.filter((url) => config.url?.indexOf(url) === 0).length === 0) {
					const token = await getShopifySessionToken();
					config.headers.common['Authorization'] = `Bearer ${token}`;
				}
				config.headers.common['Content-Type'] = 'application/json';
				if (api.corsUrl && api.corsUrl.length > 0) {
					config.headers.common['Access-Control-Allow-Origin'] = api.corsUrl;
				}
				return config;
			},
			async (error) => {
				return Promise.reject(error);
			}
		);

		api.api.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response && error.response.status === 401) {
					store.dispatch({ type: AUTH_CLEAR });
				}
				return Promise.reject(error);
			}
		);
	});
};

const getShopifySessionToken = async () => {
	let shopOrigin = store.getState().auth.authShopOrigin;
	let host = store.getState().auth.host;

	if (!shopOrigin) {
		shopOrigin = sessionStorage.getItem(SHOP_ORIGIN_KEY) as string;
		store.dispatch({ type: AUTH_SHOP_ORIGIN_ADD, data: { shopOrigin: shopOrigin } });
	}

	if (!host) {
		host = sessionStorage.getItem(HOST_KEY) as string;
		store.dispatch({ type: AUTH_HOST_ADD, data: { host: host } });
	}

	if (!shopOrigin) {
		return UNAUTHORIZED;
	}

	const { REACT_APP_API_KEY } = process.env;

	const app: any = createApp({
		apiKey: REACT_APP_API_KEY as string,
		host: host,
		forceRedirect: true,
	});

	try {
		return await getSessionToken(app);
	} catch (e) {
		console.log('Error app: ', e);
		return;
	}
};
