import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { uniqId } from 'components/helpers';
import { defineMessages, injectIntl } from 'react-intl';
import { linkShape } from './propTypes';

import './NavLinks.less';

const SubNavLinks = ({ links, linksAs }) => {
  return (
    <ul className="SubNavLinks">
      {links.map(link => (
        <NavLink
          key={link.label}
          as={linksAs}
          link={link}
          className="SubNavLinks__item"
          linkClassName="SubNavLinks__link"
        />
      ))}
    </ul>
  );
};

SubNavLinks.propTypes = {
  links: PropTypes.arrayOf(linkShape),
  linksAs: PropTypes.elementType,
};

SubNavLinks.defaultProps = {
  links: [],
  linksAs: 'a',
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Opens in new window',
    description: 'TextLink new window icon label',
  },
});

const NavLink = injectIntl(
  ({ intl, as: Component, link, className, linkClassName }) => {
    if (link.isVisible === false) {
      return null;
    }

    const hasNav = !!(
      link &&
      link.props &&
      ('href' in link.props || 'to' in link.props)
    );

    const isNew = link.newWindow === true && !('to' in link.props);
    const newWindowProps = isNew ? { target: '_blank', rel: 'noopener' } : {};

    const rootClassName = classnames(className, {
      'NavLinks__item--hasSubNav': link.links,
      [`${className}--navless`]: !hasNav,
    });

    const isActiveCheck = () => {
      if (link.isActive) {
        return true;
      }

      if (link && link.links) {
        return link.links.find(item => 'isActive' in item);
      }

      return false;
    };

    const linkComponentClassName = classnames(linkClassName, {
      [`${linkClassName}--active`]: isActiveCheck(),
      [`${linkClassName}--navless`]: !hasNav,
    });

    const linkProps = { ...link.props, ...newWindowProps };

    return (
      <li className={rootClassName}>
        <div className="NavLinks__linkWrapper">
          <Component className={linkComponentClassName} {...linkProps}>
            {link.label}
          </Component>

          {isNew && link.hideNewWindowIcon !== true && (
            <Icon
              type="external-link"
              className="NavLinks__linkIcon NavLinks__linkIcon--newWindow"
              title={intl.formatMessage(messages.title)}
            />
          )}
          {link.links && (
            <Icon
              className="NavLinks__linkIcon NavLinks__chevron"
              type="arrow-nav"
            />
          )}
        </div>

        {link.links && <SubNavLinks links={link.links} linksAs={Component} />}
      </li>
    );
  },
);

NavLink.propTypes = {
  as: PropTypes.elementType,
  link: linkShape.isRequired,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
};

NavLink.defaultProps = {
  as: 'a',
  className: 'NavLinks__item',
  linkClassName: 'NavLinks__link',
};

const NavLinks = ({ links, linksAs }) => {
  return (
    <ul className="NavLinks">
      {links.map((link, index) => (
        <NavLink
          key={link.label || uniqId(index)}
          as={link.as || linksAs}
          link={link}
        />
      ))}
    </ul>
  );
};

NavLinks.propTypes = {
  links: PropTypes.arrayOf(linkShape),
  linksAs: PropTypes.elementType,
};

NavLinks.defaultProps = {
  links: [],
  linksAs: 'a',
};

export default NavLinks;
