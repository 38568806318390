import React, { useEffect, useState } from 'react';
import { Button, Grid, GridCell, Heading, Icon, List, LoadingSpinner, PageSection, Text, TextLink } from '@blueprints2/core';
import { Modal, Spinner } from '../common';
import './CCPA.css';
import CcpaAPI from '../../actions/ccpa';

import { ICCPA } from '../../constants/interfaces';
import CCPAWelcome from './CCPAWelcome';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import { CCPA_SHOW_ERROR } from '../../constants/action-types';
import CMPNotDetected from './CMPNotDetected';
import CMPDetected from './CMPDetected';

interface CCPAProps {
	onHttpStatusChange?: Function;
}

const CCPA = (props: CCPAProps) => {
	const { onHttpStatusChange } = props;
	const [state, setState] = useState({} as ICCPA);
	const { ccpa } = useSelector((state: Store) => state.ccpa);
	const dispatch = useDispatch();
	const [formState, setFormState] = useState({
		isUSPApiPresent2: false,
		cmpSelected: false,
		getUSPButtonDisabled: false,
		isStoreLinkLoading: false,
		isLoading: true,
		isFormSubmitted: false,
		isCCPAAccordionExpanded: false,
	});

	const errorCallback = (status: number) => {
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const getUSPApiStatusLoadingCallback = (isStoreLinkLoading: boolean) => {
		setFormState({ ...formState, isStoreLinkLoading: isStoreLinkLoading });
	};

	const loading = (isLoading: boolean) => {
		setFormState({ ...formState, isLoading: isLoading });
	};

	const getCMPData = async () => {
		loading(true);
		await dispatch(CcpaAPI.getCMPData(loading, errorCallback));
	};

	const updateStep = async (currentStep: number) => {
		await dispatch(CcpaAPI.updateStep(currentStep));
	};

	const getUSPApiStatus = async () => {
		setFormState({ ...formState, isStoreLinkLoading: true, getUSPButtonDisabled: true });
		const win = window.open(`${state.website}?cmp-status`, '_blank');
		if (win) {
			win.focus();
		}

		setTimeout(async () => {
			await dispatch(CcpaAPI.getUSPApiStatus(updateStep, getUSPApiStatusLoadingCallback, errorCallback));
		}, 15000);
	};

	const closeAlert = async () => {
		await dispatch({ type: CCPA_SHOW_ERROR, data: { alertMessage: '', showAlert: false } });
	};

	const renderAlert = () => {
		return (
			<Modal
				isDismissible={true}
				body={state.alertMessage}
				isVisible={state.showAlert}
				closeHandler={() => {
					closeAlert();
				}}
				okHandler={() => {
					closeAlert();
				}}
			/>
		);
	};

	const renderCCPAAccordion = () => {
		let ccpaAccordion;
		if (formState.isCCPAAccordionExpanded) {
			ccpaAccordion = (
				<>
					<Grid className='mt-4'>
						<GridCell key={1} width='1/12' className='text-center'>
							<div
								className='icon'
								onClick={() => {
									setFormState({ ...formState, isCCPAAccordionExpanded: false });
								}}
							>
								<Icon type='chevron-outline-up' className='w-6 h-8 text-purple-500' />
							</div>
						</GridCell>
						<GridCell key={2} width='10/12' className='pt-1'>
							<Heading level={4}>What is CCPA?</Heading>
						</GridCell>
						<Grid className='mt-4'>
							<Heading level={4}>What is CCPA?</Heading>
							<Text className='pl-12'>
								The California Consumer Privacy Act (CCPA) provides protection to consumers in California by giving them the ability to
								opt-out from advertisers collecting their data and the right to ask advertisers to disclose what data they possess about
								them. Consumers can also request their data to be deleted. We anticipate other states will implement similar privacy
								regulations.
							</Text>
						</Grid>
						<Grid className='mt-4'>
							<Heading level={4}>What does CCPA Require?</Heading>
							<Text className='pl-12'>
								Unlike GDPR, there is no opt-in notice required. This means consent management platforms (CMPs) are not the prevailing
								solution for CCPA. The law requires that consumers from California have:
								<List isOrdered={true} className={'listOrdered'}>
									<li>The right to opt-out of "sales" of their personal information</li>
									<li>The right to access what information has been collected on them.</li>
									<li>The right to have their data deleted.</li>
								</List>
							</Text>
						</Grid>
						<Grid className='mt-4'>
							<Heading level={4}>What do advertisers need to do?</Heading>
							<Text className='pl-12'>
								Advertisers need to make their own determination on whether or not the CCPA applies them. Regardless of the advertisers
								assessment of their status, Rakuten Advertising has determined it qualifies as a third party that receives information from
								advertisers as well as a business that collects information that may be sold to third parties. This means Rakuten
								Advertising is required to provide explicit notice and an opportunity to opt-out at or before the point of data collection.
								Rakuten Advertising requires its advertisers with a presence in California to also provide this notice.
							</Text>
						</Grid>
					</Grid>
				</>
			);
		} else {
			ccpaAccordion = (
				<>
					<Grid className='mt-6 pb-6'>
						<GridCell key={1} width='1/12' className='text-center'>
							<div
								className='icon'
								onClick={() => {
									setFormState({ ...formState, isCCPAAccordionExpanded: true });
								}}
							>
								<Icon type='chevron-outline-down' className='w-6 h-8 text-purple-600' />
							</div>
						</GridCell>
						<GridCell key={2} width='10/12' className='pt-1'>
							<Heading level={4}>What is CCPA?</Heading>
						</GridCell>
					</Grid>
				</>
			);
		}
		return ccpaAccordion;
	};

	useEffect(() => {
		getCMPData();
		return () => {
			dispatch(CcpaAPI.clearState());
		};
	}, []);

	useEffect(() => {
		setState(ccpa);
	}, [ccpa]);

	if (formState.isLoading) {
		return <Spinner />;
	}

	return (
		<>
			{formState.isStoreLinkLoading ? (
				<>
					<PageSection className={'mb-20 m-auto'}>
						<Text>
							<Heading level={2}>Hold tight — we're just inspecting your store for consent management.</Heading>
						</Text>
						<p style={{ display: 'flex', justifyContent: 'center', marginTop: '4rem' }}>
							<LoadingSpinner color={'green'} size='lg' />
						</p>
					</PageSection>
				</>
			) : (
				<>
					{state.lastAccessedStep === 5 && <CCPAWelcome className='mb-20' />}
					{state.lastAccessedStep === 5.1 && (
						<CMPDetected
							onSelected={(selected: boolean) => {
								setFormState({ ...formState, cmpSelected: selected });
							}}
							onFormSubmitted={(formSubmitted: boolean) => {
								setFormState({ ...formState, isFormSubmitted: formSubmitted });
							}}
							cmpName={state.name}
							cmpType={state.type}
							values={state.values}
						/>
					)}
					{state.lastAccessedStep === 5.2 && (
						<CMPNotDetected
							onSelected={(selected: boolean) => {
								setFormState({ ...formState, cmpSelected: selected });
							}}
							onFormSubmitted={(formSubmitted: boolean) => {
								setFormState({ ...formState, isFormSubmitted: formSubmitted });
							}}
							className='mb-8'
							cmpName={state.name}
							cmpType={state.type}
							values={state.values}
						/>
					)}
					<Grid>
						<GridCell width='6/12' className='text-left'>
							<Button key='5.0' isDisabled={state.lastAccessedStep === 5} isSecondary onClick={() => updateStep(5)}>
								Back
							</Button>
						</GridCell>
						<GridCell width='6/12' className='text-right'>
							<TextLink to='/home'>
								<Button isTertiary>Cancel</Button>
							</TextLink>
							{state.lastAccessedStep === 5 && (
								<Button
									key='5'
									onClick={() => getUSPApiStatus()}
									isDisabled={formState.getUSPButtonDisabled}
									isLoading={formState.isStoreLinkLoading}
								>
									Next
								</Button>
							)}
							{state.lastAccessedStep === 5.1 && (
								<Button
									key='5.1'
									form='detectedCMP'
									type='submit'
									isDisabled={!formState.cmpSelected}
									isLoading={formState.isFormSubmitted}
								>
									{formState.isFormSubmitted ? '' : 'Done'}
								</Button>
							)}
							{state.lastAccessedStep === 5.2 && (
								<Button
									key='5.2'
									form='notDetectedCMP'
									type='submit'
									isDisabled={!formState.cmpSelected}
									isLoading={formState.isFormSubmitted}
								>
									{formState.isFormSubmitted ? '' : 'Done'}
								</Button>
							)}
						</GridCell>
					</Grid>
					<div className='text-center pt-8'></div>
				</>
			)}
			{renderAlert()}
			{state.lastAccessedStep == 5 && !formState.isStoreLinkLoading && renderCCPAAccordion()}
		</>
	);
};

export default CCPA;
