import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import { ADDITIONAL_SCRIP_CLEAR, ADDITIONAL_SCRIP_GET, ADDITIONAL_SCRIP_STATUS_GET } from '../constants/action-types';

export default {
	getAdditionalScript: (errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.additionalScript.get);
			dispatch({ type: ADDITIONAL_SCRIP_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while getting additional script: ' + err.response.data.message);
			errorCallback(err.response.status);
		}
	},
	getAdditionalScriptStatus: (errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.additionalScript.getStatus);
			dispatch({ type: ADDITIONAL_SCRIP_STATUS_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while getting additional script status: ' + err.response.data.message);
			errorCallback(err.response.status);
		}
	},
	reviewStatus: (status: number, errorCallback: Function, redirectCallback: Function) => async (dispatch: Dispatch) => {
		try {
			await ShopifyAPi.post(`${API.V1.additionalScript.reviewStatus}/${status}`);
			redirectCallback(true);
		} catch (err: any) {
			console.log(err.response, 'err.response');
			console.log('Error while reviewing additional script status: ' + err.response.data.message);
			errorCallback(err.response.status);
		}
	},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: ADDITIONAL_SCRIP_CLEAR }),
};
