import { Spinner } from '../common';
import React, { useEffect, useState } from 'react';
import { Icon, LoadingSpinner, Text } from '@blueprints2/core';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import SpiAPI from '../../actions/spi';

interface SPIProps {
	onHttpStatusChange?: Function;
}

const SPI = (props: SPIProps) => {
	const { onHttpStatusChange } = props;
	const { isScriptPlaced } = useSelector((state: Store) => state.spi);
	const [state, setState] = useState({ isScriptPlaced: undefined });
	const dispatch = useDispatch();

	const errorCallback = (status: number) => {
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const successCallback = async () => {
		await dispatch(SpiAPI.createScript(errorCallback));
	};

	const getScript = async () => {
		await dispatch(SpiAPI.getScript(successCallback, errorCallback));
	};

	useEffect(() => {
		setState({ isScriptPlaced: isScriptPlaced });
	}, [isScriptPlaced]);

	useEffect(() => {
		getScript();
		return () => {
			dispatch(SpiAPI.clearState());
		};
	}, []);

	if (state.isScriptPlaced === undefined) {
		return <Spinner />;
	}

	if (!state.isScriptPlaced) {
		return (
			<div className='flex flex-wrap'>
				<div className='w-8/12 m-auto text-center'>
					<div className='mt-32'>
						<LoadingSpinner initialPercentage={95} size='lg' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='flex flex-wrap'>
			<div className='w-8/12 m-auto text-center'>
				<Icon type='thumbs-up' className='mt-32 w-16 h-12' />
				<Text className='mt-10' style={{ fontSize: '23px', fontWeight: '600px' }}>
					SPI Tags Installed
				</Text>
			</div>
		</div>
	);
};

export default SPI;
