import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import Blanket from 'components/Blanket';
import Icon from 'components/Icon';
import Modal from './DrawerModal';
import './Drawer.less';

class Drawer extends PureComponent {
  static propTypes = {
    /**
     * Child text/nodes
     */
    children: PropTypes.node,

    /**
     * Position of draw on desktop screens - left or right
     */
    position: PropTypes.oneOf(['left', 'right']),

    /**
     * Sets visibility of the Drawer
     */
    isVisible: PropTypes.bool.isRequired,

    /**
     * Function to be triggered when the drawer closes itself
     */
    onClose: PropTypes.func.isRequired,

    /**
     * When true, drawer will display Modal to confirm when user tries to close
     */
    confirmClose: PropTypes.bool,

    /**
     * Element ID to provide offset, by default this is the BP header ID
     */
    topOffsetElementID: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    useCustomClose: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    position: 'right',
    confirmClose: false,
    topOffsetElementID: 'HeaderContainer',
    useCustomClose: false,
  };

  messages = defineMessages({
    closeButton: {
      defaultMessage: 'Close drawer',
      description: 'Drawer close ariaLabel',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { confirmClose, onClose } = this.props;

    if (confirmClose) {
      this.setState({ modalVisible: true });
      return;
    }

    onClose();
  }

  render() {
    const {
      children,
      position,
      intl,
      // following props for Blanket
      isVisible,
      topOffsetElementID,
      useCustomClose,
    } = this.props;

    return (
      <Fragment>
        <Blanket
          topOffsetElementID={topOffsetElementID}
          isVisible={isVisible}
          onClose={this.handleClose}
        >
          <div
            onClick={event => event.stopPropagation()}
            className={classnames('Drawer', {
              'Drawer--right': position === 'right',
              'Drawer--left': position === 'left',
            })}
            role="presentation"
          >
            {children}
            {!useCustomClose && (
              <button
                type="button"
                className="Drawer__close my close"
                aria-label={intl.formatMessage(this.messages.closeButton)}
                onClick={this.handleClose}
              >
                <Icon type="close" />
              </button>
            )}
          </div>
        </Blanket>

        <Modal
          isVisible={this.state.modalVisible}
          onClose={() => {
            this.setState({ modalVisible: false });
          }}
          onConfirm={() => {
            this.setState({ modalVisible: false });
            this.props.onClose();
          }}
        />
      </Fragment>
    );
  }
}

export default injectIntl(Drawer);
