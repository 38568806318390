import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Badge.less';

const Badge = props => {
  const { children, className, ...rest } = props;
  const classes = classnames('Badge', className, {
    'Badge--empty': !children,
  });

  return (
    <span {...rest} className={classes}>
      {children}
    </span>
  );
};

Badge.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Badge.defaultProps = {
  children: null,
  className: '',
};

export default Badge;
