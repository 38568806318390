import React, {useState} from 'react';
import {
    Button,
    ConfirmationModal,
    GridCell,
    Heading,
    Icon,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Switch,
    Text
} from '@blueprints2/core';

interface OrderTrackingFieldConfirmationProps {
    open: boolean;
    closeHandler: Function;
    errorMessage: string;
    headerText: string;
    confirmButtonText: string;
    handleConfirm: Function;
}
const OrderTrackingFieldConfirmation = (props: OrderTrackingFieldConfirmationProps) => {
    const { open, closeHandler, errorMessage, headerText, confirmButtonText, handleConfirm } = props;
    const [loadingState, setLoadingState] = useState(false);

    const loadingCallback = (value: boolean) => {
        setLoadingState(value);
    };

    const confirm = () => {
        setLoadingState(true);
        handleConfirm(loadingCallback);
    };

    return (
        <React.Fragment>
            <Modal style={{ width: 600 }} isVisible={open} onClose={() => closeHandler()}>
                <ModalHeader>
                    <Heading level={5} className={'text-red-600'}>{errorMessage ? errorMessage : ' '}</Heading>
                </ModalHeader>
                <ModalHeader>
                    <Heading className={'text-lg mt-4'} level={3}>{headerText}</Heading>
                </ModalHeader>

                <ModalFooter className={'text-center'}>
                    <Button isSecondary onClick={() => closeHandler()}>Cancel</Button>
                    <Button
                        isLoading={loadingState}
                        isDisabled={loadingState}
                        onClick={() => {
                            confirm();
                        }}
                    >
                        {confirmButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );

};

export default OrderTrackingFieldConfirmation;