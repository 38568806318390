import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import Icon from 'components/Icon';

class DismissibleTag extends PureComponent {
  static propTypes = {
    /**
     * Defines what to do during 'onDismiss' event
     */
    onDismiss: PropTypes.func,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string.isRequired,

    /**
     * Child text/nodes
     */
    children: PropTypes.node.isRequired,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    onDismiss: null,
  };

  constructor(props) {
    super(props);

    this.state = { isDismissed: false };
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss(event) {
    const { onDismiss } = this.props;

    this.setState({ isDismissed: true });

    if (onDismiss) {
      onDismiss(event);
    }
  }

  render() {
    const { intl } = this.props;

    const messages = defineMessages({
      ariaLabel: {
        defaultMessage: 'Dismiss tag',
        description: 'Tag dismiss ariaLabel',
      },
    });

    return (
      !this.state.isDismissed && (
        <div className={this.props.className}>
          <div className="Tag__content">{this.props.children}</div>
          <button
            className="Tag__dismiss"
            type="button"
            aria-label={intl.formatMessage(messages.ariaLabel)}
            onClick={this.onDismiss}
          >
            <Icon type="close" className="Tag__dismissIcon" />
          </button>
        </div>
      )
    );
  }
}

export default injectIntl(DismissibleTag);
