import { Redirect, Route, RouteProps } from 'react-router-dom';
import React, { createElement } from 'react';

interface PrivateRouteProps extends RouteProps {
	httpStatus: number | undefined;
}

const PrivateRoute = (props: PrivateRouteProps) => {
	return (
		<Route
			//@ts-ignore
			render={(renderProps) => {
				if (props.httpStatus === 401) {
					return <Redirect to='/error/unauthorized' />;
				}

				if (props.httpStatus === 403) {
					return <Redirect to='/error/forbidden' />;
				}

				if (props.httpStatus === 500) {
					return <Redirect to='/error/server_error' />;
				}

				if (props.component) {
					return createElement(props.component);
				}

				if (props.render) {
					return props.render(renderProps);
				}

				if (props.children) {
					return props.children;
				}
			}}
		/>
	);
};

export default PrivateRoute;
