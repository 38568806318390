import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class DataTableSorting extends PureComponent {
  static propTypes = {
    /**
     * Passed internally from parent DataTable component
     */
    render: PropTypes.func.isRequired, // @see https://reactjs.org/docs/render-props.html
    data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    defaultSort: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    reset: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this.onInitialSort();

    this.onColumnSort = this.onColumnSort.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reset !== this.props.reset) {
      this.onUserReset(); // Full reset
    }
    if (prevProps.data !== this.props.data) {
      this.onDataUpdate(); // Re-sort data with current sort
    }
  }

  /**
   * Resetting
   */

  onUserReset() {
    this.setState(this.onInitialSort());
  }

  onInitialSort() {
    const {
      defaultSort: { columnIndex: index, order },
    } = this.props;

    const noSort = {
      currentSort: { column: undefined, order: undefined },
      sortedData: this.props.data,
    };

    // Check if the columnIndex passed is valid
    const hasColumnIndex = index || index === 0;
    const column = this.props.columns[index];
    const hasValidSort = hasColumnIndex && order && column;

    // If there isn't a valid defaultSort, return unsorted data
    if (!hasValidSort) return noSort;

    const currentSort = { column, order };
    const sortedData = this.sortData(currentSort);

    if (!sortedData) return noSort;

    return { currentSort, sortedData };
  }

  onDataUpdate() {
    const { data } = this.props;
    const { currentSort } = this.state;

    // If there isn't a current valid sort, fall back to the unsorted data
    const sortedData = this.sortData(currentSort) || data;

    this.setState({ sortedData });
  }

  /**
   * Sort logic
   */

  onColumnSort(column, order) {
    const currentSort = { column, order };
    const sortedData = this.sortData(currentSort);

    if (sortedData) this.setState({ currentSort, sortedData });
  }

  sortData({ column = {}, order }) {
    const data = Array.from(this.props.data); // Shallow copy
    let sortedData;

    const hasCustomSortFunction = typeof column.sort === 'function';
    const keyToSort = column.dataKey;

    if (hasCustomSortFunction) {
      sortedData = column.sort(data, order);
    } else if (keyToSort) {
      sortedData = this.basicSort(data, order, keyToSort);
    }
    // If there's no dataKey to sort by & no custom sort function, there's nothing we can do.

    return sortedData;
  }

  basicSort(array, order, keyToSort) {
    return array.sort((a, b) => {
      if (a[keyToSort] < b[keyToSort]) return order === 'asc' ? -1 : 1;
      if (a[keyToSort] > b[keyToSort]) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }

  /**
   * Rendering
   */

  render() {
    const { sortedData, currentSort } = this.state;

    return this.props.render({
      data: sortedData,
      currentSort,
      onColumnSort: this.onColumnSort,
    });
  }
}

export default DataTableSorting;
