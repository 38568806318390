import React from 'react';
import { Heading, PageSection, Text } from '@blueprints2/core';

interface CCPAWelcomeProps {
	className?: string;
}

const CCPAWelcome = (props: CCPAWelcomeProps) => {
	const { className } = props;
	return (
		<PageSection className={className ? className + ' m-auto' : 'm-auto'}>
			<Text>
				<Heading level={2}>We’re going to inspect your store to see how you handle CCPA</Heading>
			</Text>
			<Text>We’ll launch your store when you click next — please leave it open.</Text>
		</PageSection>
	);
};

export default CCPAWelcome;
