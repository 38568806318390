import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PromotionalBannerWrapper from './components/wrapper';
import PromotionalBannerContent from './components/content';
import PromotionalBannerImage from './components/image';

const PromotionalBanner = memo(
  ({ options, heading, onClick, className, imageFallback, src }) => {
    return (
      <PromotionalBannerWrapper className={className}>
        <PromotionalBannerImage fallback={imageFallback} src={src} />
        <PromotionalBannerContent
          options={options}
          heading={heading}
          onClick={onClick}
        />
      </PromotionalBannerWrapper>
    );
  },
);

PromotionalBanner.propTypes = {
  /**
   * Promotional Banner heading
   */
  heading: PropTypes.node.isRequired,

  /**
   * Array of options
   */
  options: PropTypes.arrayOf(PropTypes.node),

  /**
   * Function to execute on the button click
   */
  onClick: PropTypes.func,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Image fallback message
   */
  imageFallback: PropTypes.node,

  /**
   * Image path
   */
  src: PropTypes.string.isRequired,
};

PromotionalBanner.defaultProps = {
  options: undefined,
  onClick: undefined,
  className: '',
  imageFallback: undefined,
};

export default PromotionalBanner;
