import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classnames from 'classnames';
import { actionShape } from './propTypes';

import './NavbarActions.less';

const NavbarAction = ({ action, isActive, onToggle }) => {
  if (action.isVisible === false) {
    return null;
  }

  const rootClassName = classnames('NavbarActions__item', {
    'NavbarActions__item--active': isActive && action.isDrawer,
  });

  const Component = action.content;

  return (
    <li className={rootClassName}>
      <Component
        isActive={isActive}
        onToggle={state => onToggle(action, state)}
      />
    </li>
  );
};

NavbarAction.propTypes = {
  action: actionShape.isRequired,
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const NavbarActions = ({ actions, onToggle }) => {
  const [currActionIndex, setCurrActionIndex] = useState(null);

  const isActive = action =>
    action.isActive ||
    currActionIndex === actions.findIndex(a => a.key === action.key);

  const handleToggle = (action, state) => {
    const actionIndex = actions.indexOf(action);

    if (!state || currActionIndex === actionIndex) {
      setCurrActionIndex(null);
      onToggle();

      return;
    }

    setCurrActionIndex(actions.findIndex(a => a.key === action.key));
    onToggle();
  };

  return (
    <ul className="NavbarActions">
      {actions.map(action => (
        <NavbarAction
          key={action.key}
          action={action}
          onToggle={(action, state) => handleToggle(action, state)}
          isActive={isActive(action)}
        />
      ))}
    </ul>
  );
};

NavbarActions.propTypes = {
  actions: PropTypes.arrayOf(actionShape),

  /**
   * Callback that is called very time there's an interaction with NavbarActions
   */
  onToggle: PropTypes.func.isRequired,
};

NavbarActions.defaultProps = {
  actions: [],
};

export default NavbarActions;
