import React from 'react';
import { Modal, ModalBody, ModalFooter, Text, Button } from '@blueprints2/core';

interface CustomModalProps {
	isVisible: boolean;
	closeHandler: () => void;
	okHandler: () => void;
	body: string;
	isDismissible?: boolean;
}

const CustomModal = (props: CustomModalProps) => {
	return (
		<Modal
			isDismissible={props.isDismissible ? props.isDismissible : false}
			isVisible={props.isVisible}
			onClose={() => props.closeHandler()}
		>
			<ModalBody>
				<Text className='font-light text-md leading-tight text-left mb-4' style={{ fontWeight: 400, lineHeight: 1.5 }}>
					{props.body}
				</Text>
			</ModalBody>

			<ModalFooter>
				<Button onClick={() => props.okHandler()}>Ok</Button>
			</ModalFooter>
		</Modal>
	);
};
export default CustomModal;
