import React from 'react';
import Icon from 'components/Icon';

export const DottedMenu = () => (
  <div className="AppSwitcher__DottedMenu">
    <Icon type="app-switcher" />
  </div>
);

export default DottedMenu;
