import { MerchantState } from '../constants/interfaces';
import { AnyAction } from 'redux';
import { MERCHANT_CLEAR, MERCHANT_GET, MERCHANT_UPDATE } from '../constants/action-types';

const initialState: MerchantState = {
	merchant: null,
};

export const merchant = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case MERCHANT_GET: {
			return {
				...state,
				merchant: action.data,
			};
		}
		case MERCHANT_UPDATE: {
			return {
				...state,
				merchant: action.data,
			};
		}
		case MERCHANT_CLEAR:
			return initialState;
		default:
			return state;
	}
};
