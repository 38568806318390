import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Table } from 'components/Table';

import DataTableControllers from './RenderProps';
import DataTableHeading from './DataTableHeading';
import DataTableHeader from './DataTableHeader';
import DataTableBody from './DataTableBody';
import DataTableFooter from './DataTableFooter';

import './DataTable.less';

class DataTable extends PureComponent {
  static propTypes = {
    /**
     * Table data is represented as an array of objects, where each object is a table row,
     * and each object key represents a table cell. Table cells will attempt to print its corresponding object value as a string.
     * If the value is an object/array, it's up to the developer to format the display value.
     */
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,

    /**
     * Table column data is represented as an array of objects, where each object is a table column.
     * See the documentation below for a more detailed breakdown of what each object key does.
     */
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.node,
        dataKey: PropTypes.string,
        format: PropTypes.func,
        sort: PropTypes.func,
        align: PropTypes.oneOf(['left', 'right', 'center']),
        valign: PropTypes.oneOf(['top', 'bottom', 'middle']),
        hideSort: PropTypes.bool,
        disableHideOption: PropTypes.bool,
      }),
    ).isRequired,

    /**
     * Table title
     */
    header: PropTypes.node,

    /**
     * Optional footer for dataTable
     */
    footer: PropTypes.objectOf(PropTypes.any),

    /**
     * Optional custom content displayed between the table header and the table.
     * Can include anything from action buttons to a text description.
     */
    supplementalContent: PropTypes.node,

    /**
     * Optional link to an export/file (e.g. CSV, JSON) of your data that users can download
     */
    dataExportLink: PropTypes.string,

    /**
     * Initializes your DataTable with a specific column already sorted.
     * `order` should be either 'asc' or 'desc'.
     * `columnIndex` should be the array index of the column to sort (starting at 0 for the first column)
     */
    defaultSort: PropTypes.shape({
      columnIndex: PropTypes.number,
      order: PropTypes.oneOf(['asc', 'desc']),
    }),

    /**
     * Options for user to change the number of rows displayed. To disable pagination, set the value to [0].
     */
    paginationOptions: PropTypes.arrayOf(PropTypes.number),

    /**
     * Displays checkboxes to the left of each row, allowing users to select multiple items from the DataTable
     */
    canSelect: PropTypes.bool,

    /**
     * Hides row checkbox and makes row unselectable when provided function returns `true`.
     * Requires `canSelect` flag
     */
    hideCheckbox: PropTypes.func,

    /**
     * Displays an icon instead of checkbox and makes row unselectable when provided function returns `true`.
     * Requires `canSelect` and `checkboxAlternative` flags
     */
    showCheckboxAlternative: PropTypes.func,

    /**
     * Accepts an icon component to display instead of checkbox when
     * `showCheckboxAlternative ` function returns `true`.
     * Requires `canSelect` and `showCheckboxAlternative` flags
     */
    checkboxAlternative: PropTypes.node,

    /**
     * Displays drag button/handle in column headers, allowing users to reorder columns
     */
    hideDragControls: PropTypes.bool,

    /**
     * Function to call when the user selects DataTable row(s). Requires `canSelect` flag
     *
     * @param {array} selectedData - Returns array of selected data objects
     */
    onSelect: PropTypes.func,

    /**
     * Hide the cog wheel menu button
     *
     * Disable the hide options for columns
     */
    hideCog: PropTypes.bool,

    /**
     * Additional Table classes to apply
     */
    className: PropTypes.string,

    /**
     * Set true if you want to set the checkbox in custom column(in the custom column should be 'withCustomCheckbox: true')
     */
    customColumnCheckbox: PropTypes.bool,

    /**
     * When true - show the ControlStrip component above the table
     */
    withControlStrip: PropTypes.bool,

    /**
     * ControlStrip childrens
     */
    controlStripChildren: PropTypes.node,

    /**
     * Pass additional props to the ControlStrip component
     */
    controlStripProps: PropTypes.shape({}),

    /**
     * Pass action buttons to the ControlStrip component
     */
    controlStripActionButtons: PropTypes.node,

    /**
     * Pass fallback message when nothing to show
     */
    fallbackMessage: PropTypes.node,

    /**
     * Array of options to display in a ThreeDotMenu pinned in the last column of the table.
     */
    threeDotMenuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.node.isRequired,
        icon: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        hasSeparator: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
      }),
    ),
  };

  static defaultProps = {
    supplementalContent: null,
    dataExportLink: '',
    paginationOptions: [25, 50, 100],
    defaultSort: { columnIndex: null, order: null },
    canSelect: false,
    hideCheckbox: () => false,
    showCheckboxAlternative: () => false,
    checkboxAlternative: null,
    hideDragControls: false,
    onSelect: () => {},
    hideCog: false,
    header: '',
    footer: null,
    className: '',
    customColumnCheckbox: false,
    withControlStrip: false,
    controlStripChildren: null,
    controlStripProps: {},
    controlStripActionButtons: null,
    fallbackMessage: null,
    threeDotMenuOptions: [],
  };

  constructor(props) {
    super(props);
    this.state = { reset: 0 };

    this.onReset = this.onReset.bind(this);
  }

  onReset() {
    this.setState(({ reset }) => ({ reset: reset + 1 }));
  }

  render() {
    const { reset } = this.state;
    const {
      // Controller props
      data,
      columns,
      defaultSort,
      paginationOptions,
      canSelect,
      hideCheckbox,
      showCheckboxAlternative,
      checkboxAlternative,
      customColumnCheckbox,
      hideDragControls,
      onSelect,
      // View props
      header,
      footer,
      supplementalContent,
      dataExportLink,
      hideCog,
      className,
      withControlStrip,
      controlStripChildren,
      controlStripProps,
      controlStripActionButtons,
      fallbackMessage,
      threeDotMenuOptions,
      ...rest
    } = this.props;

    return (
      <DataTableControllers
        data={data}
        columns={columns}
        defaultSort={defaultSort}
        paginationOptions={paginationOptions}
        canSelect={canSelect}
        hideCheckbox={hideCheckbox}
        showCheckboxAlternative={showCheckboxAlternative}
        checkboxAlternative={checkboxAlternative}
        onSelect={onSelect}
        fallbackMessage={fallbackMessage}
        reset={reset}
        threeDotMenuOptions={threeDotMenuOptions}
        render={renderedProps => (
          <Fragment>
            <DataTableHeading
              header={header}
              hideCog={hideCog}
              dataExportLink={dataExportLink}
              columns={renderedProps.columns}
              onColumnsToggle={renderedProps.onColumnsToggle}
              visibilityMapHelpers={renderedProps.visibilityMapHelpers}
              getConfigRef={renderedProps.getConfigButton}
              onReset={this.onReset}
              withControlStrip={withControlStrip}
              controlStripChildren={controlStripChildren}
              controlStripProps={controlStripProps}
              controlStripActionButtons={controlStripActionButtons}
            />
            {supplementalContent}
            {renderedProps.renderSelectAllButton}

            {fallbackMessage || (
              <Table
                {...rest}
                className={classnames(
                  'DataTable',
                  className,
                  renderedProps.dragClass,
                  {
                    'Table--pinnedThreeDotMenuOptions':
                      threeDotMenuOptions.length > 0,
                  },
                )}
              >
                <DataTableHeader
                  columns={renderedProps.columns}
                  visibleColumns={renderedProps.visibleColumns}
                  onColumnHide={renderedProps.onColumnHide}
                  hideDragControls={hideDragControls}
                  disableHideColumns={hideCog}
                  currentSort={renderedProps.currentSort}
                  onColumnSort={renderedProps.onColumnSort}
                  renderDragger={renderedProps.renderDragger}
                  draggedColumn={renderedProps.draggedColumn}
                  draggingOver={renderedProps.draggingOver}
                  renderResizer={renderedProps.renderResizer}
                  columnWidths={renderedProps.columnWidths}
                  renderCheckbox={renderedProps.renderPageCheckbox}
                  customColumnCheckbox={customColumnCheckbox}
                />
                <DataTableBody
                  data={renderedProps.data[renderedProps.currentPagination]}
                  columns={renderedProps.columns}
                  visibleColumns={renderedProps.visibleColumns}
                  draggedColumn={renderedProps.draggedColumn}
                  draggingOver={renderedProps.draggingOver}
                  renderResizer={renderedProps.renderResizer}
                  renderCheckbox={renderedProps.renderRowCheckbox}
                  customColumnCheckbox={customColumnCheckbox}
                  isRowSelected={renderedProps.isRowSelected}
                  threeDotMenuOptions={threeDotMenuOptions}
                />
                {!!footer && (
                  <DataTableFooter
                    footer={footer}
                    columns={renderedProps.columns}
                    visibleColumns={renderedProps.visibleColumns}
                    draggedColumn={renderedProps.draggedColumn}
                    draggingOver={renderedProps.draggingOver}
                    renderResizer={renderedProps.renderResizer}
                  />
                )}
              </Table>
            )}
          </Fragment>
        )}
      />
    );
  }
}

export default DataTable;
