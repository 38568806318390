import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import hi from './hi.json';
import id from './id.json';
import ja from './ja.json';
import ko from './ko.json';
import ms from './ms.json';
import pt from './pt.json';
import ru from './ru.json';
import th from './th.json';
import tl from './tl.json';
import vi from './vi.json';
import zhTW from './zh-TW.json';
import zh from './zh.json';

export default {
  de,
  en,
  es,
  fr,
  hi,
  id,
  ja,
  ko,
  ms,
  pt,
  ru,
  th,
  tl,
  vi,
  'zh-TW': zhTW,
  zh,
};
