import './AdvancedRadioButton.less';

import { LoadingSpinner } from 'components/Loading';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';

const AdvancedRadioButton = ({
  id: incomingId,
  onChange,
  value,
  name,
  className,
  isChecked,
  isRequired,
  html5,
  label,
  isFetching,
  helperText,
  ...rest
}) => {
  const id = incomingId || `AdvancedRadioButton-${shortid.generate()}`;
  const isDisabled = !isChecked && isFetching;

  const handleChange = useCallback(
    event => {
      event.persist();
      onChange({ value, name, event });
    },
    [name, onChange, value],
  );

  const classes = classnames('AdvancedRadioButton', className, {
    'AdvancedRadioButton--disabled': isDisabled,
    'AdvancedRadioButton--checked': isChecked,
    'pointer-events-none': isFetching,
  });
  const radioClasses = classnames('AdvancedRadioButton__canvas', {
    AdvancedRadioButton__radio: helperText,
  });

  const [loaderContent, setLoaderContent] = useState(null);

  useEffect(() => {
    setLoaderContent(null);
    setTimeout(() => {
      return setLoaderContent(<LoadingSpinner color="green" size="sm" />);
    }, 1000);
  }, [isFetching]);

  return (
    <div className="AdvancedRadioButtonWrapper">
      <input
        className="AdvancedRadioButtonInput"
        type="radio"
        id={id}
        name={name || null}
        value={value}
        checked={isChecked}
        required={isRequired && html5}
        onChange={handleChange}
      />
      <label {...rest} htmlFor={id} className={classes}>
        {isFetching && isChecked && loaderContent !== null ? (
          loaderContent
        ) : (
          <svg className={radioClasses} viewBox="0 0 24 24">
            <rect
              className="AdvancedRadioButton__background"
              width="100%"
              height="100%"
              rx="12"
            />
            {isChecked && (
              <path
                className="AdvancedRadioButton__icon"
                d="M17.1 2.9l-.2-.2c-4-3.9-10.4-3.7-14.2.2-3.8 4-3.7 10.3.2 14.1 3.9 3.7 10 3.7 13.9 0 4-3.8 4.1-10.1.3-14.1zM9 13.4L5.3 9.7l1.4-1.4L9 10.6l4.3-4.3 1.4 1.4L9 13.4z"
              />
            )}

            <path
              className="AdvancedRadioButton__border"
              d="M12 2C6.477 2 2 6.477 2 12 2 17.523 6.477 22 12 22 17.523 22 22 17.523 22 12 22 6.477 17.523 2 12 2Z
            M12 0C18.627 0 24 5.373 24 12 24 18.627 18.627 24 12 24 5.373 24 0 18.627 0 12 0 5.373 5.373 0 12 0Z"
            />
          </svg>
        )}
        <div>
          <span className="AdvancedRadioButton__label">{label}</span>
          {helperText && (
            <div className="AdvancedRadioButton__helperText">{helperText}</div>
          )}
        </div>
      </label>
    </div>
  );
};

AdvancedRadioButton.propTypes = {
  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * AdvancedRadioButton label
   */
  label: PropTypes.node.isRequired,

  /**
   * Helper text that displays under the AdvancedRadioButton label
   */
  helperText: PropTypes.node,

  /**
   * Renders selected/checked state
   */
  isChecked: PropTypes.bool,

  /**
   * Marks the AdvancedRadioButton as required, triggering HTML5 validation in modern browsers
   */
  isRequired: PropTypes.bool,

  /**
   * Controls whether to trigger HTML5 validation when the AdvancedRadioButton is required
   */
  html5: PropTypes.bool,

  /**
   * Renders disabled state
   */
  isDisabled: PropTypes.bool,

  /**
   * Handler to call when the AdvancedRadioButton state has changed
   *
   * @param {Object} args - {name, value, event}
   */
  onChange: PropTypes.func,

  /**
   * AdvancedRadioButton name
   */
  name: PropTypes.string,

  /**
   * AdvancedRadioButton ID
   */
  id: PropTypes.string,

  /**
   * AdvancedRadioButton value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /**
   * isLoading state
   */
  isFetching: PropTypes.bool,
};

AdvancedRadioButton.defaultProps = {
  id: '',
  className: '',
  isChecked: false,
  isRequired: undefined,
  isFetching: false,
  html5: undefined,
  isDisabled: undefined,
  onChange: () => {},
  helperText: '',
  name: '',
};

export default AdvancedRadioButton;
