import { AnyAction, combineReducers } from 'redux';
import { spi } from './spi';
import { home } from './home';
import { additionalScript } from './additional-script';
import { auth } from './auth';
import { appHome } from './app-home';
import { appCallback } from './app-callback';
import { thirdParty } from './third-party';
import { ccpa } from './ccpa';
import { pixelVerification } from './pixel-verification';
import { merchant } from './merchant';

const combinedReducers = combineReducers({
	additionalScript,
	appCallback,
	appHome,
	auth,
	ccpa,
	home,
	merchant,
	pixelVerification,
	spi,
	thirdParty,
});

const rootReducer = (state: any, action: AnyAction) => {
	return combinedReducers(state, action);
};

export default rootReducer;
