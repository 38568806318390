import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import './BackTo.less';

const BackTo = props => {
  const { page, href, to, className, ...rest } = props;
  const classes = classnames('BackTo', className);

  const LinkComponent = to ? Link : 'a';
  const linkProps = to ? { to } : { href };

  return (
    <div className={classes}>
      <LinkComponent {...rest} {...linkProps} className="BackTo__link">
        <Icon type="arrow-outline-left" />
        <FormattedMessage
          defaultMessage="Back to"
          description="BackTo link text"
        />
        &nbsp;
        {page}
      </LinkComponent>
    </div>
  );
};

BackTo.propTypes = {
  /**
   * Specifies the page name to take the user to
   */
  page: PropTypes.node.isRequired,

  /**
   * React Router link location
   *
   * - Either **href** or **to** should be provided
   *
   * @see https://reacttraining.com/react-router/web/api/Link
   */
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),

  /**
   * Link URL
   *
   * - Either **href** or **to** should be provided
   */
  href: PropTypes.string,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

BackTo.defaultProps = {
  href: '',
  to: '',
  className: '',
};

export default BackTo;
