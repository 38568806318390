import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { consoleWarnWithLink } from 'components/helpers';

import './Label.less';

const Label = props => {
  const { children, isSecondary, className, ...rest } = props;

  const classes = classnames('Label', className, {
    'Label--secondary': isSecondary,
  });

  consoleWarnWithLink({
    link: 'Label',
    warning: `This component will be deprecated in v4.0 in favour of <Tag />`,
  });

  return (
    <span className={classes} {...rest}>
      {children}
    </span>
  );
};

Label.propTypes = {
  /**
   * Child text/nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Renders secondary state when true
   */
  isSecondary: PropTypes.bool,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

Label.defaultProps = {
  isSecondary: false,
  className: '',
};

export default Label;
