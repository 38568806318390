import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import STATUSES from 'components/constants';

import Image from 'components/Image';

import './CardBanner.less';

const CardBanner = props => {
  const { text, imageSrc, imageFallback, status, className } = props;

  const classes = classnames('CardBanner', className, {
    'CardBanner--success': status === STATUSES.SUCCESS,
    'CardBanner--warning': status === STATUSES.WARNING,
    'CardBanner--danger': status === STATUSES.DANGER,
    'CardBanner--info': status === STATUSES.INFO,
    'CardBanner--secondary': status === STATUSES.SECONDARY,
  });

  return (
    <div className={classes}>
      <div className="CardBanner--title">{text}</div>
      {imageSrc && (
        <div>
          <Image src={imageSrc} fallback={imageFallback} alt={`${text} logo`} />
        </div>
      )}
    </div>
  );
};

CardBanner.propTypes = {
  /**
   * text to show to left of header section
   */
  text: PropTypes.node.isRequired,

  /**
   * Image path of logo at right of header section
   */
  imageSrc: PropTypes.string,

  /**
   * Image fallback path
   */
  imageFallback: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Colored highlight bar based on status
   */
  status: PropTypes.oneOf([
    STATUSES.SUCCESS,
    STATUSES.WARNING,
    STATUSES.DANGER,
    STATUSES.INFO,
    STATUSES.SECONDARY,
  ]),
};

CardBanner.defaultProps = {
  imageSrc: '',
  imageFallback: null,
  status: null,
  className: '',
};

export default CardBanner;
