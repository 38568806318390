/**
 * Fluidly set an input width (via inline styles)
 * based on the length of its text content
 *
 * @param {string} inputId
 */
const setFluidWidth = inputId => {
  const input = document.getElementById(inputId);
  if (!input) return;

  // If it's empty, use the length of its placeholder text
  const isPlaceholder = !input.value && input.placeholder;
  if (isPlaceholder) input.value = input.placeholder;

  // Get the content width by resetting to 0 and getting scrollWidth
  input.style.width = '0';
  const width = input.scrollWidth;
  const margin = 12; // Safety net for hard reload/font-loading (.75rem, matching the separator margins)

  if (input.scrollWidth) input.style.width = `${width + margin}px`;
  else input.style.width = `calc(${input.value.length}ch + ${margin}px)`;
  // ^ Workaround for Edge, which doesn't properly calculate the scrollWidths of inputs (it's always 0)
  // We'll guess an approximate width using the `ch` unit, which isn't perfect, but is still cheaper than alternatives

  if (isPlaceholder) input.value = '';
};

export default setFluidWidth;
