import React from 'react';
import { toast as toastify, Slide } from 'react-toastify';
import './toast.less';

import Icon from 'components/Icon';

const toastTypes = {
  warning: { name: 'warning', icon: 'traffic-cone', color: 'orange-500' },
  info: {
    name: 'info',
    icon: 'notifications-outline',
    color: 'indigo-500',
  },
  danger: { name: 'error', icon: 'close-outline', color: 'crimson-500' },
  success: { name: 'success', icon: 'checkmark-outline', color: 'green-500' },
};

// Global config for the Toast component
toastify.configure({
  transition: Slide,
  hideProgressBar: true,
  newestOnTop: true,
  draggablePercent: 80,
});

const ToastContent = ({ type, content, onClick, id }) => (
  <div className="flex flex-row">
    <Icon
      type={toastTypes[type].icon}
      title={`${toastTypes[type].name}Toast`}
      className={`text-${toastTypes[type].color} mr-2 mt-1`}
    />
    <div onClick={() => onClick(id)} className="Toast--content">
      {content}
    </div>
  </div>
);

const CloseButton = ({ closeToast }) => (
  <Icon
    type="close"
    onClick={closeToast}
    className="text-gray-600 hover:text-gray-400"
  />
);

const positions = Object.freeze({
  top: 'top-right',
  bottom: 'bottom-right',
});

const setPosition = position => positions[position] || positions.bottom;

/**
 * Creates Toast component
 *
 * @param {(string\|number)} id - Toast id has to be unique
 * @param {*} content - Toast content
 *
 * @param {object} options
 * @callback {onCloseCallback} [options.onClose = () => {}] - Returns id
 * @callback {onOpenCallback} [options.onOpen = () => {}] - Returns id
 * @callback {onClickCallback} [options.onClick = () => {}] - Returns id
 * @param {(number\|boolean)} [options.autoClose = 5000]
 * @param {enum} [options.position = "bottom"]
 *
 */

const buildNotification = (
  type,
  id,
  content,
  {
    autoClose = 5000,
    onOpen = () => {},
    onClick = () => {},
    onClose = () => {},
    position = 'bottom',
  },
) =>
  toastify[toastTypes[type].name](
    <ToastContent type={type} content={content} onClick={onClick} id={id} />,
    {
      toastId: id,
      className: `bg-white border border-${toastTypes[type].color} rounded`,
      closeButton: <CloseButton />,
      autoClose,
      onOpen: () => onOpen(id),
      onClose: () => onClose(id),
      position: setPosition(position),
    },
  );

const toast = {
  info(id, content, options = {}) {
    return buildNotification('info', id, content, options);
  },

  success(id, content, options = {}) {
    return buildNotification('success', id, content, options);
  },

  warning(id, content, options = {}) {
    return buildNotification('warning', id, content, options);
  },

  danger(id, content, options = {}) {
    return buildNotification('danger', id, content, options);
  },
};

export default toast;
