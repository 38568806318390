import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwind from 'tailwind';

const tw = resolveConfig(tailwind);

/**
 * Gets the component's displayName from a React Element
 *
 * @param {obj} reactElement
 */
export function getComponentName(reactElement) {
  let name = ((reactElement || {}).type || {}).displayName;

  // Grabs the innermost name for wrapped HOCs - e.g., 'Baz' in 'Foo(Bar(Baz))'
  if (name && name.includes('(')) {
    [name] = name
      .split('(')
      .pop()
      .split(')');
  }

  return name;
}

/**
 * PropTypes syntactical sugar - only allows children to be instances of a specific component
 *
 * @param {func} type - imported Component
 */
export function typeOfInstance(...type) {
  const component = PropTypes.shape({
    type: PropTypes.oneOf(type),
  });

  return PropTypes.oneOfType([
    component,
    PropTypes.arrayOf(component),
    () => null, // Allows conditional JSX nulls
  ]);
}

/**
 * Checks to see if a React element is an instance of a specific component
 *
 * @param {obj} element
 * @param {func} component
 */
export function isInstanceOf(element, component) {
  return getComponentName(element) === getComponentName({ type: component });
}

/**
 * Returns the underlying DOM node from a React element.
 * Most commonly used as a ref helper, which means elements that are
 * already DOM nodes (e.g. callback refs) can also be passed in and
 * should be returned as-is. Invalid elements return false.
 *
 * @param {obj} element
 */
export function getDOMNode(element) {
  const isDOMNode = element instanceof Element;
  if (isDOMNode) return element;

  const isReactComponent =
    element instanceof React.Component || React.isValidElement(element);
  if (isReactComponent) return ReactDOM.findDOMNode(element); // eslint-disable-line react/no-find-dom-node

  return false;
}

/**
 * Exclusive 'either or' PropType helper
 * Used primarily for React Router integrations
 *
 * @param {string} propA - e.g., 'to'
 * @param {string} propB - e.g., 'href'
 *
 * @param {object} props - implicitly passed from PropTypes to custom validator functions
 * @see https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
 */
export const requireEitherProp = (propA, propB) => props => {
  if (!props[propA] && !props[propB]) {
    return new Error(`Either "${propA}" or "${propB}" is required`);
  }
  if (props[propA] && props[propB]) {
    return new Error(`Do not include both "${propA}" and "${propB}"`);
  }
  return null;
};

/**
 * Helper for generating console warnings with @see
 * links to our Blueprints 2 documentation site.
 *
 * @param {string} link - URL component path, e.g. Form
 * @param {string} warning - Custom warning message
 */
export const consoleWarnWithLink = ({ link, warning }) => {
  if (!link || !warning) return;

  const url = 'http://bp2.local.rakutenmarketing.com/#!/';
  const see = `See ${url}${link} for more information.`;

  console.warn(`${warning}\n${see}`);
};

/**
 * Helper to prevent dynamically created portal nodes causing errors on SSR apps
 */
export const createPortalContainer = () => {
  return document.createElement('div');
};

/**
 * Prevents portals from rendering & causing errors on SSR apps
 * This will allow portal content to render dynamically in the client-side
 * (but not in the pre-rendered page source)
 *
 * @param {React.element} child - Child content of portal
 * @param {node} container - Raw DOM node containing the portal
 *
 * @TODO: If we ever want to pre-render popover content someday, @see https://michalzalecki.com/render-react-portals-on-the-server/
 */
export const createPortal = (child, container) => {
  return ReactDOM.createPortal(child, container);
};

/**
 * Helper for generating console warnings and errors for
 * deprecated prop names that are replaced with new props.
 *
 * @param {obj} props - used to get reference to new and deprecated prop values
 * @param {string} deprecatedName - name of deprecated prop
 * @param {string} newName - name of new prop to replace deprecated prop
 * @param {string} isRequired - is the prop required
 * @param {string} versionNumber - the BP2 version that the deprecated prop will be removed
 * @param {string} componentName - the name of the component to log useful warnings/errors
 * @param {string} propType - the string to check the typeof deprecated prop
 *
 * @returns {error|null} - Returns error for Proptypes validator, or null if no error
 */
export const deprecatedProp = ({
  props,
  deprecatedName,
  newName,
  isRequired,
  versionNumber,
  componentName,
  propType,
}) => {
  const deprecatedProp = props[deprecatedName];
  const newProp = props[newName];

  // Prop required and neither new/deprecated detected
  if (isRequired && newProp === undefined && deprecatedProp === undefined) {
    return new Error(
      `The prop \`${newName}\` is marked as required in \`${componentName}\`, but its value is \`${newProp}\`.`,
    );
  }

  // Deprecated prop detected
  if (deprecatedProp !== undefined) {
    console.warn(
      `Deprecation warning: \`${deprecatedName}\` will no longer be supported by \`${componentName}\` after ${versionNumber}. Use \`${newName}\` instead.`,
    );
  }

  // Both deprecated and new prop detected
  if (newProp !== undefined && deprecatedProp !== undefined) {
    console.warn(
      `Both props \`${newName}\` and \`${deprecatedName}\` detected in \`${componentName}\`, please remove \`${deprecatedName}\` and use \`${newName}\`.`,
    );
  }

  // Deprecated propType check
  if (
    newProp === undefined &&
    deprecatedProp !== undefined &&
    // eslint-disable-next-line valid-typeof
    typeof deprecatedProp !== propType
  ) {
    return new Error(
      `Invalid prop \`${deprecatedName}\` of type \`${typeof deprecatedProp}\` supplied to \`${componentName}\`, expected \`${propType}\`.`,
    );
  }

  return null;
};

export const arraysChanged = (a, b) => {
  const getLength = arr => (arr ? arr.length : 0);
  const getString = arr => (arr ? arr.sort().toString() : '');

  if (getLength(a) !== getLength(b)) return true;
  return getString(a) !== getString(b);
};

const booleanInputs = ['Checkbox', 'Switch', 'RadioButton', 'MultiSelectBrick'];
const optionsInputs = [
  'RadioButtons',
  'Select',
  'AdvancedSelect',
  'CheckboxGroup',
  'MultiSelectBrickGroup',
];
const allInputs = [
  'TextInput',
  'TextArea',
  'DatePicker',
  'DateRangePicker',
].concat(booleanInputs, optionsInputs);

/**
 * Contains field types, ordered into boolean, option and all
 */
export const inputTypes = {
  booleanInputs,
  optionsInputs,
  allInputs,
};

export const WINDOW_BREAK_POINTS = {
  MOBILE: 'MOBILE',
  LAPTOP: 'LAPTOP',
  DESKTOP: 'DESKTOP',
};

export const isMobile = window.matchMedia
  ? !window.matchMedia(`(min-width: ${tw.theme.screens.md})`).matches
  : false;

export const getCurrentWindow = () => {
  if (!window.matchMedia(`(min-width: ${tw.theme.screens.md})`).matches) {
    return WINDOW_BREAK_POINTS.MOBILE;
  }
  if (!window.matchMedia(`(min-width: ${tw.theme.screens.lg})`).matches) {
    return WINDOW_BREAK_POINTS.LAPTOP;
  }
  return WINDOW_BREAK_POINTS.DESKTOP;
};

export const uniqId = key => {
  return `${Math.random()
    .toString(16)
    .slice(2)}${new Date().getTime()}${Math.random()
    .toString(16)
    .slice(2)}${key}`;
};
