import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { icons } from './iconsMap';
import './Icon.less';

const Icon = React.forwardRef((props, ref) => {
  const { type, title, className, iconSize } = props;

  const accessibility = title
    ? { 'aria-label': title }
    : { 'aria-hidden': true, role: 'presentation' };

  const svgIcon = (icons[type] || {}).svg;
  const svgProps = {
    className: classnames('Icon', `Icon--${type}`, className),
    viewBox: '0 0 20 20',
    width: iconSize,
    height: iconSize,
    ref,
    ...accessibility,
  };

  if (svgIcon) {
    return React.cloneElement(svgIcon, svgProps);
  }
  return null;
});

Icon.propTypes = {
  /**
   * Icon to display
   */
  type: PropTypes.string.isRequired,

  /**
   * Accessible & semantic title/label. Appears on mouseover and is read out to screenreaders
   */
  title: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Icon size (in pixels)
   */
  iconSize: PropTypes.string,
};

Icon.defaultProps = {
  title: null,
  className: '',
  iconSize: '16',
};

export default Icon;
