import React, { memo, useEffect, useState } from 'react';
import { List, ListItem } from 'components/List';
import Truncate from 'components/Truncate';
import PropTypes from 'prop-types';
import './PromotionalBannerContent.less';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { getCurrentWindow, WINDOW_BREAK_POINTS } from 'components/helpers';
import classnames from 'classnames';
import TRUNCATE_HEADER_OPTIONS from './helpers';

const PromotionalBannerContent = memo(
  ({ options, heading, buttonText, onClick }) => {
    const [isWindow, setIsWindow] = useState(getCurrentWindow());
    const isMobile = isWindow === WINDOW_BREAK_POINTS.MOBILE;
    const handleResize = () => {
      const currentWindow = getCurrentWindow();
      setIsWindow(currentWindow);
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const getTruncateHeaderOptions = () => {
      if (isWindow === WINDOW_BREAK_POINTS.MOBILE) {
        return TRUNCATE_HEADER_OPTIONS.mobile;
      }

      if (isWindow === WINDOW_BREAK_POINTS.LAPTOP) {
        return TRUNCATE_HEADER_OPTIONS.laptop;
      }
      return TRUNCATE_HEADER_OPTIONS.desktop;
    };

    return (
      <div className="PromotionalBannerContent__wrapper">
        <Truncate
          {...getTruncateHeaderOptions()}
          className="PromotionalBannerContent__headerTruncate"
        >
          <h2 className="PromotionalBannerContent__heading">{heading}</h2>
        </Truncate>
        <div className="PromotionalBannerContent__main">
          <div className="PromotionalBannerContent__list">
            <Truncate
              lineClamp={3}
              lineHeight={1.3}
              className="PromotionalBannerContent__optionsTruncate"
            >
              {options.length ? (
                <List>
                  {options.map((option, index) => (
                    <ListItem
                      key={index.toString()}
                      className="PromotionalBannerContent__option"
                    >
                      {option}
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </Truncate>
          </div>
          <div
            className={classnames('PromotionalBannerContent__button_wrapper', {
              invisible: !onClick,
            })}
          >
            <Button isLarge={isMobile} onClick={onClick}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

PromotionalBannerContent.propTypes = {
  heading: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.node),
  buttonText: PropTypes.node,
  onClick: PropTypes.func,
};

PromotionalBannerContent.defaultProps = {
  options: [],
  buttonText: <FormattedMessage defaultMessage="Show me" />,
  onClick: undefined,
};

export default PromotionalBannerContent;
