import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ScrollIndicator from 'components/ScrollIndicator';
import './ModalBody.less';

const ModalBody = React.forwardRef((props, ref) => {
  const { children, className } = props;

  const ModalBodyClasses = classnames('ModalBody', className);

  return (
    <ScrollIndicator ref={ref} direction="vertical">
      <div className={ModalBodyClasses}>{children}</div>
    </ScrollIndicator>
  );
});

ModalBody.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,
};

ModalBody.displayName = 'ModalBody';

ModalBody.defaultProps = {
  className: '',
  children: null,
};

export default ModalBody;
