import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { LoadingBar } from 'components/Loading';
import Icon from 'components/Icon';

import './UploadStatus.less';
import { List, ListItem } from 'components/List';
import Thumbnail from './Thumbnail';

const UploadStatus = props => {
  const {
    id,
    removeFileHandler,
    currentProgress,
    errorMessage,
    intl,
    file,
    showImageThumbnail,
  } = props;

  const hasError = errorMessage.length !== 0;
  const isDone = currentProgress === 100 || hasError;

  const messages = defineMessages({
    ariaLabel: {
      defaultMessage: 'Remove file',
      description: 'FileUpload remove file message',
    },
    errorListCaption: {
      defaultMessage: 'File error',
      description: 'The caption of the error list',
    },
  });

  const parseFileSize = size => {
    if (size / 1000000000 > 1) {
      return `${(size / 1000000000).toFixed(1)}gb`;
    }
    if (size / 1000000 > 1) {
      return `${(size / 1000000).toFixed(1)}mb`;
    }
    if (size / 1000 > 1) {
      return `${(size / 1000).toFixed(1)}kb`;
    }
    return `${size} bytes`;
  };

  return (
    <div data-id={id} className="UploadStatus">
      <button
        type="button"
        className="UploadStatus__closeButton"
        data-label="remove"
        aria-label={intl.formatMessage(messages.ariaLabel)}
        data-id={id}
        onClick={removeFileHandler}
      >
        <Icon type="close" />
      </button>

      {isDone && !hasError && (
        <Thumbnail file={file} showImageThumbnail={showImageThumbnail} />
      )}

      <div className="UploadStatus__file">
        <div className="UploadStatus__fileName">
          <span className="UploadStatus__fileName--base" title={file.name}>
            {file.name}
          </span>
          <span className="UploadStatus__fileName--icon">
            {isDone && !hasError && (
              <Icon
                className="UploadStatus__icon UploadStatus__icon--success"
                type="checkmark-outline"
              />
            )}
            {isDone && hasError && (
              <Icon
                className="UploadStatus__icon UploadStatus__icon--error"
                type="exclamation-solid"
              />
            )}
          </span>
        </div>

        {!isDone && (
          <LoadingBar
            className="UploadStatus__LoadingBar"
            percentage={currentProgress || 0}
            isSmall
            color="green"
            showPercentage
          />
        )}

        {!hasError && (
          <div className="UploadStatus__fileSize">
            {parseFileSize(file.size)}
          </div>
        )}
        {isDone && hasError && (
          <div>
            <div className="UploadStatus__errorCaption">
              {intl.formatMessage(messages.errorListCaption)}
            </div>
            <List className="UploadStatus__errorList">
              <ListItem>{errorMessage}</ListItem>
            </List>
          </div>
        )}
      </div>
    </div>
  );
};

UploadStatus.propTypes = {
  id: PropTypes.string,
  removeFileHandler: PropTypes.func,
  currentProgress: PropTypes.number,
  errorMessage: PropTypes.node,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  file: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.object])
    .isRequired,
  showImageThumbnail: PropTypes.bool,
};

UploadStatus.defaultProps = {
  id: '',
  currentProgress: 0,
  errorMessage: '',
  removeFileHandler: () => {},
  showImageThumbnail: false,
};

export default injectIntl(UploadStatus);
