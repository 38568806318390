import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import React, { memo } from 'react';

const PanelWizardCheckMarks = memo(({ isComplete }) => {
  if (!isComplete) {
    return <div className="PanelWizard__EmptyCheck" />;
  }

  return <Icon className="PanelWizard__Checkmark" type="checkmark-outline" />;
});

PanelWizardCheckMarks.propTypes = {
  isComplete: PropTypes.bool,
};

PanelWizardCheckMarks.defaultProps = {
  isComplete: undefined,
};

export default PanelWizardCheckMarks;
