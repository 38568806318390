import React, { PureComponent } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from 'components/Button';

import './Wizard.less';
import { typeOfInstance } from 'components/helpers';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Heading from 'components/Heading';
import Text from 'components/Text';
import WizardNav from './WizardNav';

class Wizard extends PureComponent {
  static propTypes = {
    /**
     * Child [WizardStage](/#!/WizardStage) components.
     */
    children: PropTypes.node.isRequired,

    /**
     * Callback to run when the user finishes all Wizard stages
     */
    onFinish: PropTypes.func,

    /**
     * Enable this boolean flag if you want to show the Cancel button
     */
    showCancel: PropTypes.bool,

    /**
     * Callback to reset state of your components after clicking on the Cancel button and before Wizard returns back to the first step
     */
    onCancel: PropTypes.func,

    /**
     * Show confirmation modal on cancel
     */
    cancelConfirmation: PropTypes.bool,

    /**
     * Confirmation modal instance
     */
    confirmationModal: typeOfInstance(ConfirmationModal),

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /**
     * Prop from react-intl HOC
     *
     * @ignore
     */
    intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    /**
     * Enable custom callbacks
     */
    enableCallbacks: PropTypes.bool,

    /**
     * Flag to enable or disable Top Wizard Navigation
     */
    topNavigationDisabled: PropTypes.bool,

    /**
     * Custom reference to the Wizard instance
     */
    customRef: PropTypes.shape({ current: PropTypes.any }),
  };

  static defaultProps = {
    className: '',
    onFinish: () => {},
    showCancel: false,
    cancelConfirmation: false,
    confirmationModal: null,
    onCancel: () => {},
    enableCallbacks: false,
    topNavigationDisabled: false,
    customRef: { current: {} },
  };

  i18n = defineMessages({
    cancel: {
      defaultMessage: 'Cancel',
      description: 'cancel',
    },
    prev: {
      defaultMessage: 'Previous',
      description: 'previous',
    },
    next: {
      defaultMessage: 'Next',
      description: 'next',
    },
    finish: {
      defaultMessage: 'Finish',
      description: 'finish',
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      currentStage: 0,
      furthestStage: 0,
      modalVisible: false,
    };

    this.updateFurthestStage = this.updateFurthestStage.bind(this);
    this.reset = this.reset.bind(this);
    this.exit = this.exit.bind(this);
    this.hideConfirmationModal = this.hideConfirmationModal.bind(this);

    this.props.customRef.current = {};
    this.props.customRef.current.exit = this.exit;

    this.renderCancelButton = this.renderCancelButton.bind(this);
    this.getStages = this.getStages.bind(this);
    this.getStageComponent = this.getStageComponent.bind(this);

    this.goToStage = this.goToStage.bind(this);
    if (this.props.enableCallbacks) {
      // redefine goToStage using async function goToStageWithCallbacks which call callbacks and wait them
      this.goToStage = this.goToStageWithCallbacks.bind(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentStage, furthestStage } = this.state;
    const stageChanged = prevState.currentStage !== currentStage;
    const progressedFurther = currentStage > furthestStage;

    if (stageChanged && progressedFurther) {
      this.setState({ furthestStage: currentStage });
    }
  }

  getStages() {
    return React.Children.toArray(this.props.children);
  }

  getStageComponent(stage) {
    const stages = this.getStages();

    return stages[stage] ? stages[stage] : undefined;
  }

  goToStage(stage) {
    this.setState({ currentStage: stage });
  }

  async goToStageWithCallbacks(stage) {
    const { currentStage } = this.state;

    if (currentStage < stage) {
      const currentStageChild = this.getStageComponent(currentStage);
      if (currentStageChild) {
        await currentStageChild.props.beforeGoToNextStage();
      }
    }

    this.setState({ currentStage: stage });
  }

  updateFurthestStage(stage) {
    this.setState({ furthestStage: stage });
  }

  reset() {
    if (this.props.cancelConfirmation) {
      this.showConfirmationModal();
    } else {
      this.exit();
    }
  }

  exit() {
    this.props.onCancel();
    this.setState({ currentStage: 0, furthestStage: 0 });
  }

  showConfirmationModal() {
    this.setState({ modalVisible: true });
  }

  hideConfirmationModal() {
    this.setState({ modalVisible: false });
  }

  renderCancelButton() {
    const { intl } = this.props;
    const { currentStage } = this.state;
    const { cancelBtnText } = this.getStageComponent(currentStage).props || {};

    return (
      <Button isTertiary onClick={this.reset}>
        {cancelBtnText || intl.formatMessage(this.i18n.cancel)}
      </Button>
    );
  }

  renderPrevButton() {
    const { intl } = this.props;
    const { currentStage } = this.state;
    if (currentStage <= 0) return null;

    const prevStage = currentStage - 1;
    const { prevBtnText } = this.getStageComponent(currentStage).props || {};

    return (
      <Button
        className="prevButton"
        isTertiary
        onClick={() => this.goToStage(prevStage)}
      >
        {prevBtnText || intl.formatMessage(this.i18n.prev)}
      </Button>
    );
  }

  renderNextButton() {
    const { intl } = this.props;
    const { currentStage } = this.state;

    const stages = this.getStages();
    const { isComplete, nextBtnText } = stages[currentStage].props;

    if (currentStage >= stages.length - 1) {
      return this.renderFinishButton(isComplete, nextBtnText);
    }

    const nextStage = currentStage + 1;

    return (
      <Button
        onClick={() => this.goToStage(nextStage)}
        isDisabled={!isComplete}
      >
        {nextBtnText || intl.formatMessage(this.i18n.next)}
      </Button>
    );
  }

  renderFinishButton(isComplete, text) {
    const { onFinish, intl } = this.props;

    return (
      <Button onClick={onFinish} isDisabled={!isComplete}>
        {text || intl.formatMessage(this.i18n.finish)}
      </Button>
    );
  }

  renderDefaultConfirmationModal() {
    return (
      <ConfirmationModal
        header={
          <Heading level={2}>
            <FormattedMessage
              defaultMessage="Are you sure you want to exit?"
              description="ConfirmationModal header text"
            />
          </Heading>
        }
        body={
          <Text>
            <FormattedMessage
              defaultMessage="If you choose to exit, all your progress will be lost."
              description="ConfirmationModal body text"
            />
          </Text>
        }
      />
    );
  }

  render() {
    const {
      className,
      showCancel,
      cancelConfirmation,
      confirmationModal,
      topNavigationDisabled,
    } = this.props;
    const { currentStage, furthestStage, modalVisible } = this.state;
    const stages = this.getStages();
    const confirmationModalProps = {
      isVisible: modalVisible,
      callback: () => {
        this.exit();
        this.hideConfirmationModal();
      },
      onClose: this.hideConfirmationModal,
    };

    return (
      <section className={classnames('Wizard', className)}>
        <nav className="Wizard__nav">
          {stages.map((stage, i) => (
            <WizardNav
              key={stage.props.title}
              index={i}
              title={stage.props.title}
              isComplete={stage.props.isComplete}
              currentStage={currentStage}
              furthestStage={furthestStage}
              updateFurthestStage={this.updateFurthestStage}
              goToStage={this.goToStage}
              navigationDisabled={topNavigationDisabled}
            />
          ))}
        </nav>

        {stages[currentStage]}

        <footer className="Wizard__footer ">
          <div className="w-1/3 text-left">
            {showCancel && this.renderCancelButton()}
          </div>
          <div className="w-2/3 text-right">
            {this.renderPrevButton()}
            {this.renderNextButton()}
          </div>
        </footer>

        {cancelConfirmation &&
          React.cloneElement(
            confirmationModal || this.renderDefaultConfirmationModal(),
            confirmationModalProps,
          )}
      </section>
    );
  }
}

export default injectIntl(Wizard);
