import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/Icon';
import { DismissibleAlert, DefaultAlert } from './states';

import './Alert.less';

const Alert = props => {
  const { className, type, icon, isDismissible } = props;

  const classes = classnames('Alert', `Alert--${type}`, className);

  const IconComponent = icon ? (
    <Icon className="Alert__icon" type={icon} />
  ) : null;

  const Component = isDismissible ? DismissibleAlert : DefaultAlert;

  return <Component {...props} icon={IconComponent} className={classes} />;
};

Alert.propTypes = {
  /**
   * Any additional children to render
   */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes to apply
   */
  className: PropTypes.string,

  /**
   * Renders a dismissible alert
   */
  isDismissible: PropTypes.bool,

  /**
   * Defines what to do during 'onDismiss' event
   */
  onDismiss: PropTypes.func,

  /**
   * Renders an icon if a valid icon type is passed in
   */
  icon: PropTypes.string,

  /**
   * Required type of alert to render
   */
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
};

Alert.defaultProps = {
  isDismissible: true,
  onDismiss: null,
  className: '',
  type: 'warning',
  icon: null,
};

export default Alert;
