import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import { API } from '../constants/api';
import { MERCHANT_CLEAR, MERCHANT_GET, MERCHANT_UPDATE } from '../constants/action-types';
import { MerchantUpdateRequest } from '../constants/interfaces';

export default {
	getMerchant: (errorCallback?: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(API.V1.merchant.get);
			dispatch({ type: MERCHANT_GET, data: response.data });
		} catch (err: any) {
			console.log('Error while getting merchant data: ' + (err.response && err.response.data ? err.response.data.message : err));
			if (errorCallback) {
				errorCallback(err.response ? err.response.status : 500);
			}
		}
	},
	updateMerchant:
		(merchantUpdate: MerchantUpdateRequest, redirectCallback: Function, errorCallback?: Function) => async (dispatch: Dispatch) => {
			try {
				const response = await ShopifyAPi.put(API.V1.merchant.update, merchantUpdate);
				await dispatch({ type: MERCHANT_UPDATE, data: response.data });
				redirectCallback();
			} catch (err: any) {
				console.log('Error while updating merchant data: ' + +err.response.data.message);
				if (errorCallback) {
					errorCallback(err.response.status);
				}
			}
		},
	clearMerchant: () => (dispatch: Dispatch) => dispatch({ type: MERCHANT_CLEAR }),
};
