import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getComponentName, inputTypes } from 'components/helpers';

import FilterTags from './FilterTags';
import FilterPopover from './FilterPopover';
import FilterForm from './FilterForm';

import './Filter.less';

class Filter extends PureComponent {
  static propTypes = {
    /**
     * This should be primarily composed of form inputs.
     */
    children: PropTypes.node.isRequired,

    /**
     * Displays the "Find filter" search bar, allowing users to quickly find filters by input labels.
     */
    hasSearch: PropTypes.bool,

    /**
     * Optional filters to initialize the Filter with. Expects an object, with each key
     * corresponding to the `name` of the input whose value it is prepopulating.
     *
     * This prop populates the Filter on init only, and cannot be updated dynamically
     * to override filters set by users.
     */
    defaultFilters: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    /**
     * Label when there is no filter applied.
     */
    labelDefault: PropTypes.node,

    /**
     * Label when filter/s applied
     */
    labelWithFilters: PropTypes.node,

    /**
     * Additional classes to apply
     */
    className: PropTypes.string,

    /** Additional classes for the filter button
     *
     */
    filterButtonClassName: PropTypes.string,

    /**
     * Additional classes to apply to the tag
     */
    tagClassName: PropTypes.string,

    /**
     * Function run when filters are applied or removed
     *
     * @param {obj} filters - Passes an object containing all filter data
     */
    onChange: PropTypes.func,

    /**
     * Function run when filters are applied or removed
     *
     * @param {obj} filters - Passes an object containing all filter data
     */
    onSubmit: PropTypes.func,

    /**
     * When true - show default icon in the button
     */
    withIcon: PropTypes.bool,

    /**
     * Set custom text in the filter button
     */
    customBtnText: PropTypes.node,

    /**
     * When true - show filter tags
     */
    withFilterTags: PropTypes.bool,

    /**
     * When false - show big button
     */
    isSmallBtn: PropTypes.bool,

    /**
     * When true - show large button
     */
    isLargeBtn: PropTypes.bool,

    /**
     * Custom text for Apply button in the filter popover
     */
    customTextApplyBtn: PropTypes.node,

    /**
     * Custom text for Cancel button in the filter popover
     */
    customTextCancelBtn: PropTypes.node,

    /**
     * If true - show the tags after the filter button
     */
    tagsAfterFilterBtn: PropTypes.bool,

    /**
     * If true - show filter tags below the filter button
     */
    filterTagsBelow: PropTypes.bool,

    /**
     * Pass desired icon
     */
    iconType: PropTypes.string,
  };

  static defaultProps = {
    hasSearch: true,
    defaultFilters: {},
    className: '',
    filterButtonClassName: '',
    filterTagsBelow: false,
    tagClassName: '',
    customTextApplyBtn: null,
    customTextCancelBtn: null,
    onChange: () => {},
    onSubmit: () => {},
    isSmallBtn: true,
    isLargeBtn: false,
    iconType: 'add-solid',
    tagsAfterFilterBtn: false,
    labelDefault: (
      <FormattedMessage
        defaultMessage="Viewing all records"
        description="Filter viewing all"
      />
    ),
    labelWithFilters: (
      <FormattedMessage
        defaultMessage="Viewing"
        description="Filter viewing filtered"
      />
    ),
    customBtnText: null,
    withIcon: true,
    withFilterTags: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopoverOpen: false,
      activeFilters: props.defaultFilters,
    };

    this.onPopoverToggle = this.onPopoverToggle.bind(this);
    this.onPopoverDismiss = this.onPopoverDismiss.bind(this);
    this.onTagDismiss = this.onTagDismiss.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.inputMap = {};
    this.mapInputs(props.children);
  }

  onPopoverToggle() {
    this.setState(({ isPopoverOpen }) => ({ isPopoverOpen: !isPopoverOpen }));
  }

  onPopoverDismiss() {
    this.setState({ isPopoverOpen: false });
  }

  /**
   * Handles updating activeFilters state when a filter is removed via FilterTags.jsx
   *
   * @param {string} key - object key to delete or update
   * @param {string} [arrayValue] - optional value to be deleted from an array
   */
  onTagDismiss(key, arrayValue) {
    const { activeFilters } = this.state;
    const updatedFilters = { ...activeFilters };

    // Handle array of values used by CheckboxGroup
    if (Array.isArray(updatedFilters[key])) {
      updatedFilters[key] = updatedFilters[key].filter(
        item => item !== arrayValue,
      );
    }
    // Handle all other simple string values
    else {
      delete updatedFilters[key];
    }

    this.setState({ activeFilters: updatedFilters });
    // This force-updates the popover form state
    this.setState({ isPopoverOpen: true }, () => {
      this.setState({ isPopoverOpen: false });
    });
    this.props.onSubmit(updatedFilters);
  }

  /**
   * Handles updating activeFilters when FilterForm is applied/submitted
   *
   * @param {obj} event - browser onSubmit event
   * @param {obj} updatedFilters - Form value data returned by Form component
   */
  onFormSubmit(event, updatedFilters) {
    event.preventDefault();
    this.setState({ activeFilters: updatedFilters, isPopoverOpen: false });
    this.props.onSubmit(updatedFilters);
  }

  /**
   * Create a lookup map of input names/values to their labels, so that
   * we display readable text in our filter tags instead of shorthand vars
   */
  mapInputs(children) {
    const { booleanInputs, optionsInputs, allInputs } = inputTypes;

    React.Children.forEach(children, child => {
      const componentType = getComponentName(child);
      const props = (child || {}).props || {};
      const { name, label, taxonomy, isRequired, children } = props;

      const isInput = allInputs.includes(componentType);
      if (!isInput) {
        if (children) this.mapInputs(children);
        return;
      }

      const isBoolean = booleanInputs.includes(componentType);
      const hasOptions = optionsInputs.includes(componentType);

      // For inputs with options, create a map of their child labels/text
      const options = {};
      if (hasOptions) {
        React.Children.forEach(children, option => {
          const { value, label, children } = option.props;
          options[value] = label || String(children);
        });
      }

      this.inputMap[name] = {
        label,
        taxonomy,
        isRequired,
        isBoolean,
        hasOptions,
        options,
      };
    });
  }

  render() {
    const {
      hasSearch,
      labelDefault,
      labelWithFilters,
      className,
      filterButtonClassName,
      tagClassName,
      filterTagsBelow,
      children,
      withIcon,
      withFilterTags,
      customBtnText,
      isSmallBtn,
      isLargeBtn,
      iconType,
      customTextApplyBtn,
      customTextCancelBtn,
      tagsAfterFilterBtn,
    } = this.props;
    const { activeFilters, isPopoverOpen } = this.state;
    const classes = classnames('Filter', className);

    return (
      <Fragment>
        <div className={classes}>
          {withFilterTags && !tagsAfterFilterBtn && (
            <FilterTags
              className={tagClassName}
              inputMap={this.inputMap}
              activeFilters={activeFilters}
              onTagDismiss={this.onTagDismiss}
              labelDefault={labelDefault}
              labelWithFilters={labelWithFilters}
            />
          )}
          <FilterPopover
            className={filterButtonClassName}
            isSmallBtn={isSmallBtn}
            isLargeBtn={isLargeBtn}
            withIcon={withIcon}
            iconType={iconType}
            customBtnText={customBtnText}
            isPopoverOpen={isPopoverOpen}
            togglePopover={this.onPopoverToggle}
            closePopover={this.onPopoverDismiss}
          >
            <FilterForm
              isSmallBtn={isSmallBtn}
              hasSearch={hasSearch}
              activeFilters={activeFilters}
              onFormSubmit={this.onFormSubmit}
              isClosed={!isPopoverOpen}
              onFormChange={(_, formState) => {
                this.props.onChange(formState);
              }}
              togglePopover={this.onPopoverToggle}
              customTextApplyBtn={customTextApplyBtn}
              customTextCancelBtn={customTextCancelBtn}
            >
              {children}
            </FilterForm>
          </FilterPopover>
        </div>
        {filterTagsBelow && <div className="w-full" />}
        {withFilterTags && tagsAfterFilterBtn && (
          <FilterTags
            className={tagClassName}
            inputMap={this.inputMap}
            activeFilters={activeFilters}
            onTagDismiss={this.onTagDismiss}
            labelDefault={labelDefault}
            labelWithFilters={labelWithFilters}
          />
        )}
      </Fragment>
    );
  }
}

export default Filter;
